import React, {useState} from 'react';
import {StyledProductsDetailsWrapper} from "./styles";
import {Button, Modal} from "@material-ui/core";
import axios from "axios";
import {toast} from "react-toastify";
import {DataGrid} from "@mui/x-data-grid";
import { productDetailsTableColumnsConfig } from '../../../utils/index'
import {getLocalStorageItem} from "../../../utils/localStorage";
import {StyledModalWrapper} from "../styles";
import config from "../../../config";
import {isEqual} from "lodash";

const ProductsDetail = ({ actionLogger, panelID }) => {

    const [products, setProducts] = useState([]);
    const [showAddProductForm, setShowAddProductForm] = useState(false);
    const [loading, setLoading] = useState(false);

    // Add products state
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState('');
    const [sellingPrice, setSellingPrice] = useState(0);
    const [url, setUrl] = useState('');

    // Edit state
    const [editedRows, setEditedRows] = useState({});

    const onGetProductsBtnClick = async () => {
        try {
            setLoading(true);
            const response = await axios.get(
                `${config.apiUrl}/admin/products`,
                {
                    headers: {
                        adminToken: getLocalStorageItem('adminToken'),
                    }
                }
            );

            const { success, msg, products } = response.data;

            if (!success) {
                toast.error(msg || 'Unable to fetch products');
                setLoading(false);
                return;
            }

            toast.success(msg || 'Fetched products successfully');
            setProducts(products);
        } catch (e) {
            const message = e?.response?.data?.message;
            toast.error(message || 'Unable to fetch products');
        }

        setLoading(false);
    }

    const onAddProductBtnClick = async () => {

        if (isNaN(sellingPrice)) {
            toast.error('Selling price must be a number');
            return;
        }

        if (Number(sellingPrice) < 1) {
            toast.error('Selling price must be greater than 1');
            return;
        }

        try {
            const payload = {
                name: name,
                description: description,
                category: category,
                sellingPrice: sellingPrice,
                url: url
            };

            setLoading(true);
            const response = await axios.post(
                `${config.apiUrl}/admin/products`,
                payload,
                {
                    headers: {
                        admintoken: getLocalStorageItem('adminToken'),
                    }
                }
            );

            const { success, msg } = response.data;

            if (!success) {
                toast.error(msg || 'Unable to add product');
                setLoading(false);
                return;
            }
            toast.success(msg || 'Add product successfully');
            // Fetch products again to get latest product as well
            refreshAddProductsForm();
            await onGetProductsBtnClick();
        } catch (e) {
            const message = e?.response?.data?.message;
            toast.error(message || 'Unable to add product');
        }
        setLoading(false);
    }

    const shouldAddProductsBtnDisabled = () => {
        return (
            !name ||
            !category ||
            !description ||
            !sellingPrice ||
            !url
        )
    }

    const shouldRefreshBtnDisabled = () => {
        return (
            !name &&
            !category &&
            !description &&
            !sellingPrice &&
            !url
        )
    }

    const refreshAddProductsForm = () => {
        setName('');
        setDescription('');
        setCategory('');
        setSellingPrice(0);
        setUrl('');
    }

    const getLoadingModalBody = () => {
        return (
            <StyledModalWrapper className='loading-modal-body'>
                <img src={'/images/loader.svg'} alt='Loader' />
            </StyledModalWrapper>
        )
    };

    const onEditProductDetailsRowStop = ({id, row}) => {
        // First get old row and compare it value by value with new row.
        // If there is any difference then show save info button otherwise not
        const oldRow = products.find(prd => prd.id === id);
        const isEdited = oldRow && !isEqual(oldRow, row);

        isEdited && setEditedRows(prevState => {
            return { ...prevState, [id]: row }
        })
    }

    const shouldShowSaveProductsInfoBtn = () => {
        const isNewProductsInfoEmpty = Object.keys(editedRows);
        return isNewProductsInfoEmpty.length > 0;
    }

    const onSaveProductsInfoButtonClick = async () => {
        setLoading(true);

        try {
            await Promise.all(Object.values(editedRows).map(async product => {
                const response = await axios.put(
                    `${config.apiUrl}/admin/products`,
                    product,
                    {
                        headers: {
                            admintoken: getLocalStorageItem('adminToken')
                        }
                    }
                );

                const { success, msg } = response.data;

                if(!success) {
                    toast.error(msg || 'Unable to update product info successfully');
                    return;
                }

                toast.success(msg ? msg : `Product info updated successfully`);
            }));

            setEditedRows({});
            await onGetProductsBtnClick();
        } catch (e) {
            console.log('[onSaveProductsInfoButtonClick]', e);
            toast.error('Unable to update products info. Try again');
        }
        setLoading(false);
    }

    return (
        <div className='section-container'>
            <h2>Products Details</h2>
            <StyledProductsDetailsWrapper>
                <div className='get-products-section'>
                    <Button
                        className='get-products-btn'
                        variant='contained'
                        color='primary'
                        onClick={onGetProductsBtnClick}
                    >
                        Get Products
                    </Button>
                    <DataGrid
                        className='products-data-grid'
                        editMode="row"
                        rows={products}
                        columns={productDetailsTableColumnsConfig()}
                        onRowEditStop={onEditProductDetailsRowStop}
                        loading={loading}
                        pageSize={25}
                        autoHeight
                    />
                    {
                        shouldShowSaveProductsInfoBtn() && (
                            <Button
                                className='save-products-info-button'
                                variant='contained'
                                color='primary'
                                onClick={() => onSaveProductsInfoButtonClick()}
                            >
                                Save Products Info
                            </Button>
                        )
                    }
                </div>

                <div className='add-products-section'>
                    <Button
                        className='show-add-products-form-btn'
                        variant='contained'
                        color='primary'
                        onClick={() => {
                            const currentStatus = showAddProductForm;
                            setShowAddProductForm(!currentStatus);
                        }}
                    >
                        {`${!showAddProductForm ? 'Show' : 'Hide'} Add Product Form`}
                    </Button>
                    {
                        showAddProductForm ? (
                            <div>

                                <div className='add-product-fields-wrapper'>
                                    <div>
                                        <h6>Product Name</h6>
                                        <input
                                            className='search-input'
                                            type='text'
                                            placeholder='Please enter name...'
                                            value={name}
                                            onChange={e => setName(e.target.value)}
                                        />
                                    </div>

                                    <div>
                                        <h6>Product Description</h6>
                                        <input
                                            className='search-input'
                                            type='text'
                                            placeholder='Please enter description...'
                                            value={description}
                                            onChange={e => setDescription(e.target.value)}
                                        />
                                    </div>

                                    <div>
                                        <h6>Product Category</h6>
                                        <input
                                            className='search-input'
                                            type='text'
                                            placeholder='Please enter category...'
                                            value={category}
                                            onChange={e => setCategory(e.target.value)}
                                        />
                                    </div>

                                    <div>
                                        <h6>Product Selling Price</h6>
                                        <input
                                            className='search-input'
                                            type='number'
                                            placeholder='Please enter selling price...'
                                            value={sellingPrice}
                                            onChange={e => setSellingPrice(e.target.value)}
                                            min={0}
                                        />
                                    </div>

                                    <div>
                                        <h6>Product URL - Enter Product Image URL by Google or any other source</h6>
                                        <input
                                            className='search-input'
                                            type='text'
                                            placeholder='Please image url...'
                                            value={url}
                                            onChange={e => setUrl(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className='action-button-wrapper'>
                                    <Button
                                        className='add-products-btn'
                                        variant="contained"
                                        color="primary"
                                        onClick={onAddProductBtnClick}
                                        disabled={shouldAddProductsBtnDisabled()}
                                    >
                                        Add Product
                                    </Button>

                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={refreshAddProductsForm}
                                        disabled={shouldRefreshBtnDisabled()}
                                    >
                                        Refresh
                                    </Button>
                                </div>
                            </div>
                        ) : <></>
                    }
                </div>

                {/*Loading Modal*/}
                {
                    <Modal
                        disablePortal
                        open={loading}
                        onClose={() => {}}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >
                        {getLoadingModalBody()}
                    </Modal>
                }

            </StyledProductsDetailsWrapper>

        </div>
    );
};

export default ProductsDetail;