import React, {useState} from 'react';
import { StyledTransferWalletsWrapper } from "./styles";
import {Button, Modal} from "@material-ui/core";
import axios from "axios";
import config from "../../../config";
import {getLocalStorageItem} from "../../../utils/localStorage";
import {toast} from "react-toastify";
import {DataGrid} from "@mui/x-data-grid";
import {airdropWalletsTableColumnsConfig, transferWalletsTableColumnsConfig} from "../../../utils";
import {StyledModalWrapper} from "../styles";

const TransferWallets = () => {

    const [senderUsername, setSenderUsername] = useState('');
    const [receiverUsername, setReceiverUsername] = useState('');
    const [senderWallets, setSenderWallets] = useState([]);
    const [receiverWallets, setReceiverWallets] = useState([]);

    const [selectedWallets, setSelectedWallets] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const onGetWalletsBtnClick = async isSender => {
        try {
            // Remove selected wallets if there are any because we are fetching wallets again for maybe a different user
            isSender && setSelectedWallets([]);

            setIsLoading(true);
            const response = await axios.get(
             `${config.apiUrl}/admin/user/wallets/${isSender ? senderUsername : receiverUsername}`,
             { headers: { admintoken: getLocalStorageItem('adminToken') } }
         );

         const { success, msg, wallets } = response.data;

         if (!success) {
             toast.error(msg || `Unable to fetch wallets for user ${isSender ? senderUsername : receiverUsername}. Please check network tab.`);
             setIsLoading(false);
             return;
         }

         toast.success(msg || 'Fetched wallets successfully');
         if (isSender) {
             setSenderWallets(wallets);
         } else {
             setReceiverWallets(wallets);
         }
        } catch (e) {
            const msg = e?.response?.data?.message;
            toast.error(msg || `Unable to fetch wallets for user ${isSender ? senderUsername : receiverUsername}. Please check network tab.`);
        }

        setIsLoading(false);
    }

    const onTransferWalletsBtnClick = async () => {
        const selectedWalletsIds = selectedWallets.map(sw => sw.account_id);

        if (!Array.isArray(selectedWalletsIds) || !selectedWalletsIds.length) {
            toast.error('No wallets selected');
            return;
        }

        try {
            const payload = {
                wallets: selectedWalletsIds,
                receiverUsername: receiverUsername
            };

            const response = await axios.put(
                `${config.apiUrl}/admin/wallets`,
                payload,
                { headers: { admintoken: getLocalStorageItem('adminToken') } }
            );

            const { success, msg } = response.data;

            if (!success) {
                toast.error(msg);
                return;
            }

            toast.success(msg);
            // Get updated sender wallets
            await onGetWalletsBtnClick(true);
            // Get updated receiver wallets
            await onGetWalletsBtnClick();
        } catch (e) {

        }
    }

    const getLoadingModalBody = () => {
        return (
            <StyledModalWrapper className='loading-modal-body'>
                <img src={'/images/loader.svg'} alt='Loader' />
            </StyledModalWrapper>
        )
    };

    return (
        <div className='section-container'>
            <h2>Transfer Wallets</h2>
            <StyledTransferWalletsWrapper>
                <div className='sender-username-form'>
                    <div>
                        <h6>Sender Username</h6>
                        <input
                            className='username-input'
                            type='text'
                            placeholder='Please enter username of sender...'
                            value={senderUsername}
                            onChange={e => setSenderUsername(e.target.value)}
                        />
                    </div>

                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => onGetWalletsBtnClick(true)}
                            disabled={!senderUsername}
                        >
                            Get Wallets
                        </Button>
                    </div>
                </div>

                <div className='wallets-container'>
                    <p>Number of sender's wallets: {senderWallets.length}</p>
                    <div className='data-grid-wrapper'>
                        <DataGrid
                            className='wallets-data-grid'
                            rows={senderWallets}
                            columns={transferWalletsTableColumnsConfig()}
                            pageSize={100}
                            autoHeight
                            checkboxSelection
                            onSelectionModelChange={(ids) => {
                                setSelectedWallets(senderWallets.filter(wallet => ids.includes(wallet.id)));
                            }}
                        />
                    </div>
                </div>

                <div className='receiver-username-form'>
                    <div>
                        <h6>Receiver Username</h6>
                        <input
                            className='username-input'
                            type='text'
                            placeholder='Please enter username of receiver...'
                            value={receiverUsername}
                            onChange={e => setReceiverUsername(e.target.value)}
                        />
                    </div>

                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => onGetWalletsBtnClick(false)}
                            disabled={!receiverUsername}
                        >
                            Get Wallets
                        </Button>
                    </div>
                </div>

                <div className='wallets-container'>
                    <p>Number of receiver's wallets: {receiverWallets.length}</p>
                    <div className='data-grid-wrapper'>
                        <DataGrid
                            className='wallets-data-grid'
                            rows={receiverWallets}
                            columns={transferWalletsTableColumnsConfig()}
                            pageSize={100}
                            autoHeight
                        />
                    </div>

                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onTransferWalletsBtnClick}
                            disabled={
                                !(Array.isArray(selectedWallets) && selectedWallets.length) ||
                                !senderUsername ||
                                !receiverUsername
                            }
                        >
                            Transfer Wallets
                        </Button>
                    </div>
                </div>
            </StyledTransferWalletsWrapper>
            {/*Loading Modal*/}
            {
                <Modal
                    disablePortal
                    open={isLoading}
                    onClose={() => {}}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {getLoadingModalBody()}
                </Modal>
            }
        </div>
    );
};

export default TransferWallets;