import styled from 'styled-components';

export const StyledProductsDetailsWrapper = styled.div`
    .get-products-section {
      width: 90vw;
      
      .get-products-btn {
        margin-bottom: 10px;
      }
      
      .product-img {
        width: 100px;
        height: 100px;
        object-fit: contain;
      }
    }
  
    .add-products-section {
      width: 90vw;
      
      .show-add-products-form-btn {
        margin-top: 10px;
        margin-bottom: 10px;
      }
      
      .action-button-wrapper {
        margin-top: 10px;
        
        .add-products-btn {
          margin-right: 10px;
        }
      }
    }
`
