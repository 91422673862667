import styled from "styled-components";

export const StyledEodRewardInfoWrapper = styled.div`

  .search-input-fields-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    div {
      margin-right: 10px;
      margin-bottom: 10px;
    }
    
    .react-datepicker-wrapper {
      margin: 0;
      
      .react-datepicker__input-container {
        margin: 0;
      }
    }
    
    .react-datepicker__month-container {
      margin-right: 0;
      margin-bottom: 0;
      
      .react-datepicker__header {
        margin: 0;
      }
      
      .react-datepicker__month {
        margin: 0;
      }
    }
  }

  .search-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      margin-right: 10px;
    }
  }

  .data-grid-wrapper {
    margin-top: 10px;
    
    .missed-reward-row {
      background-color: indianred;
      color: white;
      
      :hover {
        background-color: indianred;
      }
      
      &.MuiDataGrid-row.Mui-selected {
        background-color: red;
        color: white;
      }
    }
    
    .compensated-reward-row {
      background-color: green;
      color: white;

      :hover {
        background-color: green;
      }

      &.MuiDataGrid-row.Mui-selected {
        background-color: green;
        color: white;
      }
    }
  }

  .MuiTypography-body1 {
    color: white;
    font-weight: bold;
  }
  
  .stats-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 10px;
    background-color: white;
  }
  
  .wallet-password-input-box {
    width: 100%;
    background-color: #ffffff;
    font-size: 15px;
    line-height: 40px;
    height: 46px;
    max-width: 100%;
    vertical-align: middle;
    padding: 2px 15px;
    border: 1px solid #ebebeb;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-bottom: 10px;
  } 
  
  .wallet-password-time-input-box {
    margin-bottom: 10px;
  }
  
  .password-remember-time-div-wrapper {
    h6 {
      text-align: start;
      font-weight: bold;
    }
  }
`;
