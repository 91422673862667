import React, {useEffect, useState} from 'react';
import { StyledLoginPageWrapper } from "./styles";
import { Button } from "@material-ui/core";
import { NuCoinAdmin } from "../../panels";
import {toast} from "react-toastify";
import config from "../../config";
import axios from "axios";
import {encrypt} from "../../utils";
import {getLocalStorageItem, setLocalStorageItem} from "../../utils/localStorage";

const Login = () => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const isAdminTokenAvailable = getLocalStorageItem('adminToken');
        const isAlreadyLoggedIn = getLocalStorageItem('isAdminLoggedIn');
        isAdminTokenAvailable && isAlreadyLoggedIn && setIsLoggedIn(true);
    }, []);

    const onLoginBtnClick = async event => {
        event.preventDefault();

        try {
            const payload = {
                username: username,
                password: password
            };
            const response = await axios.post(
                `${config.apiUrl}/admin/login`,
                encrypt(payload)
            );

            const { success, message, token } = response.data;

            if (!success) {
                toast.error(message || 'Invalid login credentials');
                return;
            }

            toast.success(message || 'Logged in successfully');
            setIsLoggedIn(true);
            setLocalStorageItem('adminToken', token);
            setLocalStorageItem('adminPanelUsername', username);
            setLocalStorageItem('isAdminLoggedIn', true);
        } catch (e) {
            console.log('Something went wrong while logging in', e);
            const message = e?.response?.data?.message;
            toast.error(message || 'Something went wrong while logging in. Please try again');
        }
    }

    if (isLoggedIn) {
        return <NuCoinAdmin />
    }

    return (
        <StyledLoginPageWrapper>
            <div className='login-form'>

                {/*Username*/}
                <div>
                    <h6>Username</h6>
                    <input
                        className='username-input'
                        type='text'
                        placeholder='Please enter username to login...'
                        value={username}
                        onChange={e => setUsername(e.target.value)}
                    />
                </div>

                {/*Password*/}
                <div>
                    <h6>Password</h6>
                    <input
                        className='password-input'
                        type='password'
                        placeholder='Please enter password to login...'
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                </div>

                <div>
                    <Button
                        className='login-button'
                        variant='contained'
                        color='primary'
                        onClick={onLoginBtnClick}
                        disabled={!username || !password}
                    >
                        Login
                    </Button>
                </div>
            </div>
        </StyledLoginPageWrapper>
    );
};

export default Login;