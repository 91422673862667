import React, { useCallback, useEffect, useState } from 'react';
import { StyledCreateWalletWrapper } from "./styles";
import { createWalletsTableConfig, customerBasicInfoTableColumnsConfigForUserInfoPanel, businessDetailTableConfig } from "../../../utils";
import { DataGrid } from "@mui/x-data-grid";
import config from "../../../config";
import { getLocalStorageItem } from "../../../utils/localStorage";
import { Button, FormControl, FormControlLabel, FormLabel, Modal, Radio, RadioGroup } from "@material-ui/core";
import { toast } from "react-toastify";
import axios from "axios";
import { StyledModalWrapper } from "../styles";
import Swal from 'sweetalert2'

const CryptoJS = require("crypto-js");

const BusinessDetail = () => {

    const [loadingRowId, setLoadingRowId] = useState(null);
    const [businessList, setBusinessList] = useState([]);
    const [isLoading, setLoading] = useState(false);

    let handleButtonClickDelete = async (rowId, status) => {

        Swal.fire({
            title: 'Submit the reason of rejection',
            input: 'text',
            
            inputAttributes: {
                autocapitalize: 'off',
                className: 'swal-custom-input'
            },
            showCancelButton: true,
            confirmButtonText: 'Continue',
            showLoaderOnConfirm: true,
            preConfirm: (reason) => {

                handleButtonClick(rowId, status, reason)

            },

        })
    }



    const handleButtonClick = async (rowId, status, reason) => {

        try {
            setLoadingRowId(rowId.id + status);

            await axios.post(
                `${config.multicoinUrl}/coin/update-business-status`,
                { username: rowId.user_name, status: status, id: rowId.id, reason: reason }, // If you don't need to send a request body, use null or an empty object

                {
                    headers: {
                        admintoken: getLocalStorageItem('adminToken')
                    }
                }
            );
            fetchBusinessDetailList()
            setLoadingRowId(null)
            if (status === "APPROVED") {
                toast.success("Successfully verified the request.")
            }
            else {
                toast.success("Successfully rejected the request.")
            }

        }
        catch (err) {
            console.log("error", err)

            setLoadingRowId(null)
            let errorMessage = "We are facing some unexpected issue, Please try again later";
            if (err.message === "Network Error") {
                errorMessage = "We are facing some server issue, Please check your internet";
            } else if (err?.response?.data) {
                errorMessage = err?.response?.data?.errors[0]?.message;
            }

            toast.error(errorMessage);
        }




    }
    const fetchBusinessDetailList = async () => {
        try {
            // setLoading(true);
            const response = await axios.get(
                `${config.apiUrl}/admin/multcoin/businessList`,
                { headers: { admintoken: getLocalStorageItem('adminToken') } }
            );
            console.log("response abc", response.data, `${config.apiUrl}/admin/multcoin/businessList`)

            const { success, business } = response.data;

            if (!success) {
                setLoading(false);
                return;
            }
            setBusinessList(business)
            // setLoading(false);
        } catch (e) {
            // setLoading(false);
            toast.error('Fail to fetch business detail');
            console.log('[fetchManuallyCreatedWallets]', e);
        }
    };


    useEffect(() => {
        fetchBusinessDetailList();
    }, []);










    const getLoadingModalBody = () => {
        return (
            <StyledModalWrapper className='loading-modal-body'>
                <img src={'/images/loader.svg'} alt='Loader' />
            </StyledModalWrapper>
        )
    };





    return (
        <div className='section-container'>
            <h2>Business Verification</h2>

            <StyledCreateWalletWrapper>




                <div className='wallets-table-section'>

                    <DataGrid
                        className='wallets-data-table'
                        rows={businessList}
                        columns={businessDetailTableConfig(handleButtonClick, loadingRowId, handleButtonClickDelete)}

                    />
                </div>
            </StyledCreateWalletWrapper>

            {
                <Modal
                    disablePortal
                    open={isLoading}
                    onClose={() => { }}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {getLoadingModalBody()}
                </Modal>
            }
        </div>
    );
};

export default BusinessDetail;