import React, {useState} from 'react';
import {StyledCustomerWrapper} from "./styles";
import { Button, CircularProgress } from "@material-ui/core";
import {customerInfoNonEditablePanelColumnsConfig, isFunction} from "../../../utils";
import {DataGrid} from "@mui/x-data-grid";
import {toast} from "react-toastify";
import axios from "axios";
import config from "../../../config";
import {getLocalStorageItem} from "../../../utils/localStorage";

const CustomerInformation = ({ actionLogger, panelID }) => {
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [country, setCountry] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [gender, setGender] = useState('');
  const [email, setEmail] = useState('');
  const [accountStatus, setAccountStatus] = useState('');
  const [isVerified, setIsVerified] = useState('');
  const [minerFeesReceived, setMinerFeesReceived] = useState('');
  const [kycStatus, setKycStatus] = useState('');
  const [platformMinerCategory, setPlatformMinerCategory] = useState('');
  const [showUsersData, setShowUsersData] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const handleSearchUserButtonClick = async () => {
    setLoading(true);
    setShowUsersData(false);
    setUsersData([]);
    try {
      const payload = {
        username: username,
        firstName: firstName,
        lastName: lastName,
        middleName: middleName,
        country: country,
        mobileNumber: mobileNumber,
        gender: gender,
        email: email,
        accountStatus: accountStatus,
        isVerified: isVerified,
        minerFeesReceived: minerFeesReceived,
        platformMinerCategory: platformMinerCategory,
        kycStatus: kycStatus,
      }
      const response = await axios.post(
        `${config.apiUrl}/admin/users/search`,
        payload,
        {
          headers: {
            admintoken: getLocalStorageItem('adminToken'),
            othertoken: getLocalStorageItem('otherToken')
          }
        }
      );
      const { success, msg, users } = response.data;

      if (!success) {
        toast.error(msg || 'Unable to find users');
        setLoading(false);
        return;
      }

      toast.dismiss();

      if (users && Array.isArray(users) && users.length > 0) {
        toast.success(msg || 'Fetched users successfully');
        isFunction(actionLogger) && await actionLogger(panelID, `Searched users with payload ${JSON.stringify(payload)}`);
        setUsersData(users);
        setShowUsersData(true);
      } else {
        toast.error('Zero records found for search criteria');
      }
    } catch (e) {
      toast.error('Unable to find user');
      console.log('[handleSearchUserButtonClick]', e);
    }

    setLoading(false);
  }

  const shouldSearchDisabled = () => {
    return !(
      username ||
      firstName ||
      lastName ||
      middleName ||
      country ||
      mobileNumber ||
      gender ||
      email ||
      accountStatus ||
      isVerified ||
      minerFeesReceived ||
      platformMinerCategory ||
      kycStatus
    )
  }

  const resetFields = () => {
    setUsername('');
    setFirstName('');
    setLastName('');
    setMiddleName('');
    setCountry('');
    setMobileNumber('');
    setGender('');
    setEmail('');
    setAccountStatus('');
    setIsVerified('');
    setMinerFeesReceived('');
    setKycStatus('');
    setPlatformMinerCategory('');
    setShowUsersData(false);
    setUsersData([]);
    setLoading(false);
  }

  if (isLoading) {
    return (
      <div className='section-container'>
        <CircularProgress />
      </div>
    )
  }

  return (
    <div className='section-container'>
      <h2>Customer Information</h2>
      <h6>(Wildcards can be used in search boxes, for example: % or __ )</h6>
      <StyledCustomerWrapper>
        <div className='search-user-input-fields-wrapper'>
          <div>
            <h6>Username</h6>
            <input
              className='search-input'
              type='text'
              placeholder='Please enter user name...'
              value={username}
              onChange={e => setUsername(e.target.value)}
            />
          </div>

          <div>
            <h6>First Name</h6>
            <input
              className='search-input'
              type='text'
              placeholder='Please enter first name...'
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
            />
          </div>

          <div>
            <h6>Middle Name</h6>
            <input
              className='search-input'
              type='text'
              placeholder='Please enter middle name...'
              value={middleName}
              onChange={e => setMiddleName(e.target.value)}
            />
          </div>

          <div>
            <h6>Last Name</h6>
            <input
              className='search-input'
              type='text'
              placeholder='Please enter last name...'
              value={lastName}
              onChange={e => setLastName(e.target.value)}
            />
          </div>

          <div>
            <h6>County</h6>
            <input
              className='search-input'
              type='text'
              placeholder='Please enter country name...'
              value={country}
              onChange={e => setCountry(e.target.value)}
            />
          </div>

          <div>
            <h6>Mobile Number</h6>
            <input
              className='search-input'
              type='text'
              placeholder='Please enter mobile number...'
              value={mobileNumber}
              onChange={e => setMobileNumber(e.target.value)}
            />
          </div>

          <div>
            <h6>Gender</h6>
            <input
              className='search-input'
              type='text'
              placeholder='Please enter gender...'
              value={gender}
              onChange={e => setGender(e.target.value)}
            />
          </div>

          <div>
            <h6>Email</h6>
            <input
              className='search-input'
              type='text'
              placeholder='Please enter email...'
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </div>

          <div>
            <h6>Account Status</h6>
            <input
              className='search-input'
              type='text'
              placeholder='Please enter account status...'
              value={accountStatus}
              onChange={e => setAccountStatus(e.target.value)}
            />
          </div>

          <div>
            <h6>Is Verified?</h6>
            <input
              className='search-input'
              type='text'
              placeholder='Please enter is verified...'
              value={isVerified}
              onChange={e => setIsVerified(e.target.value)}
            />
          </div>

          <div>
            <h6>Miner Fees received</h6>
            <input
              className='search-input'
              type='text'
              placeholder='Please enter miner fees status (0 or 1)'
              value={minerFeesReceived}
              onChange={e => setMinerFeesReceived(e.target.value)}
            />
          </div>

          <div>
            <h6>Platform Miner Category</h6>
            <input
              className='search-input'
              type='text'
              placeholder='Please enter platform miner category...'
              value={platformMinerCategory}
              onChange={e => setPlatformMinerCategory(e.target.value)}
            />
          </div>

          <div>
            <h6>KYC Status</h6>
            <input
                className='search-input'
                type='text'
                placeholder='Please enter KYC Status'
                value={kycStatus}
                onChange={e => setKycStatus(e.target.value)}
            />
          </div>

        </div>
        <div className='search-button-wrapper'>
          <Button
            variant='contained'
            color='primary'
            onClick={() => handleSearchUserButtonClick()}
            disabled={shouldSearchDisabled()}
          >
            Search
          </Button>
          <Button
            variant='contained'
            color='secondary'
            onClick={resetFields}
            disabled={shouldSearchDisabled()}
          >
            Refresh
          </Button>
        </div>
        {
          showUsersData && (
            <div className='data-grid-wrapper'>
              <DataGrid
                className='data-grid'
                rows={usersData}
                columns={customerInfoNonEditablePanelColumnsConfig()}
              />
            </div>
          )
        }
      </StyledCustomerWrapper>
    </div>
  );
};

export default CustomerInformation;
