import React, { useState } from 'react';
import { StyledRegisterUserWrapper } from "./styles";
import { Button, Modal } from "@material-ui/core";
import { toast } from "react-toastify";
import { encrypt } from "../../../utils";
import config from "../../../config";
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import { getLocalStorageItem } from "../../../utils/localStorage";
import { StyledPaymentCardDetailsWrapper } from "../payment-card-details/styles";
import { StyledModalWrapper } from "../styles";
import axios from "axios";
const CryptoJS = require("crypto-js");
let passportFile


const RegisterUser = () => {
    const [username, setUsername] = useState('');
    const [firstName, setFirstName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [dob, setDOB] = useState('');
    const [gender, setGender] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [email, setEmail] = useState('');
    const [country, setCountry] = useState('');

    const [passportImage, setPassportImage] = useState('');
    const [photoIDImage, setPotoIDImage] = useState('');
    const [selfieImage, setSelfieImage] = useState('');
    const [webcamImage, setWebcamImage] = useState('');
    const [licenseImage, setLicenseImage] = useState('');
    const [pin, setPin] = useState('');

    const [kycError, setKycError] = useState(false);
    const [showPin, setShowPin] = useState(false);

    const [loading, setLoading] = useState(false);

    const onRegisterUserBtnClick = async () => {




        const isValid = isFormValid();

        if (!isValid) return;

        let passwordEncrypt = CryptoJS.SHA256(password).toString(CryptoJS.enc.Hex);

        const body = new FormData()
        body.append('username', username)
        body.append('firstName', firstName)
        body.append('middleName', middleName)
        body.append('lastName', lastName)
        body.append('passwordEncrypt', passwordEncrypt)
        body.append('password', password)
        body.append('dob', dob)
        body.append('gender', gender)
        body.append('mobileNumber', mobileNumber)
        body.append('email', email)
        body.append('country', country)
        body.append("file", webcamImage)

        try {
            setShowPin(false)
            setLoading(true);


            const response = await axios.post(
                `${config.apiUrl}/admin/register`, body,
                {
                    headers: {
                        admintoken: getLocalStorageItem('adminToken'),
                        'Content-Type': 'application/x-www-form-urlencoded'

                    }
                }
            );

            const { success, msg } = response.data;

            if (!success) {
                toast.error(msg || 'Unable to register user');
                setLoading(false);
                return;
            }

            //upload kyc documents


            if (passportImage) {
                try {

                    const bodyPassport = new FormData()
                    bodyPassport.append('file', passportImage)
                    bodyPassport.append('email', username)
                    bodyPassport.append('document_type', "passport")
                    bodyPassport.append('kyc_toggle_flag', 0)
                    let checkDocumentQualityResponse = await axios.post(`${config.apiUrlAIIdentity}/predict_document_quality`, bodyPassport);
                    if (checkDocumentQualityResponse.data.valid === false) {
                        return toast.error("Passport Image is not good quality")
                    }
                    if (checkDocumentQualityResponse.data.flag === true) {
                        bodyPassport.append('document_path', checkDocumentQualityResponse.data.document_path);
                        bodyPassport.append('passport_channel_source', 'NU COIN APP');
                    }
                    await axios.post(`${config.apiUrlAIIdentity}/validate_passport`, bodyPassport);

                }

                catch (e) {
                    setKycError(true)

                }
            }

            if (licenseImage) {
               
                try {

                    const bodyLicense = new FormData()
                    bodyLicense.append('file', licenseImage)
                    bodyLicense.append('email', username)
                    bodyLicense.append('document_type', "driver_license")
                    bodyLicense.append('kyc_toggle_flag', 0)
                    let checkDocumentQualityResponse = await axios.post(`${config.apiUrlAIIdentity}/predict_document_quality`, bodyLicense);
                    if (checkDocumentQualityResponse.data.valid === false) {
                        return toast.error("Passport Image is not good quality")
                    }
                    if (checkDocumentQualityResponse.data.flag === true) {
                        bodyLicense.append('document_path', checkDocumentQualityResponse.data.document_path);
                        bodyLicense.append('driver_lic_channel_source', 'NU COIN APP');
                    }
                    await axios.post(`${config.apiUrlAIIdentity}/validate_driver_license`, bodyLicense);
                }

                catch (e) {
                    setKycError(true)

                }
            }

            if (photoIDImage) {
                try {

                    const bodyPhotoID = new FormData()
                    bodyPhotoID.append('file', photoIDImage)
                    bodyPhotoID.append('email', username)
                    bodyPhotoID.append('document_type', "photo_id")
                    bodyPhotoID.append('kyc_toggle_flag', 0)
                    let checkDocumentQualityResponse1 = await axios.post(`${config.apiUrlAIIdentity}/predict_document_quality`, bodyPhotoID);
                    if (checkDocumentQualityResponse1.data.valid === false) {
                        return toast.error(" photo id Image is not good quality")
                    }
                    if (checkDocumentQualityResponse1.data.flag === true) {
                        bodyPhotoID.append('document_path', checkDocumentQualityResponse1.data.document_path);
                        bodyPhotoID.append('photo_id_channel_source', 'NU COIN APP');
                    }

                    await axios.post(`${config.apiUrlAIIdentity}/validate_photo_id`, bodyPhotoID);

                }

                catch (e) {
                    setKycError(true)

                }
            }
            if (selfieImage) {
                try {

                    const body = new FormData()
                    body.append('file', selfieImage)
                    body.append('email', username)
                    body.append('document_type', "selfie")
                    body.append('kyc_toggle_flag', 0)
                    //  upload passport
                    body.append('selfie_doc_channel_source', 'NU COIN APP');
                    await axios.post(`${config.apiUrlAIIdentity}/predict_selfie`, body);
                }

                catch (e) {
                    setKycError(true)

                }
            }




            toast.success(msg || 'Registered User Successfully');
            setShowPin(true)
            setPin(response.data.pinCode)
            // resetForm();
        } catch (e) {
            setLoading(false);
            const message = e?.response?.data?.msg;
            toast.error(message || 'Unable to register user');
            return
        }

        setLoading(false);
    }


    const copyPin = async () => {
        const el = document.createElement('textarea');
        el.value = pin
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        toast.success("You successfully copy the pin code!")
    }



    const handleChangeSelfie = ({ ...meta }, status, file) => {

        if (status === 'rejected_file_type') {
            // alert("File type not accepted");
            toast.error("File type not accepted")
        }
        if (status === 'removed') {

            setSelfieImage("")
        }
    }

    const handleChangeStatusWebCam = ({ ...meta }, status, file) => {

        if (status === 'rejected_file_type') {
            // alert("File type not accepted");
            toast.error("File type not accepted")
        }
        if (status === 'removed') {

            setWebcamImage("")

        }
    }
    const handleChangeStatusPassport = ({ ...meta }, status, file) => {

        if (status === 'rejected_file_type') {
            // alert("File type not accepted");
            toast.error("File type not accepted")
        }
        if (status === 'removed') {

            setPassportImage("")

        }
    }
    const handleChangeStatusPhotID = ({ ...meta }, status, file) => {

        if (status === 'rejected_file_type') {
            // alert("File type not accepted");
            toast.error("File type not accepted")
        }
        if (status === 'removed') {

            setPotoIDImage("")

        }
    }


    const handleChangeLicense = ({ ...meta }, status, file) => {

        if (status === 'rejected_file_type') {
            // alert("File type not accepted");
            toast.error("File type not accepted")
        }
        if (status === 'removed') {

            setLicenseImage("")

        }
    }




    const uploadPassport = async (file) => {
        setPassportImage(file.file)
        return { url: "" }
    }
    const uploadPhotoID = async (file) => {
        setPotoIDImage(file.file)
        return { url: "" }
    }
    const uploadSelfie = async (file) => {
        setSelfieImage(file.file)
        return { url: "" }
    }
    const uploadWebCam = async (file) => {
        setWebcamImage(file.file)
        return { url: "" }
    }

    const uploadLicense = async (file) => {
        setLicenseImage(file.file)
        return { url: "" }
    }


    const isFormValid = () => {
        if (!username) {
            toast.error('Username is required');
            return;
        }

        if (!firstName) {
            toast.error('First Name is required');
            return;
        }

        if (!lastName) {
            toast.error('Last Name is required');
            return;
        }

        if (!password) {
            toast.error('Password is required');
            return;
        }

        if (!confirmPassword) {
            toast.error('Confirm password is required');
            return;
        }

        if (password !== confirmPassword) {
            toast.error('Both passwords should match');
            return;
        }

        if (!dob) {
            toast.error('Date of birth is required');
            return;
        }

        if (!gender) {
            toast.error('Gender is required');
            return;
        }

        if (!["M", "F"].includes(gender)) {
            toast.error('Gender can only be M or F');
            return;
        }

        if (!mobileNumber) {
            toast.error('Mobile Number is required');
            return;
        }

        if (!email) {
            toast.error('Email is required');
            return;
        }

        if (!email.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )) {
            toast.error('Email not valid');
            return;
        }

        return true;
    }

    const resetForm = () => {
        setUsername('');
        setFirstName('');
        setMiddleName('');
        setLastName('');
        setPassword('');
        setConfirmPassword('');
        setDOB('');
        setGender('');
        setMobileNumber('');
        setEmail('');
        setCountry('');
        setKycError(false)
        setShowPin(false)
    }

    const shouldRegisterBtnDisabled = () => {
        return (
            !username ||
            !firstName ||
            !lastName ||
            !password ||
            !confirmPassword ||
            !dob ||
            !gender ||
            !mobileNumber ||
            !email ||
            // !passportImage ||
            // !photoIDImage ||
            // !selfieImage ||
            !webcamImage
        );
    }

    const shouldRefreshBtnDisabled = () => {
        return (
            !username &&
            !firstName &&
            !middleName &&
            !lastName &&
            !password &&
            !confirmPassword &&
            !dob &&
            !gender &&
            !mobileNumber &&
            !email &&
            !country
        );
    }

    const getLoadingModalBody = () => {
        return (
            <StyledModalWrapper className='loading-modal-body'>
                <img src={'/images/loader.svg'} alt='Loader' />
            </StyledModalWrapper>
        )
    };

    return (
        <div className='section-container'>
            <h2>Register User</h2>
            <StyledRegisterUserWrapper>
                <div className='register-user-fields-wrapper'>
                    <div>
                        <h6>User Name</h6>
                        <input
                            className='search-input'
                            type='text'
                            placeholder='Please enter Username...'
                            value={username}
                            onChange={e => setUsername(e.target.value)}
                            required
                        />
                    </div>

                    <div>
                        <h6>First Name</h6>
                        <input
                            className='search-input'
                            type='text'
                            placeholder='Please enter first name...'
                            value={firstName}
                            onChange={e => setFirstName(e.target.value)}
                            required
                        />
                    </div>

                    <div>
                        <h6>Middle Name</h6>
                        <input
                            className='search-input'
                            type='text'
                            placeholder='Please enter middle name...'
                            value={middleName}
                            onChange={e => setMiddleName(e.target.value)}
                        />
                    </div>

                    <div>
                        <h6>Last Name</h6>
                        <input
                            className='search-input'
                            type='text'
                            placeholder='Please enter last name...'
                            value={lastName}
                            onChange={e => setLastName(e.target.value)}
                            required
                        />
                    </div>

                    <div>
                        <h6>Date of Birth</h6>
                        <input
                            className='search-input'
                            type='date'
                            placeholder='Please enter date of birth...'
                            value={dob}
                            onChange={e => setDOB(e.target.value)}
                            required
                        />
                    </div>

                    <div>
                        <h6>Gender</h6>
                        <input
                            className='search-input'
                            type='text'
                            placeholder='Please enter gender...'
                            value={gender}
                            onChange={e => setGender(e.target.value)}
                            required
                        />
                    </div>

                    <div>
                        <h6>Mobile Number</h6>
                        <input
                            className='search-input'
                            type='text'
                            placeholder='Please enter mobile number...'
                            value={mobileNumber}
                            onChange={e => setMobileNumber(e.target.value)}
                            required
                        />
                    </div>

                    <div>
                        <h6>Email</h6>
                        <input
                            className='search-input'
                            type='email'
                            placeholder='Please enter email...'
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            required
                        />
                    </div>

                    <div>
                        <h6>Password</h6>
                        <input
                            className='search-input'
                            type='text'
                            placeholder='Please enter password...'
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            required
                        />
                    </div>

                    <div>
                        <h6>Confirm Password</h6>
                        <input
                            className='search-input'
                            type='text'
                            placeholder='Please enter password again...'
                            value={confirmPassword}
                            onChange={e => setConfirmPassword(e.target.value)}
                            required
                        />
                    </div>

                    <div>
                        <h6>Country</h6>
                        <input
                            className='search-input'
                            type='text'
                            placeholder='Please enter country...'
                            value={country}
                            onChange={e => setCountry(e.target.value)}
                        />
                    </div>





                </div>

                <div style={{ display: "flex", flexDirection: "row" }}>


                    <div style={{ flex: 1 }}>
                        <p>Store Register Image *</p>
                        <Dropzone
                            styles={{ dropzone: { minHeight: 250, maxHeight: 200 } }}
                            onChangeStatus={handleChangeStatusWebCam}
                            getUploadParams={uploadWebCam}
                            accept="image/*"
                        ></Dropzone>
                    </div>

                    <div style={{ flex: 1 }}>
                        <p>Passport Image (Optional)</p>
                        <Dropzone
                            styles={{ dropzone: { minHeight: 250, maxHeight: 250, } }}
                            onChangeStatus={handleChangeStatusPassport}
                            getUploadParams={uploadPassport}
                            disabled={false}

                            accept="image/*"
                        ></Dropzone>
                    </div>

                    <div style={{ flex: 1 }}>
                        <p>Driver License (Optional)</p>
                        <Dropzone
                            styles={{ dropzone: { minHeight: 250, maxHeight: 200 } }}
                            onChangeStatus={handleChangeLicense}
                            getUploadParams={uploadLicense}
                            accept="image/*"
                        ></Dropzone>
                    </div>


                    <div style={{ flex: 1 }}>
                        <p>Photo ID (Optional)</p>
                        <Dropzone
                            styles={{ dropzone: { minHeight: 250, maxHeight: 200 } }}
                            onChangeStatus={handleChangeStatusPhotID}
                            getUploadParams={uploadPhotoID}
                            accept="image/*"
                        ></Dropzone>
                    </div>
                    <div style={{ flex: 1 }}>
                        <p>Selfie Image (Optional)</p>
                        <Dropzone
                            styles={{ dropzone: { minHeight: 250, maxHeight: 200 } }}
                            onChangeStatus={handleChangeSelfie}
                            getUploadParams={uploadSelfie}
                            accept="image/*"
                        ></Dropzone>
                    </div>



                </div>

                {
                    showPin === true ? <div style={{ marginTop: 20, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row", }}>
                        <div style={{ display: "flex", height: 50, justifyContent: "center" }} className="alert alert-success" role="alert">
                            <p>user pin code: <span style={{ fontWeight: "bold" }}>{pin}</span></p>
                            <i onClick={copyPin} style={{ marginLeft: 20, marginTop: 4, cursor: "pointer" }} class="fas fa-copy"></i>
                        </div>

                    </div> : ""
                }

                <div style={{ marginTop: 30 }} className='action-button-wrapper'>
                    <Button
                        className='register-user-btn'
                        variant="contained"
                        color="primary"
                        onClick={onRegisterUserBtnClick}
                        disabled={shouldRegisterBtnDisabled()}
                    >
                        Register
                    </Button>

                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={resetForm}
                        disabled={shouldRefreshBtnDisabled()}
                    >
                        Refresh
                    </Button>
                </div>

                {/*Loading Modal*/}
                {
                    <Modal
                        disablePortal
                        open={loading}
                        onClose={() => { }}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >
                        {getLoadingModalBody()}
                    </Modal>
                }
            </StyledRegisterUserWrapper>
        </div>
    );
};

export default RegisterUser;