import styled from "styled-components";

export const StyledWalletInfoWrapper = styled.div`
  width: 100%;
 
  .form-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
   

    
    .search-input {
      width: 100%;
      margin-right: 10px;
    }

    button {
      margin-right: 10px;
    }
  }
 
`;

export const StyledTrustPasswordWrapper = styled.div`
  .trustBrowserCheckbox {
    color: white;
  }

  span.MuiFormControlLabel-label {
    color: white;
    font-weight: bold;
  }
  
  .password-remember-time-div-wrapper {
    margin-bottom: 10px;
    
    h6 {
      text-align: start;
    }
  }
`