import styled from "styled-components";

export const StyledBeneficiaryRewardsWrapper = styled.div`
  width: 100%;
  
  .step1-form {
    width: 30%;
    .table-dropdown, .search-input, .search-user-button {
      margin-bottom: 10px;
    }
    
    .category-fields {
      width: 90vw;
      .category-radio-buttons-group {
        flex-direction: row;
      }
    }
  }
  
  .step2-form {
    input {
      margin-bottom: 10px;
    }
    
    button {
      margin-right: 10px;
    }
  }
`
