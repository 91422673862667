import React, { Component, useState } from 'react';
import { StyledPaymentCardDetailsWrapper } from "./styles";
import { Button, FormControl, FormControlLabel, Modal, Radio, RadioGroup } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import { paymentCardDetailsTableColumnsConfig } from "../../../utils";
import axios from "axios";
import config from "../../../config";
import { getLocalStorageItem } from "../../../utils/localStorage";
import { toast } from "react-toastify";
import { StyledModalWrapper } from "../styles";
import QRCode from "react-qr-code";
import CallIcon from '@material-ui/icons/Call';
import PersonAddTwoTone from '@material-ui/icons/PersonAddTwoTone';
import EmailIcon from '@material-ui/icons/Email';
import PrintIcon from '@material-ui/icons/Print';
import PersonIcon from '@material-ui/icons/Person';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import { exportComponentAsJPEG, exportComponentAsPDF, exportComponentAsPNG } from 'react-component-export-image';
import html2canvas from 'html2canvas';
import './style.css'


class CardBoard extends Component {

    componentDidMount( ) {
        const img = document.createElement('img');
        const canvas = document.getElementById(`canvas-${this.props.card.user_name}`);
        img.src = `https://walletapi.nucoin.com.au/api/v1/nucoin/wallet/read-shop-card-image?username=${this.props.card.user_name}`;
        img.style = "width:'100%';height: '100%';";
        img.crossOrigin = "anonymous"
        img.onload = () => {
            const ctx = canvas.getContext('2d');

            ctx.drawImage(img, 0, 0, 300, 150);
            // ctx.drawImage()
        }

    }

    render() {
        
        const { card } = this.props;
        // src={`https://walletapi.nucoin.com.au/api/v1/nucoin/wallet/read-shop-card-image?username=${card.user_name}`}
        return (
            <React.Fragment>
            <div id={`div-${card.user_name}`} className="col-4 mt-3">
                <div className="card PrintCard p-3">
                    <div className='d-flex justify-content-between align-items-center'>
                        <div style={{ border: "5px solid #fff", padding: "5px" }}>
                            <QRCode style={{ color: 'blue' }} className="qr-code" fill="#fff" size={140} value={card.user_name}></QRCode>
                        </div>
                        <div>
                            <canvas id={`canvas-${card.user_name}`} style={{ border: "5px solid #fff", padding: "5px", width: '180px', height: '160px' }}  alt={card.user_name} ></canvas>
                        </div>
                    </div>
                    <hr style={{borderColor: "#fff"}} />
                    <div className="position-relative">
                        <ul className="text-left list-inline">
                            <li className="d-flex">
                                <PersonIcon /> <span className="ml-2">{card.first_name} {card.middle_name} {card.last_name}</span>
                            </li>
                            <li>
                                <PersonAddTwoTone />
                                {
                                    <span className="ml-2">{card.user_name || 'N/A'}</span>
                                }
                            </li>
                            <li className="d-flex">
                                <CallIcon /> <span className="ml-2">{card.mobile_number || 'N/A'}</span>
                            </li>
                            {/* <li className="d-flex">
                                <PrintIcon />
                                {
                                    card.printed_status !== 0 ? <span className="ml-2">Printed</span> : <span className="ml-2">Printing In Progress</span>
                                }
                            </li> */}
                            <li className="d-flex">
                                <AccountBalanceWalletIcon /> <span style={{ fontSize: '14px' }} className="ml-2">{card.account_id}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </React.Fragment>
        )
    }
}

const PaymentCardDetails = () => {

    const [cards, setCards] = useState([]);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState("0");
    const [filter, setFilter] = useState("");

    const [selectedCardsForApproval, setSelectedCardsForApproval] = useState([]);

    const onGetCardDetailsBtnClick = async () => {
        try {
            setLoading(true);
            setCards([]);
            setSelectedCardsForApproval([]);
            const response = await axios.get(
                `${config.apiUrl}/admin/payment-cards`,
                {
                    params: {
                        printStatus: status,
                    },
                    headers: {
                        adminToken: getLocalStorageItem('adminToken'),
                    }
                }
            );

            const { success, msg, cards } = response.data;

            if (!success) {
                toast.error(msg || 'Unable to fetch cards details');
                setLoading(false);
                return;
            }

            toast.success(msg || 'Fetched cards details successfully');
            setCards(cards);
        } catch (e) {
            const message = e?.response?.data?.message;
            toast.error(message || 'Unable to fetch cards details');
        }

        setLoading(false);
    }

    const onApproveCardsBtnClick = async () => {
        const usernames = selectedCardsForApproval.map(card => card.user_name);

        if (!usernames.length) {
            toast.error('At least one card must be selected for approval. Please try again');
            return;
        }

        try {
            const response = await axios.post(
                `${config.apiUrl}/admin/payment-cards`,
                {
                    usernames: usernames
                }, {
                headers: {
                    adminToken: getLocalStorageItem('adminToken'),
                }
            }
            );

            const { success, msg } = response.data;

            if (!success) {
                toast.error(msg || 'Unable to approve cards');
                setLoading(false);
                return;
            }
            toast.success(msg || 'Approved cards successfully');
            setSelectedCardsForApproval([]);
            // Fetch cards again to get latest product as well
            await onGetCardDetailsBtnClick();
        } catch (e) {
            const message = e?.response?.data?.message;
            toast.error(message || 'Unable to approve cards');
        }
    }

    const getLoadingModalBody = () => {
        return (
            <StyledModalWrapper className='loading-modal-body'>
                <img src={'/images/loader.svg'} alt='Loader' />
            </StyledModalWrapper>
        )
    };

    return (
        <div className='section-container'>
            <h2>Payment Card Details</h2>
            <StyledPaymentCardDetailsWrapper>
                <div className='get-card-details-section'>
                    <div className='get-form'>
                        <div>
                            <FormControl component="fieldset" disabled={false}>
                                <h6>Printing Status</h6>
                                <RadioGroup
                                    className='card-print-status-radio-buttons-group'
                                    aria-label='card-print-status'
                                    value={status}
                                    name="card-print-status-radio-buttons-group"
                                    onChange={(event, value) => {
                                        setStatus(value)
                                        setSelectedCardsForApproval([]);
                                        setCards([]);
                                    }}
                                >
                                    <FormControlLabel value="0" control={<Radio />} label="Pending" />
                                    <FormControlLabel value="1" control={<Radio />} label="Approved" />
                                </RadioGroup>
                            </FormControl>
                        </div>

                        <Button
                            className='get-cards-detail-btn'
                            variant='contained'
                            color='primary'
                            onClick={onGetCardDetailsBtnClick}
                        >
                            Get Cards Details
                        </Button>
                        <div>
                            <h6>Filter (Username)</h6>
                            <input
                                className='search-input'
                                type='text'
                                placeholder='Please enter username...'
                                value={filter}
                                onChange={e => setFilter(e.target.value)}
                            />
                        </div>
                    </div>

                    <DataGrid
                        className='cards-details-data-grid'
                        editMode="row"
                        rows={cards.filter(_ => _.user_name.toLowerCase().indexOf(filter.toLowerCase()) != -1)}
                        columns={paymentCardDetailsTableColumnsConfig()}
                        loading={loading}
                        pageSize={25}
                        autoHeight
                        checkboxSelection={status === "0"}
                        onSelectionModelChange={(ids) => {
                            setSelectedCardsForApproval(cards.filter(card => ids.includes(card.id)));
                        }}
                    />
                </div>

                {
                    selectedCardsForApproval.length ? (
                        <div className='approve-cards-print-status-section'>
                            <Button
                                className='approve-cards-detail-btn'
                                variant='contained'
                                color='primary'
                                onClick={onApproveCardsBtnClick}
                                disabled={!selectedCardsForApproval.length}
                            >
                                Mark as Printed
                            </Button>
                        </div>
                    ) : <></>
                }
                {
                    selectedCardsForApproval.length ? (
                        <div className='approve-cards-print-status-section'>
                            <Button
                                className='approve-cards-detail-btn'
                                variant='contained'
                                color='primary'
                                onClick={() => {
                                    
                                    const usernames = selectedCardsForApproval.map(card => {

                                        html2canvas(document.getElementById(`div-${card.user_name}`)).then(canvas => {



                                            var img = canvas.toDataURL("image/jpeg");
                                            const a = document.createElement('a');
                                            // a.target = "_blank";
                                            a.download = `${card.user_name}.png`;
                                            a.href = img;
                                            a.click()
                                        })
                                    });
                                }}
                                disabled={!selectedCardsForApproval.length}
                            >
                                Download Selected Cards
                            </Button>
                        </div>
                    ) : <></>
                }
                {
                    selectedCardsForApproval.length ? (
                        <div className='approve-cards-print-status-section'>
                            <Button
                                className='approve-cards-detail-btn'
                                variant='contained'
                                color='primary'
                                onClick={() => {
                                    
                                    // const usernames = selectedCardsForApproval.map(card => {

                                        html2canvas(document.getElementById(`card-board-container`)).then(canvas => {



                                            var img = canvas.toDataURL("image/jpeg");
                                            const a = document.createElement('a');
                                            // a.target = "_blank";
                                            a.download = `${'cards'}.png`;
                                            a.href = img;
                                            a.click()
                                        })
                                    // });
                                }}
                                disabled={!selectedCardsForApproval.length}
                            >
                                Download Selected Cards as in One Image Document
                            </Button>
                        </div>
                    ) : <></>
                }
                <div id="card-board-container" className="row">
                    {
                        selectedCardsForApproval.map(card => {
                            return (
                                <CardBoard key={card.user_name} card={card}/>
                            )
                        })
                    }
                </div>
                {
                    <Modal
                        disablePortal
                        open={loading}
                        onClose={() => { }}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >
                        {getLoadingModalBody()}
                    </Modal>
                }
            </StyledPaymentCardDetailsWrapper>

        </div>
    );
};

export default PaymentCardDetails;