import React, {useState} from 'react';
import {StyledPlatformMinerWrapper} from "./styles";
import {Button, CircularProgress} from "@material-ui/core";
import {DataGrid} from "@mui/x-data-grid";
import {isFunction, platformMinerTableColumnsConfig} from "../../../utils";
import {toast} from "react-toastify";
import config from "../../../config";
import axios from "axios";
import {getLocalStorageItem} from "../../../utils/localStorage";

const PlatformMiner = ({ actionLogger, panelID }) => {
  const [username, setUsername] = useState('');
  const [accountId, setAccountId] = useState('');
  const [category, setCategory] = useState('');
  const [usiLimit, setUsiLimit] = useState(null);

  const [data, setData] = useState([]);
  const [showUsersData, setShowUsersData] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const shouldSearchDisabled = () => {
    return !(
      username ||
      accountId ||
      category ||
      usiLimit
    )
  }

  const shouldRefreshButtonDisabled = () => {
    return !(
      username ||
      accountId ||
      category ||
      usiLimit
    )
  }

  const resetFields = () => {
    setUsername('');
    setAccountId('');
    setCategory('');
    setUsiLimit(null);
    setShowUsersData(false);
    setData([]);
    setLoading(false);
  }

  const handlePlatformMinerSearch = async () => {
    setLoading(true);
    setData([]);
    setShowUsersData(false);

    try {
      const payload = {
        username: username,
        accountId: accountId,
        category: category,
        usiLimit: usiLimit
      };
      const response = await axios.post(
        `${config.apiUrl}/admin/get-platform-miner-info`,
        payload,
        {
          headers: {
            admintoken: getLocalStorageItem('adminToken'),
            othertoken: getLocalStorageItem('otherToken')
          }
        }
      );

      const { success, msg, results } = response.data;

      if (!success) {
        toast.error(msg || 'Unable to fetch platform miners info');
        setLoading(false);
        return;
      }

      if (results && Array.isArray(results) && results.length > 0) {
        toast.success(msg || 'Fetched platform miners info successfully');
        setData(results);
        setShowUsersData(true);
        isFunction(actionLogger) &&
        await actionLogger(panelID, `Retrieved platform miners info with following payload ${JSON.stringify(payload)}`);
      } else {
        toast.error('No records found');
      }
    } catch (e) {
      console.log('[handlePlatformMinerSearch]', e);
      toast.error('Unable to fetch platform miners info');
    }

    setLoading(false);
  }

  if (isLoading) {
    return (
      <div className='section-container'>
        <CircularProgress />
      </div>
    )
  }

  return (
    <div className='section-container'>
      <h2>Platform Miners</h2>
      <StyledPlatformMinerWrapper>
        <div className='search-input-fields-wrapper'>
          <div>
            <h6>Username</h6>
            <input
              className='search-input'
              type='text'
              placeholder='Please enter user name...'
              value={username}
              onChange={e => setUsername(e.target.value)}
            />
          </div>

          <div>
            <h6>Account ID</h6>
            <input
              className='search-input'
              type='text'
              placeholder='Please enter account id...'
              value={accountId}
              onChange={e => setAccountId(e.target.value)}
            />
          </div>

          <div>
            <h6>Category</h6>
            <input
              className='search-input'
              type='text'
              placeholder='Please enter category...'
              value={category}
              onChange={e => setCategory(e.target.value)}
            />
          </div>

          <div>
            <h6>USI Limit</h6>
            <input
              className='search-input'
              type='text'
              placeholder='Please enter USI limit...'
              value={usiLimit}
              onChange={e => setUsiLimit(e.target.value)}
            />
          </div>
        </div>
        <div className='search-button-wrapper'>
          <Button
            variant='contained'
            color='primary'
            onClick={() => handlePlatformMinerSearch()}
            disabled={shouldSearchDisabled()}
          >
            Search
          </Button>
          <Button
            variant='contained'
            color='secondary'
            onClick={resetFields}
            disabled={shouldRefreshButtonDisabled()}
          >
            Refresh
          </Button>
        </div>
        {
          showUsersData && (
            <div className='data-grid-wrapper'>
              <DataGrid
                className='data-grid'
                rows={data}
                columns={platformMinerTableColumnsConfig()}
              />
            </div>
          )
        }
      </StyledPlatformMinerWrapper>
    </div>
  );
};

export default PlatformMiner;