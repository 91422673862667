// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { StyledEodRewardInfoWrapper } from "./styles";
import {Button, Checkbox, FormControlLabel, FormGroup, Modal} from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import { encrypt, endOfDayRewardTableColumnsConfig, isFunction } from "../../../utils";
import { getLocalStorageItem, setLocalStorageItem } from "../../../utils/localStorage";
import axios from "axios";
import config from "../../../config";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { StyledModalWrapper } from "../styles";
import { sortBy } from "lodash";
const CryptoJS = require("crypto-js");
declare var $: any;

const EODReward = ({ actionLogger, panelID }) => {
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [accountDescription, setAccountDescription] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [accountID, setAccountID] = useState('');
  const [data, setData] = useState([]);
  const [isDataSingleton, setIsDataSingleton] = useState(false);
  const [showUsersData, setShowUsersData] = useState(false);
  const [missedRows, setMissedRows] = useState([]);
  const [compensatedRows, setCompensatedRows] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isMissedRowClicked, setIsMissedRowClicked] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [fromWallet, setFromWallet] = useState('');
  const [fromWalletMnemonics, setFromWalletMnemonics] = useState('');

  const [beforeBalance, setBeforeBalance] = useState(null);
  const [afterBalance, setAfterBalance] = useState(null);
  const [mnemonicsPassword, setMnemonicsPassword] = useState('');
  const [passwordTrustTime, setPasswordTrustTime] = useState(15);
  const [trustBrowser, setTrustBrowser] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [isFilteredData, setIsFilteredData] = useState(false);
  const [isMissedRowsButtonClicked, setIsMissedRowsButtonClicked] = useState(false);
  const [isCompensatedRowsButtonClicked, setIsCompensatedRowsButtonClicked] = useState(false);
  const [isNewCompensatedRowsButtonClicked, setIsNewCompensatedRowsButtonClicked] = useState(false);
  const [currentMissedRowsCount, setCurrentMissedRowsCount] = useState(0);
  const [compensatedRowsCount, setCompensatedRowsCount] = useState(0);
  const [totalRowsCount, setTotalRowsCount] = useState(0);
  const [totalMissedRowsCount, setTotalMissedRowsCount] = useState(0);
  const [showStats, setShowStats] = useState(false);

  const [newCompensationDatesList, setNewCompensationDatesList] = useState({});

  useEffect(() => {
    const fetchRecords = async () => {
      await handleSearchEODRewardInfo();
    };
    Object.keys(newCompensationDatesList).length > 0 && fetchRecords()
  }, [JSON.stringify(newCompensationDatesList)])

  const handleSearchEODRewardInfo = async () => {
    setLoading(true);
    setData([]);
    setMissedRows([]);
    setCompensatedRows([]);
    setShowUsersData(false);
    setShowStats(false);

    try {
      const payload = {
        username: username,
        firstName: firstName,
        lastName: lastName,
        mobileNumber: mobileNumber,
        email: email,
        accountID: accountID,
        accountDescription: accountDescription,
      }

      // Check if dates are present, then they must be valid otherwise they are not included in payload
      let areDatesValid;
      if (startDate && endDate) {
        areDatesValid = validateDates();
      }

      if (areDatesValid) {
        payload['startDate'] = moment(startDate).format('YYYY-MM-DD');
        payload['endDate'] = moment(endDate).format('YYYY-MM-DD');
      }

      const response = await axios.post(
        `${config.apiUrl}/admin/eod-reward-processing`,
        payload,
        {
          headers: {
            admintoken: getLocalStorageItem('adminToken')
          }
        }
      );

      const { success, msg, results, isResultSetSingleton } = response.data;

      if (!success) {
        toast.error(msg || 'Unable to fetch end of day reward info');
        setLoading(false);
        return;
      }

      if (results && Array.isArray(results) && results.length > 0) {
        // TODO: HERE CALCULATE STATS IF RESULTS BELONG TO SINGLE USER
        if (isResultSetSingleton) {
          let mRows = 0;
          let cRows = 0;
          let tRows = 0;
          let tmRows = 0;
          results.forEach(row => {
            tRows += 1;

            // If row is currently marked as miss
            if (!row.block_id && row.block_id !== 0) {
              mRows += 1;
              tmRows += 1;
              return;
            }

            // If row is compensated
            if (row.account_desc && row.account_desc.endsWith('COMPENSATORY')) {
              cRows += 1;
              tmRows += 1;
              return;
            }
          });
          setCurrentMissedRowsCount(mRows);
          setCompensatedRowsCount(cRows);
          setTotalRowsCount(tRows);
          setTotalMissedRowsCount(tmRows);
          setShowStats(true);
        }

        toast.success(msg || 'Fetched end of day reward info successfully');
        isFunction(actionLogger) &&
        await actionLogger(panelID, `Retrieved EOD rewards info with following payload. ${JSON.stringify(payload)}`);

        if (isResultSetSingleton) {
          const missedRecords = results.filter(row => !row.block_id && row.block_id !== 0);
          setMissedRows(missedRecords);

          const compensatedRecords = results.filter(row => row.account_desc && row.account_desc.endsWith('COMPENSATORY'));
          setCompensatedRows(compensatedRecords)

          const missedWithCompensationRecords = sortBy(
              Object.values(
                  results.filter(row => {
                    const isoDatePart = moment(row.create_time).startOf('day').toISOString();
                    return newCompensationDatesList[isoDatePart];
                  }).concat(missedRecords)
              ),
              ['create_time']
          ).reverse();

          if (isMissedRowsButtonClicked) {
            setIsFilteredData(true);
            setFilteredData(missedRecords)
          } else if (isCompensatedRowsButtonClicked) {
            setIsFilteredData(true);
            setFilteredData(compensatedRecords)
          } else if (isNewCompensatedRowsButtonClicked) {
            setIsFilteredData(true);
            setFilteredData(missedWithCompensationRecords);
          }
        }

        setData(results);
        setShowUsersData(true);
        setIsDataSingleton(isResultSetSingleton);
      } else {
        toast.error('No records found');
      }
    } catch (e) {
      console.log('[handleSearchEODRewardInfo]', e);
      toast.error('Unable to fetch end of day rewards info');
    }

    setLoading(false);
  }

  const validateDates = () => {
    if (!startDate) {
      toast.error('Start date is required');
      return false;
    }

    if (startDate) {
      const isValid = moment(startDate, 'YYYY-MM-DD', true).isValid();
      if (!isValid) {
        toast.error('Start date is not valid. Format must be YYYY-MM-DD');
        return false;
      }
    }

    if (!endDate) {
      toast.error('End date is required');
      return false;
    }

    if (endDate) {
      const isValid = moment(endDate, 'YYYY-MM-DD', true).isValid();
      if (!isValid) {
        toast.error('End date not valid. Format must be YYYY-MM-DD');
        return false;
      }
    }

    if (moment(startDate).diff(moment(endDate)) > 0) {
      toast.error('End date cannot be less than start date');
      return false;
    }

    return true;
  }

  const shouldSearchDisabled = () => {
    return !(
      username ||
      firstName ||
      lastName ||
      email ||
      accountID ||
      mobileNumber ||
      accountDescription ||
      (startDate && endDate)
    )
  }

  const shouldRefreshButtonDisabled = () => {
    return !(
      username ||
      firstName ||
      lastName ||
      email ||
      accountID ||
      mobileNumber ||
      accountDescription ||
      startDate ||
      endDate ||
      isFilteredData
    )
  }

  const resetFields = () => {
    // Expire existing password time for mnemonics
    const mnemonics = JSON.parse(getLocalStorageItem(`${fromWallet}-mnemonics`));

    if (mnemonics && typeof mnemonics === 'object') {
      mnemonics.expiry = null;
      setLocalStorageItem(`${fromWallet}-mnemonics`, JSON.stringify(mnemonics));
    }

    setUsername('');
    setFirstName('');
    setLastName('');
    setEmail('');
    setAccountID('');
    setMobileNumber('');
    setAccountDescription('');
    setShowUsersData(false);
    setData([]);
    setLoading(false);
    setStartDate(null);
    setEndDate(null);
    setIsMissedRowClicked(false);
    setSelectedRowData({});
    setFromWallet('');
    setFromWalletMnemonics('');
    setBeforeBalance(null);
    setAfterBalance(null);
    setFilteredData([]);
    setIsFilteredData(false);
    setCurrentMissedRowsCount(0);
    setCompensatedRowsCount(0);
    setTotalRowsCount(0);
    setTotalMissedRowsCount(0);
    setShowStats(false);
    setFromWalletMnemonics('');
    setTrustBrowser(false);
    setMnemonicsPassword('');
    setPasswordTrustTime(15);
    setIsCompensatedRowsButtonClicked(false);
    setIsMissedRowsButtonClicked(false);
    setIsNewCompensatedRowsButtonClicked(false);
    setNewCompensationDatesList({});
    setMissedRows([]);
    setCompensatedRows([]);
  }

  const onRowClickHandler = event => {
    const { row } = event;
    setSelectedRowData({...row});
    if (row.block_id || row.block_id === 0) {
      setIsMissedRowClicked(false)
    } else {
      setIsMissedRowClicked(true);
    }
  }

  const showFromWalletModal = event => {
    event.preventDefault();
    $('#from-wallet-modal').modal('show');
  }

  const checkForExistingMnemonics = async event => {
    event.preventDefault();

    const mnemonics = JSON.parse(getLocalStorageItem(`${fromWallet}-mnemonics`));
    if (!mnemonics || typeof mnemonics !== 'object') {
      console.log('1');
        $('#from-wallet-modal').modal('hide');
        $('#wallet-mnemonics-modal').modal('show');
        return;
    }

    const { expiry, encryptedMnemonics } = mnemonics;

    if (!encryptedMnemonics) {
      console.log('2');
      $('#from-wallet-modal').modal('hide');
      $('#wallet-mnemonics-modal').modal('show');
      return;
    }

    /*
       If expiry date is not passed then use these mnemonics and proceed with transfer
       logic else ask for password
    */

    const difference = expiry && moment(expiry).diff(moment(), 'minutes') > 0;

    // Ask for password and show checkbox for remember as well
    if (!difference) {
      console.log('3');
        $('#from-wallet-modal').modal('hide');
        $('#wallet-password-modal').modal('show');
        return;
    }

    const password = getLocalStorageItem(`${fromWallet}-password`);
    let decryptedPassword;
    try {
      decryptedPassword = CryptoJS.enc.Utf8.stringify(CryptoJS.AES.decrypt(password, config.mnemonicsPasswordEncryptionKey));
    } catch (e) {
      toast.error('Please make sure your password is correct.');
      return;
    }

    let decryptedMnemonics;
    try {
      decryptedMnemonics = CryptoJS.enc.Utf8.stringify(CryptoJS.AES.decrypt(encryptedMnemonics, decryptedPassword));
    } catch (e) {
      toast.error('Please make sure your password is correct.');
      return;
    }

    if (!decryptedMnemonics) {
      toast.error('Please make sure your password is correct.');
      return;
    }

    await processMissedRowHandler(`${decryptedMnemonics}`);
  }

  const showMnemonicsPasswordModal = event => {
    event.preventDefault();
    $('#wallet-password-modal').modal('show');
  }

  const mnemonicsPasswordHandler = async event => {
    event.preventDefault();

    if (trustBrowser && passwordTrustTime < 1) {
      toast.error('Trust time must be 1 minute or more');
      return;
    }

    let mnemonics = fromWalletMnemonics;

    if (!fromWalletMnemonics) {
      const encMncs = JSON.parse(getLocalStorageItem(`${fromWallet}-mnemonics`));
      const { encryptedMnemonics } = encMncs;
      try {
        console.log('before decrypt mncs in mnemonicsPasswordHandler');
        mnemonics = CryptoJS.enc.Utf8.stringify(CryptoJS.AES.decrypt(encryptedMnemonics, mnemonicsPassword));
      } catch (e) {
        console.log('inside mnemonicsPasswordHandler decrypt catch');
        toast.error('Please make sure your password is correct');
        return;
      }
    }
    if (!mnemonics) {
      toast.error('Please make sure your password is correct');
      return;
    }

    const encryptedMnemonics = CryptoJS.AES.encrypt(mnemonics, mnemonicsPassword).toString();
    const encryptedMnemonicsEncryptPassword = CryptoJS.AES.encrypt(mnemonicsPassword, config.mnemonicsPasswordEncryptionKey).toString();

    const obj = {
      encryptedMnemonics: encryptedMnemonics,
    }

    if (trustBrowser) {
      obj.expiry = moment().add(passwordTrustTime || 1, 'minutes');
    }

    setLocalStorageItem(`${fromWallet}-mnemonics`, JSON.stringify(obj));
    setLocalStorageItem(`${fromWallet}-password`, encryptedMnemonicsEncryptPassword);

    $('#wallet-password-modal').modal('hide');
    await processMissedRowHandler(`${mnemonics}`)
  }

  const processMissedRowHandler = async (fromWalletMncs = null) => {
    const fetchLoggedInUserToken = async () => {
      const payload = {
        username: 'luaymm',
        password: 'luaymm@2020',
      }

      try {
        const response = await axios.post(
          `${config.apiUrl}/admin/generate_jwt_Token`,
          encrypt(payload)
        );
        const { success, access_token } = response.data;
        if (!success) return false;

        return access_token;
      } catch (e) {
        console.log('[fetchLoggedInUserToken]', e);
        toast.error('Unable to fetch logged in user token');
        return false;
      }
    }

    const getAccountDescriptionForMissingRows = category => {
        if (!category || typeof category !== 'string') return 'COMPENSATORY';
        switch (category.toLowerCase()) {
            case 'gold':
                return 'GOLD COMPENSATORY';
            case 'platinum':
                return 'PLATINUM COMPENSATORY';
            case 'silver':
                return 'SILVER COMPENSATORY';
            case 'diamond':
                return 'DIAMOND COMPENSATORY';
            default:
                return 'COMPENSATORY';
        }
    };

    if (!fromWallet) {
      toast.error('From wallet is required');
      return;
    }

    if (!fromWalletMnemonics && !fromWalletMncs) {
      toast.error('From wallet mnemonics are required');
      return;
    }

    if (!selectedRowData) {
      toast.error('No row is selected');
      return;
    }

    let {
      user_name,
      first_name,
      last_name,
      mobile_number,
      email,
      create_time,
      account_id,
      category,
    } = selectedRowData;

    let oldBalance = null;
    let updatedBalance = null;

    if (!account_id) {
      toast.error('account_id is required');
      return;
    }

    setLoading(true);

    // Send coins to user first then we will post compensatory record in eod reward table
    let token = null
    try {
      // First get token of a logged in user, Luay for now, because transfer call needs a token
      token = await fetchLoggedInUserToken();
      if (!token) {
        toast.error('Unable to fetch logged in user token');
        setLoading(false);
        return;
      }

      // Fetch user's before transfer balance
      try {
        const balanceResponse = await axios.get(
            `${config.userServicesApiUrl}/wallet/balance-v2/${account_id}`,
            {
              headers: {
                authorization: token,
              }
            }
        );

        const { balance } = balanceResponse.data;

        if (balance === null || balance == undefined) {
          toast.error('Unable to fetch initial balance. Please try again');
          setLoading(false);
          return;
        }

        setBeforeBalance(balance);
        oldBalance = balance;
      } catch (e) {
        toast.error(`Unable to get initial balance before transfer of coins. Please try again`);
        console.log('[processMissedRowHandler] fetch latest balance', e);
        setLoading(false);
        return;
      }

      const encryptedData = encrypt({
        secret: fromWalletMnemonics || fromWalletMncs,
        recipient: account_id,
        amount: 25.92
      })

      const response = await axios({
        method: 'post',
        url: `${config.userServicesApiUrl}/wallet/transfer`,
        headers: { Authorization : token },
        data: encryptedData
      });

      const { transferred: { status } = {} } = response.data
      if (status !== 'ExtrinsicSuccess') {
        toast.error(`Unable to transfer compensatory coins. Please check if source wallet have enough coins to transfer.`);
        setLoading(false);
        return;
      }
    } catch (e) {
      toast.error(`Unable to transfer compensatory coins to ${account_id}. Please try again`);
      console.log('[processMissedRowHandler] transfer coins', e);
      setLoading(false);
      return;
    }

    // Now fetch user's updated balance
    try {
      const balanceResponse = await axios.get(
          `${config.userServicesApiUrl}/wallet/balance-v2/${account_id}`,
          {
            headers: {
              authorization: token,
            }
          }
      );
      const { balance } = balanceResponse.data;
      setAfterBalance(balance);
      updatedBalance = balance;
    } catch (e) {
      toast.error(`Unable to get updated balance after transfer of coins`);
      console.log('[processMissedRowHandler] fetch latest balance', e);
    }

    const processDescription = `Sending compensatory rewards of amount 25.92 NUC from account of ${fromWallet}`

    const payload = {
      username: user_name,
      first_name: first_name,
      last_name: last_name,
      mobile_number: mobile_number,
      email: email,
      create_time: create_time,
      account_id: account_id,
      process_description: processDescription,
      account_description: getAccountDescriptionForMissingRows(category),
      // TODO: if we need to show after comp balance, then replace oldBalance with updatedBalance
      // TODO: DONE
      balance: updatedBalance,
    };

    try {
      const response = await axios.post(
        `${config.apiUrl}/admin/eod-reward-compensation`,
        payload,
        {
          headers: {
            adminToken: getLocalStorageItem('adminToken'),
          }
        }
      );

      const { success, msg } = response.data;

      if (!success) {
        toast.error(msg || 'Unable to post eod reward compensation but coins got transferred to user');
        setLoading(false);
        return;
      }

      toast.success(msg || 'EOD reward compensated successfully');
      isFunction(actionLogger) &&
      await actionLogger(
        panelID,
        `Compensated ${user_name} for end of day reward with following payload. ${JSON.stringify(payload)}. Previous balance: ${oldBalance}. New balance: ${updatedBalance}`,
        user_name
      );
      setIsMissedRowClicked(false);
      setMnemonicsPassword('');
      setTrustBrowser(false);
      setPasswordTrustTime(15);
      setFromWalletMnemonics('');

      // Todo: add data temporarily in new compensation section
      // Todo: done
      const { create_time } = selectedRowData || {};

      if (create_time) {
        const date = moment(create_time).startOf('day').toISOString();
        console.log('date', date);
        setNewCompensationDatesList(prevState => {
          return {
            ...prevState,
            [date]: true
          }
        });
      } else {
        // Now fetch latest results
        await handleSearchEODRewardInfo();
      }
    } catch (e) {
      toast.error('Unable to post eod reward compensation but coins got transferred to user');
      console.log('[processMissedRowHandler]', e);
    }
    setLoading(false);
  }

  const getLoadingModalBody = () => {
    return (
        <StyledModalWrapper className='loading-modal-body'>
          <img src={'/images/loader.svg'} alt='Loader' />
        </StyledModalWrapper>
    )
  };

  const showAllData = () => {
      setIsFilteredData(false);
      setIsMissedRowsButtonClicked(false);
      setIsCompensatedRowsButtonClicked(false);
      setIsNewCompensatedRowsButtonClicked(false);
      setNewCompensationDatesList({});
  }

  const showMissedRecordsButtonClickHandler = () => {
      setFilteredData(missedRows);
      setIsFilteredData(true);
      setIsMissedRowsButtonClicked(true);
      setIsCompensatedRowsButtonClicked(false);
      setIsNewCompensatedRowsButtonClicked(false);
      setNewCompensationDatesList({});
  };

  const showCompensatedRecordsButtonClickHandler = () => {
      setFilteredData(compensatedRows);
      setIsFilteredData(true);
      setIsCompensatedRowsButtonClicked(true);
      setIsMissedRowsButtonClicked(false);
      setIsNewCompensatedRowsButtonClicked(false);
      setNewCompensationDatesList({});
  }

  const showNewCompensatedRecordsButtonClickHandler = () => {
    setFilteredData(missedRows);
    setIsFilteredData(true);
    setIsNewCompensatedRowsButtonClicked(true);
    setIsCompensatedRowsButtonClicked(false);
    setIsMissedRowsButtonClicked(false);
    setNewCompensationDatesList({});
  }

  return (
    <div className='section-container'>
      <h2>End of Day Reward Processing Info</h2>
      <h6>(Wildcards can be used in search boxes, for example: % or __ )</h6>
      <StyledEodRewardInfoWrapper>
        <div className='search-input-fields-wrapper'>
          <div>
            <h6>Username</h6>
            <input
              className='search-input'
              type='text'
              placeholder='Please enter user name...'
              value={username}
              onChange={e => setUsername(e.target.value)}
            />
          </div>

          <div>
            <h6>First Name</h6>
            <input
              className='search-input'
              type='text'
              placeholder='Please enter user name...'
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
            />
          </div>

          <div>
            <h6>Last Name</h6>
            <input
              className='search-input'
              type='text'
              placeholder='Please enter user name...'
              value={lastName}
              onChange={e => setLastName(e.target.value)}
            />
          </div>

          <div>
            <h6>Email</h6>
            <input
              className='search-input'
              type='text'
              placeholder='Please enter user name...'
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </div>

          <div>
            <h6>Account ID</h6>
            <input
              className='search-input'
              type='text'
              placeholder='Please enter account id...'
              value={accountID}
              onChange={e => setAccountID(e.target.value)}
            />
          </div>

          <div>
            <h6>Mobile Number</h6>
            <input
              className='search-input'
              type='text'
              placeholder='Please enter mobile number...'
              value={mobileNumber}
              onChange={e => setMobileNumber(e.target.value)}
            />
          </div>

          <div>
            <h6>Account Description</h6>
            <input
              className='search-input'
              type='text'
              placeholder='Please enter account description...'
              value={accountDescription}
              onChange={e => setAccountDescription(e.target.value)}
            />
          </div>

          <div>
            <h6>Start Date</h6>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              placeholderText="Enter start date here..."
              dateFormat='dd/MM/yyyy'
            />
          </div>

          <div>
            <h6>To Date</h6>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              placeholderText="Enter end date here..."
              dateFormat='dd/MM/yyyy'
            />
          </div>
        </div>
        <div className='search-button-wrapper'>
          <Button
            variant='contained'
            color='primary'
            onClick={() => handleSearchEODRewardInfo()}
            disabled={shouldSearchDisabled()}
          >
            Search
          </Button>
          <Button
            variant='contained'
            color='secondary'
            onClick={resetFields}
            disabled={shouldRefreshButtonDisabled()}
          >
            Refresh
          </Button>
          <Button
            variant='contained'
            color='secondary'
            onClick={showFromWalletModal}
            disabled={!isMissedRowClicked}
          >
            Process
          </Button>
            <Button
                variant='contained'
                color='secondary'
                onClick={showAllData}
                disabled={!isFilteredData}
            >
                Show all records
            </Button>
            <Button
                variant='contained'
                color='secondary'
                onClick={showMissedRecordsButtonClickHandler}
                disabled={data.length === 0 || isMissedRowsButtonClicked}
            >
                Show missed records
            </Button>
            <Button
                variant='contained'
                color='secondary'
                onClick={showCompensatedRecordsButtonClickHandler}
                disabled={data.length === 0 || isCompensatedRowsButtonClicked}
            >
                Show compensated records
            </Button>

            <Button
                variant='contained'
                color='secondary'
                onClick={showNewCompensatedRecordsButtonClickHandler}
                disabled={data.length === 0 || isNewCompensatedRowsButtonClicked}
            >
              Show Missed Records With Compensation
            </Button>
        </div>
        {
          showStats && (
              <div className='stats-wrapper'>
                <p><b>Total Days:</b>{` ${totalRowsCount}`}</p>
                <p><b>Currently Missed Days:</b>{` ${currentMissedRowsCount} / ${totalRowsCount}`}</p>
                <p><b>Compensated Days:</b>{` ${compensatedRowsCount} / ${totalRowsCount}`}</p>
                <p><b>Total Missed Days Till Now:</b>{` ${totalMissedRowsCount} / ${totalRowsCount} (${((totalMissedRowsCount/totalRowsCount) * 100).toFixed(2)} %)`}</p>
                {
                  afterBalance && beforeBalance ? (
                      <>
                        <p><b>Current Balance: </b>{afterBalance}</p>
                        <p><b>Previous Balance: </b>{beforeBalance}</p>
                      </>
                  ) : <></>
                }
              </div>
          )
        }
        {
          showUsersData && (
            <div className='data-grid-wrapper'>
              <DataGrid
                className='data-grid'
                rows={isFilteredData ? filteredData : data}
                getRowClassName={(params) => {
                  if (!isDataSingleton) return '';
                  const isBlockIdPresent = params.getValue(params.id, 'block_id');
                  if (!isBlockIdPresent && isBlockIdPresent !== 0) {
                    return 'missed-reward-row'
                  } else {
                   const account_desc: string = `${params.getValue(params.id, 'account_desc')}`;
                   if (account_desc && account_desc.endsWith('COMPENSATORY')) {
                     return 'compensated-reward-row'
                   } else {
                     return 'complete-reward-row'
                   }
                  }
                }}
                columns={endOfDayRewardTableColumnsConfig()}
                onRowClick={onRowClickHandler}
                pageSize={100}
                autoHeight
              />
            </div>
          )
        }

        {/*MODALS*/}
        {/* FROM WALLET MODAL */}
        <div id="from-wallet-modal" className="modal show" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content bg-success text-white" style={{ top: '120px' }}>
              <div className="modal-body text-center">
                <form>
                  <h3 className="text-white mb-15">Enter from wallet</h3>
                  {
                    selectedRowData &&
                    typeof selectedRowData === 'object' &&
                    Object.keys(selectedRowData).length > 0 &&
                    selectedRowData.category ? (
                        <h6>You are going to compensate a <b>{`${selectedRowData.category}`}</b> wallet</h6>
                    ) : <></>
                  }
                  <input
                      className='from-wallet-input-box'
                      value={fromWallet}
                      required
                      onChange={e => setFromWallet(e.target.value)}
                      placeholder="Enter wallet address from which coins will be transferred..."
                      style={{ marginBottom: '10px' }}
                      type="text"/>
                  <button type="button" className="btn btn-light" data-dismiss="modal">Cancel</button>
                  <button
                      style={{ marginLeft: "8px" }}
                      onClick={checkForExistingMnemonics}
                      type="button"
                      className="btn btn-light"
                      data-dismiss="modal"
                      disabled={!fromWallet}
                  >
                    Continue
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* WALLET MNEMONICS MODAL */}
        <div id="wallet-mnemonics-modal" className="modal show" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content bg-success text-white" style={{ top: '120px' }}>
              <div className="modal-body text-center">
                <form>
                  <h3 className="text-white mb-15">Enter Mnemonics</h3>
                  <input
                      className='wallet-mnemonics-input-box'
                      value={fromWalletMnemonics}
                      required
                      onChange={e => setFromWalletMnemonics(e.target.value)}
                      placeholder="Enter mnemonics of wallet from which coins will be transferred..."
                      style={{ marginBottom: '10px' }}
                      type="text"
                  />
                  <button type="button" className="btn btn-light" data-dismiss="modal">Cancel</button>
                  <button
                      style={{ marginLeft: "8px" }}
                      onClick={showMnemonicsPasswordModal}
                      type="button"
                      className="btn btn-light"
                      data-dismiss="modal"
                      disabled={!fromWalletMnemonics}
                  >
                    Continue
                  </button>
                </form>
              </div>
            </div>

          </div>
        </div>

        {/* PASSWORD MODAL */}
        <div id="wallet-password-modal" className="modal show" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content bg-success text-white" style={{ top: '120px' }}>
              <div className="modal-body text-center">
                <form>
                  <h3 className="text-white mb-15">Enter Password</h3>
                  <h5>
                    This password will be used for encryption/decryption of mnemonics.
                    This is not necessarily wallet's own real password.
                  </h5>
                  <input
                      className='wallet-password-input-box'
                      value={mnemonicsPassword}
                      required
                      onChange={e => setMnemonicsPassword(e.target.value)}
                      placeholder="Enter password..."
                      type="password"
                  />
                  <FormGroup row>
                    <FormControlLabel
                        control={
                          <Checkbox
                              checked={trustBrowser}
                              onChange={e => setTrustBrowser(e.target.checked)}
                              name="trustedBrowser"
                              className='trustBrowserCheckbox'
                          />
                        }
                        label="I trust this browser to keep this password"
                    />
                  </FormGroup>

                  {
                    trustBrowser ? (
                        <div className='password-remember-time-div-wrapper'>
                          <h6>Password Remember Time (Minutes)</h6>
                          <input
                              className='wallet-password-time-input-box'
                              value={passwordTrustTime}
                              required
                              onChange={e => setPasswordTrustTime(e.target.value)}
                              placeholder="Enter time to remember..."
                              type='number'
                              disabled={!trustBrowser}
                              min={1}
                          />
                        </div>
                    ) : <></>
                  }

                  <button type="button" className="btn btn-light" data-dismiss="modal">Cancel</button>
                  <button
                      style={{ marginLeft: "8px" }}
                      onClick={mnemonicsPasswordHandler}
                      type="button"
                      className="btn btn-light"
                      data-dismiss="modal"
                      disabled={!mnemonicsPassword}
                  >
                    Continue
                  </button>
                </form>
              </div>
            </div>

          </div>
        </div>

      </StyledEodRewardInfoWrapper>
      {/*Loading Modal*/}
      {
        <Modal
            disablePortal
            open={isLoading}
            onClose={() => {}}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
          {getLoadingModalBody()}
        </Modal>
      }

    </div>
  );
};

export default EODReward;