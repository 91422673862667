import styled from "styled-components";

export const StyledKYCWrapper = styled.div`
  .miner-fees-form {
    input {
      width: 75%;
    }
    .kyc-refresh-button {
      margin: 0 10px 0 10px;
      width: 10%;
    }
    .kyc-progress-button {
      width: 15%;
    }
  }
  
`

export const StyledDocumentsSectionWrapper = styled.div`
  .show-path-button {
    margin: 10px 0 10px 0;
  }
`;

export const StyledCustomerInfoWrapper = styled.div`
  .customer-address-data-grid,
  .user-basic-info-data-grid {
    width: 90vw;
    //height: 300px;
    //min-height: 200px;
  }

  .save-customer-address-button,
  .save-user-info-button {
    width: 50%;
    margin: 10px 25% auto;
  }
  
  .customer-address-data-grid,
  .user-basic-info-data-grid {
    .MuiDataGrid-cell {
      display: flex;
      flex-direction: column;

      .MuiDataGrid-editInputCell {
        height: 100%;
      }
    }
    
    .Dropdown-control {
      height: 52px;
    }
    
    .address-type-dropdown,
    .country-dropdown
    {
      width: 100%;
    }
  }
`;
