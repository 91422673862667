import React, {useState} from 'react';
import { Button, TextField } from "@material-ui/core";
import { toast } from "react-toastify";
import moment from "moment";
import { StyledContactUsWrapper } from "./styles";
import axios from "axios";
import config from "../../../config";
import { getLocalStorageItem } from "../../../utils/localStorage";
import {isFunction} from "../../../utils";

const ContactUsCSVDownload = ({ actionLogger, panelID }) => {

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const CSVDownloadClickHandler = async () => {
    if (startDate && !endDate) {
      toast.error('End date is also required');
      return;
    }

    if (endDate && !startDate) {
      toast.error('Start date is also required');
      return;
    }

    if (startDate && !moment(startDate, 'YYYY-MM-DD', true).isValid()) {
      toast.error('Start date not valid. Required format (YYYY-MM-DD)');
      return;
    }

    if (endDate && !moment(endDate, 'YYYY-MM-DD', true).isValid()) {
      toast.error('End date not valid. Required format (YYYY-MM-DD)');
      return;
    }

    const isStartDateBeforeEndDate = startDate && endDate && moment(endDate).diff(moment(startDate), 'days') >= 0;

    if (startDate && endDate && !isStartDateBeforeEndDate) {
      toast.error('Start date must be before end date');
      return;
    }

    try {
      const url = startDate && endDate ? `${config.apiUrl}/admin/contact-us/${startDate}/${endDate}` : `${config.apiUrl}/admin/contact-us`;
      console.log('url', url);
      const csv = await axios.get(
        url,
        {
          headers: {
            admintoken: getLocalStorageItem('adminToken'),
            othertoken2: getLocalStorageItem('otherToken2')
          }
        }
      );
      const data = `data:text/csv;charset=utf-8,${encodeURIComponent(csv.data)}`;
      const downloadElement = document.createElement('a');
      const fileName = startDate && endDate ?
        `Contact-Us-${startDate}&${endDate}.csv`:
        `Contact-Us-${moment().toISOString()}.csv`;
      downloadElement.setAttribute('download', fileName);
      downloadElement.setAttribute('href', data);
      document.body.appendChild(downloadElement);
      downloadElement.click();
      isFunction(actionLogger) &&
      await actionLogger(panelID, `Contact Us CSV file (${fileName}) downloaded`);
    } catch (e) {
      toast.error('Unable to download CSV');
      console.log('[CSVDownloadClickHandler]', e);
    }
  }

  return (
    <div className='section-container'>
      <h2>Contact Us List</h2>
      <StyledContactUsWrapper>
        <TextField
          id="start-date"
          label="Start Date"
          type="date"
          value={startDate}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={({ target: { value= null }}) => setStartDate(value)}
        />
        <TextField
          id="end-date"
          label="End Date"
          type="date"
          value={endDate}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={({ target: { value= null }}) => setEndDate(value)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={CSVDownloadClickHandler}
        >
          Download CSV
        </Button>
      </StyledContactUsWrapper>
    </div>
  );
};

export default ContactUsCSVDownload;