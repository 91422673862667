/* eslint-disable */
// @ts-nocheck

import React, { useEffect, useState } from 'react';
import { Button, FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import {toast} from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";
import config from "../../../config";
import axios from "axios";
import {getLocalStorageItem} from "../../../utils/localStorage";
import {isFunction} from "../../../utils";

declare var $: any;

const UserToggle = ({ toggleSelected, actionLoggerCallback, panelID }) => {
  const [initialValue, setInitialValue] = useState(null);
  const [value, setValue] = useState(null);
  const [username, setUsername] = useState('');
  const [loading, setLoading] = useState(false);
  const [isSaveDisabled, setSaveDisabled] = useState(true);
  const [currentUserData, setCurrentUserData] = useState(null);
  const [userNotFound, setUserNotFound] = useState(true);
  const [bulkUpdate, setBulkUpdate] = useState('NOT_SET');

  useEffect(() => {
    currentUserData && (currentUserData[toggleSelected] === 0 || currentUserData[toggleSelected] === 1) && setValue(`${currentUserData[toggleSelected]}`)
    // Set initial value for first time only so that we can revert back to it when required
    if (initialValue !== 0 || initialValue !== 1) {
      currentUserData && (currentUserData[toggleSelected] === 0 || currentUserData[toggleSelected] === 1) && setInitialValue(`${currentUserData[toggleSelected]}`)
    }
  }, [toggleSelected])

  const saveUserToggleHandler = async () => {
    if (!username) {
      toast.error('Username is required');
      return;
    }

    const toggleValue = parseInt(value);
    if (![0,1].includes(toggleValue)) {
      toast.error('Please select on/off value for toggle');
      return;
    }

    if (!toggleSelected) {
      toast.error('Please select toggle from top dropdown');
      return;
    }

    setLoading(true);

    try {
      const payload = {
        username: username,
        toggleType: toggleSelected,
        toggleValue: toggleValue
      }
      const response = await axios.post(
        `${config.apiUrl}/toggle/user`,
        payload,
        {
          headers: {
            adminToken: getLocalStorageItem('adminToken'),
            othertoken: getLocalStorageItem('otherToken'),
          }
        }
      );

      const { success, msg } = response.data;

      if (!success) {
        toast.error(msg || 'Unable to save user toggle');
        setLoading(false);
        return;
      }

      toast.success(msg || 'Saved user toggle successfully');
      isFunction(actionLoggerCallback) &&
      await actionLoggerCallback(panelID, `Update user ${toggleSelected} for username ${username} to ${toggleValue}.`);
      setUsername('');
      setValue(null);
      setInitialValue(null);
      setSaveDisabled(true);
      setCurrentUserData(null);
      setUserNotFound(true);
    } catch (e) {
      console.log('[saveUserToggleHandler]', e);
      toast.error('Unable to save user toggle');
    }
    setLoading(false);
  }

  const showConfirmationModal = toggleFlag => {
    if (typeof toggleFlag !== 'boolean') {
      toast.error('Toggle flag can only be true or false');
      return;
    }

    $('#all-users-update-confirmation-modal').modal('show');
    setBulkUpdate(toggleFlag)
  }

  const onToggleAllUsersButtonClick = async (event, toggleFlag) => {
    event && event.preventDefault();

    if (!toggleSelected) {
      toast.error('Please select toggle from top dropdown');
      return;
    }

    if (![0,1].includes(Number(toggleFlag))) {
      toast.error('Toggle value can only be 0 or 1');
      return;
    }

    setLoading(true);

    try {
      const payload = {
        toggleType: toggleSelected,
        toggleValue: Number(toggleFlag),
      }
      const response = await axios.post(
          `${config.apiUrl}/toggle/all/users`,
          payload,
          {
            headers: {
              adminToken: getLocalStorageItem('adminToken')
            }
          }
      );

      const { success, msg } = response.data;

      if (!success) {
        toast.error(msg || `Unable to toggle all users to ${Number(toggleFlag)}`);
        setLoading(false);
        return;
      }

      toast.success(msg || `Toggled all users to ${Number(toggleFlag)} successfully`);
      setBulkUpdate('NOT_SET');

      // If there already a user in search form, so update his/her data as well otherwise it will be old
      if (currentUserData && Object.keys(currentUserData).length > 0) {
        setCurrentUserData(prevState => {
          return {
            ...prevState,
            [payload.toggleType]: payload.toggleValue,
          }
        });

        setValue(`${payload.toggleValue}`);
        setInitialValue(`${payload.toggleValue}`);
      }

      isFunction(actionLoggerCallback) &&
      await actionLoggerCallback(
          panelID,
          `Update all users ${toggleSelected} to ${Number(toggleFlag)}.`
      );
    } catch (e) {
      console.log('[onToggleAllUsersButtonClick]', e);
      toast.error('Unable to update all users toggle');
    }
    setLoading(false);
  }

  const searchUserClickHandler = async () => {
    if (!username) {
      toast.error('Username is required');
      return;
    }

    if (!toggleSelected) {
      toast.error('Please select top level dropdown toggle value');
      return;
    }

    try {
      setLoading(true);
      const response = await axios.get(
        `${config.apiUrl}/admin/user/${username}`,
        {
          headers: {
            admintoken: getLocalStorageItem('adminToken'),
            othertoken: getLocalStorageItem('otherToken')
          }
        }
      );

      const { success, message: userData } = response.data;

      if (!success) {
        toast.error('Unable to find user');
        setLoading(false);
        setUserNotFound(true);
        return;
      }

      setCurrentUserData(userData);
      setUserNotFound(false);
      userData && (userData[toggleSelected] === 0 || userData[toggleSelected] === 1) && setValue(`${userData[toggleSelected]}`)
      // Set initial value for first time only so that we can revert back to it when required
      if (initialValue !== 0 || initialValue !== 1) {
        userData && (userData[toggleSelected] === 0 || userData[toggleSelected] === 1) && setInitialValue(`${userData[toggleSelected]}`)
      }
    } catch (e) {
      toast.error('Unable to find user');
      setValue(null);
      setUserNotFound(true);
      console.log('[searchUserClickHandler]', e);
    }

    setLoading(false);
  }

  if (loading) {
    return <CircularProgress />
  }

  return (
    <div className='user-toggle-wrapper'>
      <h3>User Toggle</h3>
      <div className='form-wrapper'>
        <div className='search-user-wrapper'>
          <input
            type='text'
            placeholder='Please enter user name...'
            value={username}
            onChange={e => setUsername(e.target.value)}
          />
          <Button
            disabled={!username}
            onClick={searchUserClickHandler}
            variant="contained"
            color='primary'
          >
            Search User
          </Button>
        </div>
        <div>
          <FormControl component="fieldset">
            <RadioGroup
              className='user-toggle-radio-buttons-group'
              aria-label="user-toggle"
              value={value}
              name="user-toggle-radio-buttons-group"
              onChange={(event, value) => {
                setValue(value);
                setSaveDisabled(false);
                // Set initial value for first time only so that we can revert back to it when required
                if (initialValue !== 0 || initialValue !== 1) {
                  currentUserData && (currentUserData[toggleSelected] === 0 || currentUserData[toggleSelected] === 1) && setInitialValue(`${currentUserData[toggleSelected]}`)
                }
              }}
            >
              <FormControlLabel value="1" control={<Radio />} label="On" disabled={userNotFound}/>
              <FormControlLabel value="0" control={<Radio />} label="Off" disabled={userNotFound}/>
            </RadioGroup>
          </FormControl>
          <div className='action-buttons-wrapper'>
            <Button
              variant="contained"
              color="primary"
              onClick={saveUserToggleHandler}
              disabled={isSaveDisabled || value === initialValue}>Save</Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setValue(initialValue);
                // setUsername('');
                setSaveDisabled(true);
                // setUserNotFound(true);
                // setCurrentUserData(null);
              }}
              disabled={!value || value === initialValue}>Reset</Button>

            <div className='all-users-toggle-buttons'>

              <Button
                  variant="contained"
                  color="primary"
                  onClick={() => showConfirmationModal(true)}
                  disabled={!toggleSelected}
              >
                Turn ALL users toggle ON
              </Button>

              <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => showConfirmationModal(false)}
                  disabled={!toggleSelected}
              >
                Turn ALL users toggle OFF
              </Button>
            </div>

          </div>
        </div>
      </div>

      {/*MODALS*/}
      {/* ALL USERS TOGGLE UPDATE CONFIRMATION MODAL */}
      <div id="all-users-update-confirmation-modal" className="modal show" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content bg-success text-white" style={{ top: '120px' }}>
            <div className="modal-body text-center">
              {
                typeof bulkUpdate === 'boolean' ? (
                    <form>
                      <h3 className="text-white mb-15">UPDATE ALL USERS TOGGLE</h3>
                      <h6>
                        {`Are you sure you want to turn ${bulkUpdate ? 'on' : 'off'} all users ${toggleSelected}?`}
                      </h6>
                      <button type="button" className="btn btn-light" data-dismiss="modal">Cancel</button>
                      <button
                          style={{ marginLeft: "8px" }}
                          onClick={event => onToggleAllUsersButtonClick(event, bulkUpdate)}
                          type="button"
                          className="btn btn-light"
                          data-dismiss="modal"
                          disabled={!toggleSelected}
                      >
                        Confirm
                      </button>
                    </form>
                ) : (
                    <React.Fragment>
                      <h3>Something went wrong. Please close modal & try again</h3>
                      <button type="button" className="btn btn-light" data-dismiss="modal">Close</button>
                    </React.Fragment>
                )
              }
            </div>
          </div>

        </div>
      </div>

    </div>
  );
};

export default UserToggle;
/* eslint-disable */

