/* eslint-disable */
// @ts-nocheck

import React, {useEffect, useState} from 'react';
import {
  Button, CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from "@material-ui/core";
import config from "../../../config";
import { getLocalStorageItem } from "../../../utils/localStorage";
import axios from "axios";
import { toast } from "react-toastify";
import Autosuggest from 'react-autosuggest';
import {isFunction} from "../../../utils";

declare var $: any;

const CountryToggle = ({ toggleSelected, actionLoggerCallback, panelID }) => {
  const [initialValue, setInitialValue] = useState(null);
  const [value, setValue] = useState(null);
  const [countries, setCountries] = useState([]);
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countriesTogglesMapping, setCountriesTogglesMapping] = useState({})
  const [currentToggle, setCurrentToggle] = useState(toggleSelected);
  const [isSaveDisabled, setSaveDisabled] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [bulkUpdate, setBulkUpdate] = useState('NOT_SET');

  useEffect(() => {
    setCurrentToggle(toggleSelected);
    countriesTogglesMapping[selectedCountry] && toggleSelected && countriesTogglesMapping[selectedCountry] &&
    setValue(`${countriesTogglesMapping[selectedCountry][toggleSelected]}`)

    // Set initial value for first time only so that we can revert back to it when required
    if (initialValue !== 0 || initialValue !== 1) {
      countriesTogglesMapping[selectedCountry] && toggleSelected && countriesTogglesMapping[selectedCountry] &&
      setInitialValue(`${countriesTogglesMapping[selectedCountry][toggleSelected]}`)
    }
  }, [toggleSelected])

  useEffect(() => {
    const fetchCountries = async () => {
      const response = await axios.get(
        `${config.apiUrl}/available-countries`,
        {
          headers: {
            adminToken: getLocalStorageItem('adminToken'),
            othertoken: getLocalStorageItem('otherToken'),
          }
        }
      );

      const { success, msg, countries } = response.data;

      if (!success) {
        toast.error(msg || '[Toggle Management] Unable to get countries list');
        return;
      }
      const mappedCountries = []
      const countriesTogglesMappingObject = {};
      countries.forEach(country => {
        // This mapped countries array will help us fill list of countries
        mappedCountries.push({ label: country.country_name})

        // This countries toggles mapping object will help us get value of toggles of selected country
        countriesTogglesMappingObject[country.country_name] =
          Object.fromEntries(Object.entries(country).filter(country => country[0].startsWith('toggle_')));

        if (selectedCountry && selectedCountry === country.country_name) {
          setValue(`${country[toggleSelected]}`);
          setInitialValue(`${country[toggleSelected]}`);
        }
      });

      setCountries(mappedCountries);
      setCountriesTogglesMapping(countriesTogglesMappingObject)
    };
    setValue(null);
    !isLoading && fetchCountries();
  }, [isLoading])

  const saveCountryToggleHandler = async () => {
    if (!selectedCountry) {
      toast.error('Country is required');
      return;
    }

    const toggleValue = parseInt(value);
    if (![0,1].includes(toggleValue)) {
      toast.error('Please select on/off value for toggle');
      return;
    }

    if (!toggleSelected) {
      toast.error('Please select toggle from top dropdown');
      return;
    }

    setLoading(true);

    try {
      const payload = {
        country: selectedCountry,
        toggleType: toggleSelected,
        toggleValue: toggleValue
      }
      const response = await axios.post(
        `${config.apiUrl}/toggle/country`,
        payload,
        {
          headers: {
            adminToken: getLocalStorageItem('adminToken'),
            othertoken: getLocalStorageItem('otherToken'),
          }
        }
      );

      const { success, msg } = response.data;

      if (!success) {
        toast.error(msg || 'Unable to save country toggle');
        setLoading(false);
        return;
      }

      toast.success(msg || 'Saved country toggle successfully');
      isFunction(actionLoggerCallback) &&
      await actionLoggerCallback(panelID, `Updated country ${toggleSelected} toggle for ${selectedCountry} to ${toggleValue}.`);
      setCountriesTogglesMapping(prevState => {
        const changedCountry = prevState[selectedCountry]
        changedCountry[currentToggle] = toggleValue;
        return {
          ...prevState,
          changedCountry
        }
      })
      setSelectedCountry('');
      setValue(null);
      setSaveDisabled(true);
      setInitialValue(null);
    } catch (e) {
      console.log('[saveCountryToggleHandler]', e);
      toast.error('Unable to save country toggle');
    }
    setLoading(false);
  }

  const showConfirmationModal = toggleFlag => {
    if (typeof toggleFlag !== 'boolean') {
      toast.error('Toggle flag can only be true or false');
      return;
    }

    $('#all-countries-update-confirmation-modal').modal('show');
    setBulkUpdate(toggleFlag)
  }

  const onAllCountriesToggleButtonClick = async (event, toggleFlag) => {
    event && event.preventDefault();

    if (!toggleSelected) {
      toast.error('Please select toggle from top dropdown');
      return;
    }

    if (![0, 1].includes(Number(toggleFlag))) {
      toast.error('Toggle flag can only be 0 or 1');
      return;
    }

    setLoading(true);

    try {
      const payload = {
        toggleType: toggleSelected,
        toggleValue: Number(toggleFlag),
      }
      const response = await axios.post(
          `${config.apiUrl}/toggle/all/countries`,
          payload,
          {
            headers: {
              adminToken: getLocalStorageItem('adminToken'),
            }
          }
      );

      const { success, msg } = response.data;

      if (!success) {
        toast.error(msg || `Unable to toggle all countries to ${Number(toggleFlag)}`);
        setLoading(false);
        return;
      }

      toast.success(msg || `All countries toggle updated to ${Number(toggleFlag)} successfully`);
      setBulkUpdate('NOT_SET');

      isFunction(actionLoggerCallback) &&
      await actionLoggerCallback(
          panelID,
          `Updated all countries ${toggleSelected} toggle to ${Number(toggleFlag)}`);
    } catch (e) {
      console.log('[onAllCountriesToggleButtonClick]', e);
      toast.error('Unable to update all countries toggle. Something went wrong. Please check');
    }
    setLoading(false);
  }

  // Teach Autosuggest how to calculate suggestions for any given input value.
  const getSuggestions = ({ value }) => {
    const inputValue = value && typeof value === 'string' && value.trim().toLowerCase();
    const inputLength = inputValue.length;

    const valueToReturn = inputLength === 0 ? [] : countries.filter(country =>
      country.label.toLowerCase().includes(inputValue))

    setFilteredCountries(valueToReturn)

    return valueToReturn
  };

  const suggestedListOptionClickHandler = country => {
    countriesTogglesMapping[country] && toggleSelected &&
    setValue(`${countriesTogglesMapping[country][toggleSelected]}`)
    // Set initial value for first time only so that we can revert back to it when required
    if (initialValue !== 0 || initialValue !== 1) {
      countriesTogglesMapping[country] && toggleSelected &&
      setInitialValue(`${countriesTogglesMapping[country][toggleSelected]}`)
    }
  }

  if(isLoading) {
    return <CircularProgress />
  }

  return (
    <div className='country-toggle-wrapper'>
      <h3>Country Toggle</h3>
      <h4>Select country</h4>
      <div className='form-wrapper'>
        <Autosuggest
          className='auto-suggest-countries-list'
          suggestions={filteredCountries}
          onSuggestionsFetchRequested={getSuggestions}
          onSuggestionsClearRequested={() => {}}
          getSuggestionValue={ option => option.label }
          renderSuggestion={option => <p onClick={() => suggestedListOptionClickHandler(option.label)}>{option.label}</p> }
          inputProps={{
            placeholder: 'Type country name here...',
            onChange: (e, { newValue }) => {
              setSelectedCountry(newValue)
              const cname = newValue && typeof newValue === 'string' && `${newValue[0].toUpperCase()}${newValue.substr(1,)}`
              if (!countriesTogglesMapping[cname]) {
                setValue(null);
              }
            },
            value: selectedCountry || ''
          }}
        />
        <FormControl component="fieldset">
          <RadioGroup
            className='country-toggle-radio-buttons-group'
            aria-label="country-toggle"
            value={value}
            name="country-toggle-radio-buttons-group"
            onChange={(event, value) => {
              setValue(value);
              setSaveDisabled(false);
              // Set initial value for first time only so that we can revert back to it when required
              if (initialValue !== 0 || initialValue !== 1) {
                countriesTogglesMapping[value] && currentToggle && countriesTogglesMapping[value] &&
                setInitialValue(`${countriesTogglesMapping[value][currentToggle]}`)
              }
            }}
          >
            <FormControlLabel value="1" control={<Radio />} label="On" />
            <FormControlLabel value="0" control={<Radio />} label="Off" />
          </RadioGroup>
        </FormControl>
        <div className='action-buttons-wrapper'>
          <Button
            variant="contained"
            color="primary"
            onClick={saveCountryToggleHandler}
            disabled={isSaveDisabled || value === initialValue}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setValue(initialValue);
              // setSelectedCountry('');
              setSaveDisabled(true);
            }}
            disabled={!value || value === initialValue}
          >
            Reset
          </Button>

          <div className='all-countries-toggle-buttons'>

            <Button
                variant="contained"
                color="primary"
                onClick={() => showConfirmationModal(true)}
                disabled={!toggleSelected}
            >
              Turn ALL countries toggle ON
            </Button>

            <Button
                variant="contained"
                color="secondary"
                onClick={() => showConfirmationModal(false)}
                disabled={!toggleSelected}
            >
              Turn ALL countries toggle OFF
            </Button>
          </div>
        </div>
      </div>

      {/*MODALS*/}
      {/* ALL COUNTRIES TOGGLE UPDATE CONFIRMATION MODAL */}
      <div id="all-countries-update-confirmation-modal" className="modal show" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content bg-success text-white" style={{ top: '120px' }}>
            <div className="modal-body text-center">
              {
                typeof bulkUpdate === 'boolean' ? (
                    <form>
                      <h3 className="text-white mb-15">UPDATE ALL COUNTRIES TOGGLE</h3>
                      <h6>
                        {`Are you sure you want to turn ${bulkUpdate ? 'on' : 'off'} all countries ${toggleSelected}?`}
                      </h6>
                      <button type="button" className="btn btn-light" data-dismiss="modal">Cancel</button>
                      <button
                          style={{ marginLeft: "8px" }}
                          onClick={event => onAllCountriesToggleButtonClick(event, bulkUpdate)}
                          type="button"
                          className="btn btn-light"
                          data-dismiss="modal"
                          disabled={!toggleSelected}
                      >
                        Confirm
                      </button>
                    </form>
                ) : (
                    <React.Fragment>
                      <h3>Something went wrong. Please close modal & try again</h3>
                      <button type="button" className="btn btn-light" data-dismiss="modal">Close</button>
                    </React.Fragment>
                )
              }
            </div>
          </div>

        </div>
      </div>

    </div>
  );
};

export default CountryToggle;
/* eslint-disable */

