import styled from 'styled-components';

export const StyledCreateWalletWrapper = styled.div`
  .wallets-data-table {
    margin-top: 10px;
    width: 90vw;
    height: 400px;
    min-height: 200px;
  }
  
  .wallets-create-section {
    .create-wallet-count-input {
      width: 25%;
      margin-bottom: 10px;
    }
  }
  
  
  .wallets-assign-section {
    ${
        ({ disabled }) => disabled && `pointer-events: none; opacity: 0.7`
    };
    
    .username-input {
      width: 25%;
      margin-bottom: 10px;
    }
   
    .mintable-wallet-radio-buttons-group {
      flex-direction: row;
    }
    
    .account-name-input {
      margin-bottom: 10px;
    }
    
    .wallet-password-input {
      margin-right: 10px;
      margin-bottom: 10px;
      width: 50%;
    }
  }
  
  .wallets-table-section {
    .filter-wallets-radio-buttons-group {
      flex-direction: row;
    }
  }
`