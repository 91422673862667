import React, {useState} from 'react';
import {StyledBeneficiaryRewardsWrapper} from "./styles";
import Dropdown from "react-dropdown";
import { getBeneficiaryRewardTablesList, isFunction } from "../../../utils";
import {Button, FormControl, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import { toast } from "react-toastify";
import config from "../../../config";
import { getLocalStorageItem } from "../../../utils/localStorage";
import axios from "axios";

const BeneficiaryRewards = ({ panelID, actionLogger }) => {
  const [tables, setTables] = useState(getBeneficiaryRewardTablesList());
  const [selectedTable, setSelectedTable] = useState('');
  const [showFormFields, setShowFormFields] = useState(false);
  const [areMnemonicsEncryptedAndValid, setMnemonicsEncryptedAndValid] = useState(false);
  const [encryptedMnemonics, setEncryptedMnemonics] = useState('');
  const [isUpsertDone, setIsUpsertDone] = useState(false);
  const [isUserExisting, setUserExisting] = useState(false);
  const [category, setCategory] = useState('');

  // Step 2 form state
  const [systemUsername, setSystemUsername] = useState('');
  const [systemAccountId, setSystemAccountId] = useState('');
  const [systemAccountKey, setSystemAccountKey] = useState('');
  const [beneficiaryUsername, setBeneficiaryUsername] = useState('');
  const [beneficiaryAccountId, setBeneficiaryAccountId] = useState('');
  // todo: only present in platinum and tech
  const [rewardPercent, setRewardPercent] = useState('');
  const [rewardValue, setRewardValue] = useState('');
  const [beneficiaryPositionDescription, setBeneficiaryPositionDescription] = useState('');
  // todo: only present in diamond, silver, gold,
  const [rewardAccounts, setRewardAccounts] = useState('');

  const onTableSelectHandler = ({ value }) => {
    setSelectedTable(value);
    setShowFormFields(false);
    setMnemonicsEncryptedAndValid(false);
    setEncryptedMnemonics('');
    setUserExisting(false);
    setSystemUsername('');
    setSystemAccountId('');
    setSystemAccountKey('');
    setBeneficiaryUsername('');
    setBeneficiaryAccountId('');
    setRewardPercent('');
    setRewardValue('');
    setBeneficiaryPositionDescription('');
    setRewardAccounts('');
  }

  const onSearchUserButtonClick = async () => {
    if (!beneficiaryUsername) {
      toast.error('Username is required');
      return;
    }

    try {
      const response = await axios.get(
        `${config.apiUrl}/admin/beneficiary-rewards/user/${beneficiaryUsername}/${selectedTable}`,
        {
          headers: {
            adminToken: getLocalStorageItem('adminToken'),
          }
        }
      );

      const { success, msg, doesUserExist, result } = response.data;

      if (!success) {
        toast.error(msg || 'Unable to find user');
        return;
      }

      console.log('DATA', { success, msg, doesUserExist, result });
      isFunction(actionLogger) &&
      await actionLogger(
        panelID,
        `Retrieved info of user with username ${beneficiaryUsername} from table ${selectedTable}. User info ${JSON.stringify(result)}`,
        beneficiaryUsername,
      );
      setShowFormFields(true);
      setUserExisting(doesUserExist);

      // If user not exists already then just return as we dont need to fill existing data
      if (!doesUserExist) return;

      // As user is already there, fill in the existing data
      const {
        reward_accounts,
        system_user_name,
        system_account_id,
        system_account_key,
        beneficiary_user_name,
        beneficiary_account_id,
        reward_percent,
        reward_value,
        beneficiary_position_description,
      } = result;

      setSystemUsername(system_user_name);
      setRewardAccounts(reward_accounts);
      setSystemAccountId(system_account_id);
      setSystemAccountKey(system_account_key);
      setBeneficiaryUsername(beneficiary_user_name);
      setBeneficiaryAccountId(beneficiary_account_id);
      setRewardPercent(reward_percent);
      setRewardValue(reward_value);
      setBeneficiaryPositionDescription(beneficiary_position_description);
      system_account_key && setMnemonicsEncryptedAndValid(true);
      setEncryptedMnemonics(system_account_key);

    } catch (e) {
      toast.error('Unable to search user');
      console.log('[onSearchUserButtonClick]', e);
    }
  }

  const resetState = () => {
    setSelectedTable('');
    setUserExisting(false);
    setCategory('');
    setShowFormFields(false);
    setRewardAccounts('');
    setSystemAccountId('');
    setSystemUsername('');
    setSystemAccountKey('');
    setBeneficiaryUsername('');
    setBeneficiaryAccountId('');
    setRewardPercent('');
    setRewardValue('');
    setBeneficiaryPositionDescription('');
    setMnemonicsEncryptedAndValid(false);
    setEncryptedMnemonics('');
    setIsUpsertDone(false);
  }

  const onEncryptMnemonicsButtonClick = async () => {
    if (!systemAccountKey) {
      toast.error('Mnemonics are required');
      return;
    }

    try {
      const response = await axios.post(
        `${config.userServicesApiUrl}/wallet/encrypt-dataV2`,
        { data: systemAccountKey },
      );
      const { data, encryptedData } = response.data;

      if (!data || !encryptedData) {
        toast.error('Unable to encrypt mnemonics');
        return;
      }

      const generateWalletAddress = await axios.post(
        `${config.apiUrl}/admin/generate-wallet-address`,
        {
          mnemonicCode: data
        },
        {
          headers: {
            adminToken: getLocalStorageItem('adminToken'),
          }
        }
      );

      const { success, address } = generateWalletAddress.data;

      if (!success || !address) {
        toast.error('Unable to match mnemonics with wallet');
        return;
      }

      if (address === systemAccountId) {
        toast.success('Encrypted and verified mnemonics successfully');
        setMnemonicsEncryptedAndValid(true);
        setEncryptedMnemonics(encryptedData);
        return
      }

      toast.error('Unable to encrypt and verify mnemonics');

    } catch (e) {
      toast.error('Unable to encrypt and verify mnemonics');
      console.log('[onEncryptMnemonicsButtonClick] ', e.response);
    }
  }

  const onUpdateAddRewardButtonClick = async () => {
    if (!selectedTable) {
      toast.error('No table is selected');
      return;
    }

    if (!encryptedMnemonics) {
      toast.error('encryptedMnemonics are required');
      return;
    }

    if (!category) {
      toast.error('Category is required');
      return;
    }

    const payload = {
      system_user_name: systemUsername,
      reward_accounts: rewardAccounts,
      system_account_id: systemAccountId,
      system_account_key: encryptedMnemonics,
      beneficiary_user_name: beneficiaryUsername,
      beneficiary_account_id: beneficiaryAccountId,
      reward_percent: rewardPercent,
      reward_value: rewardValue,
      beneficiary_position_description: beneficiaryPositionDescription,
      selected_table: selectedTable,
      isUpdating: isUserExisting,
      category: category
    }

    try {
       const response = await axios.post(
         `${config.apiUrl}/admin/beneficiary-rewards`,
         payload,
         {
           headers: {
             adminToken: getLocalStorageItem('adminToken'),
           }
         }
       );

       const { success, msg } = response.data;

       if (!success) {
         toast.error(msg);
         return;
       }
      isFunction(actionLogger) &&
      await actionLogger(
        panelID,
        `${isUserExisting ? 'Updated' : 'Added'} beneficiary reward info of user with username ${beneficiaryUsername} for table ${selectedTable}. Reward info ${JSON.stringify(payload)}`,
        beneficiaryUsername,
      );
     toast.success(msg);
     setIsUpsertDone(true);
    } catch (e) {
      toast.error('Unable to process beneficiary reward');
      console.log('[onUpdateAddRewardButtonClick]', e);
    }
  }

  const shouldShowRewardPercent = () => {
    return selectedTable === 'platform_platinum_beneficiary_reward' || selectedTable === 'platform_tech_beneficiary_reward'
  }

  const shouldShowRewardAccounts = () => {
    return [
      'platform_diamond_beneficiary_reward',
      'platform_silver_bundle_beneficiary_reward',
      'platform_special_gold_beneficiary_reward',
    ].includes(selectedTable);
  }

  return (
    <div className='section-container'>
      <h2>Add Beneficiary Rewards</h2>
      <StyledBeneficiaryRewardsWrapper>
        <div className='step1-form'>
          <Dropdown
            className='table-dropdown'
            options={tables}
            onChange={onTableSelectHandler}
            value={selectedTable}
            placeholder="Select table..."
            disabled={isUpsertDone}
          />
          <input
            className='search-input'
            type='text'
            placeholder='Please enter user name...'
            value={beneficiaryUsername}
            onChange={e => setBeneficiaryUsername(e.target.value)}
            disabled={isUpsertDone}
          />

          <div className='category-fields'>
            <FormControl component="fieldset" disabled={isUpsertDone}>
              <h6>Choose Category</h6>
              <RadioGroup
                  className='category-radio-buttons-group'
                  aria-label='category'
                  value={category}
                  name="category-radio-buttons-group"
                  onChange={(event, value) => {
                    setCategory(value)
                  }}
              >
                <FormControlLabel value="SILVER" control={<Radio />} label="1 SILVER" />
                <FormControlLabel value="GOLD" control={<Radio />} label="2 GOLD" />
                <FormControlLabel value="DIAMOND" control={<Radio />} label="3 DIAMOND" />
                <FormControlLabel value="S-BUNDLE" control={<Radio />} label="4 S-BUNDLE" />
                <FormControlLabel value="NEW_BUNDLE" control={<Radio />} label="5 New Bundle wallet" />
                <FormControlLabel value="SUPER_BUNDLE" control={<Radio />} label="6 Super Bundle wallet" />
              </RadioGroup>
            </FormControl>
          </div>

          <Button
            variant='contained'
            color='primary'
            className='search-user-button'
            disabled={!beneficiaryUsername || !selectedTable || isUpsertDone}
            onClick={onSearchUserButtonClick}
          >
            Search User
          </Button>
        </div>
        {
          showFormFields && (
            <div className='step2-form'>
              <div className='step2-form-fields'>

                <div>
                  <h6>System User Name</h6>
                  <input
                    className='search-input'
                    type='text'
                    placeholder='Please enter system user name...'
                    value={systemUsername}
                    onChange={e => setSystemUsername(e.target.value)}
                    disabled={isUpsertDone}
                  />
                </div>

                <div>
                  <h6>System Account ID</h6>
                  <input
                    className='search-input'
                    type='text'
                    placeholder='Please enter system account id...'
                    value={systemAccountId}
                    onChange={e => setSystemAccountId(e.target.value)}
                    disabled={isUpsertDone}
                  />
                </div>

                <div>
                  <h6>System Account Key (Mnemonics)</h6>
                  <input
                    className='search-input'
                    type='text'
                    placeholder='Please enter system account key (Mnemonics)...'
                    value={encryptedMnemonics || systemAccountKey}
                    onChange={e => {
                      setSystemAccountKey(e.target.value);
                      setMnemonicsEncryptedAndValid(false);
                      setEncryptedMnemonics('');
                    }}
                    disabled={isUpsertDone}
                  />
                  <Button
                    variant='contained'
                    color='primary'
                    disabled={!systemAccountKey || areMnemonicsEncryptedAndValid || isUpsertDone}
                    onClick={onEncryptMnemonicsButtonClick}
                  >
                    Encrypt Mnemonics
                  </Button>
                </div>

                <div>
                  <h6>Beneficiary Username</h6>
                  <input
                    className='search-input'
                    type='text'
                    placeholder='Please enter beneficiary username...'
                    value={beneficiaryUsername}
                    onChange={e => setBeneficiaryUsername(e.target.value)}
                    disabled={isUpsertDone}
                  />
                </div>

                <div>
                  <h6>Beneficiary Account ID</h6>
                  <input
                    className='search-input'
                    type='text'
                    placeholder='Please enter beneficiary account id...'
                    value={beneficiaryAccountId}
                    onChange={e => setBeneficiaryAccountId(e.target.value)}
                    disabled={isUpsertDone}
                  />
                </div>

                {
                  shouldShowRewardAccounts() && (
                      <div>
                        <h6>Number of Reward Accounts</h6>
                        <input
                            className='search-input'
                            type='text'
                            placeholder='Please enter number of reward accounts...'
                            value={rewardAccounts}
                            onChange={e => setRewardAccounts(e.target.value)}
                            disabled={isUpsertDone}
                        />
                      </div>
                  )
                }

                {
                  // Reward percent is not common field to all tables so showing it conditionally only
                  shouldShowRewardPercent() && (
                    <div>
                      <h6>Reward Percent</h6>
                      <input
                        className='search-input'
                        type='text'
                        placeholder='Please enter reward percent...'
                        value={rewardPercent}
                        onChange={e => setRewardPercent(e.target.value)}
                        disabled={isUpsertDone}
                      />
                    </div>
                  )
                }

                <div>
                  <h6>Reward Value</h6>
                  <input
                    className='search-input'
                    type='text'
                    placeholder='Please enter reward value...'
                    value={rewardValue}
                    onChange={e => setRewardValue(e.target.value)}
                    disabled={isUpsertDone}
                  />
                </div>

                <div>
                  <h6>Beneficiary Position Description</h6>
                  <input
                    className='search-input'
                    type='text'
                    placeholder='Please enter beneficiary position description...'
                    value={beneficiaryPositionDescription}
                    onChange={e => setBeneficiaryPositionDescription(e.target.value)}
                    disabled={isUpsertDone}
                  />
                </div>

              </div>

              <Button
                variant='contained'
                color='primary'
                className='reward-button'
                disabled={
                  !beneficiaryUsername ||
                  !areMnemonicsEncryptedAndValid ||
                  isUpsertDone ||
                  !category
                }
                onClick={onUpdateAddRewardButtonClick}
              >
                {isUserExisting ? 'Update Reward' : 'Add Reward'}
              </Button>
              <Button
                variant='contained'
                color='secondary'
                className='refresh-button'
                onClick={resetState}
              >
                Refresh
              </Button>
            </div>
          )
        }
      </StyledBeneficiaryRewardsWrapper>
    </div>
  );
};

export default BeneficiaryRewards;