import styled from "styled-components";

export const StyledCustomerWrapper = styled.div`
  width: 100%;
  
  .search-user-input-fields-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    
    div {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }

  .search-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    
    button {
      margin-right: 10px;
    }
  }
  
  .data-grid-wrapper {
    margin-top: 10px;
    height: 400px;
  }
`