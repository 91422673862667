import styled from "styled-components";

export const StyledAirdropWalletManagementWrapper = styled.div`
  .search-user-input-fields-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    
    > div {
      width: 20%;
    }

    div {
      margin-right: 10px;
    }
  }

  .search-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;

    button {
      margin-right: 10px;
    }
  }
  
  .airdrop-processing-form {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: self-end;
    justify-content: center;
    margin-top: 10px;
    
    button {
      margin-left: 10px;
    }
  }
  
  .data-grid-wrapper {
    margin-top: 10px;
    
    .airdrop-wallets-data-grid {
      width: 90vw;
    }
  }

  .wallet-password-input-box {
    width: 100%;
    background-color: #ffffff;
    font-size: 15px;
    line-height: 40px;
    height: 46px;
    max-width: 100%;
    vertical-align: middle;
    padding: 2px 15px;
    border: 1px solid #ebebeb;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-bottom: 10px;
  }

  .wallet-password-time-input-box {
    margin-bottom: 10px;
  }

  .password-remember-time-div-wrapper {
    h6 {
      text-align: start;
      font-weight: bold;
    }
  }
`;
