import config from "../config";
import JSEncrypt from 'jsencrypt';
import { CERTIFICATE_PUB } from './security/certificate';
import { Tooltip } from "@material-ui/core";
const jsEncrypt = new JSEncrypt();
jsEncrypt.setPublicKey(CERTIFICATE_PUB);

export const getMinerFeesStatusInteger = minerFeesString => {
    switch (minerFeesString) {
        case 'Fees not received':
            return 0;

        case 'Fees received':
            return 1;

        case 'Fees exempted':
            return 2;

        default:
            return 0;
    }
}

export const getMinerFeesStatusString = (minerFeesInteger) => {
    switch (minerFeesInteger) {
        case 0:
            return 'Fees not received';

        case 1:
            return 'Fees received'

        case 2:
            return 'Fees exempted'

        default:
            return 'Fees not received'
    }
}

export const isFunction = func => {
    return func && typeof func === 'function';
}

export const customerAddressTableColumnsConfig = (
    addressEditCellComponentRenderer,
    countryEditCellComponentRenderer
) => {
    return [
        { field: 'id', headerName: 'ID', width: 160, editable: false },
        { field: 'user_name', headerName: 'Username', width: 160, editable: false },
        { field: 'address_line1', headerName: 'Address Line 1', width: 300, editable: true },
        { field: 'address_line2', headerName: 'Address Line 2', width: 250, editable: true },
        { field: 'city_town_suburb', headerName: 'City/Town/Suburb', width: 175, editable: true },
        { field: 'state_province', headerName: 'State/Province', width: 175, editable: true },
        { field: 'post_code', headerName: 'Post Code', width: 175, type: 'number', editable: true },
        { field: 'country', headerName: 'Country', width: 175, editable: true, renderEditCell: countryEditCellComponentRenderer },
        { field: 'region', headerName: 'Region', width: 175, editable: true },
        { field: 'address_type', headerName: 'Address Type', width: 175, editable: true, renderEditCell: addressEditCellComponentRenderer },
    ]
}

export const customerAddressTableColumnsConfigForUserInfoPanel = (
    addressEditCellComponentRenderer,
    countryEditCellComponentRenderer
) => {
    return [
        { dbColumnName: 'id', field: 'id', headerName: 'ID', width: 160, editable: false },
        { dbColumnName: 'user_name', field: 'user_name', headerName: 'Username', width: 160, editable: false },
        { dbColumnName: 'address_line1', field: 'address_line1', headerName: 'Address Line 1', width: 300, editable: true },
        { dbColumnName: 'address_line2', field: 'address_line2', headerName: 'Address Line 2', width: 250, editable: true },
        { dbColumnName: 'city_town_suburb', field: 'city_town_suburb', headerName: 'City/Town/Suburb', width: 175, editable: true },
        { dbColumnName: 'state_province', field: 'state_province', headerName: 'State/Province', width: 175, editable: true },
        { dbColumnName: 'post_code', field: 'post_code', headerName: 'Post Code', width: 175, type: 'number', editable: true },
        { dbColumnName: 'country', field: 'country', headerName: 'Country', width: 175, editable: true, renderEditCell: countryEditCellComponentRenderer },
        { dbColumnName: 'region', field: 'region', headerName: 'Region', width: 175, editable: true },
        { dbColumnName: 'address_type', field: 'address_type', headerName: 'Address Type', width: 175, editable: true, renderEditCell: addressEditCellComponentRenderer },
    ]
}

export const customerBasicInfoTableColumnsConfig = countryEditCellComponentRenderer => {
    return [
        { field: 'id', headerName: 'ID', width: 160, editable: false },
        { field: 'user_name', headerName: 'Username', width: 160, editable: false },
        { field: 'first_name', headerName: 'First Name', width: 160, editable: true },
        { field: 'middle_name', headerName: 'Middle Name', width: 160, editable: true },
        { field: 'last_name', headerName: 'Last Name', width: 160, editable: true },
        { field: 'mobile_number', headerName: 'Mobile Number', width: 175, editable: true },
        { field: 'email', headerName: 'Email', width: 225, editable: true },
        { field: 'user_country', headerName: 'Country', width: 150, editable: true, renderEditCell: countryEditCellComponentRenderer },
        { field: 'gender', headerName: 'Gender', width: 150, editable: true },
        { field: 'account_status', headerName: 'Account Status', width: 175, editable: true },
        { field: 'is_verified', headerName: 'Is Verified?', width: 150, type: 'number', editable: true },
        { field: 'miner_fees_received', headerName: 'Miner Fees Received?', width: 225 },
        { field: 'toggle_login_otp', headerName: 'Toggle Login OTP', width: 200 },
        { field: 'toggle_kyc', headerName: 'Toggle KYC', width: 175 },
        { field: 'create_time', headerName: 'Create Time', width: 225 },
        { field: 'update_time', headerName: 'Update Time', width: 225 },
    ]
}

export const customerBasicInfoTableColumnsConfigForUserInfoPanel = countryEditCellComponentRenderer => {
    // TODO: dbColumnName for non-editable columns may not be matching with db.
    // TODO: before making a column editable, please make sure its dbColumnName is same as it is in db
    return [
        { dbColumnName: 'id', field: 'id', headerName: 'ID', width: 160 },
        { dbColumnName: 'user_name', field: 'user_name', headerName: 'Username', width: 160 },
        { dbColumnName: 'first_name', field: 'first_name', headerName: 'First Name', width: 160, editable: true },
        { dbColumnName: 'middle_name', field: 'middle_name', headerName: 'Middle Name', width: 160, editable: true },
        { dbColumnName: 'last_name', field: 'last_name', headerName: 'Last Name', width: 160, editable: true },
        { dbColumnName: 'mobile_number', field: 'mobile_number', headerName: 'Mobile Number', width: 175, editable: true },
        { dbColumnName: 'email', field: 'email', headerName: 'Email', width: 225, editable: true },
        { dbColumnName: 'country_name', field: 'user_country', headerName: 'Country', width: 150, editable: true, renderEditCell: countryEditCellComponentRenderer },
        { dbColumnName: 'gender', field: 'gender', headerName: 'Gender', width: 150, editable: true },
        { dbColumnName: 'account_status', field: 'account_status', headerName: 'Account Status', width: 175, editable: true },
        { dbColumnName: 'kyc_status', field: 'kyc_status', headerName: 'KYC Status', width: 180 },
        { dbColumnName: 'isVerified', field: 'is_verified', headerName: 'Is Verified?', width: 150, type: 'number', editable: true },
        { dbColumnName: 'wallets_count', field: 'wallets_count', headerName: 'Wallets', width: 150 },
        { dbColumnName: 'miner_fees_received', field: 'miner_fees_received', headerName: 'Miner Fees Received?', width: 225 },
        { dbColumnName: 'toggle_login_otp', field: 'toggle_login_otp', headerName: 'Toggle Login OTP', width: 200 },
        { dbColumnName: 'toggle_kyc', field: 'toggle_kyc', headerName: 'Toggle KYC', width: 175 },
        { dbColumnName: 'create_time', field: 'create_time', headerName: 'Create Time', width: 225 },
        { dbColumnName: 'update_time', field: 'update_time', headerName: 'Update Time', width: 225 },
    ]
}

export const customerInfoNonEditablePanelColumnsConfig = () => {
    return [
        { field: 'id', headerName: 'ID', width: 180 },
        { field: 'user_name', headerName: 'Username', width: 180 },
        { field: 'first_name', headerName: 'First Name', width: 180 },
        { field: 'middle_name', headerName: 'Middle Name', width: 180 },
        { field: 'last_name', headerName: 'Last Name', width: 180 },
        { field: 'mobile_number', headerName: 'Mobile Number', width: 180 },
        { field: 'platform_miner_category', headerName: 'Platform Miner Category', width: 250 },
        { field: 'email', headerName: 'Email', width: 250 },
        { field: 'country_name', headerName: 'Country', width: 180 },
        { field: 'gender', headerName: 'Gender', width: 180 },
        { field: 'account_status', headerName: 'Account Status', width: 180 },
        { field: 'kyc_status', headerName: 'KYC Status', width: 180 },
        { field: 'isVerified', headerName: 'Is Verified?', width: 180 },
        { field: 'wallets_count', headerName: 'Wallets', width: 150 },
        { field: 'miner_fees_received', headerName: 'Miner Fees Received?', width: 225 },
        { field: 'create_time', headerName: 'Create Time', width: 225 },
        { field: 'update_time', headerName: 'Update Time', width: 225 },
    ]
};

export const endOfDayRewardTableColumnsConfig = () => {
    return [
        { field: 'id', headerName: 'Process ID', width: 150 },
        { field: 'create_time', headerName: 'Create Time', width: 250 },
        { field: 'reward_value', headerName: 'Reward Value', width: 250 },
        { field: 'user_name', headerName: 'Username', width: 150 },
        { field: 'first_name', headerName: 'First Name', width: 150 },
        { field: 'last_name', headerName: 'Last Name', width: 180 },
        { field: 'mobile_number', headerName: 'Mobile Number', width: 180 },
        { field: 'account_desc', headerName: 'Account Description', width: 250 },
        { field: 'process_desc', headerName: 'Process Description', width: 900 },
        { field: 'account_id', headerName: 'Account ID', width: 550 },
        { field: 'current_balance', headerName: 'Current Balance', width: 250 },
        { field: 'block_id', headerName: 'Block ID', width: 250 },
        { field: 'block_hash', headerName: 'Block Hash', width: 550 },
        { field: 'source_block_hash', headerName: 'Source Block Hash', width: 550 },
    ]
}

export const platformMinerTableColumnsConfig = () => {
    return [
        { field: 'id', headerName: 'ID', width: 150 },
        { field: 'user_name', headerName: 'Username', width: 150 },
        { field: 'account_id', headerName: 'Account ID', width: 550 },
        { field: 'category', headerName: 'Category', width: 150 },
        { field: 'miner_account_status', headerName: 'Miner Account Status', width: 250 },
        { field: 'usi_limit', headerName: 'USI Limit', type: 'number', width: 150 },
        { field: 'create_time', headerName: 'Create Time', width: 200 },
        { field: 'c_local_time', headerName: 'Create Local Time', width: 200 },
        { field: 'update_time', headerName: 'Update Time', width: 200 },
    ]
}

export const platformMinerBookingTableColumnsConfig = () => {
    return [
        { field: 'id', headerName: 'ID', width: 150 },
        { field: 'user_name', headerName: 'Username', width: 150 },
        { field: 'category', headerName: 'Category', width: 150 },
        { field: 'create_time', headerName: 'Create Time', width: 200 },
        { field: 'c_local_time', headerName: 'Create Local Time', width: 200 },
        { field: 'update_time', headerName: 'Update Time', width: 200 },
    ]
}

export const getAvailableDatabasesList = () => {
    return config.environment === 'production' ? [
        'ai_kyc_db_prd',
        'back_office_platform_db_prd',
        'blockchain_db_prd',
        'customer_db_prd',
        'payment_platform_db_prd'
    ] : [
        'ai_kyc_db_dev',
        'back_office_platform_db_dev',
        'blockchain_db_dev',
        'customer_db_dev',
        'payment_platform_db_dev'
    ];
}

export const getBeneficiaryRewardTablesList = () => {
    return config.environment === 'production' ? [
        // TODO: Replace below names with prod table names, for now they are of dev env
        'platform_platinum_beneficiary_reward',
        'platform_diamond_beneficiary_reward',
        'platform_silver_bundle_beneficiary_reward',
        'platform_special_gold_beneficiary_reward',
        'platform_tech_beneficiary_reward'
    ] : [
        'platform_platinum_beneficiary_reward',
        'platform_diamond_beneficiary_reward',
        'platform_silver_bundle_beneficiary_reward',
        'platform_special_gold_beneficiary_reward',
        'platform_tech_beneficiary_reward'
    ];
}

export const encrypt = message => {
    if (typeof message === 'object') return {
        data: jsEncrypt.encrypt(JSON.stringify(message))
    };

    else return jsEncrypt.encrypt(message);

}

export const createWalletsTableConfig = () => {
    return [
        { field: 'id', headerName: 'ID', width: 150 },
        { field: 'user_name', headerName: 'Username', width: 200 },
        { field: 'account_id', headerName: 'Account ID', width: 575 },
        { field: 'mnemonics', headerName: 'Mnemonics', width: 575 },
        { field: 'type', headerName: 'Account Type', width: 200 },
        { field: 'publicKey', headerName: 'Public Key', width: 575 },
        { field: 'encryptedMnemonic', headerName: 'Encrypted Mnemonics', width: 575 },
        { field: 'create_time', headerName: 'Create Time', width: 200 },
    ]
}

export const businessDetailTableConfig = (handleButtonClick, loadingRowId, handleButtonClickDelete) => {
    return [
        { field: 'id', headerName: 'ID', width: 150 },
        { field: 'user_name', headerName: 'Username', width: 200 },
        { field: 'name', headerName: "Fullname", width: 250 },
        { field: 'email', headerName: "Email", width: 200 },
        { field: 'contact_phone_number', headerName: "Contact Number", width: 300 },
        { field: 'project_name', headerName: "Project Name", width: 200 },
        { field: 'project_website_url', headerName: "Website Url", width: 200 },



        {
            field: 'button',
            headerName: 'Action',
            width: 400,
            renderCell: (params) => (
                <>
                    {console.log("loadinDisable inside", loadingRowId, params.row.id + "APPROVED", params.row.id + "REJECTED")}
                    {console.log("loadinDisable condition", loadingRowId === (params.row.id + "APPROVED" || params.row.id + "REJECTED"))}

                    <button
                        onClick={() => handleButtonClick(params.row, "APPROVED")}
                        disabled={loadingRowId === params.row.id + "APPROVED" || loadingRowId === params.row.id + "REJECTED"}
                        style={{ backgroundColor: 'blue', color: 'white', padding: '5px 10px', margin: '5px 10px', border: '12', cursor: "pointer" }}
                    >
                        {loadingRowId === params.row.id + "APPROVED" ? <img style={{ height: '20px', width: '20px', marginRight: '8px' }} src="images/spinner3.gif"></img> : 'Approved Request'}
                    </button>
                    <button
                        onClick={() => handleButtonClickDelete(params.row, "REJECTED")}
                        disabled={loadingRowId === params.row.id + "APPROVED" || loadingRowId === params.row.id + "REJECTED"}
                        style={{ backgroundColor: 'red', color: 'white', padding: '5px 10px', margin: '5px 10px', border: '12', cursor: "pointer" }}
                    >
                        {loadingRowId === params.row.id + "REJECTED" ? <img style={{ height: '20px', width: '20px', marginRight: '8px' }} src="images/spinner3.gif"></img> : 'Reject Request'}
                    </button>
                </>
            ),
        },
    ]
}

export const airdropWalletsTableColumnsConfig = () => {
    return [
        { field: 'id', headerName: 'ID', width: 150 },
        { field: 'user_name', headerName: 'Username', width: 250 },
        { field: 'first_name', headerName: 'First Name', width: 250 },
        { field: 'last_name', headerName: 'Last Name', width: 250 },
        { field: 'mobile_number', headerName: 'Mobile Number', width: 250 },
        { field: 'email', headerName: 'Email', width: 250 },
        { field: 'account_id', headerName: 'Wallet Address', width: 575 },
        { field: 'category', headerName: 'Category', width: 250 },
        { field: 'airdrop_nominated_wallet', headerName: 'Wallet Nominated?', width: 200 },
        { field: 'airdrop_reward_issued', headerName: 'Reward Issued?', width: 200 },
        { field: 'airdrop_reward_value', headerName: 'Reward Value', width: 200 },
    ]
};

export const transferWalletsTableColumnsConfig = () => {
    return [
        { field: 'id', headerName: 'ID', width: 150 },
        { field: 'user_name', headerName: 'Username', width: 250 },
        { field: 'account_name', headerName: 'Account Name', width: 250 },
        // {field: 'first_name', headerName: 'First Name', width: 250},
        // {field: 'last_name', headerName: 'Last Name', width: 250},
        // {field: 'mobile_number', headerName: 'Mobile Number', width: 250},
        // {field: 'email', headerName: 'Email', width: 250},
        { field: 'account_id', headerName: 'Wallet Address', width: 575 },
        { field: 'category', headerName: 'Category', width: 250 },
        // {field: 'airdrop_nominated_wallet', headerName: 'Wallet Nominated?', width: 200},
        // {field: 'airdrop_reward_issued', headerName: 'Reward Issued?', width: 200},
        // {field: 'airdrop_reward_value', headerName: 'Reward Value', width: 200},
    ]
}

export const productDetailsTableColumnsConfig = () => {
    return [
        { field: 'id', headerName: 'ID', width: 150 },
        { field: 'item_name', headerName: 'Item Name', width: 250, editable: true },
        { field: 'category', headerName: 'Category', width: 200, editable: true },
        {
            field: 'description',
            headerName: 'Description',
            width: 750,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <span className="csutable-cell-trucate">{params.value}</span>
                </Tooltip>
            ),
            editable: true
        },
        { field: 'item_sell_price', headerName: 'Item Sell Price', width: 200, editable: true },
        {
            field: 'resource_url',
            headerName: 'URL',
            width: 250,
            renderCell: (data) => {
                let imgUrl = data?.row?.resource_url;
                if (imgUrl) {
                    imgUrl = JSON.parse(imgUrl)?.url;
                }
                return <img className='product-img' src={imgUrl} alt={`${data.row.item_name}`} />
            },
            editable: true
        },
        { field: 'create_time', headerName: 'Create Time', width: 200 },
        { field: 'update_time', headerName: 'Update Time', width: 200 },
    ]
}

export const paymentCardDetailsTableColumnsConfig = () => {
    return [
        { field: 'id', headerName: 'ID', width: 150 },
        { field: 'user_name', headerName: 'Username', width: 250 },
        { field: 'account_id', headerName: 'Account ID', width: 500 },
        { field: 'printed_status', headerName: 'Printed Status?', width: 200 },
        { field: 'create_time', headerName: 'Create Time', width: 200 },
    ]
}
