import React, {useCallback, useEffect, useState} from 'react';
import {StyledCustomerInfoWrapper, StyledUserInfoWrapper} from "./styles";
import {DataGrid} from "@mui/x-data-grid";
import {
  customerAddressTableColumnsConfigForUserInfoPanel,
  customerBasicInfoTableColumnsConfigForUserInfoPanel,
  getMinerFeesStatusString,
  isFunction
} from "../../../utils";
import {Button, CircularProgress, MenuItem, Modal, Select} from "@material-ui/core";
import {toast} from "react-toastify";
import axios from "axios";
import config from "../../../config";
import {getLocalStorageItem} from "../../../utils/localStorage";
import moment from "moment";
import { uuid } from 'uuidv4';
import { sortBy, isEqual } from 'lodash';
import Dropdown from "react-dropdown";
import {StyledModalWrapper} from "../styles";

const UserInfo = ({ actionLogger, panelID }) => {
  // Search state
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [country, setCountry] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [gender, setGender] = useState('');
  const [email, setEmail] = useState('');
  const [accountStatus, setAccountStatus] = useState('');
  const [isVerified, setIsVerified] = useState('');
  const [minerFeesReceived, setMinerFeesReceived] = useState('');
  const [kycStatus, setKycStatus] = useState('');

  // Misc
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [countryFilter, setCountryFilter] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [countryFilterAutoFocus, setCountryFilterAutoFocus] = useState(false);

  // Bulk update
  const [bulkUpdateUserInfoValue, setBulkUpdateUserInfoValue] = useState('');
  const [bulkUpdateUserInfoColumn, setBulkUpdateUserInfoColumn] = useState('');
  const [bulkUpdateUserInfoColumnOptions, setBulkUpdateUserInfoColumnOptions] = useState(
      customerBasicInfoTableColumnsConfigForUserInfoPanel()
          .filter(column => column.editable)
          .map(column => { return { value: column.dbColumnName, label: column.headerName } })
  )
  const [bulkUpdateUserAddressValue, setBulkUpdateUserAddressValue] = useState('');
  const [bulkUpdateUserAddressColumn, setBulkUpdateUserAddressColumn] = useState('');
  const [bulkUpdateUserAddressColumnOptions, setBulkUpdateUserAddressColumnOptions] = useState(
      customerAddressTableColumnsConfigForUserInfoPanel()
          .filter(column => column.editable)
          .map(column => { return { value: column.dbColumnName, label: column.headerName } })
  )

  // User info state
  const [userInfo, setUserInfo] = useState([]);
  const [editedRows, setEditedRows] = useState({});

  // User address state
  const [customerAddress, setCustomerAddress] = useState([]);
  const [editedAddressRows, setEditedAddressRows] = useState({});

  useEffect(() => {
    const fetchCountries = async () => {
      const response = await axios.get(
          `${config.apiUrl}/available-countries`,
          {
            headers: {
              adminToken: getLocalStorageItem('adminToken'),
            }
          }
      );

      const { success, msg, countries } = response.data;

      if (!success) {
        toast.error(msg || '[User Info Management Panel] Unable to get countries list');
        return;
      }
      const mappedCountries = []
      countries.forEach(country => {
        // This mapped countries array will help us fill list of countries
        mappedCountries.push(country.country_name);
      });

      setCountries(mappedCountries);
      setFilteredCountries(mappedCountries);
    };
    fetchCountries();
  }, [])

  const resetState = () => {
    setLoading(false);
    setUserInfo([]);
    setEditedRows({});
    setCustomerAddress([]);
    setEditedAddressRows({});
  }

  const resetButtonClickHandler = () => {
    setUsername('');
    setFirstName('');
    setLastName('');
    setMiddleName('');
    setCountry('');
    setMobileNumber('');
    setGender('');
    setEmail('');
    setAccountStatus('');
    setIsVerified('');
    setMinerFeesReceived('');
    setKycStatus('');

    setLoading(false);

    setUserInfo([]);
    setEditedRows({});

    setCustomerAddress([]);
    setEditedAddressRows({});

    setBulkUpdateUserInfoColumn('');
    setBulkUpdateUserInfoValue('');
    setBulkUpdateUserAddressColumn('');
    setBulkUpdateUserAddressValue('');
  }

  const getUserDetailsOnClickHandler = async () => {
    resetState();

    setLoading(true);
    const payload = {
      username: username,
      firstName: firstName,
      lastName: lastName,
      middleName: middleName,
      country: country,
      mobileNumber: mobileNumber,
      gender: gender,
      email: email,
      accountStatus: accountStatus,
      isVerified: isVerified,
      minerFeesReceived: minerFeesReceived,
      kycStatus: kycStatus,
    }
    const mappedData = {};
    const mappedAddressData = {};
    try {
      const getUser = await axios.post(
        `${config.apiUrl}/admin/user/info`,
          payload,
        {
          headers: {
            admintoken: getLocalStorageItem('adminToken'),
            othertoken: getLocalStorageItem('otherToken')
          }
        }
      );

      const { success, message, data } = getUser.data;

      if (!success) {
        console.log('before toasting /admin/user/ api error');
        toast.error(message || 'Invalid username');
        setLoading(false);
        return;
      }

      data.forEach(user => {
        const currentUserId = Object.keys(user)[0];
        const userData = user[currentUserId];
        mappedData[currentUserId] = {
          id: userData.id,
          user_name: userData.user_name,
          first_name: userData.first_name,
          middle_name: userData.middle_name || '',
          last_name: userData.last_name,
          mobile_number: userData.mobile_number,
          user_country: userData.country_name,
          mintable_wallet: userData.mintable_wallet_address,
          gender: userData.gender,
          toggle_kyc: userData.toggle_kyc,
          toggle_login_otp: userData.toggle_login_otp,
          account_status: userData.account_status,
          kyc_status: userData.kyc_status,
          is_verified: userData.isVerified,
          wallets_count: userData.wallets_count,
          email: userData.email,
          miner_fees_received: getMinerFeesStatusString(userData.miner_fees_received),
          fees_received_date: moment(userData.fees_received_date).isValid() ? moment(userData.fees_received_date).format('YYYY-MM-DD') : 'N/A',
          create_time: userData.create_time ? moment(userData.create_time).format('MMMM Do YYYY, H:mm:ss') : '',
          update_time: userData.update_time ? moment(userData.update_time).format('MMMM Do YYYY, H:mm:ss') : '',
        }
      });

      const arrayOfData = sortBy(Object.values(mappedData), ['user_name']);

      setUserInfo(arrayOfData)
    } catch (e) {
      toast.error('Invalid payload');
      setLoading(false);
      return;
    }

    // Step 2: Now get user address details and merge with basic details
    // Here first gather all the usernames for which we want to fetch address details
    const addressUsernames =  Object.values(mappedData).map(user => user.user_name);

    if (!addressUsernames.length) {
      toast.error('No username present for address fetching. Please try again searching users first.');
      return;
    }

    try {
      const customerAddress = await axios.post(
        `${config.apiUrl}/admin/get-customer-address/info`,
        { usernames: addressUsernames },
        {
          headers: {
            admintoken: getLocalStorageItem('adminToken'),
            othertoken: getLocalStorageItem('otherToken')
          }
        }
      );

      const { success, msg, data: addressData } = customerAddress.data;

      if (!success) {
        console.log('before toasting/admin/get-customer-address api error', msg);
        // toast.error(`Unable to fetch customer address details. ${msg || ''}`);
        addressUsernames.forEach((au, idx) => {
          if (!mappedAddressData[au]) {
            mappedAddressData[au] = {
              id: uuid(),
              user_name: au,
              address_line1: 'N/A',
              address_line2: 'N/A',
              city_town_suburb: 'N/A',
              state_province: 'N/A',
              post_code: 'N/A',
              country: 'N/A',
              region: 'N/A',
              address_type: 'N/A',
              create_time: '-',
              update_time: '-',
            };
          }
        });


        const addressDataArray = sortBy(Object.values(mappedAddressData), ['user_name']);

        setCustomerAddress(addressDataArray);
        // TODO: Uncomment below 2 lines when we want to restrict process if address is not there
        setLoading(false);
        return;
      }

      // If customer address for a user is not present, show data for that user with N/A so that we can at least proceed
      addressData.forEach(address => {
        const currentAddressId = Object.keys(address)[0];
        const username = address[currentAddressId].user_name;
        mappedAddressData[username] = address[currentAddressId];
      });

      addressUsernames.forEach((au, idx) => {
        if (!mappedAddressData[au]) {
          mappedAddressData[au] = {
            id: uuid(),
            user_name: au,
            address_line1: 'N/A',
            address_line2: 'N/A',
            city_town_suburb: 'N/A',
            state_province: 'N/A',
            post_code: 'N/A',
            country: 'N/A',
            region: 'N/A',
            address_type: 'N/A',
            create_time: '-',
            update_time: '-',
          };
        }
      });


      const addressDataArray = sortBy(Object.values(mappedAddressData), ['user_name']);

      setCustomerAddress(addressDataArray);
      isFunction(actionLogger) &&
      await actionLogger(panelID, `Retrieved users info with payload ${JSON.stringify(payload)}`);
    } catch (e) {
      console.log('[getKycProgressOnClickHandler] user address details', e);
      toast.error('Unable to get customer address');
      // TODO: Uncomment below 2 lines when we want to restrict process if address is not there
      setLoading(false);
      return;

      setCustomerAddress([]);
    }
    setLoading(false);
  }

  const onEditUserRowStop = ({id, row}) => {
    // First get old row and compare it value by value with new row.
    // If there is any difference then show save info button otherwise not
    const oldRow = userInfo.find(ui => ui.id === id);
    const isEdited = oldRow && !isEqual(oldRow, row);

    isEdited && setEditedRows(prevState => {
      return { ...prevState, [id]: row }
    })
  }

  const onEditAddressRowStop = ({id, row}) => {
    // First get old row and compare it value by value with new row.
    // If there is any difference then show save info button otherwise not
    const oldRow = customerAddress.find(ca => ca.id === id);
    const isEdited = oldRow && !isEqual(oldRow, row);

    isEdited && setEditedAddressRows(prevState => {
      return { ...prevState, [id]: row }
    })
  }

  const onSaveUserInfoButtonClick = async () => {
    setTableLoading(true);

    try {
      await Promise.all(Object.values(editedRows).map(async userRow => {
        const response = await axios.post(
            `${config.apiUrl}/admin/user`,
            userRow,
            {
              headers: {
                admintoken: getLocalStorageItem('adminToken'),
                othertoken: getLocalStorageItem('otherToken')
              }
            }
        );

        const { success, msg } = response.data;

        if(!success) {
          toast.error(
              `
            Username: ${userRow.user_name},\n${msg}`
          );
          return;
        }

        toast.success( msg ?
            `Username:${userRow.user_name}, ${msg}` :
            `Updated user ${userRow.user_name} info successfully`
        );
      }));

      setCountryFilter('');
      setEditedRows({});
      await getUserDetailsOnClickHandler();
    } catch (e) {
      console.log('[onSaveUserInfoButtonClick]', e);
      toast.error('Unable to update user info. Try again');
    }
    setTableLoading(false);
  }

  const onSaveEnterAddressButtonClick = async () => {
    setTableLoading(true);

    try {
      await Promise.all(Object.values(editedAddressRows).map(async addressRow => {
        const response = await axios.post(
            `${config.apiUrl}/admin/upsert-customer-address`,
            addressRow,
            {
              headers: {
                admintoken: getLocalStorageItem('adminToken'),
                othertoken: getLocalStorageItem('otherToken')
              }
            }
        );

        const { success, msg } = response.data;

        if(!success) {
          toast.error(`Username: ${addressRow.user_name}, ${msg}`);
          return;
        }
        toast.success( msg ?
            `Username:${addressRow.user_name}, ${msg}` :
            `Updated ${addressRow.user_name} address info successfully`
        );
      }));
      setCountryFilter('');
      setEditedAddressRows({});
      await getUserDetailsOnClickHandler();
    } catch (e) {
      console.log('[onSaveEnterAddressButtonClick]', e);
      toast.error('Unable to save address. Try again');
    }
    setTableLoading(false);
  }

  const shouldShowSaveUserInfoButton = () => {
    const isNewUserInfoEmpty = Object.keys(editedRows);
    return isNewUserInfoEmpty.length > 0;
  }

  const shouldShowSaveAddressButton = () => {
    const isNewAddressEmpty = Object.keys(editedAddressRows);
    return isNewAddressEmpty.length > 0;
  }

  const shouldActionButtonsDisable = () => {
    return !(
        username ||
        firstName ||
        lastName ||
        middleName ||
        country ||
        mobileNumber ||
        gender ||
        email ||
        accountStatus ||
        isVerified ||
        minerFeesReceived ||
        kycStatus
    )
  }

  const renderEditAddressCell = params => {
    return <EditAddressEditCell {...params} />
  }

  const EditAddressEditCell = ({ id, value, api, field }) => {

    const onChangeHandler = option => {
      api.setEditCellValue({id, field, value: option.value }, option);
    }

    return (
        <Dropdown
            className='address-type-dropdown'
            options={['Resident', 'Work']}
            onChange={onChangeHandler}
            value={value}
            placeholder="Select address type..."
        />
    );
  };

  const renderEditCountryCell = params => <UpdateCountryEditCell {...params} />

  const UpdateCountryEditCell = ({ id, value, api, field }) => {

    const onChangeHandler = event => {
      api.setEditCellValue({id, field, value: event.target.value }, event);
    }

    return (
        <React.Fragment>
          <input
              autoFocus={countryFilterAutoFocus}
              className='search-input'
              type='text'
              placeholder='Please enter country name...'
              value={countryFilter}
              onChange={e => {
                const value = e.target.value;
                setCountryFilter(value);
                setFilteredCountries(countries.filter(country => country.toLowerCase().includes(value.toLowerCase())));
                setCountryFilterAutoFocus(true)
              }}
              onBlur={() => setCountryFilterAutoFocus(false)}
          />
          <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              open={showDropdown}
              onClose={() => setShowDropdown(false)}
              onOpen={() => setShowDropdown(true)}
              value={value}
              onChange={onChangeHandler}
          >
            {
              filteredCountries.map(country => <MenuItem value={country}>{country}</MenuItem>)
            }
          </Select>
        </React.Fragment>
    );
  };

  const onUserInfoUpdateAllButtonClick = async event => {
    event && event.preventDefault();
    const usernames = userInfo.map(user => user.user_name);
    await bulkUpdateHandler('users', usernames, bulkUpdateUserInfoColumn, bulkUpdateUserInfoValue, 'user');
    setBulkUpdateUserInfoValue('');
    setBulkUpdateUserInfoColumn('');
  }

  const onUserAddressUpdateAllButtonClick = async event => {
    event && event.preventDefault();
    const usernames = customerAddress.map(address => address.user_name);
    await bulkUpdateHandler('customer_address', usernames, bulkUpdateUserAddressColumn, bulkUpdateUserAddressValue, 'address');
    setBulkUpdateUserAddressValue('');
    setBulkUpdateUserAddressColumn('');
  }

  const bulkUpdateHandler = async (tableName, usernames, column, value, reqType) => {
    if (!tableName) {
      toast.error('table_name is required');
      return;
    }

    if (!usernames) {
      toast.error('usernames are required');
      return;
    }

    if (!column) {
      toast.error('column is required');
      return;
    }

    if (!value) {
      toast.error('value is required');
      return;
    }

    if (!reqType) {
      toast.error('reqType is required');
      return;
    }

    if (!['user', 'address'].includes(reqType)) {
      toast.error('reqType can only be "user" or "address"');
      return;
    }

    setTableLoading(true);

    try {
      const payload = {
        table_name: tableName,
        column: column,
        value: value,
        usernames: usernames,
        reqType: reqType,
      }
      const response = await axios.post(
          `${config.apiUrl}/admin/update/column`,
          payload,
          { headers: { admintoken: getLocalStorageItem('adminToken') } }
      );

      const { success, msg, updatedRowsFetched, updatedRows } = response.data;

      if(!success) {
        toast.error(msg || 'Unable to update column values in bulk');
        setTableLoading(false);
        return;
      }

      toast.success(msg || 'Update column values successfully');

      if (!updatedRowsFetched) {
        toast.error('Please press Get Details button to see updated data');
        return;
      }

      if (reqType === 'user') {
        const mappedData = {};
        updatedRows.forEach(user => {
          const currentUserId = Object.keys(user)[0];
          const userData = user[currentUserId];
          mappedData[currentUserId] = {
            id: userData.id,
            user_name: userData.user_name,
            first_name: userData.first_name,
            middle_name: userData.middle_name || '',
            last_name: userData.last_name,
            mobile_number: userData.mobile_number,
            user_country: userData.country_name,
            mintable_wallet: userData.mintable_wallet_address,
            gender: userData.gender,
            toggle_kyc: userData.toggle_kyc,
            toggle_login_otp: userData.toggle_login_otp,
            account_status: userData.account_status,
            kyc_status: userData.kyc_status,
            is_verified: userData.isVerified,
            wallets_count: userData.wallets_count,
            email: userData.email,
            miner_fees_received: getMinerFeesStatusString(userData.miner_fees_received),
            fees_received_date: moment(userData.fees_received_date).isValid() ? moment(userData.fees_received_date).format('YYYY-MM-DD') : 'N/A',
            create_time: userData.create_time ? moment(userData.create_time).format('MMMM Do YYYY, H:mm:ss') : '',
            update_time: userData.update_time ? moment(userData.update_time).format('MMMM Do YYYY, H:mm:ss') : '',
          }
        });
        const arrayOfData = sortBy(Object.values(mappedData), ['user_name']);
        setUserInfo(arrayOfData)
      } else if (reqType === 'address') {
        const mappedAddressData = {};
        updatedRows.forEach(address => {
          const currentAddressId = Object.keys(address)[0];
          const username = address[currentAddressId].user_name;
          mappedAddressData[username] = address[currentAddressId];
        });

        usernames.forEach((au, idx) => {
          if (!mappedAddressData[au]) {
            mappedAddressData[au] = {
              id: uuid(),
              user_name: au,
              address_line1: 'N/A',
              address_line2: 'N/A',
              city_town_suburb: 'N/A',
              state_province: 'N/A',
              post_code: 'N/A',
              country: 'N/A',
              region: 'N/A',
              address_type: 'N/A',
              create_time: '-',
              update_time: '-',
            };
          }
        });
        const addressDataArray = sortBy(Object.values(mappedAddressData), ['user_name']);
        setCustomerAddress(addressDataArray);
      }
    } catch (e) {
      console.log('[bulkUpdateHandler]', e);
      toast.error('Unable to update info in bulk. Try again');
    }
    setTableLoading(false);
  }

  const getLoadingModalBody = () => {
    return (
        <StyledModalWrapper className='loading-modal-body'>
          <img src={'/images/loader.svg'} alt='Loader' />
        </StyledModalWrapper>
    )
  };

  return (
    <div className='section-container'>
      <h2>User Information Management</h2>
      <h6>
        Wildcards can be used in search boxes, for example: <b>%</b> or <b>__</b>
        <br />
        For searching a filed for empty/null values, use <b>###</b> as its value
      </h6>
      <StyledUserInfoWrapper>
        <div className='search-user-input-fields-wrapper'>
          <div>
            <h6>Username</h6>
            <input
                className='search-input'
                type='text'
                placeholder='Please enter user name...'
                value={username}
                onChange={e => setUsername(e.target.value)}
            />
          </div>

          <div>
            <h6>First Name</h6>
            <input
                className='search-input'
                type='text'
                placeholder='Please enter first name...'
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
            />
          </div>

          <div>
            <h6>Middle Name</h6>
            <input
                className='search-input'
                type='text'
                placeholder='Please enter middle name...'
                value={middleName}
                onChange={e => setMiddleName(e.target.value)}
            />
          </div>

          <div>
            <h6>Last Name</h6>
            <input
                className='search-input'
                type='text'
                placeholder='Please enter last name...'
                value={lastName}
                onChange={e => setLastName(e.target.value)}
            />
          </div>

          <div>
            <h6>County</h6>
            <input
                className='search-input'
                type='text'
                placeholder='Please enter country name...'
                value={country}
                onChange={e => setCountry(e.target.value)}
            />
          </div>

          <div>
            <h6>Mobile Number</h6>
            <input
                className='search-input'
                type='text'
                placeholder='Please enter mobile number...'
                value={mobileNumber}
                onChange={e => setMobileNumber(e.target.value)}
            />
          </div>

          <div>
            <h6>Gender</h6>
            <input
                className='search-input'
                type='text'
                placeholder='Please enter gender...'
                value={gender}
                onChange={e => setGender(e.target.value)}
            />
          </div>

          <div>
            <h6>Email</h6>
            <input
                className='search-input'
                type='text'
                placeholder='Please enter email...'
                value={email}
                onChange={e => setEmail(e.target.value)}
            />
          </div>

          <div>
            <h6>Account Status</h6>
            <input
                className='search-input'
                type='text'
                placeholder='Please enter account status...'
                value={accountStatus}
                onChange={e => setAccountStatus(e.target.value)}
            />
          </div>

          <div>
            <h6>Is Verified?</h6>
            <input
                className='search-input'
                type='text'
                placeholder='Please enter is verified...'
                value={isVerified}
                onChange={e => setIsVerified(e.target.value)}
            />
          </div>

          <div>
            <h6>Miner Fees received</h6>
            <input
                className='search-input'
                type='text'
                placeholder='Please enter miner fees status (0 or 1)'
                value={minerFeesReceived}
                onChange={e => setMinerFeesReceived(e.target.value)}
            />
          </div>

          <div>
            <h6>KYC Status</h6>
            <input
                className='search-input'
                type='text'
                placeholder='Please enter KYC Status...'
                value={kycStatus}
                onChange={e => setKycStatus(e.target.value)}
            />
          </div>

        </div>
        <div className='search-button-wrapper'>
          <Button
              variant="contained"
              color="primary"
              onClick={getUserDetailsOnClickHandler}
              disabled={shouldActionButtonsDisable()}
          >
            Get Details
          </Button>

          <Button
              variant="contained"
              color="secondary"
              onClick={resetButtonClickHandler}
              disabled={shouldActionButtonsDisable()}
          >
            Refresh
          </Button>
        </div>
      </StyledUserInfoWrapper>

      <div className='user-info-section'>
        {
          userInfo && Array.isArray(userInfo) && userInfo.length ? (
              <StyledCustomerInfoWrapper>
                <h3>User Details</h3>
                <div className='bulk-actions-wrapper'>
                  <h5>
                    For bulk update, please select required column from below dropdown and provide value to
                    apply that value to all fetched rows
                  </h5>
                  <div className='bulk-action-form'>

                    {/*Bulk update dropdown*/}
                    <div>
                      <h6>Bulk Update Column</h6>
                      <Dropdown
                          className='bulk-update-column-dropdown'
                          options={bulkUpdateUserInfoColumnOptions}
                          onChange={(option) => {
                            setBulkUpdateUserInfoColumn(option.value);
                            setBulkUpdateUserInfoValue('');
                          }}
                          value={bulkUpdateUserInfoColumn}
                          placeholder="Select bulk update column..."
                      />
                    </div>

                    {/*Bulk update value input*/}
                    <div>
                      <h6>Bulk Update Value</h6>
                      <input
                          className='search-input'
                          type='text'
                          placeholder='Please enter bulk update value...'
                          value={bulkUpdateUserInfoValue}
                          onChange={e => setBulkUpdateUserInfoValue(e.target.value)}
                      />
                    </div>

                    {/*Bulk update submit button*/}
                    <div>
                      <Button
                          className='bulk-update-button'
                          variant='contained'
                          color='primary'
                          onClick={onUserInfoUpdateAllButtonClick}
                          disabled={!bulkUpdateUserInfoValue || !bulkUpdateUserInfoColumn}
                      >
                        Update All Rows
                      </Button>
                    </div>

                  </div>
                </div>
              </StyledCustomerInfoWrapper>
          ) : <></>
        }
        {
          userInfo && Array.isArray(userInfo) && userInfo.length ? (
            <StyledCustomerInfoWrapper>
              <DataGrid
                className='user-basic-info-data-grid'
                editMode="row"
                rows={userInfo}
                columns={customerBasicInfoTableColumnsConfigForUserInfoPanel(renderEditCountryCell)}
                onRowEditStop={onEditUserRowStop}
                loading={tableLoading}
                pageSize={100}
                autoHeight
              />
              {
                shouldShowSaveUserInfoButton() && (
                  <Button
                    className='save-user-info-button'
                    variant='contained'
                    color='primary'
                    onClick={() => onSaveUserInfoButtonClick()}
                  >
                    Save User Info
                  </Button>
                )
              }
              <hr />
            </StyledCustomerInfoWrapper>
          ) : <></>
        }

        {/*Bulk Update Address*/}
        {
          customerAddress && Array.isArray(customerAddress) && customerAddress.length ? (
              <StyledCustomerInfoWrapper>
                <h3>Address Details</h3>
                <div className='bulk-actions-wrapper'>
                  <h5>
                    For bulk update, please select required column from below dropdown and provide value to
                    apply that value to all fetched rows
                  </h5>
                  <div className='bulk-action-form'>

                    {/*Bulk update dropdown*/}
                    <div>
                      <h6>Bulk Update Column</h6>
                      <Dropdown
                          className='bulk-update-column-dropdown'
                          options={bulkUpdateUserAddressColumnOptions}
                          onChange={(option) => {
                            setBulkUpdateUserAddressColumn(option.value);
                            setBulkUpdateUserAddressValue('');
                          }}
                          value={bulkUpdateUserAddressColumn}
                          placeholder="Select bulk update column..."
                      />
                    </div>

                    {/*Bulk update value input*/}
                    <div>
                      <h6>Bulk Update Value</h6>
                      <input
                          className='search-input'
                          type='text'
                          placeholder='Please enter bulk update value...'
                          value={bulkUpdateUserAddressValue}
                          onChange={e => setBulkUpdateUserAddressValue(e.target.value)}
                      />
                    </div>

                    {/*Bulk update submit button*/}
                    <div>
                      <Button
                          className='bulk-update-button'
                          variant='contained'
                          color='primary'
                          onClick={onUserAddressUpdateAllButtonClick}
                          disabled={!bulkUpdateUserAddressValue || !bulkUpdateUserAddressColumn}
                      >
                        Update All Rows
                      </Button>
                    </div>

                  </div>
                </div>
              </StyledCustomerInfoWrapper>
          ) : <></>
        }

        {
          customerAddress && Array.isArray(customerAddress) && customerAddress.length ? (
            <StyledCustomerInfoWrapper>
              <DataGrid
                className='customer-address-data-grid'
                editMode="row"
                rows={customerAddress}
                columns={
                  customerAddressTableColumnsConfigForUserInfoPanel(
                      renderEditAddressCell,
                      renderEditCountryCell
                  )
                }
                onRowEditStop={onEditAddressRowStop}
                loading={tableLoading}
                pageSize={100}
                autoHeight
              />
              {
                shouldShowSaveAddressButton() && (
                  <Button
                    className='save-customer-address-button'
                    variant='contained'
                    color='primary'
                    onClick={() => onSaveEnterAddressButtonClick()}
                  >
                    Save Address Details
                  </Button>
                )
              }
              <hr />
            </StyledCustomerInfoWrapper>
          ) : <></>
        }
      </div>
      {/*Loading Modal*/}
      {
        <Modal
            disablePortal
            open={loading}
            onClose={() => {}}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
          {getLoadingModalBody()}
        </Modal>
      }
    </div>
  );
};

export default UserInfo
