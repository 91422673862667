import styled from "styled-components";

export const StyledBulkEmailWrapper = styled.div`
  width: 100%;
  
  .email-form-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    
    .left-section, .right-section {
      display: flex;
      width: 80%;
      flex-direction: column;
    }
    
    .left-section {
      margin-right: 20px;
      
      #multiline-names , #multiline-emails {
        height: 50px;
      }
    }
    
    .right-section {
      margin-left: 20px;
      
      #body, #subject {
        background-color: transparent;
        border: none;
      }
    }
  }
  
  .buttons-wrapper {
    margin-top: 10px;
    button {
      margin-right: 20px;
    }
  }
`
