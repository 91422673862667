import styled from 'styled-components'

export const StyledPlatformMinerUpdateWrapper = styled.div`
    .form-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      
      .form-fields {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        
        div {
          margin-right: 10px;
        }
      }
      
      .form-action-buttons {
        button {
          margin-right: 10px;
        }
      }
    }
`