import React, { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup
} from "@material-ui/core";
import { toast } from "react-toastify";
import axios from "axios";
import config from "../../../config";
import { getLocalStorageItem } from "../../../utils/localStorage";
import { isFunction } from "../../../utils";

const PlatformToggle = ({ toggleSelected, platformTogglesState, actionLoggerCallback, panelID }) => {
  const [initialValue, setInitialValue] = useState(null);
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [togglesState, setTogglesState] = useState(() => platformTogglesState);
  const [isSaveDisabled, setSaveDisabled] = useState(true);

  useEffect(() => {
    setTogglesState(platformTogglesState)
  }, [platformTogglesState])

  useEffect(() => {
    if (togglesState[toggleSelected] === 0 || togglesState[toggleSelected] === 1) {
      setValue(`${togglesState[toggleSelected]}`)
      // Set initial value for first time only so that we can revert back to it when required
      if (initialValue !== 0 || initialValue !== 1) setInitialValue(`${togglesState[toggleSelected]}`)
    }
  }, [toggleSelected, platformTogglesState, togglesState])

  const savePlatformToggleHandler = async () => {
    const toggleValue = parseInt(value);
    if (![0,1].includes(toggleValue)) {
      toast.error('Please select on/off value for toggle');
      return;
    }

    if (!toggleSelected) {
      toast.error('Please select toggle from top dropdown');
      return;
    }

    setLoading(true);

    try {
      const payload = {
        toggleType: toggleSelected,
        toggleValue: toggleValue
      }
      const response = await axios.post(
        `${config.apiUrl}/toggle/platform`,
        payload,
        {
          headers: {
            adminToken: getLocalStorageItem('adminToken'),
            othertoken: getLocalStorageItem('otherToken'),
          }
        }
      );

      const { success, msg } = response.data;

      if (!success) {
        toast.error(msg || 'Unable to save platform toggle');
        setLoading(false);
        return;
      }

      toast.success(msg || 'Saved platform toggle successfully');
      isFunction(actionLoggerCallback) &&
      await actionLoggerCallback(panelID, `Updated platform ${toggleSelected} to ${toggleValue}.`);
      setValue(null);
      setSaveDisabled(true);
      setTogglesState(prevState => {
        return {
          ...prevState,
          [toggleSelected]: toggleValue
        }
      })
    } catch (e) {
      console.log('[savePlatformToggleHandler]', e);
      toast.error('Unable to save platform toggle');
    }
    setLoading(false);
  }

  if (loading) {
    return <CircularProgress />
  }

  return (
    <div className='platform-toggle-wrapper'>
      <h3>Platform Toggle</h3>
      <FormControl component="fieldset">
        <FormLabel component="legend">Turn platform toggle ON/OFF</FormLabel>
        <RadioGroup
          className='platform-toggle-radio-buttons-group'
          aria-label="platform-toggle"
          value={value}
          name="platform-toggle-radio-buttons-group"
          onChange={(event, value) => {
            setValue(value);
            setSaveDisabled(false);
            // Set initial value for first time only so that we can revert back to it when required
            if (initialValue !== 0 || initialValue !== 1) setInitialValue(`${togglesState[toggleSelected]}`)
          }}
        >
          <FormControlLabel value="1" control={<Radio />} label="On" />
          <FormControlLabel value="0" control={<Radio />} label="Off" />
        </RadioGroup>
      </FormControl>
      <div className='action-buttons-wrapper'>
        <Button variant="contained" color="primary" onClick={savePlatformToggleHandler} disabled={isSaveDisabled || value === initialValue}>Save</Button>
        <Button variant="contained" color="secondary" onClick={() => {
          setValue(initialValue);
          setSaveDisabled(true);
        }} disabled={!value || value === initialValue}>Reset</Button>
      </div>
    </div>
  );
};

export default PlatformToggle;
