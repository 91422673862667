import styled from 'styled-components'

export const StyledAdminPanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  overflow: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  ::-webkit-scrollbar {
    display: none;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: whitesmoke;
    width: 100%;
    padding: 0 20px;
    border-bottom: 1px solid black;
    
    h1 {
      color: black;
      text-align: center;
    }
  }
  
  .section-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    width: 100vw;
    border: 1px solid white;
    background-color: whitesmoke;
    color: black;
    margin-bottom: 20px;
    
    hr {
      height: 1px;
      background-color: black;
    }
    
    .miner-fees-form {
      width: 100vw;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 20px;

      input {
        width: 80%;
      }

      .miner-fees-status-dropdown {
        width: 15%;
      }
      
      .miner-fees-update-input-box {
        width: 60%;
      }
    }
    
    .miner-fees-update-button {
      width: 100%;
      button {
        width: 100%;
      }
    }
    
    .kyc-docs-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      
      .kyc-status-title {
        text-align: center;
      }
      
      .photo-card {
        margin: 20px;
        width: 200px;
        display: flex;
        flex-direction: column;
        
        .kyc-img {
          object-fit: cover;
          cursor: pointer;
        }
      }
      
      .kyc-docs-section {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
      }

      input[type='file'] {
        color: rgba(0, 0, 0, 0)
      }
      
      .complete-kyc-button {
        margin-top: 10px;
        width: 100%;
      }
    }
  }
  
  .admin-panel-title {
    color: white;
  }
  
  font-family: Poppins, "sans-serif";
  
  .btn {
    color: #fff;
    padding: 14px 30px;
    background: #182231;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    -moz-transition: 0.5s;
    /*box-shadow: 0 0 12px 0 #fff;*/
    /* transition: 0.5s; */
    background-image: linear-gradient(40deg, #182231 20%, #000 51%, #000 90%);
    background-position: left center;
    font-weight: 700;
    text-transform: uppercase;
  }
`

export const StyledModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: whitesmoke;
  margin: 50px;
  overflow: scroll;
  
  &.notif-modal-body {
    height: 50vh;
    width: 50vw;
    position: absolute;
    left: 50%;
    top: 47%;
    transform: translate(-50%, -50%);
  }

  &.show-file-path-modal-body {
    height: 50vh;
    position: absolute;
    left: 50%;
    top: 47%;
    transform: translate(-50%, -50%);
    width: fit-content;
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    padding: 20px;
    
    p {
      margin-bottom: 20px;
    }
    
    .action-buttons-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      
      button {
        margin: 0 5px;
      }
    }
    
  }
  
  &.loading-modal-body {
    background-color: transparent;
    height: 100vh;
    overflow: hidden;
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  
  .content-wrapper {
    padding: 50px;
    display: flex;
    justify-content: space-between;
    
    .left-section,
    .right-section {
      padding: 10px;
      width: 50%;
    }
    
    .left-section {
      div {
        display: flex;
        align-items: center;
        height: 50vh;
        justify-content: center;
        
        &.get-ocr-text-section {
          display: flex;
          flex-direction: column;
          overflow: scroll;
          height: 50vh;
          margin-top: 20px;
          ::-webkit-scrollbar {
            display: none;
          }
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
        }

          .ocr-text-section {
            border: 1px solid black;
            padding: 30px;
            overflow: scroll;
          }
          
          .ocr-text {
            white-space: break-spaces;
            height: 40vh;
          }
        }
      }
      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
    
    .right-section {
      color: black;
      input {
        margin-bottom: 5px;
      }
    }
  }

  .action-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    
    .reject-button {
      margin-right: 10px;
    }
    
    .accept-button {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  
`
