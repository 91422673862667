import React, {useState} from 'react';
import {StyledReferralCodeGeneratorWrapper} from "./styles";
import {Button, CircularProgress} from "@material-ui/core";
import {toast} from "react-toastify";
import config from "../../../config";
import {getLocalStorageItem} from "../../../utils/localStorage";
import axios from "axios";
import {isFunction} from "../../../utils";

const ReferralCodeGenerator = ({ panelID, actionLogger }) => {

  const [walletAddress, setWalletAddress] = useState('');
  const [username, setUsername] = useState('');
  const [loading, setLoading] = useState(false);
  const [codeResponse, setCodeResponse] = useState('');

  const resetFields = (event = null) => {
    event && event.preventDefault();
    setWalletAddress('');
    setUsername('');
    setLoading(false);
    setCodeResponse('');
  }

  const shouldRefreshButtonDisabled = () => {
    return (
      !walletAddress ||
      !username
    )
  }

  const handleGenerateCodeButtonClick = async event => {
    event.preventDefault();
    setCodeResponse('');
    setLoading(true);
    try {
      const response = await axios.get(
        `${config.apiUrl}/generate_referral`,
        {
          params: {
            wallet: walletAddress,
            username: username
          },
          headers: {
            adminToken: getLocalStorageItem('adminToken'),
          }
        }
      );

      const { success, message } = response.data;

      if (!success) {
        toast.error(message || 'Unable to generate referral code');
        setLoading(false);
        return;
      }

      toast.success(message || 'Generated referral code successfully');
      setCodeResponse(response.data);
      isFunction(actionLogger) &&
      await actionLogger(
        panelID,
        `Referral code generated for username ${username} against wallet ${walletAddress}`,
        username
      );
    }
     catch (e) {
      const { data: { message } = {} } = e.response || {};
      toast.error(message || 'Unable to generate referral code');
    }

    setLoading(false);
  }

  const handleGetCodeButtonClick = async event => {
    event.preventDefault();
    setCodeResponse('');
    setLoading(true);
    try {
      const response = await axios.get(
          `${config.apiUrl}/get_referral_code`,
          {
            params: {
              username: username
            },
            headers: {
              adminToken: getLocalStorageItem('adminToken'),
            }
          }
      );

      const { success, message } = response.data;

      if (!success) {
        toast.error(message || 'Unable to get referral code');
        setLoading(false);
        return;
      }

      toast.success(message || 'Retrieved referral code successfully');
      setCodeResponse(response.data);
      isFunction(actionLogger) &&
      await actionLogger(
          panelID,
          `Referral code retrieved for username ${username}`,
          username
      );
    }
    catch (e) {
      const { data: { message } = {} } = e.response || {};
      toast.error(message || 'Unable to retrieve referral code');
    }

    setLoading(false);
  }

  if (loading) {
    return (
      <CircularProgress />
    );
  }

  return (
    <div className='section-container'>
      <h2>Generate Referral Code</h2>
      <StyledReferralCodeGeneratorWrapper>
        <div className='search-input-fields-wrapper'>
          <div>
            <h6>Wallet Address</h6>
            <input
              className='search-input'
              type='text'
              placeholder='Please enter wallet address...'
              value={walletAddress}
              onChange={e => setWalletAddress(e.target.value)}
            />
          </div>

          <div>
            <h6>Username</h6>
            <input
              className='search-input'
              type='text'
              placeholder='Please enter user name...'
              value={username}
              onChange={e => setUsername(e.target.value)}
            />
          </div>
        </div>
        <div className='search-button-wrapper'>
          <Button
            variant='contained'
            color='primary'
            onClick={handleGenerateCodeButtonClick}
            disabled={shouldRefreshButtonDisabled()}
          >
            Generate Code
          </Button>
          <Button
            variant='contained'
            color='secondary'
            onClick={resetFields}
            disabled={shouldRefreshButtonDisabled()}
          >
            Refresh
          </Button>
          <Button
              variant='contained'
              color='primary'
              onClick={handleGetCodeButtonClick}
              disabled={!username}
          >
            Get Code
          </Button>
        </div>
        {
          codeResponse && (
            <pre>
              {JSON.stringify(codeResponse, null, 4 )}
            </pre>
          )
        }
      </StyledReferralCodeGeneratorWrapper>
    </div>
  );
};

export default ReferralCodeGenerator;