import React, {useState} from 'react';
import {StyledWalletInfoWrapper} from "./styles";
import {Button} from "@material-ui/core";
import {toast} from "react-toastify";
import axios from "axios";
import config from "../../../config";
import {getLocalStorageItem} from "../../../utils/localStorage";
import {encrypt, isFunction} from "../../../utils";

const WalletInformation = ({ actionLogger, panelID }) => {
  const [value, setValue] = useState('');
  const [walletsInfo, setWalletsInfo] = useState('');

  const fetchLoggedInUserToken = async () => {
    const payload = {
      username: 'luaymm',
      password: 'luaymm@2020',
    }

    try {
      const response = await axios.post(
        `${config.apiUrl}/admin/generate_jwt_token`,
        encrypt(payload)
      );
      const { success, access_token } = response.data;
      if (!success) return false;

      return access_token;
    } catch (e) {
      console.log('[fetchLoggedInUserToken]', e);
      toast.error('Unable to fetch logged in user token');
      return false;
    }
  }

  const searchByUsernameHandler = async (username = null) => {
    if (!value) {
      toast.error('Username is required');
      return
    }

    setWalletsInfo('');

    try {
      const token = await fetchLoggedInUserToken();
      if (!token) {
        toast.error('Unable to fetch logged in user token');
        return;
      }

      const response = await axios.get(
        `${config.apiUrl}/admin/wallets/${username || value}`,
        {
          headers: {
            authorization: token,
            admintoken: getLocalStorageItem('adminToken'),
            othertoken: getLocalStorageItem('otherToken')
          }
        }
      );
      const { success, data, msg } = response.data;

      if (!success) {
        toast.error(msg || 'Unable to fetch wallet details');
        return;
      }
      isFunction(actionLogger)
      && await actionLogger(panelID, `Retrieved wallets info of username ${username || value}.`, username || value);
      setWalletsInfo(data);
    }
    catch (e) {
      console.log('[searchByUsernameHandler]', e);
      toast.error('Unable to get wallets by username');
    }
  }

  const searchByWalletAddressHandler = async () => {
    if (!value) {
      toast.error('Wallet address is required');
      return;
    }

    try {
      const response = await axios.get(
        `${config.apiUrl}/admin/wallets-info/${value}`,
        {
          headers: {
            admintoken: getLocalStorageItem('adminToken'),
            othertoken: getLocalStorageItem('otherToken')
          }
        }
      );

      const { success, msg, data: { username } } = response.data;

      if (!success) {
        toast.error(msg || 'Unable to fetch wallets details by wallet address');
        return;
      }

      if (!username) {
        toast.error(msg || 'Unable to fetch username by wallet address');
        return;
      }

      await searchByUsernameHandler(username);
    } catch (e) {
      console.log('[searchByWalletAddressHandler]', e);
      toast.error('Unable to fetch wallet details by wallet address');
    }
  }

  return (
    <div className='section-container'>
      <h2>Wallets Information</h2>
      <StyledWalletInfoWrapper>
        <div className='form-wrapper'>
          <input
            className='search-input'
            type='text'
            placeholder='Please enter user name or wallet address...'
            value={value}
            onChange={e => setValue(e.target.value)}
          />
          <Button
            disabled={!value}
            variant="contained"
            color="primary"
            onClick={() => searchByUsernameHandler()}
          >
            Search by Username
          </Button>
          <Button
            disabled={!value}
            variant="contained"
            color="primary"
            onClick={searchByWalletAddressHandler}
          >
            Search by Wallet Address
          </Button>
        </div>
        {
          walletsInfo && (
            <pre>{JSON.stringify(walletsInfo, null, 4)}</pre>
          )
        }
      </StyledWalletInfoWrapper>
    </div>
  );
};

export default WalletInformation;