import styled from "styled-components";

export const StyledCSVDataFetchWrapper = styled.div`
  width: 100%;

  .database-dropdown, .database-tables-dropdown {
    width: 25%;
  }

  .database-tables-dropdown {
    margin-top: 10px;
  }
  
  .columns-list-wrapper {
    height: 200px;
    width: 25%;
    overflow: scroll;
    margin-top: 20px;
    background: white;
  }
  
  .buttons-wrapper {
    margin-top: 20px;
    button {
      margin-right: 10px;
    }
  }
`