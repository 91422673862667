import React, {useEffect, useState} from 'react';
import PlatformToggle from "./platform-toggle";
import CountryToggle from "./country-toggle";
import UserToggle from "./user-toggle";
import { StyledTogglesWrapper } from "./styles";
import Dropdown from "react-dropdown";
import config from '../../../config'
import axios from "axios";
import {getLocalStorageItem} from "../../../utils/localStorage";
import {toast} from "react-toastify";

const ToggleManagement = ({ actionLogger, panelID }) => {
  const [toggleOptions, setToggleOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [togglesState, setTogglesState] = useState({});

  useEffect(() => {
    const fetchToggleOptions = async () => {
      const response = await axios.get(
        `${config.apiUrl}/available-toggles`,
        {
          headers: {
            adminToken: getLocalStorageItem('adminToken'),
            othertoken: getLocalStorageItem('otherToken'),
          }
        }
      );

      const { success, toggles, msg } = response.data;

      if (!success) {
        toast.error(msg || '[Toggle Management] Unable to get available toggles. Please try again');
        return;
      }

      setToggleOptions(Object.keys(toggles));
      setTogglesState(toggles);
    };

    fetchToggleOptions();
  }, [])

  return (
    <div className='section-container'>
      <h2>Toggle Management</h2>
      <StyledTogglesWrapper>
        <h4>Select toggle type</h4>
        <Dropdown
          className='toggle-management-dropdown'
          options={toggleOptions}
          onChange={(option) => setSelectedOption(option.value)}
          value={selectedOption}
          placeholder="Select toggle type..."
        />
        <hr />
        <PlatformToggle
          toggleSelected={selectedOption}
          platformTogglesState={togglesState}
          actionLoggerCallback={actionLogger}
          panelID={panelID}
        />
        <hr />
        <CountryToggle toggleSelected={selectedOption} actionLoggerCallback={actionLogger} panelID={panelID} />
        <hr />
        <UserToggle toggleSelected={selectedOption} actionLoggerCallback={actionLogger} panelID={panelID} />
      </StyledTogglesWrapper>
    </div>
  );
};

export default ToggleManagement;
