import styled from "styled-components";

export const StyledTransferWalletsWrapper = styled.div`
  width: 100%;
  .sender-username-form {
    width: 25%;
    .username-input {
      margin-bottom: 10px;
    }
  }
  
  .receiver-username-form {
    width: 25%;
    .username-input {
      margin-bottom: 10px;
    }
  }
  
  .data-grid-wrapper {
    margin-top: 10px;

    .wallets-data-grid {
      width: 90vw;
    }
  }
  
  .receiver-username-form {
    margin-top: 20px;
  }
`
