import React, {useEffect, useState} from 'react';
import { StyledCSVDataFetchWrapper } from "./styles";
import Dropdown from "react-dropdown";
import {getAvailableDatabasesList, isFunction} from "../../../utils";
import {Button, Checkbox, List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import config from "../../../config";
import {getLocalStorageItem} from "../../../utils/localStorage";
import {toast} from "react-toastify";
import axios from "axios";

const CSVDataFetch = ({ panelID, actionLogger }) => {

  const [databaseList, setDatabaseList] = useState(getAvailableDatabasesList());
  const [selectedDatabase, setSelectedDatabase] = useState('');

  const [selectedDbTables, setSelectedDbTables] = useState([]);
  const [selectedTable, setSelectedTable] = useState('');

  const [selectedTableColumns, setSelectedTableColumns] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);

  const [isAllSelected, setIsAllSelected] = useState(false);

  // Fetch tables of selected database
  useEffect(() => {
    const fetchDbTables = async () => {
      const response = await axios.get(
        `${config.apiUrl}/admin/${selectedDatabase}/tables`,
        {
          headers: {
            adminToken: getLocalStorageItem('adminToken'),
          }
        }
      );

      const { success, msg, tables } = response.data;

      if (!success) {
        toast.error(msg || 'Unable to fetch database tables list');
        return;
      }

      setSelectedDbTables(tables);
    };

    if (!selectedDatabase) return;
    fetchDbTables();
  }, [selectedDatabase]);

  // Fetch columns of selected table
  useEffect(() => {
    const fetchTableColumns = async () => {
      const response = await axios.get(
        `${config.apiUrl}/admin/${selectedDatabase}/${selectedTable}/columns`,
        {
          headers: {
            adminToken: getLocalStorageItem('adminToken'),
          }
        }
      );

      const { success, msg, columns } = response.data;

      if (!success) {
        toast.error(msg || 'Unable to fetch table columns list');
        return;
      }

      setSelectedTableColumns(columns);
    };

    if (!selectedTable) return;
    fetchTableColumns();
  }, [selectedTable]);

  const onDatabaseSelectHandler = ({ value }) => {
    if (!value) return;

    setSelectedDatabase(value);
    setSelectedDbTables([]);
    setSelectedTable('');
    setSelectedTableColumns([]);
  }

  const onTableSelectHandler = ({ value }) => {
    if (!value) return;

    setSelectedTable(value);
    setSelectedTableColumns([]);
  }

  const handleColumnClick = value => () => {
    const currentIndex = selectedColumns.indexOf(value);
    const newCheckedList = [...selectedColumns];

    // If column is not already selected then add it to selected list
    if (currentIndex === -1) {
      newCheckedList.push(value);
    } // If column is already selected then remove it from selected list
    else {
      newCheckedList.splice(currentIndex, 1);
    }

    setSelectedColumns(newCheckedList);
    newCheckedList.length === selectedTableColumns.length ? setIsAllSelected(true) : setIsAllSelected(false);
  };

  const onDownloadButtonClick = async event => {
    event.preventDefault();
    const columns = {};
    selectedColumns.forEach(column => columns[column] = column);
    try {
      const response = await axios.get(
        `${config.apiUrl}/admin/csv-data`,
        {
          params: {
            columns: { ...columns },
            table: selectedTable,
            db: selectedDatabase,
          },
          headers: {
            adminToken: getLocalStorageItem('adminToken'),
          }
        }
      );

      const { success, msg, data } = response.data;

      if (!success) {
        toast.error(msg || 'Unable to fetch data');
        return;
      }

      toast.success(msg || 'Fetch data successfully');
      const csvData = `data:text/csv;charset=utf-8,${encodeURIComponent(data)}`;
      const downloadElement = document.createElement('a');
      const fileName = `${selectedDatabase}-${selectedTable}-data.csv`;
      downloadElement.setAttribute('download', fileName);
      downloadElement.setAttribute('href', csvData);
      document.body.appendChild(downloadElement);
      downloadElement.click();
      isFunction(actionLogger) &&
      await actionLogger(
        panelID,
        `Data fetched from ${selectedDatabase}'s ${selectedTable} table with columns ${selectedColumns.join(',')}`
      );
      resetState();
    } catch (e) {
      console.log('[onDownloadButtonClick] ', e);
    }
  }

  const resetState = event => {
    event && event.preventDefault();
    setSelectedDatabase('');
    setSelectedDbTables([]);
    setSelectedTable('');
    setSelectedTableColumns([]);
    setSelectedColumns([]);
    setIsAllSelected(false);
  }

  return (
    <div className='section-container'>
      <h2>CSV DATA DOWNLOAD</h2>
      <h6>Download data from any table by selecting db, table and required columns</h6>
      <StyledCSVDataFetchWrapper>
        <Dropdown
          className='database-dropdown'
          options={databaseList}
          onChange={onDatabaseSelectHandler}
          value={selectedDatabase}
          placeholder="Select database..."
        />
        <Dropdown
          className='database-tables-dropdown'
          options={selectedDbTables}
          onChange={onTableSelectHandler}
          value={selectedTable}
          placeholder="Select database table..."
        />
        {
          selectedTableColumns && selectedTableColumns.length ? (
            <div className='columns-list-wrapper'>
              <List>
                {/*Select all option*/}
                <ListItem
                  key={'all'}
                  dense
                  button
                  onClick={() => {
                    const isAllOptionSelected = isAllSelected;
                    console.log('isAllOptionSelected ', isAllOptionSelected);
                    !isAllOptionSelected ? setSelectedColumns(selectedTableColumns) : setSelectedColumns([]);
                    setIsAllSelected(!isAllOptionSelected);
                  }}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={selectedTableColumns.length === selectedColumns.length}
                      tabIndex={-1}
                      inputProps={{ 'aria-labelledby': 'all' }}
                    />
                  </ListItemIcon>
                  <ListItemText id={'all'} primary={'Select All'} />
                </ListItem>

                {/*Rest of the options*/}
                {
                  selectedTableColumns.map(column => {
                    // do some processing here like computing id, classname etc if required.

                    // return column one by one
                    return (
                      <ListItem key={column} dense button onClick={handleColumnClick(column)}>
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={selectedColumns.indexOf(column) !== -1}
                            tabIndex={-1}
                            inputProps={{ 'aria-labelledby': column }}
                          />
                        </ListItemIcon>
                        <ListItemText id={column} primary={column} />
                      </ListItem>
                    )
                  })
                }
              </List>
            </div>
          ) : <></>
        }
        <div className='buttons-wrapper'>
          <Button
            variant='contained'
            color='primary'
            disabled={!selectedColumns.length}
            onClick={onDownloadButtonClick}
          >
            Download Data
          </Button>
          <Button
            variant='contained'
            color='secondary'
            disabled={!selectedColumns.length}
            onClick={resetState}
          >
            Refresh
          </Button>
        </div>
      </StyledCSVDataFetchWrapper>
    </div>
  );
};

export default CSVDataFetch;