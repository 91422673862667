import styled from 'styled-components';

export const StyledLoginPageWrapper = styled.div`
    color: white;
  
    .login-form {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      margin-top: 25vh;
      
      div {
        width: 50%;
        margin-top: 5px;
      }
      
      .password-input {
        width: 90%;
        background-color: #ffffff;
        font-size: 15px;
        line-height: 40px;
        height: 46px;
        max-width: 100%;
        vertical-align: middle;
        padding: 2px 15px;
        border: 1px solid #ebebeb;
        border-radius: 4px;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      }
      
      button {
        margin-top: 10px;
        
        &.Mui-disabled {
          color: white;
          background-color: grey;
        }
      }
    }
`