import React, {useState} from 'react';
import {StyledKycLedgerWrapper} from "./styles";
import config from "../../../config";
import axios from "axios";
import {toast} from "react-toastify";
import {getLocalStorageItem} from "../../../utils/localStorage";
import {Button} from "@material-ui/core";
import {isFunction} from "../../../utils";

const KYCLedgerX = ({ actionLogger, panelID }) => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [userInfo, setUserInfo] = useState('');
    const [kycStatus, setKycStatus] = useState('');
    const [isUserFetched, setIsUserFetched] = useState(false);
    const [ledgerXResponse, setLedgerXResponse] = useState('');
    const [ledgerXUserId, setLedgerXUserId] = useState('');

    const onSearchUserByUsernameButtonClick = async () => {

        if (!username) {
            toast.error('Username is required');
            return;
        }

        onRefreshClick();

        try {
            const response = await axios.get(
                `${config.apiUrl}/admin/user/${username}`,
                {
                    headers: {
                        adminToken: getLocalStorageItem('adminToken'),
                    }
                }
            );

            const { success, message } = response.data;

            if (!success) {
                toast.error(message || 'Unable to find user');
                return;
            }

            const kycStatus = await axios.post(
                `${config.apiUrl}/admin/get-kyc-status`,
                { user_name: username },
                {
                    headers: {
                        admintoken: getLocalStorageItem('adminToken')
                    }
                }
            );

            const { success: kycSuccess, msg: kycMsg, data: kycData } = kycStatus.data;

            if (!kycSuccess) {
                toast.error(kycMsg || 'Unable to get KYC Status');
                return;
            }

            const { kyc_status } = kycData;

            const {
                user_name,
                first_name,
                middle_name,
                last_name,
                email,
            } = message;

            toast.success('User Fetched Successfully');
            setIsUserFetched(true);
            setUserInfo({
                user_name,
                first_name,
                middle_name,
                last_name,
                email,
            });
            setKycStatus(kyc_status);
            isFunction(actionLogger) &&
            await actionLogger(panelID, `Retrieved User info & KYC Status for username ${username}.`, username);
        } catch (e) {
            const { response: { data: { message } = {} } = {} }  = e
            toast.error(message || 'Something work wrong while getting user details');
            console.log('[onSearchUserButtonClick]', e);
        }
    }

    const onSearchUserByEmailButtonClick = async () => {

        if (!email) {
            toast.error('Email is required');
            return;
        }

        onRefreshClick();

        try {
            const response = await axios.get(
                `${config.apiUrl}/admin/user/info/${email}`,
                {
                    headers: {
                        adminToken: getLocalStorageItem('adminToken'),
                    }
                }
            );

            const { success, message } = response.data;

            if (!success) {
                toast.error(message || 'Unable to find user');
                return;
            }

            let requiredUsername = null;
            if (typeof message !== 'object' || !message.user_name) {
                toast.error('Invalid user. Please try again');
                return;
            }

            requiredUsername = message.user_name;

            const kycStatus = await axios.post(
                `${config.apiUrl}/admin/get-kyc-status`,
                { user_name: requiredUsername },
                {
                    headers: {
                        admintoken: getLocalStorageItem('adminToken')
                    }
                }
            );

            const { success: kycSuccess, msg: kycMsg, data: kycData } = kycStatus.data;

            if (!kycSuccess) {
                toast.error(kycMsg || 'Unable to get KYC Status');
                return;
            }

            const { kyc_status } = kycData;

            const {
                user_name,
                first_name,
                middle_name,
                last_name,
                email: user_email,
            } = message;

            toast.success('User Fetched Successfully');
            setIsUserFetched(true);
            setUserInfo({
                user_name,
                first_name,
                middle_name,
                last_name,
                user_email,
            });
            setKycStatus(kyc_status);
            isFunction(actionLogger) &&
            await actionLogger(
                panelID,
                `Retrieved User info & KYC Status for username ${requiredUsername}.`,
                requiredUsername
            );
        } catch (e) {
            const { response: { data: { message } = {} } = {} }  = e
            toast.error(message || 'Something work wrong while getting user details');
            console.log('[onSearchUserByEmailButtonClick]', e);
        }
    }

    const onSendKycStatusButtonClick = async () => {
        const { user_name } = userInfo || {};

        if (!user_name) {
            toast.error('Username is required');
            return;
        }

        try {
            const response = await axios.post(
                `${config.kycServiceUrl}/send-kyc-status-to-ledgerx`,
                {
                    userName: user_name,
                    ledgerXUserId: ledgerXUserId,
                }
            );

            const { Success } = response.data;

            if (!Success) {
                toast.error( 'Unable to send KYC status to Ledger X');
                setLedgerXResponse(response.data);
                isFunction(actionLogger) &&
                await actionLogger(
                    panelID,
                    `Sent an unsuccessful KYC Status update request to Ledger X for user ${username}`,
                    username
                );
                return;
            }

            toast.success('Status sent to Ledger X');
            setLedgerXResponse(response.data);
            isFunction(actionLogger) &&
            await actionLogger(
                panelID,
                `Sent a successful KYC Status update request to Ledger X for user ${username}`,
                username
            );

        } catch (e) {
            toast.error('Something work wrong while sending status to Ledger X');
            console.log('[onSendKycStatusButtonClick]', e);
        }
    }

    const onRefreshClick = removeUsernameAndEmail => {
        removeUsernameAndEmail && setUsername('');
        removeUsernameAndEmail && setEmail('');
        setUserInfo('');
        setKycStatus('');
        setIsUserFetched(false);
        setLedgerXResponse('');
        setLedgerXUserId('');
    }

    return (
        <div className='section-container'>
            <h2>KYC - Ledger X</h2>
            <StyledKycLedgerWrapper>
                <div className='user-info-get-section'>
                    <h6>Username or Email</h6>
                    <input
                        className='search-input'
                        type='text'
                        placeholder='Please enter user name...'
                        value={username}
                        onChange={e => {
                            setUsername(e.target.value);
                            setEmail(e.target.value);
                        }}
                    />
                    <Button
                        className='search-user-button'
                        variant='contained'
                        color='primary'
                        onClick={onSearchUserByUsernameButtonClick}
                        disabled={!username}
                    >
                        Get User Details by Username
                    </Button>

                    <Button
                        className='search-user-button'
                        variant='contained'
                        color='primary'
                        onClick={onSearchUserByEmailButtonClick}
                        disabled={!email}
                    >
                        Get User Details by Email
                    </Button>

                    <Button
                        variant='contained'
                        color='secondary'
                        onClick={() => onRefreshClick(true)}
                        disabled={!username}
                    >
                        Refresh
                    </Button>
                </div>

                {
                    userInfo && <pre>{JSON.stringify(userInfo, null, 4)}</pre>
                }

                <h6>KYC Status</h6>
                <input
                    className='search-input'
                    type='text'
                    placeholder='KYC Status'
                    value={kycStatus}
                    disabled
                />

                {
                    isUserFetched ?
                        <React.Fragment>
                            <hr />
                            <h6>Ledger X User Id (Optional)</h6>
                            <input
                                className='search-input ledger-x-user-id-input'
                                type='text'
                                placeholder='Ledger X User Id'
                                value={ledgerXUserId}
                                onChange={e => {
                                    setLedgerXUserId(e.target.value);
                                }}
                            />
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={onSendKycStatusButtonClick}
                                disabled={!username && !isUserFetched}
                            >
                                Send KYC Status to Ledger X
                            </Button>
                        </React.Fragment>
                        :
                        <></>
                }

                {
                    ledgerXResponse && <pre>{JSON.stringify(ledgerXResponse, null, 4)}</pre>
                }


            </StyledKycLedgerWrapper>

        </div>
    );
};

export default KYCLedgerX;