import React, {useState} from 'react';
import {StyledBulkEmailWrapper} from "./styles";
import {Button, TextField} from "@material-ui/core";
import {toast} from "react-toastify";
import config from "../../../config";
import { getLocalStorageItem } from "../../../utils/localStorage";
import axios from "axios";
import { isFunction } from "../../../utils";

const BulkEmail = ({ panelID, actionLogger }) => {

  const [names, setNames] = useState('');
  const [emails, setEmails] = useState('');
  const [body, setBody] = useState('');
  const [subject, setSubject] = useState('');
  const [totalEmails, setTotalEmails] = useState(0);
  const [sentEmails, setSentEmails] = useState(0);
  const [missedEmailsList, setMissedEmailsList] = useState([]);
  const [isSending, setIsSending] = useState(false);

  const sendEmailButtonClickHandler = async event => {
    event && event.preventDefault();

    const requiredNames = names && names.split('\n');
    const requiredEmails = emails && emails.split('\n');

    if (!requiredEmails || !requiredEmails.length || !requiredNames || !requiredNames.length) {
      toast.error('Names and email list not valid');
      return;
    }

    const emailNameMapping = {};
    requiredEmails.forEach((email, idx) => {
      emailNameMapping[email] = requiredNames[idx];
    });

    setTotalEmails(requiredEmails.length);
    setIsSending(true);
    await Promise.all(Object.keys(emailNameMapping).map(async email => await sendEmail(emailNameMapping[email], email)));
    setIsSending(false);
  }

  const sendEmail = async (name, email) => {
    try {
      const payload = {
        name: name,
        email: email,
        subject: subject,
        body: body,
      };
      const response = await axios.post(
        `${config.apiUrl}/bulk-email`,
        payload,
        {
          headers: {
            adminToken: getLocalStorageItem('adminToken'),
          }
        }
      );

      const { success, msg } = response.data;

      if (!success) {
        toast.error(msg || `Unable to send email to ${email}`);
        setMissedEmailsList(prevState => [...prevState, email]);
        return Promise.resolve();
      }
      setSentEmails(prevState => prevState + 1);
      isFunction(actionLogger) &&
      await actionLogger(
        panelID,
        `Sent email to ${email} (${name}) with subject: ${subject} and its content was ${body}`,
        email);
    } catch (e) {
      console.log(`Unable to send email to ${email}`);
      setMissedEmailsList(prevState => [...prevState, email]);
      console.log('[sendEmail]', e);
    }
  }

  const resetState = event => {
    event && event.preventDefault();
    setNames('');
    setEmails('');
    setBody('');
    setSubject('');
    setTotalEmails(0);
    setSentEmails(0);
    setMissedEmailsList([])
    setIsSending(false);
  }

  return (
    <div className='section-container'>
      <h2>Email</h2>
      <h6>Send email to one or multiple users</h6>
      <StyledBulkEmailWrapper>
        {
          totalEmails && totalEmails > 0 ? (
            <div>
              <p>{`Total Emails sent: ${sentEmails} / ${totalEmails}`}</p>
            </div>
          ) : <></>
        }
        {
          missedEmailsList && missedEmailsList.length > 0 ? (
            <div className='missed-emails-list-wrapper'>
              <h6>Following emails were missed. Please retry sending them manually</h6>
              <ul>
                {
                  missedEmailsList.forEach(email => <li>{email}</li>)
                }
              </ul>
            </div>
          ) : <></>
        }
        <div className='email-form-wrapper'>
          <div className='left-section'>
            <TextField
              id="multiline-names"
              label="Names"
              // placeholder="Enter names, every new name must be on new line"
              multiline
              value={names}
              onChange={event => setNames(event.target.value)}
              disabled={isSending}
            />
            <TextField
              id="multiline-emails"
              label="Emails"
              // placeholder="Enter emails, every new email must be on new line"
              multiline
              value={emails}
              onChange={event => setEmails(event.target.value)}
              disabled={isSending}
            />
          </div>
          <div className='right-section'>
            <TextField
              id="subject"
              className='subject'
              label="Subject"
              // placeholder="Enter email subject"
              value={subject}
              onChange={event => setSubject(event.target.value)}
              disabled={isSending}
            />
            <TextField
              id="body"
              className='body'
              label="Body"
              multiline
              // placeholder="Enter email content"
              value={body}
              onChange={event => setBody(event.target.value)}
              disabled={isSending}
            />
          </div>
        </div>
        <div className='buttons-wrapper'>
          <Button
            variant='contained'
            color='primary'
            onClick={sendEmailButtonClickHandler}
            disabled={!names || !emails || !subject || !body || isSending}
          >
            Send Email
          </Button>
          <Button
            variant='contained'
            color='secondary'
            onClick={resetState}
            disabled={(!names && !emails && !subject && !body) || isSending}
          >
            Refresh
          </Button>
        </div>
      </StyledBulkEmailWrapper>
    </div>

  );
};

export default BulkEmail;
