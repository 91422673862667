// @ts-nocheck
import React, {useState} from 'react';
import {StyledAirdropWalletManagementWrapper} from "./styles";
import {Button, Checkbox, FormControlLabel, FormGroup, Modal} from "@material-ui/core";
import config from "../../../config";
import {getLocalStorageItem, setLocalStorageItem} from "../../../utils/localStorage";
import axios from "axios";
import {toast} from "react-toastify";
import {DataGrid} from "@mui/x-data-grid";
import { airdropWalletsTableColumnsConfig, encrypt } from "../../../utils";
import Dropdown from "react-dropdown";
import {StyledModalWrapper} from "../styles";
import moment from "moment";
const CryptoJS = require("crypto-js");
declare var $: any;

const AirdropWalletManagement = () => {
    // Search state
    const [username, setUsername] = useState('');
    const [walletAddress, setWalletAddress] = useState('');
    const [airdropWalletNominated, setAirdropWalletNominated] = useState('');
    const [airdropRewardIssued, setAirdropRewardIssued] = useState('');
    const [airdropRewardValue, setAirdropRewardValue] = useState('');
    const [category, setCategory] = useState('');

    // Data state
    const [wallets, setWallets] = useState([]);
    const [airdropProcessReward, setAirdropProcessReward] = useState(0);
    const [fromWallet, setFromWallet] = useState('');
    const [mnemonics, setMnemonics] = useState('');
    const [mnemonicsPassword, setMnemonicsPassword] = useState('');
    const [trustBrowser, setTrustBrowser] = useState('');
    const [passwordTrustTime, setPasswordTrustTime] = useState(15);

    // Misc
    const [loading, setLoading] = useState(false);
    const [showStats, setShowStats] = useState(false);

    // Stats
    const [totalWallets, setTotalWallets] = useState(0);
    const [nominatedWallets, setNominatedWallets] = useState(0);
    const [unrewardedWallets, setUnrewardedWallets] = useState(0);
    const [unrewardedNotNominatedWallets, setUnrewardedNotNominatedWallets] = useState(0);

    const onGetDetailsBtnClick = async () => {

        if (airdropWalletNominated && (airdropWalletNominated < 0 || airdropWalletNominated > 1)) {
            toast.error('Wallet nominated value can be 0 or 1 only');
            return;
        }

        if (airdropRewardIssued && (airdropRewardIssued < 0 || airdropRewardIssued > 1)) {
            toast.error('Wallet issued value can be 0 or 1 only');
            return;
        }

        if (airdropWalletNominated && airdropWalletNominated < 0) {
            toast.error('Reward value can minimum be 0');
            return;
        }

        try {
            const payload = {
                username: username,
                walletAddress: walletAddress,
                walletNominated: airdropWalletNominated,
                rewardIssued: airdropRewardIssued,
                rewardValue: airdropRewardValue,
                category: category
            };

            setLoading(true);
            setShowStats(false);

            const response = await axios.post(
                `${config.apiUrl}/admin/airdrop-wallet`,
                payload,
                { headers: { adminToken: getLocalStorageItem('adminToken') } }
            );

            const { success, msg, results } = response.data;

            if (!success) {
                toast.error(msg || 'Unable to fetch records');
                setLoading(false);
                return;
            }

            // Todo: Calculate stats here
            if (results && Array.isArray(results) && results.length) {
                /*
                    nw = nominated wallets
                    urw = unrewarded wallets
                    urnn = unrewarded not nominated wallets
                */
                let nw = 0, urw = 0, urnn = 0;
                results.forEach(result => {
                    const { airdrop_nominated_wallet, airdrop_reward_issued } = result;

                    if (airdrop_nominated_wallet === 1) nw += 1;

                    // Unrewarded and nominated wallet i.e nominated for reward but not yet received rew.
                    if (airdrop_reward_issued === 0 && airdrop_nominated_wallet === 1) urw += 1;

                    // Unrewarded and non-nominated wallet i.e not nominated for reward so didn't receive it
                    if (airdrop_reward_issued === 0 && airdrop_nominated_wallet === 0) urnn += 1;
                });
                setShowStats(true);
                setTotalWallets(results.length);
                setNominatedWallets(nw);
                setUnrewardedWallets(urw);
                setUnrewardedNotNominatedWallets(urnn);
            }

            toast.success(msg);
            setWallets(results);
        } catch (e) {
            console.log('[onGetDetailsBtnClick]', e);
            console.log(e.response);
            toast.error('Unable to fetch records. Please try again or check network request tab for possible error');
        }

        setLoading(false);
    }

    const shouldGetDetailsBtnDisabled = () => {
        return (
            !username &&
            !walletAddress &&
            (!airdropWalletNominated && airdropWalletNominated !== 0) &&
            (!airdropRewardIssued && airdropRewardIssued !== 0) &&
            (!airdropRewardValue && airdropRewardValue !== 0) &&
            !category
        )
    }

    const resetState = () => {
        setUsername('');
        setWalletAddress('');
        setAirdropWalletNominated('');
        setAirdropRewardIssued('');
        setAirdropRewardValue('');
        setWallets([]);
        setCategory('');
        setAirdropProcessReward(0);
        setLoading(false);
        setShowStats(false);
    }

    const shouldRefreshBtnDisabled = () => {
        return (
            !username &&
            !walletAddress &&
            (!airdropWalletNominated && airdropWalletNominated !== 0) &&
            (!airdropRewardIssued && airdropRewardIssued !== 0) &&
            (!airdropRewardValue && airdropRewardValue !== 0) &&
            !category
        )
    }

    const onAirdropProcessBtnClick = async _mnemonics => {
        if (airdropProcessReward <=0) {
            toast.error('Airdrop processing value cannot be 0 or less');
            return;
        }

        if (!_mnemonics) {
            toast.error('Unable to process. Please clear airdrop related local storage and try again');
            return;
        }

        const payload = {
            reward_amount: airdropProcessReward,
            wallets: wallets.filter(wallet => {
                return wallet.airdrop_reward_issued == 0 && wallet.airdrop_nominated_wallet == 1
            }).map(wallet => {
                return {
                    user_name: wallet.user_name,
                    account_id: wallet.account_id
                }
            }),
            treasury_mnemonics: _mnemonics,
        };

        if (!payload.wallets.length) {
            toast.error('No eligible wallets to process');
            return;
        }

        setLoading(true);

        try {
            const encryptedPayload = encrypt(payload);

            const response = await axios.post(
                `${config.apiUrl}/admin/airdrop-wallets-processing`,
                encryptedPayload,
                { headers: { adminToken: getLocalStorageItem('adminToken') } }
            );

            const { success, msg, blockHash, updatedResults } = response.data;

            if (!success) {
                toast.error(msg);
                setLoading(false);
                return;
            }

            // Calculate stats here
            if (updatedResults && Array.isArray(updatedResults) && updatedResults.length) {
                /*
                    nw = nominated wallets
                    urw = unrewarded wallets
                    urnn = unrewarded not nominated wallets
                */
                let nw = 0, urw = 0, urnn = 0;
                updatedResults.forEach(result => {
                    const { airdrop_nominated_wallet, airdrop_reward_issued } = result;

                    if (airdrop_nominated_wallet === 1) nw += 1;

                    // Unrewarded and nominated wallet i.e nominated for reward but not yet received rew.
                    if (airdrop_reward_issued === 0 && airdrop_nominated_wallet === 1) urw += 1;

                    // Unrewarded and non-nominated wallet i.e not nominated for reward so didn't receive it
                    if (airdrop_reward_issued === 0 && airdrop_nominated_wallet === 0) urnn += 1;
                });
                setShowStats(true);
                setTotalWallets(updatedResults.length);
                setNominatedWallets(nw);
                setUnrewardedWallets(urw);
                setUnrewardedNotNominatedWallets(urnn);
            }

            toast.success(msg);
            setWallets(updatedResults);
        } catch (e) {
            console.log('[onAirdropProcessBtnClick]', e);
            console.log(e.response);
            toast.error('Something went wrong. Please check db airdrop processing logs table to see if wallets are processed.');
        }
        setLoading(false);
    }

    const getLoadingModalBody = () => {
        return (
            <StyledModalWrapper className='loading-modal-body'>
                <img src={'/images/loader.svg'} alt='Loader' />
            </StyledModalWrapper>
        )
    };

    const showFromWalletModal = event => {
        event.preventDefault();
        $('#from-wallet-modal-airdrop').modal('show');
    }

    const checkForExistingMnemonics = async event => {
        event.preventDefault();

        const mnemonics = JSON.parse(getLocalStorageItem(`${fromWallet}-mnemonics-airdrop`));
        if (!mnemonics || typeof mnemonics !== 'object') {
            $('#from-wallet-modal-airdrop').modal('hide');
            $('#wallet-mnemonics-modal-airdrop').modal('show');
            return;
        }

        const { expiry, encryptedMnemonics } = mnemonics;

        if (!encryptedMnemonics) {
            $('#from-wallet-modal-airdrop').modal('hide');
            $('#wallet-mnemonics-modal-airdrop').modal('show');
            return;
        }

        /*
           If expiry date is not passed then use these mnemonics and proceed with transfer
           logic else ask for password
        */

        const difference = expiry && moment(expiry).diff(moment(), 'minutes') > 0;

        // Ask for password and show checkbox for remember as well
        if (!difference) {
            $('#from-wallet-modal-airdrop').modal('hide');
            $('#wallet-password-modal-airdrop').modal('show');
            return;
        }

        const password = getLocalStorageItem(`${fromWallet}-password-airdrop`);

        let decryptedPassword;
        try {
            decryptedPassword = CryptoJS.enc.Utf8.stringify(CryptoJS.AES.decrypt(password, config.mnemonicsPasswordEncryptionKey));
        } catch (e) {
            toast.error('Please make sure your password is correct.');
            return;
        }

        let decryptedMnemonics;
        try {
            decryptedMnemonics = CryptoJS.enc.Utf8.stringify(CryptoJS.AES.decrypt(encryptedMnemonics, decryptedPassword));
        } catch (e) {
            toast.error('Please make sure your password is correct.');
            return;
        }

        if (!decryptedMnemonics) {
            toast.error('Please make sure your password is correct.');
            return;
        }

        await onAirdropProcessBtnClick(`${decryptedMnemonics}`);
    }

    const showMnemonicsPasswordModal = event => {
        event.preventDefault();
        $('#wallet-password-modal-airdrop').modal('show');
    }

    const mnemonicsPasswordHandler = async event => {
        event.preventDefault();

        if (trustBrowser && passwordTrustTime < 1) {
            toast.error('Trust time must be 1 minute or more');
            return;
        }

        let _mnemonics = mnemonics;

        if (!mnemonics) {
            const encMncs = JSON.parse(getLocalStorageItem(`${fromWallet}-mnemonics-airdrop`));
            const { encryptedMnemonics } = encMncs;
            try {
                _mnemonics = CryptoJS.enc.Utf8.stringify(CryptoJS.AES.decrypt(encryptedMnemonics, mnemonicsPassword));
            } catch (e) {
                toast.error('Please make sure your password is correct');
                return;
            }
        }

        if (!_mnemonics) {
            toast.error('Please make sure your password is correct');
            return;
        }

        const encryptedMnemonics = CryptoJS.AES.encrypt(_mnemonics, mnemonicsPassword).toString();
        const encryptedMnemonicsEncryptPassword = CryptoJS.AES.encrypt(mnemonicsPassword, config.mnemonicsPasswordEncryptionKey).toString();

        const obj = {
            encryptedMnemonics: encryptedMnemonics,
        }

        if (trustBrowser) {
            obj.expiry = moment().add(passwordTrustTime || 1, 'minutes');
        }

        setLocalStorageItem(`${fromWallet}-mnemonics-airdrop`, JSON.stringify(obj));
        setLocalStorageItem(`${fromWallet}-password-airdrop`, encryptedMnemonicsEncryptPassword);

        $('#wallet-password-modal-airdrop').modal('hide');
        await onAirdropProcessBtnClick(`${_mnemonics}`)
    }

    return (
        <div className='section-container'>
            <h2>Airdrop Wallet Management</h2>
            <h6>
                Wildcards can be used in search boxes, for example: <b>%</b> or <b>__</b>
            </h6>
            <StyledAirdropWalletManagementWrapper>

                <div className='search-user-input-fields-wrapper'>
                    <div>
                        <h6>Username</h6>
                        <input
                            className='search-input'
                            type='text'
                            placeholder='Please enter user name...'
                            value={username}
                            onChange={e => setUsername(e.target.value)}
                        />
                    </div>

                    <div>
                        <h6>Wallet Address</h6>
                        <input
                            className='search-input'
                            type='text'
                            placeholder='Please enter wallet address...'
                            value={walletAddress}
                            onChange={e => setWalletAddress(e.target.value)}
                        />
                    </div>

                    <div>
                        <h6>Wallet Nominated?</h6>
                        <input
                            className='search-input'
                            type='number'
                            placeholder='Please enter value from 0 or 1'
                            value={airdropWalletNominated}
                            onChange={e => setAirdropWalletNominated(e.target.value)}
                            min={0}
                            max={1}
                        />
                    </div>

                    <div>
                        <h6>Reward Issued?</h6>
                        <input
                            className='search-input'
                            type='number'
                            placeholder='Please enter value from 0 or 1'
                            value={airdropRewardIssued}
                            onChange={e => setAirdropRewardIssued(e.target.value)}
                            min={0}
                            max={1}
                        />
                    </div>

                    <div>
                        <h6>Reward value</h6>
                        <input
                            className='search-input'
                            type='number'
                            placeholder='Please enter reward value'
                            value={airdropRewardValue}
                            onChange={e => setAirdropRewardValue(e.target.value)}
                            min={0}
                        />
                    </div>

                    <div>
                        <h6>Category</h6>
                        <Dropdown
                            className='category-dropdown'
                            options={[
                                'N/A',
                                'SILVER',
                                'GOLD',
                                'S-BUNDLE',
                                'PLATINUM',
                                'TECH',
                                'DIAMOND',
                            ]}
                            onChange={({ value }) => setCategory(value)}
                            value={category}
                            placeholder="Select category..."
                        />
                    </div>
                </div>

                <div className='search-button-wrapper'>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onGetDetailsBtnClick}
                        disabled={shouldGetDetailsBtnDisabled()}
                    >
                        Get Details
                    </Button>

                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={resetState}
                        disabled={shouldRefreshBtnDisabled()}
                    >
                        Refresh
                    </Button>
                </div>

                {
                    wallets && Array.isArray(wallets) && wallets.length ? (
                        <div className='airdrop-processing-form'>
                            <div>
                                <h6>Airdrop Value</h6>
                                <input
                                    className='search-input'
                                    type='number'
                                    placeholder='Please enter airdrop reward value to process'
                                    value={airdropProcessReward}
                                    onChange={e => setAirdropProcessReward(e.target.value)}
                                    min={0}
                                />
                            </div>

                            <Button
                                variant="contained"
                                color="primary"
                                onClick={showFromWalletModal}
                                disabled={
                                    !airdropProcessReward ||
                                    airdropProcessReward <= 0 ||
                                    !wallets ||
                                    !wallets.length
                                }
                            >
                                Process
                            </Button>

                        </div>
                    ) : <></>
                }

                {
                    showStats && (
                        <div className='stats-wrapper'>
                            <p><b>Total Wallets:</b>{` ${totalWallets}`}</p>
                            <p><b>Nominated Wallets:</b>{` ${nominatedWallets}`}</p>
                            <p><b>Unrewarded Nominated Wallets :</b>{` ${unrewardedWallets}`}</p>
                            <p><b>Unrewarded Non Nominated Wallets :</b>{` ${unrewardedNotNominatedWallets}`}</p>
                        </div>
                    )
                }

                <div className='data-grid-wrapper'>
                    <DataGrid
                        className='airdrop-wallets-data-grid'
                        rows={wallets}
                        columns={airdropWalletsTableColumnsConfig()}
                        pageSize={100}
                        autoHeight
                    />
                </div>

                {/*Loading Modal*/}
                {
                    <Modal
                        disablePortal
                        open={loading}
                        onClose={() => {}}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >
                        {getLoadingModalBody()}
                    </Modal>
                }

                {/* FROM Wallet MODAL */}
                <div id="from-wallet-modal-airdrop" className="modal show" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content bg-success text-white" style={{ top: '120px' }}>
                            <div className="modal-body text-center">
                                <form>
                                    <h3 className="text-white mb-15">Enter From wallet</h3>
                                    <h6>{`You are going to process ${unrewardedWallets} airdrop wallets`}</h6>
                                    <input
                                        className='from-wallet-input-box'
                                        value={fromWallet}
                                        required
                                        onChange={e => {
                                            setFromWallet(e.target.value)
                                        }}
                                        placeholder="Enter from wallet for airdrop processing..."
                                        style={{ marginBottom: '10px' }}
                                        type="text"/>
                                    <button type="button" className="btn btn-light" data-dismiss="modal">Cancel</button>
                                    <button
                                        style={{ marginLeft: "8px" }}
                                        onClick={checkForExistingMnemonics}
                                        type="button"
                                        className="btn btn-light"
                                        data-dismiss="modal"
                                        disabled={!fromWallet}
                                    >
                                        Continue
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                {/* WALLET MNEMONICS MODAL */}
                <div id="wallet-mnemonics-modal-airdrop" className="modal show" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content bg-success text-white" style={{ top: '120px' }}>
                            <div className="modal-body text-center">
                                <form>
                                    <h3 className="text-white mb-15">Enter Mnemonics</h3>
                                    <input
                                        className='wallet-mnemonics-input-box'
                                        value={mnemonics}
                                        required
                                        onChange={e => setMnemonics(e.target.value)}
                                        placeholder="Enter mnemonics of wallet from which coins will be transferred..."
                                        style={{ marginBottom: '10px' }}
                                        type="text"
                                    />
                                    <button type="button" className="btn btn-light" data-dismiss="modal">Cancel</button>
                                    <button
                                        style={{ marginLeft: "8px" }}
                                        onClick={showMnemonicsPasswordModal}
                                        type="button"
                                        className="btn btn-light"
                                        data-dismiss="modal"
                                        disabled={!mnemonics}
                                    >
                                        Continue
                                    </button>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>

                {/* PASSWORD MODAL */}
                <div id="wallet-password-modal-airdrop" className="modal show" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content bg-success text-white" style={{ top: '120px' }}>
                            <div className="modal-body text-center">
                                <form>
                                    <h3 className="text-white mb-15">Enter Password</h3>
                                    <h5>
                                        This password will be used for encryption/decryption of mnemonics.
                                        This is not necessarily wallet's own real password.
                                    </h5>
                                    <input
                                        className='wallet-password-input-box'
                                        value={mnemonicsPassword}
                                        required
                                        onChange={e => setMnemonicsPassword(e.target.value)}
                                        placeholder="Enter password..."
                                        type="password"
                                    />
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={trustBrowser}
                                                    onChange={e => setTrustBrowser(e.target.checked)}
                                                    name="trustedBrowser"
                                                    className='trustBrowserCheckbox'
                                                />
                                            }
                                            label="I trust this browser to keep this password"
                                        />
                                    </FormGroup>

                                    {
                                        trustBrowser ? (
                                            <div className='password-remember-time-div-wrapper'>
                                                <h6>Password Remember Time (Minutes)</h6>
                                                <input
                                                    className='wallet-password-time-input-box'
                                                    value={passwordTrustTime}
                                                    required
                                                    onChange={e => setPasswordTrustTime(e.target.value)}
                                                    placeholder="Enter time to remember..."
                                                    type='number'
                                                    disabled={!trustBrowser}
                                                    min={1}
                                                />
                                            </div>
                                        ) : <></>
                                    }

                                    <button type="button" className="btn btn-light" data-dismiss="modal">Cancel</button>
                                    <button
                                        style={{ marginLeft: "8px" }}
                                        onClick={mnemonicsPasswordHandler}
                                        type="button"
                                        className="btn btn-light"
                                        data-dismiss="modal"
                                        disabled={!mnemonicsPassword}
                                    >
                                        Continue
                                    </button>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>

            </StyledAirdropWalletManagementWrapper>
        </div>
    );
};

export default AirdropWalletManagement;