import styled from "styled-components";

export const StyledKycLedgerXBulkWrapper = styled.div`
    width: 100%;
    
    .form-1 {
      display: flex;
      align-items: flex-end;
      margin-bottom: 20px;
      
      button {
        margin-left: 20px;
      }
      
      .emails-textbox {
        width: 25vw;
      }
    }
`
