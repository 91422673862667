import styled from "styled-components";

export const StyledTogglesWrapper = styled.div`
  width: 100%;
  
  .toggle-management-dropdown {
    width: fit-content;
    margin-bottom: 10px;
    
    .Dropdown-menu {
      width: fit-content;
    }
  }
  
  .platform-toggle-wrapper {
    .platform-toggle-radio-buttons-group {
      flex-direction: row;
    }
    
    .action-buttons-wrapper {
      button {
        margin-right: 5px;
      }
    }
  }
  
  .country-toggle-wrapper {
    .country-toggle-radio-buttons-group {
      flex-direction: row;
    }
    
    .form-wrapper {
      display: flex;
      align-items: center;
      
      div[role=combobox] {
        margin-right: 5px;
        div[role=listbox] {
          ul {
            list-style: none;
            background-color: white;
            padding: 5px;
            
            li {
              border-bottom: 1px solid grey;
              
              :last-child {
                border: none;
              }
              
              :hover {
                background-color: whitesmoke;
              }
            }
          }
        }
      }
      
      //.country-dropdown {
      //  margin-right: 5px;
      //  width: 20%;
      //  
      //  input {
      //    background: transparent;
      //    width: 100%;
      //    border: none;
      //  }
      //}
      
      .action-buttons-wrapper {
        
        display: flex;
        
        button {
          margin-right: 5px;
        }
        
        .all-countries-toggle-buttons {
          margin-left: 25px;
        }
      }
    }
  }
  
  .user-toggle-wrapper {
    .user-toggle-radio-buttons-group {
      flex-direction: row;
    }

    .form-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      
      .search-user-wrapper {
        width: 100%;
      }
      
      input {
        width: 20%;
        margin-right: 5px;
      }

      .action-buttons-wrapper {
        
        display: flex;
        
        button {
          margin-right: 5px;
        }

        .all-users-toggle-buttons {
          margin-left: 25px;
        }
      }
    }
  }
`;
