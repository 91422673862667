/* eslint-disable */

import React, {useCallback, useEffect, useState} from 'react';
import {
  Button,
  FormControl,
  FormControlLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select
} from "@material-ui/core";
import { toast } from "react-toastify";
import axios from "axios";
import config from '../../../config';
import {
  getLocalStorageItem,
  setLocalStorageItem
} from "../../../utils/localStorage";
import  SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  customerAddressTableColumnsConfig,
  customerBasicInfoTableColumnsConfig,
  getMinerFeesStatusString,
  isFunction,
} from "../../../utils";
import moment from "moment";
import {StyledModalWrapper} from "../styles";
import { createWorker } from 'tesseract.js';
import {StyledCustomerInfoWrapper, StyledDocumentsSectionWrapper, StyledKYCWrapper} from './styles'
import { DataGrid } from '@mui/x-data-grid';
import { uuid } from "uuidv4";
import Dropdown from "react-dropdown";

const KYCProgress = ({ actionLogger, panelID }) => {
  const [passportDoc, setPassportDoc] = useState(null);
  const [passportLoading, setPassportLoading] = useState(false);
  const [passportDocS3Path, setPassportDocS3Path] = useState('');
  const [passportOptionSelected, setPassportOptionSelected] = useState('invalid');
  const [passportAlreadyValid, setPassportAlreadyValid] = useState(false);
  const [drivingLicense, setDrivingLicense] = useState(null);
  const [drivingLicenseLoading, setDrivingLicenseLoading] = useState(false);
  const [drivingLicenseS3Path, setDrivingLicenseS3Path] = useState('');
  const [licenseOptionSelected, setLicenseOptionSelected] = useState('invalid');
  const [drivingLicenseAlreadyValid, setDrivingLicenseAlreadyValid] = useState(false);
  const [photoId, setPhotoId] = useState(null);
  const [photoIdLoading, setPhotoIdLoading] = useState(false);
  const [photoIdS3Path, setPhotoIdS3Path] = useState('');
  const [photoIdOptionSelected, setPhotoIdOptionSelected] = useState('invalid');
  const [photoIdAlreadyValid, setPhotoIdAlreadyValid] = useState(false);
  const [selfie, setSelfie] = useState(null);
  const [selfieLoading, setSelfieLoading] = useState(false);
  const [selfieS3Path, setSelfieS3Path] = useState('');
  const [selfieOptionSelected, setSelfieOptionSelected] = useState('invalid');
  const [selfieAlreadyValid, setSelfieAlreadyValid] = useState(false);

  // Invalid docs
  const [invalidPassport, setInvalidPassport] = useState(null);
  const [invalidPassportLoading, setInvalidPassportLoading] = useState(false);
  const [invalidPassportS3Path, setInvalidPassportS3Path] = useState('');
  const [invalidPassportVerifiedByHuman, setInvalidPassportVerifiedByHuman] = useState(0);
  const [showPassportModal, setPassportModal] = useState(false);
  const [isPassportFromInvalidDocs, setPassportFromInvalidDocs] = useState(false);
  const [invalidDrivingLicense, setInvalidDrivingLicense] = useState(null);
  const [invalidDrivingLicenseLoading, setInvalidDrivingLicenseLoading] = useState(false);
  const [invalidDrivingLicenseS3Path, setInvalidDrivingLicenseS3Path] = useState('');
  const [invalidDrivingLicenseVerifiedByHuman, setInvalidDrivingLicenseVerifiedByHuman] = useState(0);
  const [showLicenseModal, setLicenseModal] = useState(false);
  const [isLicenseFromInvalidDocs, setLicenseFromInvalidDocs] = useState(false);
  const [invalidPhotoId, setInvalidPhotoId] = useState(null);
  const [invalidPhotoIdLoading, setInvalidPhotoIdLoading] = useState(false);
  const [invalidPhotoIdS3Path, setInvalidPhotoIdS3Path] = useState('');
  const [invalidPhotoIdVerifiedByHuman, setInvalidPhotoIdVerifiedByHuman] = useState(0);
  const [showPhotoIdModal, setPhotoIdModal] = useState(false);
  const [isPhotoIdFromInvalidDocs, setPhotoIdFromInvalidDocs] = useState(false);
  const [invalidSelfie, setInvalidSelfie] = useState(null);
  const [invalidSelfieLoading, setInvalidSelfieLoading] = useState(false);
  const [invalidSelfieS3Path, setInvalidSelfieS3Path] = useState('');
  const [invalidSelfieVerifiedByHuman, setInvalidSelfieVerifiedByHuman] = useState(0);
  const [showSelfieModal, setSelfieModal] = useState(false);
  const [isSelfieFromInvalidDocs, setSelfieFromInvalidDocs] = useState(false);

  // Manual Passport verification state
  const [passportNationality, setPassportNationality] = useState('');
  const [passportNumber, setPassportNumber] = useState('');
  const [passportSurname, setPassportSurname] = useState('');
  const [passportGivenNames, setPassportGivenNames] = useState('');
  const [passportDOB, setPassportDOB] = useState('');
  const [passportCitizenNumber, setPassportCitizenNumber] = useState('');
  const [passportPlaceOfBirth, setPassportPlaceOfBirth] = useState('');
  const [passportFatherName, setPassportFatherName] = useState('');
  const [passportGender, setPassportGender] = useState('');
  const [passportIssuanceDate, setPassportIssuanceDate] = useState('');
  const [passportExpiryDate, setPassportExpiryDate] = useState('');

  // Manual Driver License state
  const [driverLicenseName, setDriverLicenseName] = useState('');
  const [driverLicenseCardNumber, setDriverLicenseCardNumber] = useState('');
  const [driverLicenseNumber, setDriverLicenseNumber] = useState('');
  const [driverLicenseAddress, setDriverLicenseAddress] = useState('');
  const [driverLicenseDOB, setDriverLicenseDOB] = useState('');
  const [driverLicenseExpiry, setDriverLicenseExpiry] = useState('');

  // Manual Photo ID state
  const [photoIdName, setPhotoIdName] = useState('');
  const [photoIdFamilyName, setPhotoIdFamilyName] = useState('');
  const [photoIdCardNumber, setPhotoIdCardNumber] = useState('');
  const [photoIdAddress, setPhotoIdAddress] = useState('');
  const [photoIdExpiryDate, setPhotoIdExpiryDate] = useState('');
  const [photoIdDOB, setPhotoIdDOB] = useState('');

  // Others
  const [username, setUsername] = useState('');
  const [loading, setLoading] = useState(false);
  const [showFilePathModal, setFilePathModal] = useState(false);
  const [kycValidationFlag, setKYCValidationFlag] = useState(false);
  const [currentFileToShowPath, setCurrentFileToShowPath] = useState('');
  const [currentFilePath, setCurrentFilePath] = useState('');
  const [showKYCCompleteButton, setShowKYCCompleteButton] = useState(false);
  const [kycStatus, setKycStatus] = useState(false);
  const [userInfo, setUserInfo] = useState('');
  const [initialUserInfo, setInitialUserInfo] = useState({});
  const [newUserInfo, setNewUserInfo] = useState({});
  const [customerAddress, setCustomerAddress] = useState('');
  const [initialCustomerAddress, setInitialCustomerAddress] = useState({});
  const [newAddress, setNewAddress] = useState({});
  const [countries, setCountries] = useState([]);
  const [gettingText, setGettingText] = useState(false);
  const [ocrText, setOcrText] = useState('');
  const [showNotifModal, setNotifModal] = useState(false);
  const [notifModalMessage, setNotifModalMessage] = useState('');
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [countryFilter, setCountryFilter] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [countryFilterAutoFocus, setCountryFilterAutoFocus] = useState(false);

  useEffect(() => {
    const fetchCountries = async () => {
      const response = await axios.get(
          `${config.apiUrl}/available-countries`,
          {
            headers: {
              adminToken: getLocalStorageItem('adminToken'),
            }
          }
      );

      const { success, msg, countries } = response.data;

      if (!success) {
        toast.error(msg || '[KYC Panel] Unable to get countries list');
        return;
      }
      const mappedCountries = []
      countries.forEach(country => {
        // This mapped countries array will help us fill list of countries
        // mappedCountries.push({ label: country.country_name})
        mappedCountries.push(country.country_name);
      });

      setCountries(mappedCountries);
    };
    fetchCountries();
  }, [])

  useEffect(() => {
    if (!currentFileToShowPath) return;
    setCurrentFilePath(() => getCurrentSelectDocPath(currentFileToShowPath))
  }, [currentFileToShowPath])

  const resetState = () => {
    setPassportDoc(null);
    setPassportLoading(false);
    setPassportDocS3Path('');
    setPassportOptionSelected('invalid');
    setPassportAlreadyValid(false);
    setDrivingLicense(null);
    setDrivingLicenseLoading(false);
    setDrivingLicenseS3Path('');
    setLicenseOptionSelected('invalid');
    setDrivingLicenseAlreadyValid(false);
    setPhotoId(null);
    setPhotoIdLoading(false);
    setPhotoIdS3Path('');
    setPhotoIdOptionSelected('invalid');
    setPhotoIdAlreadyValid(false);
    setSelfie(null);
    setSelfieLoading(false);
    setSelfieS3Path('');
    setSelfieOptionSelected('invalid');
    setSelfieAlreadyValid(false);

    // Invalid docs
    setInvalidPassport(null);
    setInvalidPassportLoading(false);
    setInvalidPassportS3Path('');
    setInvalidPassportVerifiedByHuman(0);
    setPassportModal(false);
    setPassportFromInvalidDocs(false);
    setInvalidDrivingLicense(null);
    setInvalidDrivingLicenseLoading(false);
    setInvalidDrivingLicenseS3Path('');
    setInvalidDrivingLicenseVerifiedByHuman(0);
    setLicenseModal(false);
    setLicenseFromInvalidDocs(false);
    setInvalidPhotoId(null);
    setInvalidPhotoIdLoading(false);
    setInvalidPhotoIdS3Path('');
    setInvalidPhotoIdVerifiedByHuman(0);
    setPhotoIdModal(false);
    setPhotoIdFromInvalidDocs(false);
    setInvalidSelfie(null);
    setInvalidSelfieLoading(false);
    setInvalidSelfieS3Path('');
    setInvalidSelfieVerifiedByHuman(0);
    setSelfieModal(false);
    setSelfieFromInvalidDocs(false);

    // Manual Passport verification state
    setPassportNationality('');
    setPassportNumber('');
    setPassportSurname('');
    setPassportGivenNames('');
    setPassportDOB('');
    setPassportCitizenNumber('');
    setPassportPlaceOfBirth('');
    setPassportFatherName('');
    setPassportGender('');
    setPassportIssuanceDate('');
    setPassportExpiryDate('');

    // Manual Driver License state
    setDriverLicenseName('');
    setDriverLicenseCardNumber('');
    setDriverLicenseNumber('');
    setDriverLicenseAddress('');
    setDriverLicenseDOB('');
    setDriverLicenseExpiry('');

    // Manual Photo ID state
    setPhotoIdName('');
    setPhotoIdFamilyName('');
    setPhotoIdCardNumber('');
    setPhotoIdAddress('');
    setPhotoIdExpiryDate('');
    setPhotoIdDOB('');

    // Other
    setLoading(false);
    setShowKYCCompleteButton(false);
    setKycStatus(false);
    setCustomerAddress('');
    setInitialCustomerAddress({});
    setNewAddress({});
    setUserInfo('');
    setInitialUserInfo({});
    setNewUserInfo({});
    setGettingText(false);
    setOcrText('');
    setNotifModal(false);
    setNotifModalMessage('');
    setFilePathModal(false);
    setCurrentFileToShowPath('');
    setCurrentFilePath('');
    setKYCValidationFlag(false);

    setCountryFilterAutoFocus(false);
  }

  const getTextFromImage = async fileBlob => {
    const worker = createWorker({});
    setGettingText(true);
    await worker.load();
    await worker.loadLanguage('eng');
    await worker.initialize('eng');
    const { data: { text } } = await worker.recognize(fileBlob);
    setOcrText(text);
    setGettingText(false);
    await worker.terminate();
  }

  const getToggleKycFlagStatus = () => {
    const fetchKYCValidationFlag = async country => {
      try {
        const payload = new FormData();
        payload.append('country', country);
        payload.append('email', username);

        const response = await axios.post(
          `${config.apiUrlAIIdentity}/get_kyc_validation_toggle`,
          payload
        );

        const { kyc_toggle_flag } = response.data;
        setKYCValidationFlag(!!kyc_toggle_flag);
        setLocalStorageItem(`${username}-kyc-validation-flag`, !!kyc_toggle_flag)
      } catch (e) {
        console.log('KYC Admin panel [fetchKYCValidationFlag]', e);
      }
    }

    let kycValidationFlagStatus = getLocalStorageItem(`${username}-kyc-validation-flag`);

    // Checking for false as well because we want to stop here even if value if false
    // We just want to proceed further if value is other than true or false
    if (!!kycValidationFlagStatus || kycValidationFlagStatus === false) {
      setKYCValidationFlag(!!kycValidationFlagStatus);
      return;
    }

    const { user_country } = customerAddress;
    if (!user_country) return;

    fetchKYCValidationFlag(user_country)
  }

  const getKycProgressOnClickHandler = async () => {
    toast.dismiss();
    resetState();

    // Step 1: Get User basic details
    if (!username) {
      toast.error('Username is required');
      return;
    }

    setLoading(true);

    let userData;
    let userDataToShow;

    try {
      const getUser = await axios.get(
        `${config.apiUrl}/admin/user/${username}`,
        {
          headers: {
            admintoken: getLocalStorageItem('adminToken'),
            othertoken: getLocalStorageItem('otherToken')
          }
        }
      );

      const { success, message: userData } = getUser.data;

      if (!success) {
        console.log('before toasting /admin/user/ api error');
        toast.error('Invalid username');
        setLoading(false);
        return;
      }
      userDataToShow = {
        id: userData.id,
        user_name: userData.user_name,
        first_name: userData.first_name,
        middle_name: userData.middle_name || '',
        last_name: userData.last_name,
        mobile_number: userData.mobile_number,
        user_country: userData.country_name,
        mintable_wallet: userData.mintable_wallet_address,
        gender: userData.gender,
        toggle_kyc: userData.toggle_kyc,
        toggle_login_otp: userData.toggle_login_otp,
        account_status: userData.account_status,
        is_verified: userData.isVerified,
        email: userData.email,
        miner_fees_received: getMinerFeesStatusString(userData.miner_fees_received),
        fees_received_date: moment(userData.fees_received_date).isValid() ? moment(userData.fees_received_date).format('YYYY-MM-DD') : 'N/A',
        create_time: userData.create_time ? moment(userData.create_time).format('MMMM Do YYYY, H:mm:ss') : '',
        update_time: userData.update_time ? moment(userData.update_time).format('MMMM Do YYYY, H:mm:ss') : '',
      };
      setUserInfo(userDataToShow)
    } catch (e) {
      toast.error('Invalid username');
      setLoading(false);
      return;
    }

    // Step 2: Now get user address details and merge with basic details
    let userAddressData;
    try {
      const customerAddress = await axios.post(
        `${config.apiUrl}/admin/get-customer-address`,
        { user_name: username },
        {
          headers: {
            admintoken: getLocalStorageItem('adminToken'),
            othertoken: getLocalStorageItem('otherToken')
          }
        }
      );

      const { success, msg, data } = customerAddress.data;

      if (!success) {
        console.log('before toasting/admin/get-customer-address api error', msg);
        toast.error(`Unable to fetch customer address details. ${msg || ''}`);
        // TODO: Uncomment below 2 lines when we want to restrict process if address is not there
        // setLoading(false);
        // return;
      }

      // If customer address is not present, show data with N/A so that we can at least proceed with KYC
      userAddressData =
        success ?
          data :
          {
              id: uuid(),
              user_name: userDataToShow.user_name,
              address_line1: 'N/A',
              address_line2: 'N/A',
              city_town_suburb: 'N/A',
              state_province: 'N/A',
              post_code: 'N/A',
              country: 'N/A',
              region: 'N/A',
              address_type: 'N/A',
              create_time: 'N/A',
              update_time: 'N/A',
          };

      setCustomerAddress(userAddressData);
      setInitialCustomerAddress(userAddressData);
    } catch (e) {
      console.log('[getKycProgressOnClickHandler] user address details', e);
      toast.error('Unable to get customer address');
      // TODO: Uncomment below 2 lines when we want to restrict process if address is not there
      // setLoading(false);
      // return;
      userAddressData = {
        id: 'N/A',
        address_line1: 'N/A',
        address_line2: 'N/A',
        city_town_suburb: 'N/A',
        state_province: 'N/A',
        post_code: 'N/A',
        country: 'N/A',
        region: 'N/A',
        address_type: 'N/A',
        create_time: 'N/A',
        update_time: 'N/A'
      }

      setCustomerAddress(userAddressData);
    }

    // Step 3: Now get user's KYC status
    try {
      const kycResponse = await axios.post(
        `${config.apiUrl}/admin/get-kyc-status`,
        { user_name: username },
        {
          headers: {
            admintoken: getLocalStorageItem('adminToken'),
            othertoken: getLocalStorageItem('otherToken')
          }
        }
      );

      const { success, msg, data: kyc_data } = kycResponse.data;

      if (!success) {
        console.log('before toasting /admin/get-kyc-status api error 0', msg);
        toast.error(msg || 'Unable to get kyc status');
        // setLoading(false);
        // return;
      }

      if (!kyc_data) {
        console.log('before toasting /admin/get-kyc-status api error 1', msg);
        // toast.error(msg || 'Unable to get kyc status');
        // setLoading(false);
        // return;
      }

      const { kyc_status } = kyc_data;

      // TODO: Change this back to Step 3 IF kyc_status flag have any issue and uncomment following line
      // const isKYCComplete = msg === 'Step 3 completed';
      // New check
      const isKYCComplete = kyc_status === 1;

      setShowKYCCompleteButton(!isKYCComplete)
      setKycStatus(isKYCComplete);

    } catch (e) {
      console.log('[getKycProgressOnClickHandler] user kyc status', e);
      toast.dismiss()
      toast.error('Unable to get KYC status');
      // setLoading(false);
      // return;
    }


    // Step 4: Now get user's valid documents
    await getValidDocs();

    // Step 5: Now get Invalid docs of user
    await getInvalidDocs();

    // Step 6: Get kyc toggle flag status
    getToggleKycFlagStatus();

    isFunction(actionLogger) &&
    await actionLogger(panelID, `Retrieved KYC progress for username ${username}.`, username);

    setLoading(false);

  }

  const getValidDocs = async () => {
    try {
      const kycProgressResponse = await axios.get(
        `${config.apiUrl}/kyc-progress-with-images/${username}`,
        {
          headers: {
            admintoken: getLocalStorageItem('adminToken'),
            othertoken: getLocalStorageItem('otherToken')
          }
        }
      );

      const { docs } = kycProgressResponse.data;
      console.log('VALID DOCS', docs);

      setPhotoId(null);
      setDrivingLicense(null);
      setPassportDoc(null);
      setSelfie(null);

      if (docs && Array.isArray(docs) && docs.length > 0) {
        await Promise.all(
          docs.forEach(doc => { downloadDoc(doc) })
        )
      }

    } catch (e) {
      console.log('[getKycProgressOnClickHandler] get valid kyc docs', e);
    }
  }

  const getInvalidDocs = async () => {
    setLoading(true);
    try {
      console.log('here inside try catch of getting invalid docs');
      const invalidDocsResponse = await axios.get(
        `${config.apiUrl}/admin/kyc-invalid-docs/${username}`,
        {
          headers: {
            admintoken: getLocalStorageItem('adminToken'),
            othertoken: getLocalStorageItem('otherToken')
          }
        }
      );

      const { success, docs, msg } = invalidDocsResponse.data;

      if (!success) {
        console.log('before toasting /admin/kyc-invalid-docs api error', msg);
        toast.error(msg || 'Unable to get invalid kyc docs');
        setLoading(false);
        return;
      }

      if (docs && Array.isArray(docs) && docs.length > 0) {
        await Promise.all(
          docs.forEach(doc => { downloadDoc(doc) })
        )
      }
    } catch (e) {
      console.log('[getKycProgressOnClickHandler] get invalid kyc docs', e);
    }
  }

  const downloadDoc = async doc => {
    toast.dismiss();

    if (!doc) {
      toast.error('Doc is required before downloading');
      return;
    }

    if (!(doc && doc.doc_type && doc.formattedPath && doc.extension)) {
      toast.error('Doc payload not valid');
      return;
    }

    console.log('doc ->', doc.doc_type, ' -> path -> ', doc.formattedPath)

    switch (doc.doc_type.toLowerCase()) {
      case 'passport':
        setPassportLoading(true);
        setPassportDocS3Path(doc.formattedPath);
        break;

      case 'driving_license':
       setDrivingLicenseLoading(true);
       setDrivingLicenseS3Path(doc.formattedPath);
        break;

      case 'photo_id':
        setPhotoIdLoading(true);
        setPhotoIdS3Path(doc.formattedPath);
        break;

      case 'selfie':
        setSelfieLoading(true);
        setSelfieS3Path(doc.formattedPath);
        break;

      case 'invalid_passport':
        setInvalidPassportS3Path(doc.formattedPath);
        break;

      case 'invalid_driver_license':
        setInvalidDrivingLicenseS3Path(doc.formattedPath);
        break;

      case 'invalid_photo_id':
        setInvalidPhotoIdS3Path(doc.formattedPath);
        break;

      case 'invalid_selfie':
        setInvalidSelfieS3Path(doc.formattedPath);
        break;

      default:
        // toast.error('Unable to download file');
        break;
    }

    try {
      const docResponse = await axios.get(
        `${config.apiUrl}/s3-download/${encodeURIComponent(doc.formattedPath)}`,
        {
          responseType: 'blob',
          headers: {
            admintoken: getLocalStorageItem('adminToken'),
            othertoken: getLocalStorageItem('otherToken')
          }
        }
      );

      const { data } = docResponse;

      switch (doc.doc_type.toLowerCase()) {
        case 'passport':
          setPassportDoc(data);
          setPassportOptionSelected('valid');
          setPassportLoading(false);
          setPassportAlreadyValid(true);
          return;

        case 'driving_license':
          setDrivingLicense(data);
          setLicenseOptionSelected('valid');
          setDrivingLicenseLoading(false);
          setDrivingLicenseAlreadyValid(true);
          return;

        case 'photo_id':
          setPhotoId(data);
          setPhotoIdOptionSelected('valid');
          setPhotoIdLoading(false);
          setPhotoIdAlreadyValid(true);
          return;

        case 'selfie':
          setSelfie(data);
          setSelfieOptionSelected('valid');
          setSelfieLoading(false);
          setSelfieAlreadyValid(true);
          return;

        case 'invalid_passport':
          setInvalidPassport(data);
          setInvalidPassportLoading(false);
          return;

        case 'invalid_driver_license':
          setInvalidDrivingLicense(data);
          setInvalidDrivingLicenseLoading(false);
          return;

        case 'invalid_photo_id':
          setInvalidPhotoId(data);
          setInvalidPhotoIdLoading(false);
          return;

        case 'invalid_selfie':
          setInvalidSelfie(data);
          setInvalidSelfieLoading(false);
          return;

        default:
          toast.error('Unable to download file');
          setPassportLoading(false);
          setDrivingLicenseLoading(false);
          setPhotoIdLoading(false);
          setSelfieLoading(false);
          setInvalidPhotoIdLoading(false);
          setInvalidDrivingLicenseLoading(false);
          setInvalidPhotoIdLoading(false);
          setInvalidSelfieLoading(false);
          return;
      }
    } catch (e) {
      console.log('[downloadDoc]', e);
    }
  }

  const manuallyUpdateKYCOnClickHandler = async () => {
    toast.dismiss();

    if (!username) {
      toast.error('User name is required');
      return;
    }

    if(!passportDoc && !drivingLicense) {
      toast.error('At least one of passport or license must be selected');
      return;
    }

    if (passportDoc && passportOptionSelected !== 'valid') {
      toast.error('Passport must be marked as valid');
      return;
    }

    if (drivingLicense && licenseOptionSelected !== 'valid') {
      toast.error('License must be marked as valid');
      return;
    }

    if (!(
        photoIdOptionSelected === 'valid' && selfieOptionSelected === 'valid' && photoId && selfie
    )) {
      toast.error('Photo Id & Selfie must be marked valid and loaded.');
      return;
    }

    if (!passportDoc && passportOptionSelected === 'valid') {
      toast.error('Passport is missing. Please load it or mark it as invalid/check');
      return;
    }

    if (!drivingLicense && licenseOptionSelected === 'valid') {
      toast.error('Driver License is missing. Please load it or mark it as invalid/check');
      return;
    }

    // if passport came from invalid docs, upload and make it valid here
    if (isPassportFromInvalidDocs) {
      const response = await acceptPassport();
      if (!response) {
        setPassportModal(true);
        return;
      }
    }

    // if license came from invalid docs, upload and make it valid here
    if (isLicenseFromInvalidDocs) {
      const response = await acceptDrivingLicense();
      if (!response) {
        setLicenseModal(true);
        return;
      }
    }

    // if photo id came from invalid docs, upload and make it valid here
    if (isPhotoIdFromInvalidDocs) {
      const response = await acceptPhotoId();
      if (!response) {
        setPhotoIdModal(true);
        return;
      }
    }

    // if selfie came from invalid docs, upload and make it valid here
    if (isSelfieFromInvalidDocs) {
      const response = await acceptSelfie();
      if (!response) {
        setSelfieModal(true);
        return;
      }
    }

    setLoading(true);

    const updateableDocs = {};

    if (passportDoc) {
      updateableDocs['passport'] = { verifiedByWhom: invalidPassportVerifiedByHuman };
    }

    if (drivingLicense) {
      updateableDocs['driving_license'] = { verifiedByWhom: invalidDrivingLicenseVerifiedByHuman };
    }

    if (photoId) {
      updateableDocs['photoid'] = { verifiedByWhom: invalidPhotoIdVerifiedByHuman };
    }

    if (selfie) {
      updateableDocs['selfie'] = { verifiedByWhom: invalidSelfieVerifiedByHuman };
    }

    try {
      const payload = {
        username,
        updateableDocs
      }
      const updateKYCResponse = await axios.post(
        `${config.apiUrl}/admin/complete-kyc`,
        payload,
        {
          headers: {
            admintoken: getLocalStorageItem('adminToken'),
            othertoken: getLocalStorageItem('otherToken')
          }
        }
      );

      const { success, msg } = updateKYCResponse.data;

      if (!success) {
        console.log('before toasting /admin/complete-kyc error msg', msg);
        toast.error(msg || 'Unable to complete KYC');
        setLoading(false);
        setNotifModalMessage(msg || 'Unable to complete KYC');
        setNotifModal(true);
        return;
      }

      toast.success(msg);
      setNotifModalMessage(msg);
      setNotifModal(true);
      isFunction(actionLogger) &&
      await actionLogger(panelID,`Completed KYC manually for username ${username}.`, username);
    } catch (e) {
      console.log('[manuallyUpdateKYCOnClickHandler]', e);
      setNotifModalMessage('Unable to complete KYC');
      setNotifModal(true);
      return;
    }

    setPassportDoc(null);
    setDrivingLicense(null);
    setPhotoId(null);
    setSelfie(null);
    setLoading(false);
    setShowKYCCompleteButton(false);
    setKycStatus(false);
    setInvalidPassport(null);
    setInvalidDrivingLicense(null);
    setInvalidPhotoId(null);
    setInvalidSelfie(null);

    await getKycProgressOnClickHandler();
  }

  const uploadFile = async (file, type) => {
    if (!file || !type) {
      toast.error('Unable to upload file');
      return;
    }

    if (!['passport', 'driver_license', 'photo_id', 'selfie'].includes(type)) {
      toast.error('Invalid file type');
      return;
    }

    if (!username) {
      toast.error('Username is required');
      return;
    }

    setLoading(true);

    // 1. check document quality if it is passport, license or photo id
    // If it is selfie then skip step 1 i.e quality prediction part and directly predict selfie is valid or not
    // let isSelfieCheck = false;
    if (type === 'selfie') {
      await validateSelfie(file, type);
      setLoading(false);
      return;
    }

    let documentFilePath = null;
    let formData;
    try {
      formData = new FormData();
      formData.append('file', file);
      formData.append('document_type', type);
      formData.append('email', username);
      formData.append('kyc_toggle_flag', +kycValidationFlag)

      const predictQualityResponse = await axios.post(
        `${config.apiUrlAIIdentity}/predict_document_quality`,
        formData
      );

      const { flag, document_path } = predictQualityResponse.data;
      documentFilePath = document_path;

      if (!flag) {
        toast.error('Image is not of good quality');
        setLoading(false);
        return;
      }
    } catch (e) {
      console.log('[uploadFile] predict doc quality', e);
      toast.error('Unable to upload file');
      return;
    }

    // 2. call validate api
    if (type === 'passport') {
      const response = await validatePassport(formData, documentFilePath);
      if (!response) {
        setLoading(false);
        return;
      }
    } else if (type === 'driver_license') {
      const response = await validateDrivingLicense(formData, documentFilePath);
      if (!response) {
        setLoading(false);
        return;
      }
    } else if (type === 'photo_id') {
      const response = await validatePhotoId(formData, documentFilePath);
      if (!response) {
        setLoading(false);
        return;
      }
    }

    setLoading(false);
  }

  // Manually pick file and make it go through AI flow
  const passportFilPickerHandler = async e => {
    const file = e.target.files[0];
    if (!file) {
      toast.error('Unable to pick file');
      return;
    }

    const reader = new FileReader();
    reader.onload = async e => {
      let blob = new Blob([new Uint8Array(e.target.result)], {type: file.type });
      setPassportDoc(blob);
      setPassportOptionSelected('check');
      await uploadFile(file, 'passport');
    }
    reader.readAsArrayBuffer(file);
  };

  const validatePassport = async (formData, documentFilePath) => {
    if (!documentFilePath) {
      toast.error('Document file path is required for uploading');
      return;
    }

    if (!formData) {
      toast.error('Form data is required');
      return;
    }

    try {
      formData.append('document_path', documentFilePath)
      formData.append('passport_channel_source', config.KYC_CHANNEL_SOURCE_ADMIN)
      formData.append('kyc_toggle_flag', +kycValidationFlag)
      const validatePassportResponse = await axios.post(
        `${config.apiUrlAIIdentity}/validate_passport`,
        formData
      );

      const { validation, msg } = validatePassportResponse.data;

      if (!validation) {
        console.log('before toasting /validate_passport error msg', msg);
        toast.error(msg || 'Passport not validated');
        setPassportDoc(null);
        setNotifModalMessage(msg || 'Passport not validated');
        setNotifModal(true);
        await getInvalidDocs();
        return;
      }

      toast.success(msg || 'Passport validated successfully');
      setPassportOptionSelected('valid');
      setPassportAlreadyValid(true);
      setNotifModalMessage(msg || 'Passport validated successfully');
      setNotifModal(true);
      setPassportFromInvalidDocs(false);
      isFunction(actionLogger) &&
      await actionLogger(panelID, `Validated passport for username ${username}.`, username);
      return true;
    } catch (e) {
      console.log('[validatePassport]', e);
      toast.error('Unable to validate passport');
      setNotifModalMessage('Unable to validate passport');
      setNotifModal(true);
      setPassportDoc(null);
    }
  }

  const drivingLicenseFilPickerHandler = async e => {
    const file = e.target.files[0];
    if (!file) {
      toast.error('Unable to pick file');
      return;
    }

    const reader = new FileReader();
    reader.onload = async e => {
      let blob = new Blob([new Uint8Array(e.target.result)], {type: file.type });
      setDrivingLicense(blob);
      setLicenseOptionSelected('check');
      await uploadFile(file, 'driver_license');
    }
    reader.readAsArrayBuffer(file);
  };

  const validateDrivingLicense = async (formData, documentFilePath) => {
    if (!documentFilePath) {
      toast.error('Document file path is required for uploading');
      return;
    }

    if (!formData) {
      toast.error('Form data is required');
      return;
    }

    try {
      formData.append('document_path', documentFilePath)
      formData.append('driver_lic_channel_source', config.KYC_CHANNEL_SOURCE_ADMIN)
      formData.append('kyc_toggle_flag', +kycValidationFlag)
      const validateLicense = await axios.post(
        `${config.apiUrlAIIdentity}/validate_driver_license`,
        formData
      );

      const { validation, msg } = validateLicense.data;

      if (!validation) {
        console.log('before toasting /valie_driver_license error msg', msg);
        toast.error(msg || 'License not validated');
        setDrivingLicense(null);
        setNotifModalMessage(msg || 'License not validated');
        setNotifModal(true);
        await getInvalidDocs();
        return;
      }

      toast.success(msg || 'License validated successfully');
      setLicenseOptionSelected('valid');
      setDrivingLicenseAlreadyValid(true);
      setNotifModalMessage(msg || 'License validated successfully');
      setNotifModal(true);
      setLicenseFromInvalidDocs(false);
      isFunction(actionLogger) &&
      await actionLogger(panelID, `Validated driver license for username ${username}.`, username);
      return true;
    } catch (e) {
      console.log('[validateDrivingLicense]', e);
      toast.error('Unable to validate license');
      setNotifModalMessage('Unable to validate license');
      setNotifModal(true);
      setDrivingLicense(null);
    }
  }

  const photoIdFilPickerHandler = async e => {
    const file = e.target.files[0];
    if (!file) {
      toast.error('Unable to pick file');
      return;
    }

    const reader = new FileReader();
    reader.onload = async e => {
      let blob = new Blob([new Uint8Array(e.target.result)], {type: file.type });
      setPhotoId(blob);
      setPhotoIdOptionSelected('check');
      await uploadFile(file, 'photo_id');
    }
    reader.readAsArrayBuffer(file);
  };

  const validatePhotoId = async (formData, documentFilePath) => {
    if (!documentFilePath) {
      toast.error('Document file path is required for uploading');
      return;
    }

    if (!formData) {
      toast.error('Form data is required');
      return;
    }

    try {
      formData.append('document_path', documentFilePath)
      formData.append('photo_id_channel_source', config.KYC_CHANNEL_SOURCE_ADMIN)
      formData.append('kyc_toggle_flag', +kycValidationFlag)
      const validateLicense = await axios.post(
        `${config.apiUrlAIIdentity}/validate_photo_id`,
        formData
      );

      const { validation, msg } = validateLicense.data;

      if (!validation) {
        console.log('before toasting /validate_photo_id error msg', msg);
        toast.error(msg || 'Photo Id not validated');
        setPhotoId(null);
        setNotifModalMessage(msg || 'Photo Id not validated');
        setNotifModal(true);
        await getInvalidDocs();
        return;
      }

      toast.success(msg || 'Photo Id validated successfully');
      setPhotoIdOptionSelected('valid');
      setPhotoIdAlreadyValid(true);
      setNotifModalMessage(msg || 'Photo Id validated successfully');
      setNotifModal(true);
      setPhotoIdFromInvalidDocs(false);
      isFunction(actionLogger) &&
      await actionLogger(panelID, `Validated photo id for username ${username}.`, username);
      return true;
    } catch (e) {
      console.log('[validatePhotoId]', e);
      toast.error('Unable to validate photo id');
      setNotifModalMessage('Unable to validate photo id');
      setNotifModal(true);
      setPhotoId(null);
    }
  }

  const selfieFilPickerHandler = async e => {
    const file = e.target.files[0];
    if (!file) {
      toast.error('Unable to pick file');
      return;
    }

    const reader = new FileReader();
    reader.onload = async e => {
      let blob = new Blob([new Uint8Array(e.target.result)], {type: file.type });
      setSelfie(blob);
      setSelfieOptionSelected('check');
      await uploadFile(file, 'selfie');
    }
    reader.readAsArrayBuffer(file);
  };

  const validateSelfie = async (file, type) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('document_type', type);
      formData.append('email', username);
      formData.append('selfie_doc_channel_source', config.KYC_CHANNEL_SOURCE_ADMIN);
      formData.append('kyc_toggle_flag', +kycValidationFlag)

      const validateSelfieResponse = await axios.post(
        `${config.apiUrlAIIdentity}/predict_selfie`,
        formData
      );

      const { valid, msg } = validateSelfieResponse.data;

      if (!valid) {
        console.log('before toasting /predict_selfie error msg', msg);
        toast.error(msg || 'Selfie not validated');
        setSelfie(null);
        setNotifModalMessage(msg || 'Selfie not validated');
        setNotifModal(true);
        await getInvalidDocs();
        return;
      }

      toast.success(msg || 'Selfie validated successfully');
      setSelfieOptionSelected('valid');
      setSelfieAlreadyValid(true);
      setNotifModalMessage(msg || 'Selfie validated successfully');
      setNotifModal(true);
      setSelfieFromInvalidDocs(false);
      isFunction(actionLogger) &&
      await actionLogger(panelID, `Selfie validated for username ${username}.`, username);

      return true;
    } catch (e) {
      console.log('[validateSelfie]', e);
      toast.error('Unable to validate selfie');
      setNotifModalMessage('Unable to validate selfie');
      setNotifModal(true);
      setSelfie(null);
    }
  }

  const uploadInvalidFileToValidS3Folder = async (file, type) => {
    try {

      const formData = new FormData();
      formData.append('file', file);
      formData.append('email', username);
      formData.append('doc_type', type);
      formData.append('valid_document', "true");

      const uploadFileResponse = await axios.post(
        `${config.apiUrl}/s3-upload-with-encryption`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      const { success, message, filepath } = uploadFileResponse.data;

      if (!success) {
        console.log('before toasting /s3-upload-with-encryption error msg', message);
        toast.error(message || 'Unable to upload file');
        setLoading(false);
        return {
          success: false,
          msg: message || 'Unable to upload file'
        };
      }

      toast.success('File uploaded successfully');
      return {
        success: true,
        msg: 'File uploaded successfully',
        filePath: filepath,
      }
    } catch (e) {
      console.log('[uploadFile]', e);
      toast.error('Unable to upload file');
      setLoading(false);
      return {
        success: false,
        msg: 'Unable to upload file',
      };
    }
  };

  const getPassportVerifyModalBody = () => {
    return (
      <StyledModalWrapper>
        <div className='content-wrapper'>
          <div className='left-section'>
            <SimpleReactLightbox>
              <SRLWrapper>
                <img
                  src={invalidPassport ? URL.createObjectURL(invalidPassport) : '/images/no-user-img.jpeg'}
                  alt='inv-passport-img'
                />
              </SRLWrapper>
            </SimpleReactLightbox>
            <div className='get-ocr-text-section'>
              {
                !gettingText && !ocrText && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => getTextFromImage(invalidPassport)}>
                    Get text from image
                  </Button>
                )
              }
              {
                gettingText ? <img src={'/images/loader_black.svg'} alt='Loader' /> : ocrText ? <div className='ocr-text-section'><p className='ocr-text'>{ocrText}</p></div> : <></>
              }
            </div>
          </div>
          <div className='right-section'>
            <h4>Passport Verification</h4>

            <h6>Nationality</h6>
            <input
              type='text'
              placeholder='Nationality...'
              value={passportNationality}
              onChange={e => setPassportNationality(e.target.value)}
            />

            <h6>Passport Number</h6>
            <input
              type='text'
              placeholder='Passport number...'
              value={passportNumber}
              onChange={e => setPassportNumber(e.target.value)}
            />

            <h6>Surname</h6>
            <input
              type='text'
              placeholder='Surname...'
              value={passportSurname}
              onChange={e => setPassportSurname(e.target.value)}
            />

            <h6>Date of Birth</h6>
            <input
              type='text'
              placeholder='Date of birth YYYY-MM-DD'
              value={passportDOB}
              onChange={e => setPassportDOB(e.target.value)}
            />

            <h6>Passport Given Names</h6>
            <input
              type='text'
              placeholder='Given names on passport...'
              value={passportGivenNames}
              onChange={e => setPassportGivenNames(e.target.value)}
            />

            <h6>Citizen Number</h6>
            <input
              type='text'
              placeholder='Citizen number...'
              value={passportCitizenNumber}
              onChange={e => setPassportCitizenNumber(e.target.value)}
            />

            <h6>Place of birth</h6>
            <input
              type='text'
              placeholder='Passport Place of birth...'
              value={passportPlaceOfBirth}
              onChange={e => setPassportPlaceOfBirth(e.target.value)}
            />

            <h6>Father</h6>
            <input
              type='text'
              placeholder='Father name...'
              value={passportFatherName}
              onChange={e => setPassportFatherName(e.target.value)}
            />

            <h6>Gender</h6>
            <input
              type='text'
              placeholder='Gender...'
              value={passportGender}
              onChange={e => setPassportGender(e.target.value)}
            />

            <h6>Issuance Date</h6>
            <input
              type='text'
              placeholder='Issuance date YYYY-MM-DD'
              value={passportIssuanceDate}
              onChange={e => setPassportIssuanceDate(e.target.value)}
            />

            <h6>Date of Expiry</h6>
            <input
              type='text'
              placeholder='Expiry date YYYY-MM-DD'
              value={passportExpiryDate}
              onChange={e => setPassportExpiryDate(e.target.value)}
            />
          </div>
        </div>
        <div className='action-buttons'>
          <Button
            variant="contained"
            color="secondary"
            className='reject-button'
            onClick={() => {
              setPassportModal(false);
              setOcrText('');
            }}
          >
            Reject
          </Button>
          <Button
            variant="contained"
            color="primary"
            className='accept-button'
            // onClick={acceptPassport}
            onClick={() => {
              setPassportDoc(invalidPassport);
              setPassportFromInvalidDocs(true);
              setPassportOptionSelected('check');
              setPassportModal(false);
              setInvalidPassportVerifiedByHuman(1);
              toast.success('Passport marked as check');
            }}
          >
            Mark as valid
          </Button>
          <Button
            variant="contained"
            className='fill-missing-values-button'
            onClick={fillPassportMissingValues}
          >
            Fill missing values
          </Button>
        </div>
      </StyledModalWrapper>
    )
  }

  const acceptPassport = async () => {
    toast.dismiss();

    if (!username) {
      toast.error('Username is required');
      return;
    }

    if (!passportNationality) {
      toast.error('Passport nationality is required');
      return;
    }

    if (!passportNumber) {
      toast.error('Passport Number is required');
      return;
    }

    if (!passportSurname) {
      toast.error('Passport Surname is required');
      return;
    }

    if (!passportGivenNames) {
      toast.error('Passport Given Names are required');
      return;
    }

    if (!passportDOB) {
      toast.error('Passport Date of birth required');
      return;
    }

    if (!moment(passportDOB, 'YYYY-MM-DD', true).isValid()) {
      toast.error('Passport date of birth must be in YYYY-MM-DD format');
      return;
    }

    if (!passportCitizenNumber) {
      toast.error('Passport Citizen number is required');
      return;
    }

    if (!passportPlaceOfBirth) {
      toast.error('Passport place of birth is required');
      return;
    }

    if (!passportFatherName) {
      toast.error('Passport father name is required');
      return;
    }

    if (!passportGender) {
      toast.error('Passport gender is required');
      return;
    }

    if (!passportIssuanceDate) {
      toast.error('Passport issuance date is required');
      return;
    }

    if (!moment(passportIssuanceDate, 'YYYY-MM-DD', true).isValid()) {
      toast.error('Passport date of issuance must be in YYYY-MM-DD format');
      return;
    }

    if (!passportExpiryDate) {
      toast.error('Passport expiry date is required');
      return;
    }

    if (!moment(passportExpiryDate, 'YYYY-MM-DD', true).isValid()) {
      toast.error('Passport date of expiry must be in YYYY-MM-DD format');
      return;
    }

    setLoading(true);

    const copyOfInvalidPassportBlob = invalidPassport;
    copyOfInvalidPassportBlob.lastModifiedDate = new Date();
    copyOfInvalidPassportBlob.name = `invalid-passport-${username}-${new Date().toString()}`
    console.log('blob converted to file', copyOfInvalidPassportBlob);

    // 1. upload file to s3 and get document file path from response
    const response = await uploadInvalidFileToValidS3Folder(copyOfInvalidPassportBlob, 'passport');
    if (!response || !response.success) {
      toast.error('Unable to verify passport');
      setLoading(false);
      setNotifModalMessage('Unable to verify passport');
      setNotifModal(true);
      return;
    }

    const { filePath } = response;
    // 2. add create time field and document file path in payload
    try {
      const payload = {
        username,
        passportNationality,
        passportNumber,
        passportSurname,
        passportGivenNames,
        passportDOB,
        passportCitizenNumber,
        passportPlaceOfBirth,
        passportFatherName,
        passportGender,
        passportIssuanceDate,
        passportExpiryDate,
        documentFilePath: { path: filePath },
      }

      const verify = await axios.post(
        `${config.apiUrl}/admin/save-valid-passport`,
        payload,
        {
          headers: {
            admintoken: getLocalStorageItem('adminToken'),
            othertoken: getLocalStorageItem('otherToken')
          }
        }
      );

      const { success, message } = verify.data;
      if (!success) {
        console.log('before toasting /save-valid-passport error msg', message);
        toast.error(message || 'Unable to verify passport');
        setLoading(false);
        setNotifModalMessage(message || 'Unable to verify passport');
        setNotifModal(true);
        return;
      }
      toast.success(message || 'Passport verified successfully');
      await getValidDocs();
      setPassportModal(false);
      setNotifModalMessage(message || 'Passport verified successfully')
      setNotifModal(true);
      setInvalidPassportVerifiedByHuman(1);
      setPassportAlreadyValid(true);
      setPassportFromInvalidDocs(false);
      isFunction(actionLogger) &&
      await actionLogger(panelID, `Marked invalid passport as valid for username ${username}.`, username);
    } catch (e) {
      toast.error('Unable to verify passport');
      setNotifModalMessage('Unable to verify passport');
      setNotifModal(true);
    }
    setLoading(false);
    return true;
  };

  const fillPassportMissingValues = () => {
    if (!passportNationality) {
      setPassportNationality('#Unknown#');
    }

    if (!passportNumber) {
      setPassportNumber('#Unknown#');
    }

    if (!passportSurname) {
      setPassportSurname('#Unknown#');
    }

    if (!passportGivenNames) {
      setPassportGivenNames('#Unknown#');
    }

    if (!passportDOB) {
      setPassportDOB(moment().format('YYYY-MM-DD'));
    }

    if (!passportCitizenNumber) {
      setPassportCitizenNumber('#Unknown#');
    }

    if (!passportPlaceOfBirth) {
      setPassportPlaceOfBirth('#Unknown#');
    }

    if (!passportFatherName) {
      setPassportFatherName('#Unknown#');
    }

    if (!passportGender) {
      setPassportGender('#Unknown#');
    }

    if (!passportIssuanceDate) {
      setPassportIssuanceDate(moment().format('YYYY-MM-DD'));
    }

    if (!passportExpiryDate) {
      setPassportExpiryDate(moment().format('YYYY-MM-DD'));
    }
  };

  const getLicenseVerifyModalBody = () => {
    return (
      <StyledModalWrapper>
        <div className='content-wrapper'>
          <div className='left-section'>
            <SimpleReactLightbox>
              <SRLWrapper>
                <img
                  src={invalidDrivingLicense ? URL.createObjectURL(invalidDrivingLicense) : '/images/no-user-img.jpeg'}
                  alt='inv-license-img'
                />
              </SRLWrapper>
            </SimpleReactLightbox>
            <div className='get-ocr-text-section'>
              {
               !gettingText && !ocrText && (
                 <Button
                   variant="contained"
                   color="primary"
                   onClick={() => getTextFromImage(invalidDrivingLicense)}>
                   Get text from image
                 </Button>
               )
              }
              {
                gettingText ? <img src={'/images/loader_black.svg'} alt='Loader' /> : ocrText ? <div className='ocr-text-section'><p className='ocr-text'>{ocrText}</p></div> : <></>
              }
            </div>
          </div>
          <div className='right-section'>
            <h4>Driving License Verification</h4>

            <h6>Name</h6>
            <input
              type='text'
              placeholder='Name...'
              value={driverLicenseName}
              onChange={e => setDriverLicenseName(e.target.value)}
            />

            <h6>Card Number</h6>
            <input
              type='text'
              placeholder='Card number...'
              value={driverLicenseCardNumber}
              onChange={e => setDriverLicenseCardNumber(e.target.value)}
            />

            <h6>License Number</h6>
            <input
              type='text'
              placeholder='License number...'
              value={driverLicenseNumber}
              onChange={e => setDriverLicenseNumber(e.target.value)}
            />

            <h6>License Address</h6>
            <input
              type='text'
              placeholder='License address...'
              value={driverLicenseAddress}
              onChange={e => setDriverLicenseAddress(e.target.value)}
            />

            <h6>Date of Birth</h6>
            <input
              type='text'
              placeholder='Date of birth YYYY-MM-DD'
              value={driverLicenseDOB}
              onChange={e => setDriverLicenseDOB(e.target.value)}
            />

            <h6>Date of Expiry</h6>
            <input
              type='text'
              placeholder='Date of expiry YYYY-MM-DD'
              value={driverLicenseExpiry}
              onChange={e => setDriverLicenseExpiry(e.target.value)}
            />
          </div>
        </div>
        <div className='action-buttons'>
          <Button
            variant="contained"
            color="secondary"
            className='reject-button'
            onClick={() => {
              setLicenseModal(false);
              setOcrText('');
            }}
          >
            Reject
          </Button>
          <Button
            variant="contained"
            color="primary"
            className='accept-button'
            // onClick={acceptDrivingLicense}
            onClick={() => {
              setDrivingLicense(invalidPassport);
              setLicenseFromInvalidDocs(true);
              setLicenseOptionSelected('check');
              setLicenseModal(false);
              toast.success('License marked as check');
            }}
          >
            Mark as valid
          </Button>
          <Button
            variant="contained"
            className='fill-missing-values-button'
            onClick={fillLicenseMissingValues}
          >
            Fill missing values
          </Button>
        </div>
      </StyledModalWrapper>
    )
  }

  const acceptDrivingLicense = async () => {
    toast.dismiss();

    if (!username) {
      toast.error('Username is required');
      return;
    }

    if (!driverLicenseName) {
      toast.error('Driver License name is required');
      return;
    }

    if (!driverLicenseCardNumber) {
      toast.error('Driving License card number is required');
      return;
    }

    if (!driverLicenseNumber) {
      toast.error('Driving License number is required');
      return;
    }

    if (!driverLicenseAddress) {
      toast.error('Driving License address is required');
      return;
    }

    if (!driverLicenseDOB) {
      toast.error('Driving License Date of birth is required');
      return;
    }

    if (!moment(driverLicenseDOB, 'YYYY-MM-DD', true).isValid()) {
      toast.error('License date of birth must be in YYYY-MM-DD format');
      return;
    }

    if (!driverLicenseExpiry) {
      toast.error('Driving License expiry is required');
      return;
    }

    if (!moment(driverLicenseExpiry, 'YYYY-MM-DD', true).isValid()) {
      toast.error('License date of expiry must be in YYYY-MM-DD format');
      return;
    }

    setLoading(true);

    const copyOfInvalidLicenseBlob = invalidDrivingLicense;
    copyOfInvalidLicenseBlob.lastModifiedDate = new Date();
    copyOfInvalidLicenseBlob.name = `invalid-license-${username}-${new Date().toString()}`
    console.log('blob converted to file', copyOfInvalidLicenseBlob);

    const response = await uploadInvalidFileToValidS3Folder(copyOfInvalidLicenseBlob, 'driver_license');
    if (!response || !response.success) {
      toast.error('Unable to verify license');
      setLoading(false);
      setNotifModalMessage( 'Unable to verify license');
      setNotifModal(true);
      return;
    }

    const { filePath } = response;

    try {
      const payload = {
        username,
        driverLicenseName,
        driverLicenseCardNumber,
        driverLicenseNumber,
        driverLicenseAddress,
        driverLicenseDOB,
        driverLicenseExpiry,
        documentFilePath: { path: filePath },
      }

      const verify = await axios.post(
        `${config.apiUrl}/admin/save-valid-license`,
        payload,
        {
          headers: {
            admintoken: getLocalStorageItem('adminToken'),
            othertoken: getLocalStorageItem('otherToken')
          }
        }
      );

      const { success, message } = verify.data;
      if (!success) {
        console.log('before toasting /save-valid-license error msg', message);
        toast.error(message || 'Unable to verify license');
        setLoading(false);
        setNotifModalMessage(message || 'Unable to verify license');
        setNotifModal(true);
        return;
      }
      toast.success(message || 'License verified successfully');
      await getValidDocs();
      setLicenseModal(false);
      setNotifModalMessage(message || 'License verified successfully');
      setNotifModal(true);
      setInvalidDrivingLicenseVerifiedByHuman(1);
      setDrivingLicenseAlreadyValid(true);
      setLicenseFromInvalidDocs(false);
      isFunction(actionLogger) &&
      await actionLogger(panelID, `Marked invalid driving license as valid for username ${username}.`, username);
    } catch (e) {
      toast.error('Unable to verify license');
      setNotifModalMessage('Unable to verify license');
      setNotifModal(true);
    }
    setLoading(false);
    return true;
  }

  const fillLicenseMissingValues = () => {
    if (!driverLicenseName) {
      setDriverLicenseName('#Unknown#');
    }

    if (!driverLicenseCardNumber) {
      setDriverLicenseCardNumber('#Unknown#');
    }

    if (!driverLicenseNumber) {
      setDriverLicenseNumber('#Unknown#');
    }

    if (!driverLicenseAddress) {
      setDriverLicenseAddress('#Unknown#');
    }


    if (!driverLicenseDOB) {
      setDriverLicenseDOB(moment().format('YYYY-MM-DD'));
    }

    if (!driverLicenseExpiry) {
      setDriverLicenseExpiry(moment().format('YYYY-MM-DD'));
    }
  }

  const getPhotoIdVerifyModalBody = () => {
    return (
      <StyledModalWrapper>
        <div className='content-wrapper'>
          <div className='left-section'>
            <SimpleReactLightbox>
              <SRLWrapper>
                <img
                  src={invalidPhotoId ? URL.createObjectURL(invalidPhotoId) : '/images/no-user-img.jpeg'}
                  alt='inv-photo-id-img'
                />
              </SRLWrapper>
            </SimpleReactLightbox>
            <div className='get-ocr-text-section'>
              {
                !gettingText && !ocrText && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => getTextFromImage(invalidPhotoId)}>
                    Get text from image
                  </Button>
                )
              }
              {
                gettingText ? <img src={'/images/loader_black.svg'} alt='Loader' /> : ocrText ? <div className='ocr-text-section'><p className='ocr-text'>{ocrText}</p></div> : <></>
              }
            </div>
          </div>
          <div className='right-section'>
            <h4>Photo ID Verification</h4>

            <h6>Name</h6>
            <input
              type='text'
              placeholder='Name...'
              value={photoIdName}
              onChange={e => setPhotoIdName(e.target.value)}
            />

            <h6>Family Name</h6>
            <input
              type='text'
              placeholder='Family Name...'
              value={photoIdFamilyName}
              onChange={e => setPhotoIdFamilyName(e.target.value)}
            />

            <h6>Card Number</h6>
            <input
              type='text'
              placeholder='Card number...'
              value={photoIdCardNumber}
              onChange={e => setPhotoIdCardNumber(e.target.value)}
            />

            <h6>Address</h6>
            <input
              type='text'
              placeholder='Address...'
              value={photoIdAddress}
              onChange={e => setPhotoIdAddress(e.target.value)}
            />

            <h6>Date of Birth</h6>
            <input
              type='text'
              placeholder='Date of birth YYYY-MM-DD'
              value={photoIdDOB}
              onChange={e => setPhotoIdDOB(e.target.value)}
            />

            <h6>Date of Expiry</h6>
            <input
              type='text'
              placeholder='Date of expiry YYYY-MM-DD'
              value={photoIdExpiryDate}
              onChange={e => setPhotoIdExpiryDate(e.target.value)}
            />
          </div>
        </div>
        <div className='action-buttons'>
          <Button
            variant="contained"
            color="secondary"
            className='reject-button'
            onClick={() => {
              setPhotoIdModal(false);
              setOcrText('');
            }}
          >
            Reject
          </Button>
          <Button
            variant="contained"
            color="primary"
            className='accept-button'
            // onClick={acceptPhotoId}
            onClick={() => {
              setPhotoId(invalidPassport);
              setPhotoIdFromInvalidDocs(true);
              setPhotoIdOptionSelected('check');
              setPhotoIdModal(false);
              toast.success('Photo Id marked as check');
            }}
          >
            Mark as valid
          </Button>
          <Button
            variant="contained"
            className='fill-missing-values-button'
            onClick={fillPhotoIdMissingValues}
          >
            Fill missing values
          </Button>
        </div>
      </StyledModalWrapper>
    )
  }

  const acceptPhotoId = async () => {
    toast.dismiss();

    if (!username) {
      toast.error('Username is required');
      return;
    }

    if (!photoIdName) {
      toast.error('Photo Id name is required');
      return;
    }

    if (!photoIdFamilyName) {
      toast.error('Photo Id family name is required');
      return;
    }

    if (!photoIdCardNumber) {
      toast.error('Photo Id card number is required');
      return;
    }

    if (!photoIdAddress) {
      toast.error('Photo Id address is required');
      return;
    }

    if (!photoIdDOB) {
      toast.error('Photo Id Date of birth is required');
      return;
    }

    if (!moment(photoIdDOB, 'YYYY-MM-DD', true).isValid()) {
      toast.error('Photo Id date of birth must be in YYYY-MM-DD format');
      return;
    }

    if (!photoIdExpiryDate) {
      toast.error('Photo Id expiry is required');
      return;
    }

    if (!moment(photoIdExpiryDate, 'YYYY-MM-DD', true).isValid()) {
      toast.error('Photo Id date of expiry must be in YYYY-MM-DD format');
      return;
    }

    setLoading(true);

    const copyOfInvalidPhotoIdBlob = invalidPhotoId;
    copyOfInvalidPhotoIdBlob.lastModifiedDate = new Date();
    copyOfInvalidPhotoIdBlob.name = `invalid-photo-id-${username}-${new Date().toString()}`
    console.log('blob converted to file', copyOfInvalidPhotoIdBlob);

    const response = await uploadInvalidFileToValidS3Folder(copyOfInvalidPhotoIdBlob, 'photo_id');
    if (!response || !response.success) {
      toast.error('Unable to verify photo id');
      setLoading(false);
      setNotifModalMessage('Unable to verify photo id');
      setNotifModal(true);
      return;
    }

    const { filePath } = response;

    try {
      const payload = {
        username,
        photoIdName,
        photoIdFamilyName,
        photoIdCardNumber,
        photoIdAddress,
        photoIdExpiryDate,
        photoIdDOB,
        documentFilePath: { path: filePath },
      }

      const verify = await axios.post(
        `${config.apiUrl}/admin/save-valid-photo-id`,
        payload,
        {
          headers: {
            admintoken: getLocalStorageItem('adminToken'),
            othertoken: getLocalStorageItem('otherToken')
          }
        }
      );

      const { success, message } = verify.data;
      if (!success) {
        console.log('before toasting /save-valid-photo-id error msg', message);
        toast.error(message || 'Unable to verify photo id');
        setLoading(false);
        setNotifModalMessage(message || 'Unable to verify photo id');
        setNotifModal(true);
        return;
      }
      toast.success(message || 'Photo Id verified successfully');
      await getValidDocs();
      setPhotoIdModal(false);
      setNotifModalMessage(message || 'Photo Id verified successfully')
      setNotifModal(true);
      setInvalidPhotoIdVerifiedByHuman(1);
      setPhotoIdAlreadyValid(true);
      setPhotoIdFromInvalidDocs(false);
      isFunction(actionLogger) &&
      await actionLogger(panelID, `Marked invalid photo id as valid for username ${username}.`, username);
    } catch (e) {
      toast.error('Unable to verify photo id');
      setNotifModalMessage('Unable to verify photo id');
      setNotifModal(true);
    }
    setLoading(false);
    return true;
  }

  const fillPhotoIdMissingValues = () => {
    if (!photoIdName) {
      setPhotoIdName('#Unknown#');
    }

    if (!photoIdFamilyName) {
      setPhotoIdFamilyName('#Unknown#');
    }

    if (!photoIdCardNumber) {
      setPhotoIdCardNumber('#Unknown#');
    }

    if (!photoIdAddress) {
      setPhotoIdAddress('#Unknown#');
    }

    if (!photoIdDOB) {
      setPhotoIdDOB(moment().format('YYYY-MM-DD'));
    }

    if (!photoIdExpiryDate) {
      setPhotoIdExpiryDate(moment().format('YYYY-MM-DD'));
    }
  }

  const getSelfieVerifyModalBody = () => {
    return (
      <StyledModalWrapper>
        <div className='content-wrapper'>
          <SimpleReactLightbox>
            <SRLWrapper>
              <img
                src={invalidSelfie ? URL.createObjectURL(invalidSelfie) : '/images/no-user-img.jpeg'}
                alt='inv-photo-id-img'
              />
            </SRLWrapper>
          </SimpleReactLightbox>
          <div className='left-section'>
          </div>
        </div>
        <div className='action-buttons'>
          <Button
            variant="contained"
            color="secondary"
            className='reject-button'
            onClick={() => setSelfieModal(false)}
          >
            Reject
          </Button>
          <Button
            variant="contained"
            color="primary"
            className='accept-button'
            // onClick={acceptSelfie}
            onClick={() => {
              setSelfie(invalidPassport);
              setSelfieFromInvalidDocs(true);
              setSelfieOptionSelected('check');
              setSelfieModal(false);
              toast.success('Selfie marked as check');
            }}
          >
            Mark as valid
          </Button>
        </div>
      </StyledModalWrapper>
    )
  }

  const acceptSelfie = async () => {
    toast.dismiss();

    if (!username) {
      toast.error('Username is required');
      return;
    }

    setLoading(true);

    const copyOfInvalidSelfieBlob = invalidSelfie;
    copyOfInvalidSelfieBlob.lastModifiedDate = new Date();
    copyOfInvalidSelfieBlob.name = `invalid-selfie-${username}-${new Date().toString()}`
    console.log('blob converted to file', copyOfInvalidSelfieBlob);

    const response = await uploadInvalidFileToValidS3Folder(copyOfInvalidSelfieBlob, 'selfie');
    if (!response || !response.success) {
      toast.error('Unable to verify selfie');
      setLoading(false);
      setNotifModalMessage('Unable to verify selfie');
      setNotifModal(true);
      return;
    }

    const { filePath } = response;

    try {
      const payload = {
        username,
        documentFilePath: { path: filePath },
      }

      const verify = await axios.post(
        `${config.apiUrl}/admin/save-valid-selfie`,
        payload,
        {
          headers: {
            admintoken: getLocalStorageItem('adminToken'),
            othertoken: getLocalStorageItem('otherToken')
          }
        }
      );

      const { success, message } = verify.data;
      if (!success) {
        console.log('before toasting /save-valid-selfie error msg', message);
        toast.error(message || 'Unable to verify selfie');
        setLoading(false);
        setNotifModalMessage(message || 'Unable to verify selfie');
        setNotifModal(true);
        return;
      }
      toast.success(message || 'Selfie verified successfully');
      await getValidDocs();
      setSelfieModal(false);
      setNotifModalMessage(message || 'Selfie verified successfully');
      setNotifModal(true);
      setInvalidSelfieVerifiedByHuman(1);
      setSelfieAlreadyValid(true);
      setSelfieFromInvalidDocs(false);
      isFunction(actionLogger) &&
      await actionLogger(panelID, `Marked invalid selfie as valid for username ${username}.`, username);
    } catch (e) {
      toast.error('Unable to verify selfie');
      setNotifModalMessage('Unable to verify selfie');
      setNotifModal(true);
    }
    setLoading(false);
    return true;
  }

  const getNotifyModalBody = () => {
    return (
      <StyledModalWrapper className='notif-modal-body'>
        <h3>{notifModalMessage && notifModalMessage}</h3>
        <div className='action-buttons'>
          <Button variant="contained" color="primary" onClick={() => setNotifModal(false)}>OK</Button>
        </div>
      </StyledModalWrapper>
    )
  };

  const getLoadingModalBody = () => {
    return (
      <StyledModalWrapper className='loading-modal-body'>
        <img src={'/images/loader.svg'} alt='Loader' />
      </StyledModalWrapper>
    )
  };

  const downloadImageLocally = fileType => {

    const downloadFileToSystem = blob => {
      console.log('blob', blob)
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `${username}-${fileType}-${new Date().toLocaleTimeString()}.jpeg`;
      a.click();
      window.URL.revokeObjectURL(url);
      toast.dismiss();
      toast.success('File downloaded successfully');
    };

    if (!fileType) {
      toast.error('Unable to download file');
      return;
    }

    setFilePathModal(false);

    switch (fileType) {
      case 'passport':
        return downloadFileToSystem(passportDoc);
      case 'driver_license':
        return downloadFileToSystem(drivingLicense);
      case 'photo_id':
        return downloadFileToSystem(photoId);
      case 'selfie':
        return downloadFileToSystem(selfie);
      case 'invalid_passport':
        return downloadFileToSystem(invalidPassport);
      case 'invalid_driver_license':
        return downloadFileToSystem(invalidDrivingLicense);
      case 'invalid_photo_id':
        return downloadFileToSystem(invalidPhotoId);
      case 'invalid_selfie':
        return downloadFileToSystem(invalidSelfie);

      default:
        toast.error('Unable to download file');
        return;
    }
  }

  const getShowFilePathModalBody = () => {
    return (
      <StyledModalWrapper className='show-file-path-modal-body'>
        <p>{currentFilePath}</p>
        <div className='action-buttons-wrapper'>
          <Button variant="contained" color="secondary" onClick={() => setFilePathModal(false)}>Close</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => downloadImageLocally(currentFileToShowPath)}
          >
            Download Image
          </Button>
        </div>
      </StyledModalWrapper>
    )
  };

  const getCurrentSelectDocPath = fileType => {
    if (!fileType) {
      return 'Please try again - No Path Found'
    }

    switch (fileType) {
      case 'passport':
        return passportDocS3Path;
      case 'driver_license':
        return drivingLicenseS3Path;
      case 'photo_id':
        return photoIdS3Path;
      case 'selfie':
        return selfieS3Path;
      case 'invalid_passport':
        return invalidPassportS3Path;
      case 'invalid_driver_license':
        return invalidDrivingLicenseS3Path;
      case 'invalid_photo_id':
        return invalidPhotoIdS3Path;
      case 'invalid_selfie':
        return invalidSelfieS3Path;

      default:
        return 'Please try again - No Path Found'
    }
  }

  // Below func will be used to clear value of previously selected file
  const inputOnClickHandler = event => {
    event.target.value = '';
  };

  const handleEditRowUserInfo = useCallback((model) => {
    
    const objectKeys = Object.keys(model);
    const keyToAccess = objectKeys && objectKeys.length > 0 && objectKeys[0];

    if (!keyToAccess) return;

    const requiredObject = model[keyToAccess];
    const finalObject = {};
    Object.keys(requiredObject).forEach(key => {
      finalObject[key] = requiredObject[key].value;
    });
    setNewUserInfo({
      ...userInfo,
      ...finalObject
    });
  }, []);

  const handleEditRowAddress = useCallback((model) => {
    const objectKeys = Object.keys(model);
    const keyToAccess = objectKeys && objectKeys.length > 0 && objectKeys[0];

    if (!keyToAccess) return;

    const requiredObject = model[keyToAccess];
    const finalObject = {};
    Object.keys(requiredObject).forEach(key => {
      finalObject[key] = requiredObject[key].value;
    });
    setNewAddress({
      ...customerAddress,
      ...finalObject,
    });
  }, []);

  const shouldShowSaveUserInfoButton = () => {
    const isNewUserInfoEmpty = Object.keys(newUserInfo);
    if (isNewUserInfoEmpty.length === 0) return false;

    return JSON.stringify(newUserInfo) !== JSON.stringify(initialUserInfo);
  }

  const shouldShowSaveAddressButton = () => {
    const isNewAddressEmpty = Object.keys(newAddress);
    if (isNewAddressEmpty.length === 0) return false;

    return JSON.stringify(newAddress) !== JSON.stringify(initialCustomerAddress);
  }

  const getSaveEnterAddressButtonText = () => {
    return customerAddress && customerAddress.id === 'N/A' ? 'Enter Address' : 'Save Address';
  }

  const onSaveUserInfoButtonClick = async () => {
    const validateUserInfoFields = ({
      first_name,
      last_name,
      mobile_number,
      user_country,
      gender,
      account_status,
      is_verified,
      email,
    }) => {
    
      if (!first_name) {
        toast.error('First name is required');
        return false;
      }

      if (!last_name) {
        toast.error('Last Name is required');
        return false;
      }

      if (!mobile_number) {
        toast.error('Mobile Number is required');
        return false;
      }

      if (!user_country) {
        toast.error('Country is required');
        return false;
      }

      if (!gender) {
        toast.error('Gender is required');
        return false;
      }

      if (!account_status) {
        toast.error('Account Status is required');
        return false;
      }

      if (!is_verified) {
        toast.error('Is verified is required');
        return false;
      }

      if (!email) {
        toast.error('Email is required');
        return false;
      }

      if (!userInfo.user_name) {
        toast.error('Unable to check username');
        return false;
      }

      return true;
    };

   
    const isUserInfoValid = validateUserInfoFields(newUserInfo);

    if (!isUserInfoValid) return;

    const userInfoPayload = {
      user_name: userInfo.user_name,
      ...newUserInfo,
    }

    try {
      const response = await axios.post(
        `${config.apiUrl}/admin/user`,
        userInfoPayload,
        {
          headers: {
            admintoken: getLocalStorageItem('adminToken'),
            othertoken: getLocalStorageItem('otherToken')
          }
        }
      );

      const { success, msg } = response.data;

      if(!success) {
        toast.error(msg || 'Unable to save user info');
        return;
      }


      toast.success(msg || 'Updated user info successfully');
      isFunction(actionLogger) &&
      await actionLogger(
        panelID,
        `Updated user info of username ${username} from ${JSON.stringify(userInfo)} to ${JSON.stringify(userInfoPayload)}`,
        username
      );
      setNewUserInfo({});
      setUserInfo({
        ...userInfo,
        ...newUserInfo
      });
      setCountryFilter('');
    } catch (e) {
      console.log('[onSaveUserInfoButtonClick]', e);
      toast.error('Unable to update user info. Try again');
    }
  }

  const onSaveEnterAddressButtonClick = async () => {
    const validateAddressFields = ({
      address_line1,
      address_type,
      city_town_suburb,
      country,
      post_code,
      state_province,
    }) => {
      if (!address_line1 || address_line1 === 'N/A') {
        toast.error('Address Line 1 is required');
        return false;
      }

      if (!city_town_suburb || city_town_suburb === 'N/A') {
        toast.error('City/Town/Suburb is required');
        return false;
      }

      if (!state_province || state_province === 'N/A') {
        toast.error('State/Province is required');
        return false;
      }

      if (!post_code || post_code === 'N/A') {
        toast.error('Post Code is required');
        return false;
      }

      if (!country || country === 'N/A') {
        toast.error('Country is required');
        return false;
      }

      if (!address_type || address_type === 'N/A') {
        toast.error('Address Type is required');
        return false;
      }

      if (!userInfo.user_name) {
        toast.error('Unable to check username');
        return false;
      }

      return true;
    };

    const isAddressValid = validateAddressFields(newAddress);

    if (!isAddressValid) return;

    const addressPayload = {
      user_name: userInfo.user_name,
      ...newAddress,
    }

    try {
      const response = await axios.post(
        `${config.apiUrl}/admin/upsert-customer-address`,
        addressPayload,
        {
          headers: {
            admintoken: getLocalStorageItem('adminToken'),
            othertoken: getLocalStorageItem('otherToken')
          }
        }
      );

      const { success, msg } = response.data;

      if(!success) {
        toast.error(msg || 'Unable to save address');
        return;
      }


      toast.success(msg || 'Updated customer address successfully');
      isFunction(actionLogger) &&
      await actionLogger(
        panelID,
        `Updated address info of username ${username} from ${JSON.stringify(customerAddress)} to ${JSON.stringify(addressPayload)}`,
        username
      );
      setNewAddress({});
      setCustomerAddress({
        ...customerAddress,
        ...newAddress
      });
      setCountryFilter('');
    } catch (e) {
      console.log('[onSaveEnterAddressButtonClick]', e);
      toast.error('Unable to save address. Try again');
    }
  }

  const renderEditAddressCell = params => {
    return <EditAddressEditCell {...params} />
  }

  const EditAddressEditCell = ({ id, value, api, field }) => {

    // TODO: This will be used to add filter text input. AND same will be implemented for country dropdown
    const [options, setOptions] = useState(['Resident', 'Work']);

    const onChangeHandler = option => {
      api.setEditCellValue({id, field, value: option.value }, option);
    }

    return (
        <React.Fragment>
          {/*TODO: Here a input box will be rendered which will filter values of dropdown.*/}
          {/*TODO: same will be implemented for country dropdown*/}
          <Dropdown
              className='address-type-dropdown'
              options={options}
              onChange={onChangeHandler}
              value={value}
              placeholder="Select address type..."
          />
        </React.Fragment>
    );
  };

  const renderEditCountryCell = params => <UpdateCountryEditCell {...params} />

  const UpdateCountryEditCell = ({ id, value, api, field }) => {

    const onChangeHandler = event => {
      api.setEditCellValue({id, field, value: event.target.value }, event);
    }

    return (
        <React.Fragment>
          <input
              autoFocus={countryFilterAutoFocus}
              className='search-input'
              type='text'
              placeholder='Please enter country name...'
              value={countryFilter}
              onChange={e => {
                const value = e.target.value;
                setCountryFilter(value);
                setFilteredCountries(countries.filter(country => country.toLowerCase().includes(value.toLowerCase())));
                setCountryFilterAutoFocus(true);
              }}
              onBlur={() => setCountryFilterAutoFocus(false)}
          />
          <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              open={showDropdown}
              onClose={() => setShowDropdown(false)}
              onOpen={() => setShowDropdown(true)}
              value={value}
              onChange={onChangeHandler}
          >
            {
              filteredCountries.map(country => <MenuItem value={country}>{country}</MenuItem>)
            }
          </Select>
        </React.Fragment>
    );
  };

  return (
    <StyledKYCWrapper>
      <div className='section-container'>
        <h2>KYC Progress</h2>
        <div className='miner-fees-form'>
          <input
            type='text'
            placeholder='Please enter user name...'
            value={username}
            onChange={e => setUsername(e.target.value)}
          />
          <Button
            className='kyc-refresh-button'
            variant="contained"
            color="secondary"
            onClick={() => {
              resetState();
              setUsername('');
            }}
            disabled={!username}
          >
            Refresh
          </Button>
          <Button
            className='kyc-progress-button'
            variant="contained"
            color="primary"
            onClick={getKycProgressOnClickHandler}
            disabled={!username}
          >
            Get KYC Progress
          </Button>
        </div>
        <div className='kyc-docs-container'>

          <div className='customer-address-section'>
            {
              userInfo && (
                <StyledCustomerInfoWrapper>
                  <h3>User Details</h3>
                  {/*<pre>{JSON.stringify(userInfo, null, 4)}</pre>*/}
                  <DataGrid
                    className='user-basic-info-data-grid'
                    editMode="row"
                    rows={[userInfo]}
                    columns={customerBasicInfoTableColumnsConfig(renderEditCountryCell)}
                    onEditRowsModelChange={handleEditRowUserInfo}
                    hideFooter
                    autoHeight
                  />
                  {
                    shouldShowSaveUserInfoButton() && (
                      <Button
                        className='save-user-info-button'
                        variant='contained'
                        color='primary'
                        onClick={() => onSaveUserInfoButtonClick()}
                      >
                        Save User Info
                      </Button>
                    )
                  }
                  <hr />
                </StyledCustomerInfoWrapper>
              )
            }
            {
              customerAddress && (
                <StyledCustomerInfoWrapper>
                  <h3>Address Details</h3>
                  {/*<pre>{JSON.stringify(customerAddress, null, 4)}</pre>*/}
                  <DataGrid
                    className='customer-address-data-grid'
                    editMode="row"
                    rows={[customerAddress]}
                    columns={
                      customerAddressTableColumnsConfig(renderEditAddressCell, renderEditCountryCell)
                    }
                    onEditRowsModelChange={handleEditRowAddress}
                    hideFooter
                    autoHeight
                  />
                  {
                    shouldShowSaveAddressButton() && (
                      <Button
                        className='save-customer-address-button'
                        variant='contained'
                        color='primary'
                        onClick={() => onSaveEnterAddressButtonClick()}
                      >
                        {getSaveEnterAddressButtonText()}
                      </Button>
                    )
                  }
                  <hr />
                </StyledCustomerInfoWrapper>
              )
            }
          </div>

          <div className='kyc-status-section'>
            {
              customerAddress && (
                <>
                  <h3>{`KYC Status: ${kycStatus ?'Complete' : 'Incomplete'}`}</h3>
                  <hr />
                </>
              )
            }
          </div>

          <div className='kyc-valid-docs-section'>
            {
              customerAddress && (
                <StyledDocumentsSectionWrapper>
                  <h3>Valid Documents</h3>
                  <SimpleReactLightbox>
                    <SRLWrapper>
                      <div className='kyc-docs-section'>
                        {
                          // Passport doc
                          <div className='photo-card'>
                            <h4>Passport</h4>
                            <img
                              className='kyc-img'
                              width='200px'
                              height='200px'
                              src={passportDoc ? URL.createObjectURL(passportDoc) : '/images/no-user-img.jpeg'}
                              alt='passport-doc'
                            />
                            <Button
                              className="show-path-button"
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                setFilePathModal(true);
                                setCurrentFileToShowPath('passport');
                              }}
                              disabled={!passportDoc}
                            >
                              Show File Path
                            </Button>
                            <FormControl component="fieldset">
                              <RadioGroup
                                aria-label="passport-photo"
                                name="passport-photo"
                                value={passportOptionSelected}
                                onChange={event => setPassportOptionSelected(event.target.value)}>
                                <FormControlLabel disabled={ passportAlreadyValid } value="check" control={<Radio />} label="Check" />
                                <FormControlLabel disabled={ passportAlreadyValid } value="valid" control={<Radio />} label="Valid" />
                                <FormControlLabel disabled={ passportAlreadyValid } value="invalid" control={<Radio />} label="Invalid" />
                              </RadioGroup>
                            </FormControl>
                            <input
                              id="icon-button-file"
                              type="file"
                              // disabled={passportOptionSelected === 'valid'}
                              onClick={inputOnClickHandler}
                              onChange={passportFilPickerHandler}
                            />
                          </div>
                        }
                        {
                          passportLoading && <CircularProgress />
                        }
                        {
                          // Driving License
                          <div className='photo-card'>
                            <h4>Driving License</h4>
                            <img
                              className='kyc-img'
                              width='200px'
                              height='200px'
                              src={drivingLicense ? URL.createObjectURL(drivingLicense) : '/images/no-user-img.jpeg'}
                              alt='driving-license-doc' />
                            <Button
                              className="show-path-button"
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                setFilePathModal(true);
                                setCurrentFileToShowPath('driver_license');
                              }}
                              disabled={!drivingLicense}
                            >
                              Show File Path
                            </Button>
                            <FormControl component="fieldset">
                              <RadioGroup
                                aria-label="license-photo"
                                name="license-photo"
                                value={licenseOptionSelected}
                                onChange={event => setLicenseOptionSelected(event.target.value)}>
                                <FormControlLabel disabled={ drivingLicenseAlreadyValid } value="check" control={<Radio />} label="Check" />
                                <FormControlLabel disabled={ drivingLicenseAlreadyValid } value="valid" control={<Radio />} label="Valid" />
                                <FormControlLabel disabled={ drivingLicenseAlreadyValid } value="invalid" control={<Radio />} label="Invalid" />
                              </RadioGroup>
                            </FormControl>
                            <input
                              id="icon-button-file"
                              type="file"
                              // disabled={licenseOptionSelected === 'valid'}
                              onClick={inputOnClickHandler}
                              onChange={drivingLicenseFilPickerHandler}
                            />
                          </div>
                        }
                        {
                          drivingLicenseLoading && <CircularProgress />
                        }
                        {
                          // Photo Id
                          <div className='photo-card'>
                            <h4>Photo ID</h4>
                            <img
                              className='kyc-img'
                              width='200px'
                              height='200px'
                              src={photoId ?  URL.createObjectURL(photoId) : 'images/no-user-img.jpeg'}
                              alt='photo-id-doc' />
                            <Button
                              className="show-path-button"
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                setFilePathModal(true);
                                setCurrentFileToShowPath('photo_id');
                              }}
                              disabled={!photoId}
                            >
                              Show File Path
                            </Button>
                            <FormControl component="fieldset">
                              <RadioGroup
                                aria-label="photo-id-photo"
                                name="photo-id-photo"
                                value={photoIdOptionSelected}
                                onChange={event => setPhotoIdOptionSelected(event.target.value)}>
                                <FormControlLabel disabled={ photoIdAlreadyValid } value="check" control={<Radio />} label="Check" />
                                <FormControlLabel disabled={ photoIdAlreadyValid } value="valid" control={<Radio />} label="Valid" />
                                <FormControlLabel disabled={ photoIdAlreadyValid } value="invalid" control={<Radio />} label="Invalid" />
                              </RadioGroup>
                            </FormControl>
                            <input
                              id="icon-button-file"
                              type="file"
                              // disabled={photoIdOptionSelected === 'valid'}
                              onClick={inputOnClickHandler}
                              onChange={photoIdFilPickerHandler}
                            />
                          </div>
                        }
                        {
                          photoIdLoading && <CircularProgress />
                        }
                        {
                          // Selfie
                          <div className='photo-card'>
                            <h4>Selfie</h4>
                            <img
                              className='kyc-img'
                              width='200px'
                              height='200px'
                              src={selfie ? URL.createObjectURL(selfie) : 'images/no-user-img.jpeg'}
                              alt='selfie-doc'
                            />
                            <Button
                              className="show-path-button"
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                setFilePathModal(true);
                                setCurrentFileToShowPath('selfie');
                              }}
                              disabled={!selfie}
                            >
                              Show File Path
                            </Button>
                            <FormControl component="fieldset">
                              <RadioGroup
                                aria-label="selfie-photo"
                                name="selfie-photo"
                                value={selfieOptionSelected}
                                onChange={event => setSelfieOptionSelected(event.target.value)}>
                                <FormControlLabel disabled={ selfieAlreadyValid } value="check" control={<Radio />} label="Check" />
                                <FormControlLabel disabled={ selfieAlreadyValid } value="valid" control={<Radio />} label="Valid" />
                                <FormControlLabel disabled={ selfieAlreadyValid } value="invalid" control={<Radio />} label="Invalid" />
                              </RadioGroup>
                            </FormControl>
                            <input
                              id="icon-button-file"
                              type="file"
                              // disabled={selfieOptionSelected === 'valid'}
                              onClick={inputOnClickHandler}
                              onChange={selfieFilPickerHandler}
                            />
                          </div>
                        }
                        {
                          selfieLoading && <CircularProgress />
                        }
                      </div>
                    </SRLWrapper>
                  </SimpleReactLightbox>
                  <hr />
                </StyledDocumentsSectionWrapper>
              )
            }
          </div>

          <div className='kyc-invalid-docs-section'>
            {
              customerAddress && (
                <StyledDocumentsSectionWrapper>
                  <h3>Invalid Documents</h3>
                  <SimpleReactLightbox>
                    <SRLWrapper>
                      <div className='kyc-docs-section'>
                        {
                          // Invalid passport
                          <div className='photo-card'>
                            <h4>Passport</h4>
                            <img
                              className='kyc-img'
                              width='200px'
                              height='200px'
                              src={invalidPassport ? URL.createObjectURL(invalidPassport) : '/images/no-user-img.jpeg'}
                              alt='invalid-passport-doc'
                            />
                            <Button
                              className="show-path-button"
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                setFilePathModal(true);
                                setCurrentFileToShowPath('invalid_passport');
                              }}
                              disabled={!invalidPassport}
                            >
                              Show File Path
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              disabled={!!passportDoc || kycStatus}
                              onClick={() => {
                                setPassportModal(true);
                                // setPassportDoc(invalidPassport);
                                // setPassportOptionSelected('check');
                                // toast.success('Passport marked as valid');
                              }}
                            >
                              Verify
                            </Button>
                          </div>
                        }
                        {
                          <Modal
                            disableScrollLock
                            disablePortal
                            style={{overflow: 'scroll'}}
                            open={showPassportModal}
                            onClose={() => {
                              setOcrText('');
                              setPassportModal(false);
                            }}
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                          >
                            {getPassportVerifyModalBody()}
                          </Modal>
                        }
                        {
                          invalidPassportLoading && <CircularProgress />
                        }
                        {
                          // Invalid driving license
                          <div className='photo-card'>
                            <h4>Driving License</h4>
                            <img
                              className='kyc-img'
                              width='200px'
                              height='200px'
                              src={invalidDrivingLicense ? URL.createObjectURL(invalidDrivingLicense) : '/images/no-user-img.jpeg'}
                              alt='invalid-driving-license-doc' />
                            <Button
                              className="show-path-button"
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                setFilePathModal(true);
                                setCurrentFileToShowPath('invalid_driver_license');
                              }}
                              disabled={!invalidDrivingLicense}
                            >
                              Show File Path
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              disabled={!!drivingLicense || kycStatus}
                              onClick={() => {
                                setLicenseModal(true);
                                // setDrivingLicense(invalidDrivingLicense);
                                // setLicenseOptionSelected('check');
                                // toast.success('Driving License marked as valid');
                              }}
                            >
                              Verify
                            </Button>
                          </div>
                        }
                        {
                          invalidDrivingLicenseLoading && <CircularProgress />
                        }
                        {
                          // Invalid photo id
                          <div className='photo-card'>
                            <h4>Photo ID</h4>
                            <img
                              className='kyc-img'
                              width='200px'
                              height='200px'
                              src={invalidPhotoId ? URL.createObjectURL(invalidPhotoId) : '/images/no-user-img.jpeg'}
                              alt='invalid-photo-id-doc' />
                            <Button
                              className="show-path-button"
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                setFilePathModal(true);
                                setCurrentFileToShowPath('invalid_photo_id');
                              }}
                              disabled={!invalidPhotoId}
                            >
                              Show File Path
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              disabled={!!photoId || kycStatus}
                              onClick={() => {
                                setPhotoIdModal(true);
                                // setPhotoId(invalidPhotoId);
                                // setPhotoIdOptionSelected('check');
                                // toast.success('Photo Id marked as valid');
                              }}
                            >
                              Verify
                            </Button>
                          </div>
                        }
                        {
                          <Modal
                            disableScrollLock
                            disablePortal
                            style={{overflow: 'scroll'}}
                            open={showPhotoIdModal}
                            onClose={() => {
                              setOcrText('');
                              setPhotoIdModal(false);
                            }}
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                          >
                            {getPhotoIdVerifyModalBody()}
                          </Modal>
                        }
                        {
                          invalidPhotoIdLoading && <CircularProgress />
                        }
                        {
                          // Invalid Selfie
                          <div className='photo-card'>
                            <h4>Selfie</h4>
                            <img
                              className='kyc-img'
                              width='200px'
                              height='200px'
                              src={invalidSelfie ? URL.createObjectURL(invalidSelfie) : '/images/no-user-img.jpeg'}
                              alt='invalid-selfie-doc' />
                            <Button
                              className="show-path-button"
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                setFilePathModal(true);
                                setCurrentFileToShowPath('invalid_selfie');
                              }}
                              disabled={!invalidSelfie}
                            >
                              Show File Path
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              disabled={!!selfie || kycStatus}
                              onClick={() => {
                                setSelfieModal(true);
                                // setSelfie(invalidSelfie);
                                // setSelfieOptionSelected('check');
                                // toast.success('Selfie marked as valid');
                              }}
                            >
                              Verify
                            </Button>
                          </div>
                        }
                        {
                          invalidSelfieLoading && <CircularProgress />
                        }
                        {
                          <Modal
                            disableScrollLock
                            disablePortal
                            style={{overflow: 'scroll'}}
                            open={showSelfieModal}
                            onClose={() => setSelfieModal(false)}
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                          >
                            {getSelfieVerifyModalBody()}
                          </Modal>
                        }
                      </div>
                    </SRLWrapper>
                  </SimpleReactLightbox>
                  <hr />
                </StyledDocumentsSectionWrapper>
              )
            }
          </div>

          {
            showKYCCompleteButton && (
              <Button
                variant="contained"
                color="primary"
                className='complete-kyc-button'
                onClick={manuallyUpdateKYCOnClickHandler}
              >
                Save KYC Changes
              </Button>
            )
          }
        </div>
        {/*Modals*/}
        {
          <Modal
            disableScrollLock
            disablePortal
            style={{overflow: 'scroll'}}
            open={showLicenseModal}
            onClose={() => {
              setOcrText('');
              setLicenseModal(false);
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {getLicenseVerifyModalBody()}
          </Modal>
        }

        {/*Notif Modal*/}
        {
          <Modal
            disableScrollLock
            disablePortal
            style={{overflow: 'scroll'}}
            open={showNotifModal}
            onClose={() => setNotifModal(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {getNotifyModalBody()}
          </Modal>
        }

        {/*Loading Modal*/}
        {
          <Modal
            disablePortal
            open={loading}
            onClose={() => {}}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {getLoadingModalBody()}
          </Modal>
        }

        {/*Show file path Modal*/}
        {
          <Modal
            disablePortal
            open={showFilePathModal}
            onClose={() => {}}
            aria-labelledby="show-file-path-modal"
            aria-describedby="This modal shows file path of current file in s3 bucket"
          >
            {getShowFilePathModalBody()}
          </Modal>
        }
      </div>
    </StyledKYCWrapper>
  );
};

export default KYCProgress;
/* eslint-disable */
