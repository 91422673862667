import React, {useEffect, useState} from 'react';
import { StyledKycLedgerXBulkWrapper } from "./styles";
import {Button, Modal, TextField} from "@material-ui/core";
import config from "../../../config";
import {getLocalStorageItem} from "../../../utils/localStorage";
import {toast} from "react-toastify";
import axios from "axios";
import {isFunction} from "../../../utils";
import {StyledModalWrapper} from "../styles";
import {uuid} from "uuidv4";

const KycLedgerXBulk = ({ actionLogger, panelID }) => {
    const [emailsList, setEmailsList] = useState('');
    const [totalUsers, setTotalUsers] = useState(0);
    const [sentUsers, setSentUsers] = useState(0);
    const [missedUsersList, setMissedUsersList] = useState([]);
    const [kycNotStartedList, setKycNotStartedList] = useState([]);
    const [kycZeroList, setKycZeroList] = useState([]);
    const [falseSuccessLedgerXList, setFalseSuccessLedgerXList] = useState([]);
    const [apiFailList, setApiFailList] = useState([]);
    const [usernamesToEmailMapping, setUsernamesToEmailMapping] = useState({});
    const [usernames, setUsernames] = useState([]);
    const [showStats, setShowStatus] = useState(false);
    const [isSending, setSending] = useState(false);
    const [sessionId, setSessionId] = useState('');

    useEffect(() => {
        const getUserAndSendKycToLedgerX = async () => {
            console.log('FINAL SESSION ID', sessionId);
            // We have usernames now, pick one at a time and send its status to ledger x if kyc is 1, repeat.
            console.log('Final users which will be picked one by one for ledger x process', usernames);
            const numberOfUsernames = usernames.length;
            console.log('Number of usernames picked for ledger x process', numberOfUsernames);
            const token = getLocalStorageItem('adminToken');
            const unsuccessfulUsersList = [];
            const unsuccessKycNotStartedList = [];
            const unsuccessKycZeroList = [];
            const unsuccessApiFailList = [];
            const unsuccessFalseSuccessLedgerXList = [];
            await Promise.all(usernames.map(async username => {
                try {
                    const kycStatus = await axios.post(
                        `${config.apiUrl}/admin/get-kyc-status`,
                        { user_name: username },
                        { headers: { admintoken: token }
                        }
                    );

                    const { success: kycSuccess, msg: kycMsg, data: kycData } = kycStatus.data;

                    const email = usernamesToEmailMapping[username];
                    console.log('EMAIL UNDER PROCESS', email, ' -> username', username);

                    if (!kycSuccess) {
                        toast.error(`${username}: ${kycMsg || `Unable to get KYC Status for user with username ${username}`}`);
                        // unsuccessfulUsersList.push(email);
                        unsuccessKycNotStartedList.push({ email, username });
                        await postLedgerXUpdate(username, email, 2, {}, 2, sessionId);
                        return;
                    }

                    const { kyc_status } = kycData;

                    console.log(`${username}'s kyc status -> ${kyc_status}`);

                    if (kyc_status !== 1) {
                        // unsuccessfulUsersList.push(email);
                        unsuccessKycZeroList.push({ email, username });
                        await postLedgerXUpdate(username, email, kyc_status, {}, 2, sessionId);
                        return;
                    }

                    // If you are here, it means kyc status is 1, so send this status to ledger x
                    console.log(`${username}: Here after seeing kyc status which is ${kyc_status}`);

                    const response = await axios.post(
                        `${config.kycServiceUrl}/send-kyc-status-to-ledgerx`,
                        { userName: username }
                    );

                    const {
                        Success,
                        data: { ledgerXResponse } = {}
                    } = response.data;

                    const { code = 2 } = ledgerXResponse || {};

                    await postLedgerXUpdate(username, email, kyc_status, ledgerXResponse, code, sessionId);

                    if (!Success) {
                        toast.error( `${username}: Unable to send KYC status to Ledger X`);
                        // unsuccessfulUsersList.push(email);
                        unsuccessFalseSuccessLedgerXList.push({ email, username });
                        isFunction(actionLogger) &&
                        await actionLogger(
                            panelID,
                            `Sent an unsuccessful KYC Status update request to Ledger X for user ${username}`,
                            username
                        );
                        return;
                    }

                    isFunction(actionLogger) &&
                    await actionLogger(
                        panelID,
                        `Sent a successful KYC Status update request to Ledger X for user ${username}`,
                        username
                    );
                } catch (e) {
                    const { response: { data: { message } = {} } = {} }  = e
                    toast.error(message || 'Something went wrong while getUserAndSendKycToLedgerX');
                    console.log('[getUserAndSendKycToLedgerX]', e);
                    const reqEmail = usernamesToEmailMapping[username];
                    // unsuccessfulUsersList.push(reqEmail);
                    unsuccessApiFailList.push({ email: reqEmail, username });
                    await postLedgerXUpdate(username, usernamesToEmailMapping[username], 2, {}, 2, sessionId);
                }
            }));

            console.log('Unsuccessful users after Ledger X update process started', unsuccessfulUsersList);
            console.log('Unsuccessful users after Ledger X update process started', unsuccessfulUsersList.length);

            // const finalMissedUsers = unsuccessfulUsersList.concat(missedUsersList);
            // const finalMissedUsersLength = finalMissedUsers.length;
            // console.log('Finally, these users were missed during the process', finalMissedUsers);
            // setMissedUsersList(finalMissedUsers);
            const finalMissedUsersLength =
                unsuccessKycNotStartedList.length +
                unsuccessKycZeroList.length +
                unsuccessApiFailList.length +
                unsuccessFalseSuccessLedgerXList.length +
                missedUsersList.length;

            setKycNotStartedList(unsuccessKycNotStartedList);
            setKycZeroList(unsuccessKycZeroList);
            setApiFailList(unsuccessApiFailList);
            setFalseSuccessLedgerXList(unsuccessFalseSuccessLedgerXList);

            // Now create stats here to show at the end
            console.log('TOTAL USERS', totalUsers, 'UNSUCCESSFUL USERS', finalMissedUsersLength, 'DIFF', totalUsers - finalMissedUsersLength)
            setSentUsers(totalUsers - finalMissedUsersLength);
            setShowStatus(true);
            setSending(false);
            toast.success('Sending KYC Status to Ledger X completed successfully');
        };

        if (!usernames || !Array.isArray(usernames) || !usernames.length) {
            setSending(false);
            // toast.error('Unable to send status to ledger x as usernames are not valid');
            return;
        };

        getUserAndSendKycToLedgerX();
    }, [usernames])

    const onSendKycStatusToLedgerXButtonClick = async () => {
        setSending(true);
        const session_id = uuid();
        setSessionId(session_id);
        console.log('Session Id', session_id);
        console.log('Given emails in text box', emailsList.split('\n'));
        const emails = emailsList.split('\n');
        const numberOfEmails = emails.length;
        console.log('Number of given emails', numberOfEmails);
        setTotalUsers(numberOfEmails);

        // Get usernames of users
        try {
            const response = await axios.post(
                `${config.apiUrl}/admin/users/email`,
                {
                    emails: emails
                },
                {
                    headers: {
                        adminToken: getLocalStorageItem('adminToken'),
                    }
                }
            );

            const { success, msg, users, usernameToEmailMapping } = response.data;

            if (!success) {
                setSending(false);
                toast.error(msg || 'Unable to fetch users by email');
                return;
            }

            setUsernamesToEmailMapping(usernameToEmailMapping);

            // Now here check if every email have a user name in response i.e no user is missing
            // console.log('Users returned from DB', users);
            // console.log('Number of users returned form DB', Object.keys(users).length);
            const missingUsers = emails.filter(email => !users[email]);
            const numberOfMissingUsersFromDB = missingUsers.length;
            console.log('Users whose email is not matching with given emails OR they are not even present in DB', missingUsers);
            console.log('Number of email not matching with given emails OR they are not even present in DB', numberOfMissingUsersFromDB);
            setMissedUsersList(missingUsers);

            const availableAndMatchedEmailsUsers = emails.filter(email => !!users[email]);
            const numberOfAvailableAndMatchingEmailsUsers = availableAndMatchedEmailsUsers.length;
            console.log('Users whose emails are matching with given emails', availableAndMatchedEmailsUsers);
            console.log('Number of email matching with given emails', numberOfAvailableAndMatchingEmailsUsers);
            // Available users are those whose email is matching with given emails,
            // So we will use only them and pass their usernames to ledger x process.
            const availableUsers = availableAndMatchedEmailsUsers.map(email => users[email]);


            // Post users, in ledger x updates table, that don't exist in db.
            numberOfMissingUsersFromDB && await handleMissingUsersUpdate(missingUsers, session_id);

            // Users who are present in db
            const requiredUsernames = Object.values(availableUsers);

            console.log('Usernames of available users in DB', requiredUsernames);

            // If no user is present in db, then stop process here and just show stats
            if (!requiredUsernames.length) {
                setSentUsers(numberOfEmails - numberOfMissingUsersFromDB);
                setShowStatus(true);
                setSending(false);
                return;
            }

            // Now we have required usernames so useEffect hook will be called once usernames are set in state
            setUsernames(prevState => requiredUsernames);
        } catch (e) {
            console.log('[onSendKycStatusToLedgerXButtonClick], Fetch users', e);
            toast.error('Unable to retrieve users by email');
        }
    }

    const onRefreshButtonClick = () => {
        setEmailsList('');
        setTotalUsers(0);
        setSentUsers(0);
        setMissedUsersList([]);
        setKycNotStartedList([]);
        setKycZeroList([]);
        setApiFailList([]);
        setFalseSuccessLedgerXList([]);
        setUsernamesToEmailMapping({});
        setUsernames([]);
        setShowStatus(false);
        setSending(false);
        setSessionId('');
    }

    const handleMissingUsersUpdate = async (missedUsersEmails, session_id ) => {
        try {
            await Promise.all(missedUsersEmails.map(async email => {
                await postLedgerXUpdate('', email, 2, {}, 2, session_id);
            }));
        } catch (e) {
            console.log('[handleMissingUsersUpdate]', e);
        }
    }

    const postLedgerXUpdate = async (
        user_name,
        email,
        nucoin_kyc_status,
        ledgerx_response,
        response_status,
        session_id,
    ) => {
        try {
            const payload = {
                user_name,
                email,
                nucoin_kyc_status,
                ledgerx_response,
                response_status,
                session_id
            }
            const response = await axios.post(
                `${config.apiUrl}/admin/ledger-x/update`,
                payload,
                {
                    headers: {
                        admintoken: getLocalStorageItem('adminToken'),
                    }
                }
            );

            const { success, msg } = response.data;

            if (!success) {
                toast.error(msg || 'Unable to post ledger x update');
            }
        } catch (e) {
            console.log('[postLedgerXUpdate]', e);
            toast.error(`Unable to post ledger x update for ${email}`);
        }
    }

    const getLoadingModalBody = () => {
        return (
            <StyledModalWrapper className='loading-modal-body'>
                <img src={'/images/loader.svg'} alt='Loader' />
            </StyledModalWrapper>
        )
    };

    return (
        <div className='section-container'>
            <h2>KYC - Ledger X - Bulk</h2>
            <StyledKycLedgerXBulkWrapper>
                <div className='form-1'>
                    <TextField
                        className='emails-textbox'
                        id="multiline-emails"
                        label="List of emails"
                        multiline
                        value={emailsList}
                        onChange={event => setEmailsList(event.target.value)}
                        disabled={isSending}
                    />
                    <Button
                        className='send-kyc-status-button'
                        variant='contained'
                        color='primary'
                        onClick={onSendKycStatusToLedgerXButtonClick}
                        disabled={!emailsList}
                    >
                        Send KYC Status to Ledger X
                    </Button>
                    <Button
                        className='send-kyc-status-button'
                        variant='contained'
                        color='secondary'
                        onClick={onRefreshButtonClick}
                        disabled={!emailsList}
                    >
                        Refresh
                    </Button>
                </div>
                {
                    showStats && (
                        <React.Fragment>
                            <p><b>Total Users:</b>{` ${totalUsers}`}</p>
                            <p><b>Successful Users:</b>{` ${sentUsers}`}</p>
                            <p><b>Unsuccessful Users:</b>{` ${totalUsers - sentUsers}`}</p>
                            {/*Mismatching email users*/}
                            {
                                missedUsersList &&
                                Array.isArray(missedUsersList) &&
                                missedUsersList.length ? (
                                    <React.Fragment>
                                        <b>{`Following (${missedUsersList.length}) users were missed due mismatch of emails`}<br /></b>
                                        <ol>
                                            {
                                                missedUsersList.map(user => {
                                                    return (
                                                        <li>
                                                            {user}
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ol>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment />
                                )
                            }

                            {/*KYC not started*/}
                            {
                                kycNotStartedList &&
                                Array.isArray(kycNotStartedList) &&
                                kycNotStartedList.length ? (
                                    <React.Fragment>
                                        <b>{`Following (${kycNotStartedList.length}) users were missed because they haven't started their KYC`}<br /></b>
                                        <ol>
                                            {
                                                kycNotStartedList.map(user => {
                                                    return (
                                                        <li>
                                                            {`${user.email}, ${user.username}`}
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ol>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment />
                                )
                            }

                            {/*KYC Incomplete*/}
                            {
                                kycZeroList &&
                                Array.isArray(kycZeroList) &&
                                kycZeroList.length ? (
                                    <React.Fragment>
                                        <b>{`Following (${kycZeroList.length}) users were missed because they have incomplete KYC`}<br /></b>
                                        <ol>
                                            {
                                                kycZeroList.map(user => {
                                                    return (
                                                        <li>
                                                            {`${user.email}, ${user.username}`}
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ol>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment />
                                )
                            }

                            {/*False Success from Ledger X API*/}
                            {
                                falseSuccessLedgerXList &&
                                Array.isArray(falseSuccessLedgerXList) &&
                                falseSuccessLedgerXList.length ? (
                                    <React.Fragment>
                                        <b>{`Following (${falseSuccessLedgerXList.length}) users were missed due to false success from Ledger X side`}<br /></b>
                                        <ol>
                                            {
                                                falseSuccessLedgerXList.map(user => {
                                                    return (
                                                        <li>
                                                            {`${user.email}, ${user.username}`}
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ol>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment />
                                )
                            }

                            {/*API issue */}
                            {
                                apiFailList &&
                                Array.isArray(apiFailList) &&
                                apiFailList.length ? (
                                    <React.Fragment>
                                        <b>{`Following (${apiFailList.length}) users were missed due to some error in KYC/Ledger X API`}<br /></b>
                                        <ol>
                                            {
                                                apiFailList.map(user => {
                                                    return (
                                                        <li>
                                                            {`${user.email}, ${user.username}`}
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ol>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment />
                                )
                            }
                        </React.Fragment>
                    )
                }
            </StyledKycLedgerXBulkWrapper>

            {/*Loading Modal*/}
            {
                <Modal
                    disablePortal
                    open={isSending}
                    onClose={() => {}}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {getLoadingModalBody()}
                </Modal>
            }
        </div>
    );
};

export default KycLedgerXBulk;