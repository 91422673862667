import styled from "styled-components";

export const StyledWalletTransactionWrapper = styled.div`
  
  width: 100%;
  
  .MuiFormGroup-row {
    padding: 10px;
  }

  .trustBrowserCheckbox {
    color: white;
  }
  
  span.MuiFormControlLabel-label {
    color: white;
    font-weight: bold;
  }
`;
