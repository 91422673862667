const STAGE = process.env.REACT_APP_STAGE || 'development';

/*
  Common config are already in config object
  Rest of them will be added in below switch statement based on STAGE
*/

let config = {
    KYC_CHANNEL_SOURCE_ADMIN: `NU COIN APP ADMIN PANEL - ${STAGE}`,
    mnemonicsPasswordEncryptionKey: 'MbQeThWmZq4t7w!z%C*F-JaNdRfUjXn2',
};

switch (STAGE) {
    case 'development':
        config = {
            ...config,
            apiUrl: 'https://apitest.nucoin.net.au/api',
            // apiUrl: 'http://localhost:8000/api',
            apiUrlAIIdentity: 'https://pyapitest.nucoin.net.au',
            userServicesApiUrl: 'https://dev.usiwalletapi.nucoin.net.au/v1/bc',
            // userServicesApiUrl: 'http://localhost:8003/v1/bc',
            // KYC Microservice URL
            kycServiceUrl: 'https://dev.kycapi.nucoin.net.au/api/v1/nucoin/kyc',
            environment: 'development',
            multicoinUrl: 'https://multicoinapi.nucoin.com.au/api/v1/multicoin',
        };
        break;

    case 'staging':
        config = {
            ...config,
            apiUrl: 'https://stg.api.nucoin.net.au/api',
            // apiUrl: 'http://localhost:8000/api',
            // TODO: Need to update this to staging url once it is available
            apiUrlAIIdentity: 'https://pyapitest.nucoin.net.au',
            userServicesApiUrl: 'https://stg.usiwalletapi.nucoin.net.au/v1/bc',
            // KYC Microservice URL
            kycServiceUrl: 'https://stg.kycapi.nucoin.net.au/api/v1/nucoin/kyc',
            environment: 'staging',
            multicoinUrl: 'https://multicoinapi.nucoin.com.au/api/v1/multicoin',
        };
        break;

    case 'production':
        config = {
            ...config,
            apiUrl: 'https://api.nucoin.com.au/api',
           // apiUrl: 'http://localhost:8000/api',
            apiUrlAIIdentity: 'https://pyapi.nucoin.com.au',
            userServicesApiUrl: 'https://usiwalletapi.nucoin.com.au/v1/bc',
            // KYC Microservice URL
            kycServiceUrl: 'https://kycapi.nucoin.com.au/api/v1/nucoin/kyc',
            environment: 'production',
            multicoinUrl: 'https://multicoinapi.nucoin.com.au/api/v1/multicoin',
        };
        break;
}

export default config;
