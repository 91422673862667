import React, {useState} from 'react';
import {StyledPlatformMinerUpdateWrapper} from "./styles";
import Dropdown from "react-dropdown";
import {Button, Modal} from "@material-ui/core";
import {toast} from "react-toastify";
import config from "../../../config";
import {getLocalStorageItem} from "../../../utils/localStorage";
import axios from "axios";
import {StyledModalWrapper} from "../styles";

const PlatformMinerUpdate = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [walletAddress, setWalletAddress] = useState('');
    const [category, setCategory] = useState('');
    const [usiLimit, setUsiLimit] = useState(0);
    const [minerAccountStatus, setMinerAccountStatus] = useState('');

    const shouldActionButtonsDisable = () => {
        return !username || !walletAddress || !category || (!usiLimit && usiLimit !== 0) || !minerAccountStatus
    }

    const shouldRefreshButtonsDisable = () => {
        return !username && !walletAddress && !category && !usiLimit && !minerAccountStatus
    }

    const resetState = () => {
        setUsername('');
        setWalletAddress('');
        setCategory('');
        setUsiLimit(0);
        setMinerAccountStatus('');
    }

    const onUpdateButtonClick = async () => {
        if (usiLimit < 0) {
            toast.error('USI Limit cannot be less than 0');
            return;
        }

        const payload = {
            username: username,
            account_id: walletAddress,
            usi_limit: usiLimit,
            category: category,
            miner_account_status: minerAccountStatus
        };

        try {
            setIsLoading(true);
            const response = await axios.post(
                `${config.apiUrl}/admin/platform-miner`,
                payload,
                { headers: { adminToken: getLocalStorageItem('adminToken') } }
            );

            const { success, msg } = response.data;

            if (!success) {
                toast.error(msg || 'Unable to update platform miner record');
                setIsLoading(false);
                return;
            }

            toast.success(msg || 'Updated platform miner record successfully');
        } catch (e) {
            console.log('[onUpdateButtonClick]', e.response);
            toast.error('Unable to update platform miner record');
        }

        setIsLoading(false);
    }

    const getLoadingModalBody = () => {
        return (
            <StyledModalWrapper className='loading-modal-body'>
                <img src={'/images/loader.svg'} alt='Loader' />
            </StyledModalWrapper>
        )
    };

    return (
        <div className='section-container'>
            <h2>Platform Miner Booking</h2>
            <StyledPlatformMinerUpdateWrapper>
                <div className='form-wrapper'>

                    <div className='form-fields'>
                        <div>
                            <h6>Username</h6>
                            <input
                                className='search-input'
                                type='text'
                                placeholder='Please enter username...'
                                value={username}
                                onChange={e => setUsername(e.target.value)}
                            />
                        </div>

                        <div>
                            <h6>Wallet Address</h6>
                            <input
                                className='search-input'
                                type='text'
                                placeholder='Please enter wallet address...'
                                value={walletAddress}
                                onChange={e => setWalletAddress(e.target.value)}
                            />
                        </div>

                        <div>
                            <h6>Category</h6>
                            <Dropdown
                                className='category-dropdown'
                                options={[
                                    'SILVER',
                                    'GOLD',
                                    'S-BUNDLE',
                                    'PLATINUM',
                                    'TECH',
                                    'DIAMOND',
                                ]}
                                onChange={({ value }) => setCategory(value)}
                                value={category}
                                placeholder="Select category..."
                            />
                        </div>

                        <div>
                            <h6>Miner Account Status</h6>
                            <Dropdown
                                className='mas-dropdown'
                                options={['ACTIVE', 'INACTIVE']}
                                onChange={({ value }) => setMinerAccountStatus(value)}
                                value={minerAccountStatus}
                                placeholder="Select miner account status..."
                            />
                        </div>

                        <div>
                            <h6>USI Limit</h6>
                            <input
                                className='search-input'
                                type='number'
                                min={0}
                                placeholder='Please enter USI Limit...'
                                value={usiLimit}
                                onChange={e => setUsiLimit(Number(e.target.value))}
                                onFocus={() => usiLimit ? setUsiLimit(usiLimit) : setUsiLimit('')}
                            />
                        </div>
                    </div>

                    <div className='form-action-buttons'>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onUpdateButtonClick}
                            disabled={shouldActionButtonsDisable()}
                        >
                            Update
                        </Button>

                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={resetState}
                            disabled={shouldRefreshButtonsDisable()}
                        >
                            Refresh
                        </Button>

                    </div>

                </div>
            </StyledPlatformMinerUpdateWrapper>
            {/*Loading Modal*/}
            {
                <Modal
                    disablePortal
                    open={isLoading}
                    onClose={() => {}}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {getLoadingModalBody()}
                </Modal>
            }
        </div>
    );
};

export default PlatformMinerUpdate;