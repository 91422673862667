import styled from 'styled-components'

export const StyledUserInfoWrapper = styled.div`
  .search-user-input-fields-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    div {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }

  .search-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      margin-right: 10px;
    }
  }
`

export const StyledCustomerInfoWrapper = styled.div`
  .customer-address-data-grid,
  .user-basic-info-data-grid {
    margin-top: 10px;
    width: 90vw;
  }

  .save-customer-address-button,
  .save-user-info-button {
    width: 50%;
    margin: 10px 25% auto;
  }

  .customer-address-data-grid,
  .user-basic-info-data-grid {
    .MuiDataGrid-cell {
      display: flex;
      flex-direction: column;
      
      .MuiDataGrid-editInputCell {
        height: 100%;
      }
    }

    .Dropdown-control {
      height: 52px;
    }

    .address-type-dropdown,
    .country-dropdown {
      width: 100%;
    }
  }
  
  .bulk-actions-wrapper {
    .bulk-action-form {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: end;
      
      div {
        margin-right: 10px;
      }
    }
  }
`;
