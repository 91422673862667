import React, { useEffect, useState } from 'react';
import { StyledAdminPanelWrapper, StyledModalWrapper } from "./styles";
import { getLocalStorageItem, setLocalStorageItem } from "../../utils/localStorage";
import Dropdown from "react-dropdown";
import 'react-dropdown/style.css';
import { toast } from "react-toastify";
import { getMinerFeesStatusInteger, isFunction } from "../../utils";
import moment from "moment";
import axios from "axios";
import config from "../../config";
import { Button, Modal } from "@material-ui/core";
import KYCProgress from "./kyc";
import ToggleManagement from "./toggle-management";
import ContactUsCSVDownload from "./contact-us";
import WalletInformation from "./wallet";
import CustomerInformation from "./customer-information";
import EODReward from "./eod-reward";
import UserInfo from "./user-info-copy";
import WalletTransaction from "./wallet-transaction";
import PlatformMiner from "./platfrom-miner";
import PlatformMinerBooking from "./platform-miner-booking";
import ReferralTransaction from "./referral-transaction";
import ReferralCodeGenerator from "./referral-code-generator";
import CSVDataFetch from "./csv-data-fetch";
import BulkEmail from "./bulk-email";
import BeneficiaryRewards from "./beneficiary-rewards";
import KYCLedgerX from "./kyc-ledgerx";
import KycLedgerXBulk from "./kyc-ledgerx-bulk";
import CreateWallet from "./create_wallet";
import BusinessDetail from './multicoin-business';
import PlatformMinerUpdate from "./platform-miner-update";
import AirdropWalletManagement from "./airdrop-wallet-management";
import TransferWallets from "./transfer-wallets";
import ProductsDetail from "./product-details";
import PaymentCardDetails from "./payment-card-details";
import RegisterUser from "./register-user";
import { createWalletsTableConfig } from '../../utils';
import {DataGrid} from "@mui/x-data-grid";

const Admin = () => {
    const [backofficeEmployeeUsername, setBackofficeEmployeeUsername] = useState({});
    const [accessiblePanels, setAccessiblePanels] = useState([]);
    const [renderPage, setRenderPage] = useState(false);
    const [getMinerStatusUsername, setGetMinerStatusUsername] = useState('');
    const [username, setUsername] = useState('');
    const [minerFeesStatus, setMinerFeesStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [minerStatusReceipt, setMinerStatusReceipt] = useState('');
    const [pinUserName, setPinUserName] = useState('');
    const [showCardPin, setShowCardPin] = useState(false);
    const [cardPinValue, setCardPinValue] = useState('');

    const [showCardPassword, setShowCardPassword] = useState(false);
    const [cardPasswordValue, setCardPasswordValue] = useState('');

    useEffect(() => {
        const fetchBackOfficeEmployee = async () => {
            // TODO: If in future more type of tokens are added, we need to add check here for that token type as well
            const token =
                getLocalStorageItem('adminToken') ||
                getLocalStorageItem('otherToken') ||
                getLocalStorageItem('otherToken2');

            const loginUsername = getLocalStorageItem('adminPanelUsername');

            if (!token) {
                toast.error('No back office user present. Please add credentials');
                return;
            }

            if (!loginUsername) {
                toast.error('Something went wrong. Please refresh and try to login again');
                return;
            }

            try {
                const fetchUserResponse = await axios.get(
                    `${config.apiUrl}/admin/backoffice-employee/${token}/user/${loginUsername}`,
                    {
                        headers: {
                            // TODO: If in future more type of tokens are added, we need to add here that token type as well
                            adminToken: getLocalStorageItem('adminToken'),
                            otherToken: getLocalStorageItem('otherToken'),
                            otherToken1: getLocalStorageItem('otherToken2'),
                        }
                    }
                );

                const { success, msg, data } = fetchUserResponse.data;
                if (!data) {
                    toast.error('You are not authorized to use this admin panel');
                    return;
                }

                let { accessiblePanels = [], username: bo_username = '' } = data;

                if (!success || !bo_username) {
                    toast.error('You are not authorized to use this admin panel');
                    return;
                }

                setAccessiblePanels(accessiblePanels);
                setBackofficeEmployeeUsername(bo_username);
                setRenderPage(true);
            } catch (e) {
                toast.error('Session expired. Login again please')
                onLogoutBtnClick()
            }
        };

        fetchBackOfficeEmployee();
    }, []);

    const isPanelAccessibleByCurrentUser = panelId => {
        return accessiblePanels.includes(panelId);
    }

    const backofficeActionsLogger = async (panelId, msg, actionedUsername = null) => {

        if (!backofficeEmployeeUsername || !panelId || !msg) return;

        try {
            const payload = {
                backoffice_employee_username: backofficeEmployeeUsername,
                actioned_username: actionedUsername,
                panel_id: panelId,
                msg: msg
            };
            const response = await axios.post(`${config.apiUrl}/admin/log/activity`, payload);

            const { success } = response.data;

            if (!success) {
                console.log(`Unable to log backoffice action. BO Username = ${backofficeEmployeeUsername}. Msg = ${msg}`);
            }
        } catch (e) {
            console.log('[backofficeActionsLogger]', e);
        }
    }

    const getMinerFeesDropdownOptions = () => {
        return [
            'Fees not received',
            'Fees received',
            'Fees exempted'
        ]
    }

    const minerFeesDropdownOptionSelectHandler = option => {
        setMinerFeesStatus(option.label)
    }

    const getMinerFeesStatus = async username => {
        try {
            const response = await axios.get(
                `${config.apiUrl}/miner_fees_status/${getMinerStatusUsername || username}`,
                {
                    headers: {
                        admintoken: getLocalStorageItem('adminToken'),
                        othertoken: getLocalStorageItem('otherToken')
                    }
                }
            );
            isFunction(backofficeActionsLogger) &&
                await backofficeActionsLogger(
                    'GET_MINER_FEES_STATUS',
                    `Retrieved miner fees status for username ${getMinerStatusUsername || username}`,
                    getMinerStatusUsername || username
                );

            return response;
        } catch (e) {
            console.log('[getMinerFeesStatus]', e)
        }
    }

    const updateMinerFeesStatusOnClickHandler = async () => {
        toast.dismiss();

        if (!minerFeesStatus) {
            toast.error('Miner Fees Status not selected');
            return;
        }

        if (!username) {
            toast.error('User name is required');
            return;
        }

        const payload = {
            user_name: username,
            miner_fees_status: getMinerFeesStatusInteger(minerFeesStatus),
            fees_received_date: moment().format('YYYY-MM-DD')
        }

        try {
            setLoading(true);
            setMinerStatusReceipt('');
            const updateMinerFeesStatus = await axios.post(
                `${config.apiUrl}/miner_fees_received`,
                payload,
                {
                    headers: {
                        admintoken: getLocalStorageItem('adminToken'),
                        othertoken: getLocalStorageItem('otherToken')
                    }
                }
            );

            const { success, msg } = updateMinerFeesStatus.data;

            if (!success) {
                toast.error(msg);
                setLoading(false);
                return;
            }

            toast.success(msg);

            isFunction(backofficeActionsLogger) &&
                await backofficeActionsLogger(
                    'UPDATE_MINER_FEES_STATUS',
                    `Updated miner fees status for username ${username} to ${minerFeesStatus}`,
                    username
                );
            setMinerFeesStatus('Fees not received');

            try {
                const minerFeesStatus = await getMinerFeesStatus(username);

                const { success, msg } = minerFeesStatus.data;

                if (!success) {
                    toast.error(msg);
                    setLoading(false);
                    return;
                }

                delete minerFeesStatus.data.success
                delete minerFeesStatus.data.msg
                setMinerStatusReceipt(minerFeesStatus.data)
                setUsername('');
            } catch (e) {
                console.log('[getMinerFeesStatus] in update fees', e);
            }
        } catch (e) {
            toast.error('Unable to update status');
            console.log('[updateMinerFeesStatusOnClickHandler]', e);
        }

        setLoading(false);
    }

    const getMinerFeesStatusOnClickHandler = async () => {

        toast.dismiss();

        if (!getMinerStatusUsername) {
            toast.error('User name is required');
            return;
        }

        try {
            setLoading(true);
            setMinerStatusReceipt('');
            const minerFeesStatus = await getMinerFeesStatus();
            console.log('miner fees status',)

            if (!minerFeesStatus) {
                toast.error('Unable to get miner fees status');
                return;
            }

            const { success, msg } = minerFeesStatus.data;

            setGetMinerStatusUsername('');

            if (!success) {
                toast.error(msg);
                setLoading(false);
                return;
            }

            toast.success(msg);
            delete minerFeesStatus.data.success
            delete minerFeesStatus.data.msg
            setMinerStatusReceipt(minerFeesStatus.data)
        } catch (e) {
            toast.error('Unable to get status');
            console.log('[getMinerFeesStatus]', e);
        }

        setLoading(false);
    }



    const getUserPinAndPassword = async () => {

        toast.dismiss();

        if (!pinUserName) {
            toast.error('User name is required');
            return;
        }

        try {
            setLoading(true);
            const response = await axios.post(`${config.apiUrl}/admin/get-pin-and-password`, { username: pinUserName },
                {
                    headers: {
                        // TODO: If in future more type of tokens are added, we need to add here that token type as well
                        adminToken: getLocalStorageItem('adminToken'),
                        otherToken: getLocalStorageItem('otherToken'),
                        otherToken1: getLocalStorageItem('otherToken2'),
                    }
                }




            );
            console.log("response", response.data)

            const { success, msg } = response.data;

            // setGetMinerStatusUsername('');

            if (!success) {
                toast.error(msg);
                setLoading(false);
                return;
            }

            toast.success(msg);

            setShowCardPin(true)
            setCardPinValue(response.data.userCardPin)
            setShowCardPassword(true)
            setCardPasswordValue(response.data.userCardPassword)


        } catch (e) {

            setLoading(false);
            const message = e?.response?.data?.msg;
            toast.error(message || 'Unable to get user pin');
            return
        }

        setLoading(false);
    }

    const copyPin = async () => {
        const el = document.createElement('textarea');
        el.value = cardPinValue
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        toast.success("You successfully copy the pin code!")
    }
    const copyPassword = async () => {
        const el = document.createElement('textarea');
        el.value = cardPasswordValue
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        toast.success("You successfully copy the password!")
    }



    const getLoadingModalBody = () => {
        return (
            <StyledModalWrapper className='loading-modal-body'>
                <img src={'/images/loader.svg'} alt='Loader' />
            </StyledModalWrapper>
        )
    };

    const onLogoutBtnClick = event => {
        event && event.preventDefault();

        setLocalStorageItem('isAdminLoggedIn', false);
        setLocalStorageItem('adminToken', '');
        setLocalStorageItem('adminPanelUsername', '');
        window.location.reload();
    }

    if (!renderPage) {
        return <></>
    }

    return (
        <StyledAdminPanelWrapper>
            <div className='header'>
                <h1 className='admin-panel-title'>ADMIN PANEL - NU COIN</h1>

                <Button
                    className='logout-btn'
                    variant="contained"
                    color="primary"
                    onClick={onLogoutBtnClick}
                >
                    Logout
                </Button>
            </div>

            {/*Get miner fees status*/}
            {
                isPanelAccessibleByCurrentUser('GET_MINER_FEES_STATUS') && (
                    <div className='section-container'>
                        <h2>Miner Fees Status</h2>
                        <div className='miner-fees-form'>
                            <input
                                type='text'
                                placeholder='Please enter user name...'
                                value={getMinerStatusUsername}
                                onChange={e => setGetMinerStatusUsername(e.target.value)}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={getMinerFeesStatusOnClickHandler}
                            >
                                Get Status
                            </Button>
                        </div>
                        <pre>{minerStatusReceipt && JSON.stringify(minerStatusReceipt, null, 4)}</pre>
                    </div>
                )
            }

            {/*Update miner fees status*/}
            {
                isPanelAccessibleByCurrentUser('UPDATE_MINER_FEES_STATUS') && (
                    <div className='section-container'>
                        <h2>Update Miner Fees Status</h2>
                        <div className='miner-fees-form'>
                            <input
                                className='miner-fees-update-input-box'
                                type='text'
                                placeholder='Please enter user name...'
                                value={username}
                                onChange={e => setUsername(e.target.value)}
                            />
                            <Dropdown
                                className='miner-fees-status-dropdown'
                                options={getMinerFeesDropdownOptions()}
                                onChange={(option) => minerFeesDropdownOptionSelectHandler(option)}
                                value={minerFeesStatus}
                                placeholder="Miner Fees Status"
                            />

                            <Button
                                variant="contained"
                                color="primary"
                                onClick={updateMinerFeesStatusOnClickHandler}
                            >
                                Update Miner Fees Status
                            </Button>
                        </div>
                        {/*TODO: Remove this button when new small button is improved*/}
                        {/*<div className='miner-fees-update-button'>*/}
                        {/*  <Button*/}
                        {/*    variant="contained"*/}
                        {/*    color="primary"*/}
                        {/*    onClick={updateMinerFeesStatusOnClickHandler}*/}
                        {/*  >*/}
                        {/*    Update Miner Fees Status*/}
                        {/*  </Button>*/}
                        {/*</div>*/}
                    </div>
                )
            }

            {/*KYC Progress*/}
            {
                isPanelAccessibleByCurrentUser('KYC_PROGRESS') && <KYCProgress panelID={'KYC_PROGRESS'} actionLogger={backofficeActionsLogger} />
            }

            {/*Contact Us List CSV Download*/}
            {
                isPanelAccessibleByCurrentUser('CONTACT_US_CSV') && <ContactUsCSVDownload panelID={'CONTACT_US_CSV'} actionLogger={backofficeActionsLogger} />
            }

            {/*Toggle Management*/}
            {
                isPanelAccessibleByCurrentUser('TOGGLE_MANAGEMENT') && <ToggleManagement panelID={'TOGGLE_MANAGEMENT'} actionLogger={backofficeActionsLogger} />
            }

            {/*Wallet Information*/}
            {
                isPanelAccessibleByCurrentUser('WALLETS_INFORMATION') && <WalletInformation panelID={'WALLETS_INFORMATION'} actionLogger={backofficeActionsLogger} />
            }

            {/*Customer Information Panel*/}
            {
                isPanelAccessibleByCurrentUser('CUSTOMER_INFORMATION') && <CustomerInformation panelID={'CUSTOMER_INFORMATION'} actionLogger={backofficeActionsLogger} />
            }

            {/*EOD Reward Information Panel*/}
            {
                isPanelAccessibleByCurrentUser('EOD_REWARDS') && <EODReward panelID={'EOD_REWARDS'} actionLogger={backofficeActionsLogger} />
            }

            {/*User info copy*/}
            {
                isPanelAccessibleByCurrentUser('USER_INFO_COPY') && <UserInfo panelID={'USER_INFO_COPY'} actionLogger={backofficeActionsLogger} />
            }

            {/*Platform Miners*/}
            {
                isPanelAccessibleByCurrentUser('PLATFORM_MINERS') && <PlatformMiner panelID={'PLATFORM_MINERS'} actionLogger={backofficeActionsLogger} />
            }

            {/*Platform Miner Booking */}
            {
                isPanelAccessibleByCurrentUser('PLATFORM_MINER_BOOKING') && <PlatformMinerBooking panelID={'PLATFORM_MINER_BOOKING'} actionLogger={backofficeActionsLogger} />
            }

            {/*Wallet Transaction*/}
            {
                isPanelAccessibleByCurrentUser('WALLET_TRANSACTIONS') && <WalletTransaction panelID={'WALLET_TRANSACTIONS'} actionLogger={backofficeActionsLogger} />
            }

            <ReferralTransaction />

            {/*Wallet Transaction*/}
            {
                isPanelAccessibleByCurrentUser('REFERRAL_CODE_GENERATOR') && <ReferralCodeGenerator panelID={'REFERRAL_CODE_GENERATOR'} actionLogger={backofficeActionsLogger} />
            }

            {/*CSV Data Fetch Panel*/}
            {
                isPanelAccessibleByCurrentUser('CSV_DATA_FETCH') && <CSVDataFetch panelID={'CSV_DATA_FETCH'} actionLogger={backofficeActionsLogger} />
            }

            {/*Bulk Email Send Panel*/}
            {
                isPanelAccessibleByCurrentUser('BULK_EMAIL') && <BulkEmail panelID={'BULK_EMAIL'} actionLogger={backofficeActionsLogger} />
            }

            {/*Beneficiary Rewards Panel*/}
            {
                isPanelAccessibleByCurrentUser('BENEFICIARY_REWARDS') && <BeneficiaryRewards panelID={'BENEFICIARY_REWARDS'} actionLogger={backofficeActionsLogger} />
            }

            {/*KYC Ledger X Panel*/}
            {
                isPanelAccessibleByCurrentUser('KYC_LEDGER_X') && <KYCLedgerX panelID={'KYC_LEDGER_X'} actionLogger={backofficeActionsLogger} />
            }

            {/*KYC Ledger X Bulk Panel*/}
            {
                isPanelAccessibleByCurrentUser('KYC_LEDGER_X_BULK') && <KycLedgerXBulk panelID={'KYC_LEDGER_X_BULK'} actionLogger={backofficeActionsLogger} />
            }

            {/*Create Wallet Panel*/}
            {
                isPanelAccessibleByCurrentUser('CREATE_WALLET') && <CreateWallet panelID={'CREATE_WALLET'} actionLogger={backofficeActionsLogger} />
            }

            {/* Platform Miner Update */}
            {
                isPanelAccessibleByCurrentUser('PLATFORM_MINER_UPDATE') && <PlatformMinerUpdate panelID={'PLATFORM_MINER_UPDATE'} actionLogger={backofficeActionsLogger} />
            }

            {/* Airdrop wallet management */}
            {
                isPanelAccessibleByCurrentUser('AIRDROP_WALLET_MANAGEMENT') && <AirdropWalletManagement panelID={'AIRDROP_WALLET_MANAGEMENT'} actionLogger={backofficeActionsLogger} />
            }

            {/* Wallets Transfer */}
            {
                isPanelAccessibleByCurrentUser('WALLETS_TRANSFER') && <TransferWallets panelID={'WALLETS_TRANSFER'} actionLogger={backofficeActionsLogger} />
            }

            {/*Products Details*/}
            {
                isPanelAccessibleByCurrentUser('PRODUCTS_DETAILS') && <ProductsDetail panelID={'PRODUCTS_DETAILS'} actionLogger={backofficeActionsLogger} />
            }

            {/*Payment Cards Details*/}
            {
                isPanelAccessibleByCurrentUser('PAYMENT_CARDS_DETAILS') && <PaymentCardDetails panelID={'PAYMENT_CARDS_DETAILS'} actionLogger={backofficeActionsLogger} />
            }

            {/*Register User*/}
            {
                isPanelAccessibleByCurrentUser('REGISTER_USER') && <RegisterUser panelID={'REGISTER_USER'} actionLogger={backofficeActionsLogger} />
            }

           


{
                isPanelAccessibleByCurrentUser('USER_CARD_CREDENTIAL') && (
                    <div className='section-container'>
                        <h2>Get Pin And Password</h2>
                        <div className='miner-fees-form'>
                            <input
                                type='text'
                                placeholder='Please enter user name...'
                                value={pinUserName}
                                onChange={e => setPinUserName(e.target.value)}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={getUserPinAndPassword}
                            >
                                Get Account Detail
                            </Button>

                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    setPinUserName("")
                                    setShowCardPin(false)
                                    setShowCardPassword(false)
                                    setCardPinValue("")
                                    setCardPasswordValue("")
                                }}
                            >
                                Clear Detail
                            </Button>
                        </div>
                        {
                            showCardPin === true ? <div style={{ marginTop: 20, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row", }}>
                                <div style={{ display: "flex", height: 50, justifyContent: "center" }} className="alert alert-success" role="alert">
                                    <p>user pin code: <span style={{ fontWeight: "bold" }}>{cardPinValue}</span></p>
                                    <i onClick={copyPin} style={{ marginLeft: 20, marginTop: 4, cursor: "pointer" }} class="fas fa-copy"></i>
                                </div>

                            </div> : ""
                        }

                        {
                            showCardPassword === true ? <div style={{ marginTop: 20, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row", }}>
                                <div style={{ display: "flex", height: 50, justifyContent: "center" }} className="alert alert-success" role="alert">
                                    <p>user password: <span style={{ fontWeight: "bold" }}>{cardPasswordValue}</span></p>
                                    <i onClick={copyPassword} style={{ marginLeft: 20, marginTop: 4, cursor: "pointer" }} class="fas fa-copy"></i>
                                </div>

                            </div> : ""
                        }



                    </div>
                )
            }


{
                isPanelAccessibleByCurrentUser('USER_CARD_CREDENTIAL') && <BusinessDetail panelID={'USER_CARD_CREDENTIAL'} actionLogger={backofficeActionsLogger} />
            }


            {/*Loading Modal*/}
            {
                <Modal
                    disablePortal
                    open={loading}
                    onClose={() => { }}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {getLoadingModalBody()}
                </Modal>
            }
        </StyledAdminPanelWrapper>
    );
};

export default Admin;
