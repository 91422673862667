// @ts-nocheck
import React, { useState } from 'react';
import { toast } from "react-toastify";
import axios from "axios";
import config from "../../../config";
import {Button, Checkbox, FormControlLabel, FormGroup} from "@material-ui/core";
import {encrypt} from "../../../utils";
import {getLocalStorageItem, setLocalStorageItem} from "../../../utils/localStorage";
import moment from "moment";
import {StyledTrustPasswordWrapper} from "./styles";
const CryptoJS = require("crypto-js");
const { Keyring } = require('@polkadot/api');
declare var $: any;

const ReferralTransaction = ({ actionLogger }) => {

    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');

    const [toBalance, setToBalance] = useState('');
    const [toBalanceBackend, setToBalanceBackend] = useState("");
    const [fromBalance, setFromBalance] = useState('');
    const [fromBalanceBackend, setFromBalanceBackend] = useState('');
    const [tobackend, setTobackend] = useState('');
    const [updatedFromBalance, setupdatedFromBalance] = useState('');
    const [updatedToBalance, setUpdatedToBalance] = useState('');
    const [amount, setAmount] = useState('');
    const [password, setPassword] = useState('');
    const [mnemonics, setMnemonics] = useState('');
    const [message, setMessage] = useState('');
    const [transactionBlockHash, setTransactionBlockHash] = useState('');
    const [existLocal, setExistLocal] = useState(false);
    const [passwordBtnDisable, setPasswordBtnDisable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadings, setLoadings] = useState(false);
    const [walletEmail, setWalletEmail] = useState(false);

    // wallaet info
    const [userName, setUserName] = useState("");
    const [accountName, setAccountName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");

    const [yesBtnDisable, setYesBtnDisable] = useState(false);
    const [noBtnDisable, setNoBtnDisable] = useState(false);
    const [emailMessage1, setEmailMessage1] = useState([]);
    const [emailProceedEnable, setEmailProceedEnable] = useState(false);
    const [emailPurpose, setEmailPurpose] = useState("");
    const [tranferBtnDisable, setTranferBtnDisable] = useState(false);

    const [userNameBackend, setUserNameBackend] = useState("");
    const [accountNameBackend, setAccountNameBackend] = useState("");
    const [firstNameBackend, setFirstNameBackend] = useState("");
    const [lastNameBackend, setLastNameBackend] = useState("");
    const [mobileNumberBackend, setMobileNumberBackend] = useState("");
    const [toWalletEmail, setToWalletEmail] = useState("");
    const [fieldDisable, setFieldDisable] = useState(false);
    // reset password

    const [resetPassword, setResetPassword] = useState('');
    const [confirmResetPassword, setConfirmResetPassword] = useState('');
    const [resetMnemonics, setResetMnemonics] = useState('');
    const [resetPasswordBtnDisable, setResetPasswordBtnDisable] = useState(false);
    const [passwordType, setPasswordType] = useState("password");
    const [eyeIcon, setEyeIcon] = useState("fas fa-eye-slash");
    const [resetPasswordType, setResetPasswordType] = useState("password");
    const [resetPasswordEyeIcon, setResetPasswordEyeIcon] = useState("fas fa-eye-slash");
    const [toAddress, setToAddress] = useState("");
    const [referralUser, setReferralUser] = useState("");
    const [isError, setIsError] = useState(false);
    const [fieldDisableFrom, setFieldDisableFrom] = useState(false);
    const [fieldDisableReferral, setFieldDisableReferral] = useState(false);
    const [fieldDisableAmount, setFieldDisableAmount] = useState(true);

    // Trust password work
    const [trustBrowser, setTrustBrowser] = useState(false);
    const [passwordTrustTime, setPasswordTrustTime] = useState(15);

    // const postTransferActivity = async () => {
    //     isFunction(actionLogger) && await actionLogger(
    //         panelID,
    //         `Transferred ${amount} ${Number(amount) > 0 ? 'coins' : 'coin'} from ${from} to ${to}. Pre transfer from balance: ${fromBalanceBackend}, to balance: ${tobackend}. Post transfer from balance: ${updatedFromBalance}, to balance: ${updatedToBalance}.`
    //     );
    // }

    // useEffect(() => {
    //     if (updatedToBalance && updatedFromBalance) {
    //         postTransferActivity();
    //     }
    // }, [updatedFromBalance, updatedToBalance])

    // get from balance for frontend
    const getFromBalance = async (from: any) => {
        try {

            let res = await axios.get(`${config.userServicesApiUrl}/wallet/balance-v2/${from}`)
            setFromBalance(`Current Balance : ${res.data.balance}`)
            setFromBalanceBackend(res.data.balance)

        }
        catch (e) {
            toast.error('Invalid address')
        }
    }

    const getToBalance = async (to: any) => {
        try {

            if (to) {
                let res1 = await axios.get(`${config.apiUrl}/get_referral_wallet?referral_user=${to}`)
                if (res1?.data?.data) {
                    setIsError(false)
                    setFieldDisableAmount(false)
                    setToAddress(res1?.data?.data?.reference_user_wallet)
                    let res = await axios.get(`${config.userServicesApiUrl}/wallet/balance-v2/${res1?.data?.data?.reference_user_wallet}`)
                    //alert(res.data.balance)
                    setToBalance(`Current Balance :  ${res.data.balance}`)
                    setWalletEmail(res.data.email)
                    setUserName("Reference Username : " + " " + res1?.data?.data?.reference_user)
                    setFirstName(" Wallet Address : " + " " + res1?.data?.data?.reference_user_wallet)
                    setLastName(" Reference Code : " + " " + res1?.data?.data?.reference_code)
                    setTobackend(res.data.balance)
                    setUserNameBackend(res.data.userName)
                    setAccountNameBackend(res.data.accountName)
                    setFirstNameBackend(res.data.firstName)
                    setLastNameBackend(res.data.lastName)
                    setMobileNumberBackend(res.data.mobileNumber)
                    setToBalanceBackend(res.data.balance)
                    setReferralUser(res1?.data?.data?.referral_user)

                }
                else {
                    setFieldDisableAmount(true)
                    setToAddress("")
                    setToBalance("")
                    setWalletEmail("")
                    setUserName("")
                    setFirstName("")
                    setLastName("")
                    setTobackend("")
                    setUserNameBackend("")
                    setAccountNameBackend("")
                    setFirstNameBackend("")
                    setLastNameBackend("")
                    setMobileNumberBackend("")
                    setToBalanceBackend("")
                    setReferralUser("")
                    setIsError(true)
                    // toast.error('The reward against this username is not valid.')

                }

            }
            else {
                setFieldDisableAmount(true)
                setToAddress("")
                setToBalance("")
                setWalletEmail("")
                setUserName("")
                setFirstName("")
                setLastName("")
                setTobackend("")
                setUserNameBackend("")
                setAccountNameBackend("")
                setFirstNameBackend("")
                setLastNameBackend("")
                setMobileNumberBackend("")
                setToBalanceBackend("")
                setReferralUser("")
                setIsError(true)
                //toast.error('The reward against this username is not valid.')


            }


        }
        catch (e) {
            toast.error('The reward against this username is not valid.')
        }
    }

    // get  address balances after transaction
    const getUpdatedFromBalance = async (from: any) => {
        try {
            let res = await axios.get(`${config.userServicesApiUrl}/wallet/balance-v2/${from}`)
            setFromBalance(`Current Balance :  ${res.data.balance} - Previous Balance :  ${fromBalanceBackend}`)
            setupdatedFromBalance(res.data.balance)
        }
        catch (e) {
            toast.error('Invalid address')
        }
    }
    const getUpdatedToBalance = async (to: any) => {
        try {
            let res = await axios.get(`${config.userServicesApiUrl}/wallet/balance-v2/${to}`)
            setToBalance(`Current Balance :  ${res.data.balance} - Previous Balance :  ${tobackend}`)
            setUpdatedToBalance(res.data.balance)
        }
        catch (e) {
            toast.error('Invalid address')
        }
    }

    // transfer btn on form
    const TransferCoin = async () => {
        setIsError(false)
        setLoadings(true)
        setTranferBtnDisable(true)
        let fromExist = localStorage.getItem(from)

        try {
            const response = await axios({
                method: 'get',
                url: `${config.userServicesApiUrl}/wallet/balance/` + from
            });

            setLoadings(false)
            setTranferBtnDisable(false)
            let balance = response.data.balance
            let convertBalanceToNumber = parseFloat(balance)



            if (convertBalanceToNumber <= parseFloat(amount)) {
                toast.error("Check your balance.Balance must be greater then your sending amount")
                return

            }
            // if address exist open password dialogue
            if (fromExist) {
                setExistLocal(true);
                const areMnemonicsPresentAndTrusted = checkForExistingMnemonics();
                if (!areMnemonicsPresentAndTrusted) {
                    setTrustBrowser(false);
                    $('#myModalPassword1').modal('show');
                    return;
                }

                // if value is 2 it means, storage was in old format, plain enc mncs were there instead of object
                if (areMnemonicsPresentAndTrusted === 2) return;

                // This point means mnemonics are present, password is correct and in between trusted time frame
                // Just calling the final function directly which will do the transaction.
                const reqMnemonics = JSON.parse(getLocalStorageItem(from));
                const reqPwd = getLocalStorageItem(`${from}-password`);

                if (!reqMnemonics || !reqMnemonics.encryptedMnemonics || !reqPwd) {
                    $('#myModalMnemonics1').modal('show');
                    return;
                }

                const decryptedMncs = CryptoJS.enc.Utf8.stringify(CryptoJS.AES.decrypt(reqMnemonics.encryptedMnemonics, reqPwd));

                if (!decryptedMncs) {
                    $('#myModalMnemonics1').modal('show');
                    return;
                }

                setLoadings(true);
                await saveWalletAndSend(decryptedMncs, reqPwd);
                setLoadings(false);
            }
            // //open mnemonuics dialogue
            else {
                $('#myModalMnemonics1').modal('show');
            }
        }
        catch (e) {
            setLoadings(false)
            setTranferBtnDisable(false)
            toast.error("We are facing some issue.check wether your from address is correct")
        }
    }
    // show mnemonics modal
    const saveMnemonics = async () => {


        const keyring = new Keyring({
            type: 'sr25519',
            ss58Format: 42,
        });

        const newPair = keyring.addFromUri(mnemonics);

        if (mnemonics === null || mnemonics === "" || mnemonics === undefined) {
            toast.error("Please provide mnemonics")
        }
        else if (newPair.address !== from) {
            toast.error("Your mnemonics not matched with your from address")
        }
        else {
            setTrustBrowser(false);
            $('#myModalPassword1').modal('show');
        }
    }

    const openPasswordReset = async () => {
        setEyeIcon("fas fa-eye-slash")
        setPasswordType("password")
        setResetPasswordType("password")
        setResetPasswordEyeIcon("fas fa-eye-slash")
        $('#myModalResetPassword1').modal('show')
    }

    const handlePasswordModalContinue = async event => {
        event.preventDefault();

        console.log('Inside handlePasswordModalContinue');

        if (trustBrowser && passwordTrustTime < 1) {
            toast.error('Password trust time must be 1 minute or more when trusted');
            return;
        }

        let currentMnemonics = mnemonics;

        console.log('currentMnemonics', currentMnemonics);

        if (!currentMnemonics) {
            console.log('currentMnemonics not present');
            let mnemonicsFromStorage = getLocalStorageItem(from);
            console.log('currentMnemonics from storage', mnemonicsFromStorage);

            try {
                mnemonicsFromStorage = JSON.parse(mnemonicsFromStorage);
            } catch (e) {
                toast.error('Unable to parse mnemonics from storage. Please check if mnemonics in storage are in object with key encryptedMnemonics or not');
                return;
            }

            const encMncs = mnemonicsFromStorage;

            const { encryptedMnemonics } = encMncs;

            try {
                console.log('before decrypt mncs in handlePasswordModalContinue');
                currentMnemonics = CryptoJS.enc.Utf8.stringify(CryptoJS.AES.decrypt(encryptedMnemonics, password));
            } catch (e) {
                console.log('inside handlePasswordModalContinue decrypt catch');
                toast.error('Please make sure your password is correct');
                return;
            }
        }

        if (!currentMnemonics) {
            toast.error('Please make sure your password is correct');
            return;
        }

        const encryptedMnemonics = CryptoJS.AES.encrypt(currentMnemonics, password).toString();
        console.log('encryptedMnemonics', encryptedMnemonics);

        const obj = { encryptedMnemonics: encryptedMnemonics }

        if (trustBrowser) {
            obj.expiry = moment().add(passwordTrustTime || 1, 'minutes');
        }
        console.log('final obj', obj);
        setLocalStorageItem(from, JSON.stringify(obj));
        setLocalStorageItem(`${from}-password`, password);

        // TODO: Check it later if hiding is required or not.
        // $('#myModalPassword1').modal('hide');
        // Here call the function which continue button on pwd modal is currently using, and replace that fun
        // there with this func
        console.log('before calling saveWalletAndSend');
        await saveWalletAndSend(currentMnemonics);
    }

    /// Send a transaction
    const saveWalletAndSend = async (requiredMnemonics, requiredPassword) => {


        if ((password === "" || password === null) && !requiredPassword) {
            toast.error("Please provide password")
        }
        else {
            let mnemonicsValue = requiredMnemonics
            setPasswordBtnDisable(true)
            setLoading(true)

            try {
                if (existLocal === true) {


                    // const encryptedData = localStorage.getItem(from)
                    // var decoded = await CryptoJS.enc.Utf8.stringify(CryptoJS.AES.decrypt(encryptedData, password));
                    // mnemonicsValue = decoded
                    const encryptedData = JSON.parse(localStorage.getItem(from))
                    console.log('encryptedData', encryptedData.encryptedMnemonics);
                    const encMncs = encryptedData.encryptedMnemonics;
                    mnemonicsValue = await CryptoJS.enc.Utf8.stringify(CryptoJS.AES.decrypt(encMncs, password || requiredPassword))

                }

                console.log('decoded mncs', mnemonicsValue);

                const encryptedData = encrypt({
                    "secret": mnemonicsValue,
                    "recipient": toAddress,
                    "amount": amount
                });


                let res = await axios.post(`${config.userServicesApiUrl}/wallet/transfer`, encryptedData);

                if (res.data.transferred.status === "ExtrinsicSuccess") {
                    console.log("transaction", res.data.transferred.blockHash)

                    setExistLocal(false)
                    setLoading(false)
                    setPasswordBtnDisable(false)
                    getUpdatedFromBalance(from)
                    getUpdatedToBalance(toAddress)
                    $("#myModalPassword1").modal("hide")
                    setMessage("Your transaction is successfull")
                    setTransactionBlockHash(res.data.transferred.blockHash)
                    $('#successs1').modal('show');
                    //alert(referralUser)
                    try {
                        axios.post(`${config.apiUrl}/updateReferral`, {
                            "username": referralUser,

                        });

                    }
                    catch (e) {

                    }


                }
                else {
                    setPasswordBtnDisable(false)
                    setLoading(false)
                    toast.error('Check your balance it should be greater then your sending amount')
                }
            }
            catch (e) {
                console.log("error s", e)
                setPasswordBtnDisable(false)
                setLoading(false)
                toast.error('Request fail due to some error.Make sure your password mnemonics,sender and receiver address is correct and you have enough balance')
            }
        }
    }

    //reset password


    const resetPasswordProcess = async () => {
        if (resetMnemonics === null || resetMnemonics === "" || resetMnemonics === undefined) {
            toast.error("Please enter the mnemonics")
        }
        else if (resetPassword === null || resetPassword === "" || resetPassword === undefined) {
            toast.error("Please enter the Password")
        }
        else if (resetPassword !== confirmResetPassword) {
            toast.error("Password and confirm password not match")
        }
        else {
            setResetPasswordBtnDisable(true)
            setLoading(true)
            try {
                setLoading(false)
                setResetPasswordBtnDisable(false)
                let mnemonnicsToString = resetMnemonics.trim().toString()
                let encryptedData = CryptoJS.AES.encrypt(mnemonnicsToString, resetPassword).toString();
                localStorage.setItem(from, encryptedData)
                toast.success('Password updated successfully')
                $("#myModalResetPassword1").modal("hide")
                $("#myModalPassword1").modal("hide")
                $("#myModalMnemonics1").modal("hide")


            }
            catch (e) {
                setPasswordBtnDisable(false)
                setLoading(false)

            }
        }
    }
    // get email message
    const openEmailMessagDialogue = async () => {
        //alert("i m hit")

        try {
            // show loader
            setLoading(true)
            setYesBtnDisable(true)
            setNoBtnDisable(true)
            let res = await axios.get(`${config.apiUrl}/get_email_messaged?usedBy=BACK_OFFICE&&category=reference_commission_email`)
            console.log("response email", res.data.data)
            setEmailMessage1(res.data.data)
            setLoading(false)
            setYesBtnDisable(false)
            setNoBtnDisable(false)
            $("#successs1").modal("hide")
            $("#selectEmailPurpose1").modal("show")
        }
        catch (e) {
            setLoading(false)
            setYesBtnDisable(false)
            setNoBtnDisable(false)
            toast.error("there is some error to get email messages")

        }
    }



    //send wallet transaction email
    const proceedSendWalletEmail = async () => {
        //alert("i am click")

        if (emailPurpose === "" || emailPurpose === undefined || emailPurpose === null || emailPurpose === "Select Email Purpose") {
            toast.error("Please select the purpose of email")
        }
        else {


            try {
                setLoading(true)
                setEmailProceedEnable(true)
                setNoBtnDisable(true)

                let response = await axios.post(`${config.apiUrl}/sendReferralEmail`, {
                    email: walletEmail,
                    userName: userNameBackend,
                    firstName: firstNameBackend,
                    lastName: lastNameBackend,
                    mobileNumber: mobileNumberBackend,
                    wallet: toAddress,
                    previousBalance: toBalanceBackend,
                    newBalance: updatedToBalance,
                    message: emailPurpose,
                    referalUser: referralUser,
                    commisionAmount: amount

                });
                setLoading(false)
                setEmailProceedEnable(false)
                setNoBtnDisable(false)
                setTranferBtnDisable(true)
                //setFieldDisable(true)
                setFieldDisableFrom(true)
                setFieldDisableReferral(true)
                setFieldDisableAmount(true)

                $('#selectEmailPurpose1').modal('hide');
                toast.success("Email send succesffully")
            }
            catch (e) {
                toast.error("There is some issue to send email")
            }
        }

    }

    const handleChange = (e) => {
        setEmailPurpose(e.target.value)

    }

    const showAndHidePassword = (e) => {
        if (passwordType === "text") {
            setPasswordType("password")
            setEyeIcon("fas fa-eye-slash")

        }
        else if (passwordType === "password") {
            setPasswordType("text")
            setEyeIcon("fas fa-eye")
        }
    }

    const showAndHideResetPassword = (e) => {
        if (resetPasswordType === "text") {
            setResetPasswordType("password")
            setResetPasswordEyeIcon("fas fa-eye-slash")

        }
        else if (resetPasswordType === "password") {
            setResetPasswordType("text")
            setResetPasswordEyeIcon("fas fa-eye")
        }
    }

    const repeatBtn = async () => {
        setTobackend(updatedToBalance)
        setFromBalanceBackend(updatedFromBalance)
        setToBalanceBackend(updatedToBalance)
        setTranferBtnDisable(false)
        setAmount("")
        //setFieldDisable(false)

        setFieldDisableAmount(false)
        setPassword("")
        setMnemonics("")
        setResetPasswordEyeIcon("fas fa-eye-slash")
        setEyeIcon("fas fa-eye-slash")
        setPasswordType("password")
        setResetPasswordType("password")
        setConfirmResetPassword("")
        setResetPassword("")
        setResetMnemonics("")
        setupdatedFromBalance("")
        setUpdatedToBalance("")
        setTrustBrowser(false);
        setPasswordTrustTime(15);
    }

    const newUserBtn = async () => {
        setTobackend(updatedToBalance)
        setFromBalanceBackend(updatedFromBalance)
        setToBalanceBackend(updatedToBalance)
        setTranferBtnDisable(false)
        setTo("")
        setAmount("")
        setToBalance("")
        setUserName("")
        setMobileNumber("")
        setFirstName("")
        setLastName("")
        setAccountName("")
        setToWalletEmail("")
        //setFieldDisable(false)

        setFieldDisableReferral(false)
        setFieldDisableAmount(true)
        setPassword("")
        setMnemonics("")
        setResetPasswordEyeIcon("fas fa-eye-slash")
        setEyeIcon("fas fa-eye-slash")
        setPasswordType("password")
        setResetPasswordType("password")
        setConfirmResetPassword("")
        setResetPassword("")
        setResetMnemonics("")
        setupdatedFromBalance("")
        setUpdatedToBalance("")


    }

    const refreshBtn = async () => {
        setTranferBtnDisable(false)
        setTo("")
        setFrom("")
        setAmount("")
        setToBalance("")
        setFromBalance("")
        setUserName("")
        setMobileNumber("")
        setFirstName("")
        setLastName("")
        setAccountName("")
        setToWalletEmail("")
        //setFieldDisable(false)
        setFieldDisableFrom(false)
        setFieldDisableReferral(false)
        setFieldDisableAmount(true)
        setPassword("")
        setMnemonics("")
        setResetPasswordEyeIcon("fas fa-eye-slash")
        setEyeIcon("fas fa-eye-slash")
        setPasswordType("password")
        setResetPasswordType("password")
        setConfirmResetPassword("")
        setResetPassword("")
        setResetMnemonics("")

        setTrustBrowser(false);
        setPasswordTrustTime(15);
    }

    const closeProcess = async () => {


        setTranferBtnDisable(true)
        //setFieldDisable(true)
        setFieldDisableFrom(true)
        setFieldDisableReferral(true)
        setFieldDisableAmount(true)
        $('#successs1').modal('hide');

    }

    const closeEmailProcess = async () => {


        setTranferBtnDisable(true)
        //setFieldDisable(true)
        setFieldDisableFrom(true)
        setFieldDisableReferral(true)
        setFieldDisableAmount(true)
        $('#selectEmailPurpose1').modal('hide');

    }

    const checkForExistingMnemonics = event => {
        event && event.preventDefault();

        const mnemonicsFromStorage = getLocalStorageItem(from);

        if (!mnemonicsFromStorage) {
            console.log(`0. Mncs not present in local storage for ${from}.`);
            return false;
        }

        let mnemonics;

        try {
            mnemonics = JSON.parse(mnemonicsFromStorage);
        } catch (e) {
            console.log('inside catch block of mncs parsing');
            console.log(`1. Mncs for ${from} is not an object in storage`, mnemonicsFromStorage);
            $('#myModalMnemonics1').modal('show');

            // Returning 2 to differentiate it from false on outer side because if mncs are present but format is diff
            // Then we need to do show mnemonics modal instead of pwd modal
            return 2;

        }

        const { expiry, encryptedMnemonics } = mnemonics;

        if (!encryptedMnemonics) {
            console.log('2. Mncs are present in LS, but object is not in correct shape. "encryptedMnemonics" missing');
            return false;
        }

        /*
           If expiry date is not passed then use these mnemonics and proceed with transfer
           logic else ask for password
        */

        const difference = expiry && moment(expiry).diff(moment(), 'minutes') > 0;

        // Ask for password and show checkbox for remember as well

        if (!difference) {
            console.log('3. Mncs are present but expired.');
            return false;

        }

        const password = getLocalStorageItem(`${from}-password`);

        let decryptedMnemonics;

        try {
            decryptedMnemonics = CryptoJS.enc.Utf8.stringify(CryptoJS.AES.decrypt(encryptedMnemonics, password));
        } catch (e) {
            toast.error('Please make sure your stored password is correct.');
            return false;
        }

        if (!decryptedMnemonics) {
            toast.error('Please make sure your stored password is correct.');
            return false;
        }

        return true;
    }

    return (
        <div style={{ background: 'white', width: '100%', padding: '32px', marginTop: '20px' }}
             className='section-container'
        >

            <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }} >


                <h2 style={{ color: 'black', alignItems: 'center' }}>Referral Reward</h2>
            </div>
            <div style={{ display: 'flex', width: '100%', flexDirection: 'row', background: 'white' }} >
                <div style={{ flex: '1', padding: '16px' }} >
                    <label style={{ color: 'black' }}>From <span style={{ fontWeight: 'bold', marginLeft: '8px' }}>{fromBalance}</span></label>
                    <input
                        className='search-input'
                        type='text'
                        //disabled={fieldDisable}
                        disabled={fieldDisableFrom}
                        placeholder='From'
                        style={{ color: 'black' }}
                        value={from}
                        onChange={e => {
                            getFromBalance(e.target.value)
                            setFrom(e.target.value)
                        }
                        }
                    />

                </div>
                <div style={{ flex: 1, padding: '16px' }} >
                    <label style={{ color: 'black' }}>Referral Username </label>
                    <input
                        className='search-input'
                        type='text'
                        // disabled={fieldDisable}
                        disabled={fieldDisableReferral}
                        placeholder='referral user'
                        value={to}
                        onChange={e => {
                            setTo(e.target.value)
                            getToBalance(e.target.value)
                        }}
                    />
                    {isError && (
                        <label style={{ color: 'red' }}>The reward against this username is not valid.</label>)}

                </div>
            </div>

            {/* second row */}

            <div style={{ display: 'flex', width: '100%', flexDirection: 'row' }} >
                <div style={{ flex: '1', padding: '16px' }} >
                    <label style={{ color: 'black' }}>Amount</label>
                    <input
                        className='search-input'
                        type='text'
                        // disabled={fieldDisable}
                        disabled={fieldDisableAmount}
                        placeholder='amount'
                        value={amount}
                        onChange={e => setAmount(e.target.value)}
                    />

                </div>
                <div style={{ flex: 1, padding: '16px', flexDirection: 'column', display: 'flex' }} >



                    <p style={{ color: 'black' }}>{userName}</p>
                    <p style={{ color: 'black', marginRight: '8px' }}>{firstName}</p>
                    <p style={{ color: 'black' }}>{lastName}</p>
                    <p style={{ color: 'black' }}>{toWalletEmail}</p>
                    <p style={{ color: 'black' }}>{mobileNumber}</p>
                    <p style={{ color: 'black' }}>{accountName}</p>



                </div>
            </div>

            <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: '16px' }} >
                {
                    from === "" || toAddress === "" || amount === "" ?
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={tranferBtnDisable}
                            style={{ alignSelf: 'center', cursor: "not-allowed" }}

                        >
                            Transfer coin
       </Button> :
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={TransferCoin}
                            disabled={tranferBtnDisable}

                        >
                            {
                                loadings === true ? <img style={{ height: '20px', width: '20px', marginRight: '8px' }} src="images/spinner3.gif"></img> : null
                            }
                            Transfer coin
                        </Button>
                }

                <Button style={{ marginLeft: '8px' }}
                    variant="contained"
                    color="primary"
                    onClick={refreshBtn}


                >
                    Refresh
                        </Button>
                <Button style={{ marginLeft: '8px' }}
                    variant="contained"
                    color="primary"
                    onClick={repeatBtn}


                >
                    Repeat
                        </Button>
                <Button style={{ marginLeft: '8px' }}
                    variant="contained"
                    color="primary"
                    onClick={newUserBtn}


                >
                    New User
                        </Button>

            </div>



            {/* mnemonics modal */}
            <div id="myModalMnemonics1" className="modal  show" role="dialog">
                <div className="modal-dialog">

                    <div className="modal-content bg-success text-white" style={{ top: '120px' }}>
                        <div className="modal-body text-center">
                            <form>
                                <h3 className="text-white mb-15">Enter the mnemonics</h3>

                                <input value={mnemonics} required="required"
                                    onChange={e => setMnemonics(e.target.value)} placeholder="Enter mnemonics" style={{ marginBottom: '10px' }} type="text" autoFocus={true} ></input>
                                <button type="button" className="btn btn-light" data-dismiss="modal">Cancel</button>
                                <button style={{ marginLeft: "8px" }} onClick={saveMnemonics} type="button" className="btn btn-light" data-dismiss="modal">Continue</button>
                            </form>
                        </div>
                    </div>

                </div>
            </div>


            {/* password modal */}
            <div id="myModalPassword1" className="modal  show" role="dialog">
                <div className="modal-dialog">

                    <div className="modal-content bg-success text-white" style={{ top: '120px' }}>
                        <div className="modal-body text-center">
                            <h3 className="text-white mb-15">Enter Password</h3>
                            <input value={password} required
                                onChange={e => setPassword(e.target.value)} placeholder="Enter password" style={{ marginBottom: '10px', width: '100%', height: '40px', paddingLeft: '8px' }} type={passwordType}></input>

                            <i onClick={showAndHidePassword} className={eyeIcon} id="togglePassword" style={{ marginLeft: "-30px", cursor: 'pointer', color: 'black' }}></i>

                            <StyledTrustPasswordWrapper>
                                <FormGroup row>
                                    <FormControlLabel
                                        style={{ color: 'white' }}
                                        control={
                                            <Checkbox
                                                checked={trustBrowser}
                                                onChange={e => setTrustBrowser(e.target.checked)}
                                                name="trustedBrowser"
                                                className='trustBrowserCheckbox'
                                                color='primary'
                                            />
                                        }
                                        label="I trust this browser to keep this password"
                                    />
                                </FormGroup>
                                {
                                    trustBrowser ? (
                                        <div className='password-remember-time-div-wrapper'>
                                            <h6>Password Remember Time (Minutes)</h6>
                                            <input
                                                className='wallet-password-time-input-box'
                                                value={passwordTrustTime}
                                                required
                                                onChange={e => setPasswordTrustTime(e.target.value)}
                                                placeholder="Enter time to remember..."
                                                type='number'
                                                disabled={!trustBrowser}
                                                min={1}
                                            />
                                        </div>
                                    ) : <></>
                                }
                            </StyledTrustPasswordWrapper>

                            <button disabled={passwordBtnDisable} type="button" className="btn btn-light" data-dismiss="modal">Cancel</button>
                            <button disabled={passwordBtnDisable} style={{ marginLeft: "8px" }} onClick={handlePasswordModalContinue} type="button" className="btn btn-light" >
                                {
                                    loading === true ? <img style={{ height: '20px', width: '20px', marginRight: '8px' }} src="images/spinner3.gif"></img> : null
                                }

                                Transfer Coin</button>

                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignContent: 'flex-end' }}>
                            <p onClick={openPasswordReset} style={{ textAlign: 'right', marginRight: '8px', textDecoration: 'underline', cursor: 'pointer', alignSelf: 'flex-end' }}>Reset Password</p>
                        </div>

                    </div>

                </div>
            </div>

            {/* //reset password modal */}

            <div id="myModalResetPassword1" className="modal  show" role="dialog">
                <div className="modal-dialog">

                    <div className="modal-content bg-success text-white" style={{ top: '120px' }}>
                        <div className="modal-body text-center">
                            <h3 className="text-white mb-15">Reset Password</h3>

                            <input value={resetMnemonics}
                                onChange={e => setResetMnemonics(e.target.value)} placeholder="Enter mnemonics" style={{ marginBottom: '10px', width: '100%', height: '40px', paddingLeft: '8px', marginLeft: '8px' }} type="text"></input>

                            <input value={resetPassword}
                                onChange={e => setResetPassword(e.target.value)} placeholder="Enter password" style={{ marginBottom: '10px', width: '100%', height: '40px', paddingLeft: '8px' }} type={passwordType}></input>
                            <i onClick={showAndHidePassword} className={eyeIcon} id="togglePassword" style={{ marginLeft: "-30px", cursor: 'pointer', color: 'black' }}></i>
                            <input value={confirmResetPassword}
                                onChange={e => setConfirmResetPassword(e.target.value)} placeholder="Enter confirm password" style={{ marginBottom: '10px', width: '100%', height: '40px', paddingLeft: '8px' }} type={resetPasswordType}></input>
                            <i onClick={showAndHideResetPassword} className={resetPasswordEyeIcon} id="togglePassword" style={{ marginLeft: "-30px", cursor: 'pointer', color: 'black' }}></i>

                            <button disabled={resetPasswordBtnDisable} type="button" className="btn btn-light" data-dismiss="modal">Cancel</button>
                            <button disabled={resetPasswordBtnDisable} style={{ marginLeft: "8px" }} onClick={resetPasswordProcess} type="button" className="btn btn-light" >
                                {
                                    loading === true ? <img style={{ height: '20px', width: '20px', marginRight: '8px' }} src="images/spinner3.gif"></img> : null
                                }

                                Reset Password</button>
                        </div>

                    </div>

                </div>
            </div>


            {/* success modal */}
            <div id="successs1" className="modal  show" role="dialog">
                <div className="modal-dialog">

                    <div className="modal-content bg-success text-white" style={{ top: '120px' }}>
                        <div className="modal-body text-center">
                            <h3 className="text-white mb-15">{message}</h3>


                            <b>Your transaction hash</b>
                            <p>
                                {`${transactionBlockHash.slice(0, transactionBlockHash.length/2)}`}
                                <br/>
                                {`${transactionBlockHash.slice(transactionBlockHash.length/2)}`}
                            </p>

                            <b style={{ marginRight: '8px' }}>Do you want to send an email to user?</b>
                            <div className='action-button-div'>
                                <button type="button" disabled={noBtnDisable} className="btn btn-secondary" onClick={closeProcess}>No</button>
                                <button type="button" style={{ marginLeft: '8px' }} disabled={yesBtnDisable} onClick={openEmailMessagDialogue} className="btn btn-primary">Yes</button>
                            </div>

                        </div>
                    </div>

                </div>
            </div>


            <div id="selectEmailPurpose1" className="modal  show" role="dialog">
                <div className="modal-dialog modal-lg modal-dialog-top">

                    <div className="modal-content bg-danger text-white" style={{ top: '120px' }}>
                        <div className="modal-body text-center">
                            <h3 className="text-white mb-15"><i className="fa fa-envelope"></i> Send Email</h3>


                            <div className="dropdown">

                                <select value={emailPurpose}
                                    onChange={handleChange}>
                                    <option value="">Select Purpose of email</option>

                                    {
                                        emailMessage1.map((item) => (

                                            <option value={item.message_text}>{item.message_text}</option>
                                        ))}


                                </select>
                                <button style={{ marginTop: '10px' }} type="button" disabled={noBtnDisable} className="btn btn-secondary" onClick={closeEmailProcess}>No</button>
                                <button disabled={emailProceedEnable} onClick={proceedSendWalletEmail} style={{ marginTop: '10px', marginLeft: '8px' }} type="button" className="btn btn-light">

                                    {
                                        loading === true ? <img style={{ height: '20px', width: '20px', marginRight: '8px' }} src="images/spinner3.gif"></img> : null
                                    }
                                Proceed</button>
                            </div>




                        </div>
                    </div>

                </div>
            </div>


            {/* /// Email Confirmation dialogue */}

            <div className="modal fade" id="emailConfirmationModal1" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>

                        </div>
                        <div className="modal-body">
                            <h4 style={{ color: 'black' }}>Do you want to send an email?</h4>
                        </div>
                        <div className="modal-footer">
                            <button type="button" disabled={noBtnDisable} className="btn btn-secondary" data-dismiss="modal">No</button>
                            <button type="button" disabled={yesBtnDisable} onClick={openEmailMessagDialogue} className="btn btn-primary">Yes</button>
                        </div>
                    </div>
                </div>
            </div>

        </div >


    );
};

export default ReferralTransaction;
