import styled from 'styled-components';

export const StyledRegisterUserWrapper = styled.div`
    width: 90vw;

  .register-user-fields-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    div {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }

  .action-button-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      margin-right: 10px;
    }
  }
`
