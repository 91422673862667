import styled from "styled-components";

export const StyledPaymentCardDetailsWrapper = styled.div`
    width: 90vw;
  
  .get-card-details-section {
    .get-form {
      .get-cards-detail-btn {
        margin-bottom: 10px;
      }
    }
  }
  
  .approve-cards-print-status-section {
    .approve-cards-detail-btn {
      margin-top: 10px;
    }
  }
`
