import styled from "styled-components";

export const StyledWalletInfoWrapper = styled.div`
  width: 100%;
  
  .form-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .search-input {
      width: 75%;
      margin-right: 10px;
    }

    button {
      margin-right: 10px;
    }
  }
`;