import React, {useCallback, useEffect, useState} from 'react';
import { StyledCreateWalletWrapper } from "./styles";
import {createWalletsTableConfig, customerBasicInfoTableColumnsConfigForUserInfoPanel} from "../../../utils";
import {DataGrid} from "@mui/x-data-grid";
import config from "../../../config";
import {getLocalStorageItem} from "../../../utils/localStorage";
import {Button, FormControl, FormControlLabel, FormLabel, Modal, Radio, RadioGroup} from "@material-ui/core";
import {toast} from "react-toastify";
import axios from "axios";
import {StyledModalWrapper} from "../styles";
const CryptoJS = require("crypto-js");

const CreateWallet = () => {
    const [walletsCreateCount, setWalletsCreateCount] = useState('');
    const [wallets, setWallets] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [shouldFetchWallets, setShouldFetchWallets] = useState(false);

    // Assign wallet states
    const [assignWalletsFromDisabled, setssignWalletsFromDisabled] = useState(true);
    const [username, setUsername] = useState('');
    const [mintableWallet, setMintableWallet] = useState("0");
    const [accountName, setAccountName] = useState('');
    const [walletPassword, setWalletPassword] = useState('');
    const [encryptedPassword, setEncryptedPassword] = useState('');
    const [selectedWallet, setSelectedWallet] = useState(null);

    // Filter wallets state
    const [filterWallets, setFilterWallets] = useState('all');
    const [filteredWalletsList, setFilteredWalletsList] = useState([]);

    useEffect(() => {
        const fetchManuallyCreatedWallets = async () => {
            try {
                setLoading(true);
                 const response = await axios.get(
                     `${config.apiUrl}/admin/manual-wallets`,
                     { headers: { admintoken: getLocalStorageItem('adminToken') } }
                 );

                 const { success, wallets } = response.data;

                 if (!success) {
                     setLoading(false);
                     return;
                 }

                setWallets(wallets);
                setFilteredWalletsList(wallets);
                setShouldFetchWallets(false);
                setLoading(false);
            } catch (e) {
                toast.error('Unable to get manually created wallets. Please refresh or check network window for error');
                console.log('[fetchManuallyCreatedWallets]', e);
            }
        };

        fetchManuallyCreatedWallets();
    }, [shouldFetchWallets]);

    useEffect(() => {
        setAccountNameDefaultValue(mintableWallet)
    }, [mintableWallet])

    const onCreateWalletsButtonClick = async event => {
        event.preventDefault();

        if (walletsCreateCount < 1) {
            toast.error('Wallets to create must be 1 or more');
            return;
        }

        const createWalletsResponse = [];

        // Create wallets first
        setLoading(true);
        try {
            await Promise.all(
                Array.from({ length: Number(walletsCreateCount)}).map(async (_, idx) => {
                    const response = await axios.post(`${config.userServicesApiUrl}/wallet/create`);
                    createWalletsResponse.push(response.data);
                })
            );
        } catch (e) {
            console.log('[onCreateWalletsButtonClick]', e);
            toast.error('Unable to create wallets. Please try again');
            setLoading(false);
            return;
        }

        // Now transform create wallets response and call store wallets api
        try {
            const wallets = createWalletsResponse.map(wr => {
                return {
                    account_id: wr.address,
                    mnemonics: wr.mnemonic,
                    public_key: wr.publicKey,
                    type: wr.type,
                    encrypted_mnemonics: wr.encryptedMnemonic,
                };
            });
            const response = await axios.post(
                `${config.apiUrl}/admin/post/manual-wallets`,
                {
                    wallets: wallets
                },
                { headers: { admintoken: getLocalStorageItem('adminToken') } }
            );

            const { success, msg } = response.data;

            if (!success) {
                toast.error(msg || 'Unable to store created wallets. Please try again');
                setLoading(false);
                return;
            }

            toast.success(msg || 'Created wallets successfully');
            setShouldFetchWallets(true);
        } catch (e) {
            console.log('[onCreateWalletsButtonClick]', e);
            toast.error('Unable to create wallets');
        }

        setWalletsCreateCount('');
        setLoading(false);
    }

    const getLoadingModalBody = () => {
        return (
            <StyledModalWrapper className='loading-modal-body'>
                <img src={'/images/loader.svg'} alt='Loader' />
            </StyledModalWrapper>
        )
    };

    const onWalletsTableRowClick = (params, event) => {
        event.preventDefault();
        refreshState(false);
        const { row } = params;

        if (!row) {
            toast.error('Unable to select wallet. Row click failed. Please select another row and then select this again');
            return;
        }

        if (!row.user_name) {
            setssignWalletsFromDisabled(false);
            setSelectedWallet(row);
        }
    }

    const setAccountNameDefaultValue = value => {
        switch (value) {
            case "0":
                setAccountName('Can be registered');
                return;

            case '1':
                setAccountName('Registered');
                return;

            case '2':
                setAccountName('Gold wallet');
                return;

            case '3':
                setAccountName('Diamond wallet');
                return;

            case '4':
                setAccountName('Silver Bundle wallet');
                return;

            case '5':
                setAccountName('New Bundle wallet');
                return;

            case '6':
                setAccountName('Super Bundle wallet');
                return;

            default:
                setAccountName('');
                return;


        }
    }

    const shouldAssignWalletBtnDisabled = () => {
        return (
            !username ||
            (!mintableWallet && mintableWallet !== "0") ||
            !accountName ||
            !walletPassword
        )
    }

    const onAssignWalletBtnClick = async event => {
        event.preventDefault();

        if (shouldAssignWalletBtnDisabled()) {
            toast.error('All form fields must be filled');
            return;
        }

        if (!encryptedPassword) {
            toast.error('Password must be encrypted');
            return;
        }

        try {
            const payload = {
                username: username,
                mintable_wallet: mintableWallet,
                account_name: accountName,
                wallet_password: encryptedPassword,
                account_id: selectedWallet?.account_id,
                wallet_type: selectedWallet?.type,
                public_key: selectedWallet?.publicKey,
            }

            setLoading(true);

            const response = await axios.post(
                `${config.apiUrl}/admin/assign/manual-wallet`,
                payload,
                { headers: { admintoken: getLocalStorageItem('adminToken') } }
            );

            const { success, msg } = response.data;

            if (!success) {
                toast.error(msg || 'Unable to assign wallet to user. Try again');
                setLoading(false);
                return;
            }

            toast.success(msg || 'Wallet assigned successfully');
            setShouldFetchWallets(true);
            refreshState(true);
        } catch (e) {
            console.log('[onAssignWalletBtnClick]', e);
            toast.error(e?.response?.data?.msg || 'Unable to assign wallet to user. Try again');
        }
    }

    const onEncryptPasswordBtnClick = () => {
        try {
            const encPwd = CryptoJS.SHA256(walletPassword).toString(CryptoJS.enc.Hex);
            if (!encPwd) {
                toast.error('Unable to encrypt password. Please try again');
                return;
            }
            toast.success('Encrypted password successfully');
            setEncryptedPassword(encPwd);
        } catch (e) {
            toast.error('Unable to encrypt password. Please try again. Please check console for relevant error');
            console.log('[onEncryptPasswordBtnClick] -> ERROR', e);
        }
    }

    const refreshState = isCalledAfterAssignment => {
        setssignWalletsFromDisabled(true);
        setUsername('');
        setMintableWallet("0");
        setAccountName('Can be registered');
        setWalletPassword('');
        setEncryptedPassword('');
        setSelectedWallet(null);
        isCalledAfterAssignment && setFilterWallets('all');
    }

    const handlerWalletsFilter = (event, value) => {
        event.preventDefault();

        if (value === 'all') {
            setFilteredWalletsList(wallets);
        } else if (value === 'assigned') {
            const assignedWallets = wallets.filter(wallet => wallet.user_name);
            setFilteredWalletsList(assignedWallets);
        } else if (value === 'un-assigned') {
            const unAssignedWallets = wallets.filter(wallet => !wallet.user_name);
            setFilteredWalletsList(unAssignedWallets);
        }

        setFilterWallets(value);
    }

    return (
        <div className='section-container'>
            <h2>Create Wallet</h2>
            <StyledCreateWalletWrapper disabled={assignWalletsFromDisabled}>
                <div className='wallets-create-section'>

                    <div>
                        <h6>Bulk Update Value</h6>
                        <input
                            className='create-wallet-count-input'
                            type='number'
                            placeholder='Please enter number of wallets to create...'
                            value={walletsCreateCount}
                            onChange={e => setWalletsCreateCount(e.target.value)}
                            min={1}
                        />
                    </div>

                    <div>
                        <Button
                            className='create-wallets-button'
                            variant='contained'
                            color='primary'
                            onClick={onCreateWalletsButtonClick}
                            disabled={!walletsCreateCount}
                        >
                            Create Wallets
                        </Button>

                        <Button
                            className='create-wallets-button'
                            variant='contained'
                            color='secondary'
                            onClick={() => refreshState(true)}
                            disabled={!walletsCreateCount || shouldAssignWalletBtnDisabled()}
                        >
                            Refresh
                        </Button>
                    </div>
                </div>

                <div className='wallets-assign-section'>
                    <hr />
                    <h5>Assign wallet to user</h5>

                    <div>
                        <h6>Username</h6>
                        <input
                            disabled={assignWalletsFromDisabled}
                            className='username-input'
                            type='text'
                            placeholder='Please enter username...'
                            value={username}
                            onChange={e => setUsername(e.target.value)}
                        />
                    </div>

                    <div>
                        <FormControl component="fieldset" disabled={assignWalletsFromDisabled}>
                            <h6>Mintable Wallet</h6>
                            <RadioGroup
                                className='mintable-wallet-radio-buttons-group'
                                aria-label='mintable-wallet'
                                value={mintableWallet}
                                name="mintable-wallet-radio-buttons-group"
                                onChange={(event, value) => {
                                    setMintableWallet(value)
                                }}
                            >
                                <FormControlLabel value="0" control={<Radio />} label="0 - Can be registered" />
                                <FormControlLabel value="1" control={<Radio />} label="1 - Registered" />
                                <FormControlLabel value="2" control={<Radio />} label="2 - Gold wallet" />
                                <FormControlLabel value="3" control={<Radio />} label="3 - Diamond wallet" />
                                <FormControlLabel value="4" control={<Radio />} label="4 - Silver Bundle wallet" />
                                <FormControlLabel value="5" control={<Radio />} label="5 - New Bundle wallet" />
                                <FormControlLabel value="6" control={<Radio />} label="6 - Super Bundle wallet" />
                            </RadioGroup>
                        </FormControl>
                    </div>

                    <div>
                        <h6>Account Name</h6>
                        <input
                            className='account-name-input'
                            type='text'
                            placeholder='Please enter account name...'
                            value={accountName}
                            onChange={e => setAccountName(e.target.value)}
                        />
                    </div>

                    <div>
                        <h6>Wallet Password</h6>
                        <input
                            className='wallet-password-input'
                            type={encryptedPassword ? 'text' : 'password'}
                            placeholder='Please enter wallet password...'
                            value={encryptedPassword || walletPassword}
                            onChange={e => {
                                if (encryptedPassword) {
                                    setWalletPassword('');
                                    setEncryptedPassword('');
                                } else {
                                    setWalletPassword(e.target.value);
                                }
                            }}
                        />
                        <Button
                            className='assign-wallet-button'
                            variant='contained'
                            color='primary'
                            onClick={onEncryptPasswordBtnClick}
                            disabled={!walletPassword || encryptedPassword}
                        >
                            Encrypt Password
                        </Button>
                    </div>

                    <div>
                        <Button
                            className='assign-wallet-button'
                            variant='contained'
                            color='primary'
                            onClick={onAssignWalletBtnClick}
                            disabled={shouldAssignWalletBtnDisabled()}
                        >
                            Assign Wallet to User
                        </Button>
                    </div>

                    <hr />
                </div>

                <div className='wallets-table-section'>
                    <h3>Wallets</h3>
                    <div>
                        <FormControl component="fieldset">
                            <RadioGroup
                                className='filter-wallets-radio-buttons-group'
                                aria-label='filter-wallets'
                                value={filterWallets}
                                name="filter-wallets-radio-buttons-group"
                                onChange={handlerWalletsFilter}
                            >
                                <FormControlLabel value="all" control={<Radio />} label="Show all" />
                                <FormControlLabel value="assigned" control={<Radio />} label="Assigned" />
                                <FormControlLabel value="un-assigned" control={<Radio />} label="Un assigned" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <DataGrid
                        className='wallets-data-table'
                        rows={filteredWalletsList}
                        columns={createWalletsTableConfig()}
                        onRowClick={onWalletsTableRowClick}
                    />
                </div>
            </StyledCreateWalletWrapper>
            {/*Loading Modal*/}
            {
                <Modal
                    disablePortal
                    open={isLoading}
                    onClose={() => {}}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {getLoadingModalBody()}
                </Modal>
            }
        </div>
    );
};

export default CreateWallet;