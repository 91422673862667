import styled from 'styled-components';

export const StyledKycLedgerWrapper = styled.div`
    .user-info-get-section {
      
      margin-bottom: 10px;
      
      .search-input {
        margin-bottom: 10px;
      }
      
      .search-user-button {
        margin-right: 10px;
      }
    }

    .ledger-x-user-id-input {
      margin-bottom: 10px;
    }
`